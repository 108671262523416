import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SchoolserviceService } from '../schoolservice.service';
import { DatePipe } from '@angular/common';
import { NavbarService } from '../default/navbar/navbar.service';

@Component({
  selector: 'app-editboarduser',
  templateUrl: './editboarduser.component.html',
  styleUrls: ['./editboarduser.component.scss'],
  providers: [DatePipe]
})
export class EditboarduserComponent implements OnInit {
  boarduserform: FormGroup;
  submitted = false;
  updateUserSatatus!:String;
  employeeid:String="";
  fullname:String="";
  username:String="";
  id:String="";
  enabledId:Boolean= true;
  role_type: any;
  constructor(public formbuilder:FormBuilder,public router:Router, public service:SchoolserviceService,public datepipe: DatePipe,public nav:NavbarService,private _routeParams: ActivatedRoute) {

    this.boarduserform = formbuilder.group({
      employee_id:['',Validators.required],
      board_user_fname :['',Validators.required],
      username:['',Validators.required],
     // password:['', Validators.required]

    })
   }

  ngOnInit(): void {

    this.role_type =  sessionStorage.getItem('role_type');
    if(this.role_type == 'boardadmin')
    {
      this.nav.hide();
      this.nav.dhide();
      this.nav.adshow();
      this.nav.aduserhide();
      this.nav.asdhide();
      this.nav.studhide();
      this.nav.XIstudhide();
    }
    else
    {
      this.nav.hide();
      this.nav.dhide();
      this.nav.adhide();
      this.nav.adusershow();
      this.nav.asdhide();
      this.nav.studhide();
      this.nav.XIstudhide();
    }

    var empid = this._routeParams.params.subscribe(params => {
      this.employeeid = String(params["employeeid"]);
       console.log("Employee Id: "+this.employeeid);  
       if (this.employeeid == "new") {
        return;
        }
      else {
          this.getCurrentEmployeeDetails();
      }

  });
  }
  getCurrentEmployeeDetails(){
    let schoolId: { } = {
       
    }
    
    this.service.post('/BoardApi/getAllBoarduser',schoolId).subscribe((allBoardUsers: any) => {
       console.log(allBoardUsers.Data[1].employeeid);
       var allBoardUsersLen=allBoardUsers.Data.length; 
      
       for(var i=0;i<allBoardUsersLen;i++){
          if(String(allBoardUsers.Data[i].employeeid)===this.employeeid){
            //alert(allBoardUsers.Data[i].username);
            this.id=allBoardUsers.Data[i].id;
            this.employeeid=allBoardUsers.Data[i].employeeid;
            this.fullname=allBoardUsers.Data[i].fullname;
            this.username=allBoardUsers.Data[i].username; 
          }
        }
    })
  }
  get personal() { return this.boarduserform.controls; }

  onsubmit(){

    this.submitted = true;
    if (this.boarduserform.invalid) {
      
      alert("Fill All Deatils");
      return;
    }
    else{
      this.saveupdatedBoardUser();
    }

  }
  saveupdatedBoardUser(){
    let updateBoardUser:{id:any,fullname:any,employeeid:any,username:any}={
      id:this.id,   
      fullname:this.boarduserform.value.board_user_fname,
      employeeid:this.boarduserform.value.employee_id,      
      username:this.boarduserform.value.username
    }
    this.service.post('/BoardApi/updateboarduser',updateBoardUser).subscribe((res: any) => {
      this.updateUserSatatus= res['status'];
      console.log(this.updateUserSatatus);
      if(this.updateUserSatatus == 'Success')
      {
        alert('Data Submitted');
        this.router.navigate(['/boarduserlist']);
        
      }
      else{alert('Data not Inserted');}
  });
  }

}
