import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { NavbarService } from '../default/navbar/navbar.service';
import { DataTableDirective } from 'angular-datatables';
import { Table } from 'primeng/table';
import { PrimeNGConfig } from 'primeng/api';
import { SchoolserviceService } from '../schoolservice.service';
import * as XLSX from 'xlsx';
import { Candidate } from '../candidates-details/candidate';
import { element } from 'protractor';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-updatestatus-candidate',
  templateUrl: './updatestatus-candidate.component.html',
  styleUrls: ['./updatestatus-candidate.component.scss']
  // providers: [DatePipe]
})
export class UpdatestatusCandidateComponent implements OnInit {

 // for table data
 @ViewChild('dt')
 table!: Table;
 students!: Candidate[];

 selectedCandidates!: Candidate[];
 schoolwisestudents: any;
  // getselectedids:any[]=[];
 loading: boolean = true;

 fileName= 'ExcelSheet.xlsx';
  schoolid: any;
  studentid: any;
  date!: Date;
  abc: any[]=[];
  selectedstatusvalue: any;
  selectstatus: any;
  getstatus: any;
  elevenschool: any;
  latest_date: any;
  convrtlastdate!:Date;
  lastdate: any;
  excepnldate: any;
  getday: any;
  elevnstudent: any;
  ninthstudent: any;
  standard: any;
  lastmonthdate:any;
  currmonth: any;
  currday: any;
  lastday:any
  
//select deselect on checkbox
checkAllRow(){
  
  
}
//on radio button select dropdown update
studentStatusList=['Select','Promoted','Detained','Promoted And Left','Detained And Left'];
// studentStatusList=['Select','Promoted','Detained','Left'];
registrationStatusList=['Select','New','Submitted','Approved'];
paymentStatusList=['Select','Paid','Not paid'];
dropdownList: string[] = [];
btnUpdate:boolean=true;
updateList(e:any){
  this.selectstatus = e.target.value;
  if(e.target.value=='StudentStatus'){
    this.dropdownList =this.studentStatusList;
  }
  if(e.target.value=='RegistrationStatus'){
    this.dropdownList =this.registrationStatusList;
  }
  if(e.target.value=='PaymentStatus'){
    this.dropdownList =this.paymentStatusList;
  }
}
enableBtnUpdate(e:any){ 
  this.selectedstatusvalue = e.target.value;
  // alert(this.selectedstatusvalue)
  if(e.target.value=="Select"){
   
    this.btnUpdate=true;
   }
  else{
  this.btnUpdate=false;
  }
}
//json data and functions for datatable

title = 'Candidate Details';

constructor(public service:SchoolserviceService,public nav:NavbarService,public datepipe: DatePipe,private primengConfig: PrimeNGConfig) { }


ngOnInit() {
  this.schoolid = sessionStorage.getItem('schoolid');
  this.standard = sessionStorage.getItem('Standard');
  console.log(this.standard);
  this.nav.hide();
  this.nav.dhide();
  this.nav.adhide();
  this.nav.asdshow();
  this.nav.studhide();
    this.nav.XIstudhide();
    this.nav.aduserhide();
  // primng table

//   this.service.getCustomersLarge().then((candidates:any) => {
//     this.candidates = candidates;
//     this.loading = false;
// });

this.date=new Date();
     this.latest_date = this.datepipe.transform(this.date, 'yyyy/MM/dd');

this.getallcandidates();

  this.primengConfig.ripple = true;





}

getEventValue($event:any) :string {
  return $event.target.value;
}

getidEventValue($event:any) :string {
  return $event.target.value;
}

getnameEventValue($event:any) :string {
  return $event.target.value;
}
getmiddleNameEventValue($event:any) :string {
  return $event.target.value;
}

getlastNameEventValue($event:any) :string {
  return $event.target.value;
}

getdivisionEventValue($event:any) :string {
  return $event.target.value;
}
getgenderEventValue($event:any) :string {
  return $event.target.value;
}
getresigstatusEventValue($event:any) :string {
  return $event.target.value;
}

getpaymentstatusEventValue($event:any) :string {
  return $event.target.value;
}
getpaymentIDEventValue($event:any) :string {
  return $event.target.value;
}



getallcandidates()
{
  let schoolinfo :{schoolid:any}
  ={
    schoolid:this.schoolid
  }

  this.service.post('/School/viewAllStudents',schoolinfo).subscribe((candidates:any) => {
    this.students = candidates.Data;
    // this.students.forEach(item => {
    //   item.checked = item.check === '0' ? true : false;
    // })
    this.loading = false;

    // console.log(this.students);
});

}
allselectRow()
{

    console.log(this.selectedCandidates);
    this.abc = [];
    for(var i=0;i<this.selectedCandidates.length;i++){
      //this.totalAmountCalculated=this.totalAmountCalculated+this.selectedCandidates[i].payment_amt;
         this.selectedCandidates[i].studentid;
        this.abc.push(this.selectedCandidates[i].studentid);
        // console.log(this.checkarray[i]);
        // console.log(this.selectedCandidates[i].studentid);  
    
    }
  
      // console.log(this.abc);
}
selectRow() {
  console.log(this.selectedCandidates);
  this.abc = [];
  for(var i=0;i<this.selectedCandidates.length;i++){
    //this.totalAmountCalculated=this.totalAmountCalculated+this.selectedCandidates[i].payment_amt;
       this.selectedCandidates[i].studentid;
      this.abc.push(this.selectedCandidates[i].studentid);
      // console.log(this.checkarray[i]);
      // console.log(this.selectedCandidates[i].studentid);  
  
  }

    console.log(this.abc);


}

// Update status
onSubmit()
{
  if(this.selectstatus === "StudentStatus")
  {
    let updatestatus :{schoolid:any, studentid:any,status:any}={
      schoolid:this.schoolid,
      studentid:this.abc,
      status:this.selectedstatusvalue
    }
   console.log(updatestatus);

   this.service.post('/School/updateStudentStatus',updatestatus).subscribe((res:any)=>{
     this.getstatus = res['status'];
     if(this.getstatus == 'Sucess')
     {
       alert('Student Status Updated Successfully');
       window.location.reload();
     }
     else{
      {
        alert('Student Status Not Updated');
        window.location.reload();
      }
     }
    console.log(res);

   });
  }
  else
  {

    let schoolinfo :{schoolid:any}={
      schoolid:this.schoolid,
      
    }
  

    this.service.post('/School/viewexceptionlastdateforSchools',schoolinfo).subscribe((res:any)=>{

      this.excepnldate = res['Data'];
      // console.log(this.excepnldate)     
    
      if(this.excepnldate == undefined || this.excepnldate == '')
      {
        this.elevnstudent = res['lastdateData'][0].student;
        this.ninthstudent = res['lastdateData'][1].student;
        console.log(this.ninthstudent)
        if(this.ninthstudent == this.standard)
        {
          this.ninthstudent = res['lastdateData'][1].lastdate;
          this.lastdate = this.datepipe.transform(this.ninthstudent, 'yyyy/MM/dd');

          
       var lastdate_year = new Date(this.lastdate).getFullYear();
       var lastdate_day = new Date(this.lastdate).getDate();
       var lastdate_month = new Date(this.lastdate).getMonth()+1;
       var latest_day = new Date(this.date).getDate();
       var latest_year = new Date(this.date).getFullYear();
       var latest_month =new Date(this.date).getMonth()+1;

      //  console.log('latest_day --- ' + latest_day +'lastdate_day ---- '+lastdate_day + 'latest_month --- '+
      //  latest_month + 'lastdate_month --- ' + lastdate_month);

         //  this.convrtlastdate = this.lastdate;
          // console.log(this.lastdate);
            // if(this.latest_date > this.lastdate)
            if(this.latest_date >= this.lastdate )
            {
              alert('Date is Lapsed');
              window.location.reload();
            }
            else
            {
                // alert('else')
              let updatestatus :{schoolid:any, studentid:any,regstatus:any}={
                schoolid:this.schoolid,
                studentid:this.abc,
                regstatus:this.selectedstatusvalue
              }
            
              console.log(updatestatus);
              
            this.service.post('/School/updateRegistrationStatus',updatestatus).subscribe((res:any)=>{
              this.getstatus = res['status'];
              if(this.getstatus == 'Sucess')
              {
                alert('Registration Status Updated Successfully');
                window.location.reload();
              }
              else
              {
                alert('Registration Status Not Updated, '+res['Msg']);
                window.location.reload();
              }
              
            // console.log(res);
          
            });
          
            }
        }
        else
        {
          this.elevenschool = res['lastdateData'][0].lastdate;
          this.lastdate = this.datepipe.transform(this.elevenschool, 'yyyy/MM/dd');
         //  this.convrtlastdate = this.lastdate;
         this.lastmonthdate = this.datepipe.transform(this.elevenschool, 'MM');
      //  var lastdate_year = new Date(this.elevenschool).getFullYear();
      //  var lastdate_day = new Date(this.elevenschool).getDate();
      //  var lastdate_month = new Date(this.elevenschool).getMonth()+1;
      //  var latest_day = new Date(this.latest_date).getDate();
      //  var latest_year = new Date(this.date).getFullYear();
      //  var latest_month =new Date(this.latest_date).getMonth()+1;

      // this.currday = this.datepipe.transform(this.latest_date, 'dd');
      // this.lastday = this.datepipe.transform(this.elevenschool, 'dd');
      //  this.currmonth = this.datepipe.transform(this.latest_date, 'MM');

      //  console.log('latest_day --- ' + this.currday +'lastdate_day ---- '+this.lastday + 'latest_month --- '+
      //  this.currmonth + 'lastdate_month --- ' + this.lastmonthdate);
          // console.log(this.lastdate);
          // console.log(this.latest_date);
          // console.log(this.elevenschool);
      //  if(latest_month >= latest_month)
      //  if(lastdate_month >= latest_month)
            if(this.latest_date >= this.lastdate )
            // if(this.currmonth >= this.lastmonthdate)
            // if(this.currday <= this.lastday)
            {
              alert('Date is Lapsed');
              window.location.reload();
            }
            else
            {
                // alert('else')
              let updatestatus :{schoolid:any, studentid:any,regstatus:any}={
                schoolid:this.schoolid,
                studentid:this.abc,
                regstatus:this.selectedstatusvalue
              }
            
              console.log(updatestatus);
              
            this.service.post('/School/updateRegistrationStatus',updatestatus).subscribe((res:any)=>{
              this.getstatus = res['status'];
              if(this.getstatus == 'Sucess')
              {
                alert('Registration Status Updated Successfully');
                window.location.reload();
              }
              else{
              {
                alert('Registration Status Not Updated, '+res['Msg']);
                window.location.reload();
              }
              }
            console.log(res);
          
            });
          
            }
        }
       
      }
      else
      {
        let updatestatus :{schoolid:any, studentid:any,regstatus:any}={
          schoolid:this.schoolid,
          studentid:this.abc,
          regstatus:this.selectedstatusvalue
        }
      
        console.log(updatestatus);
        
       this.service.post('/School/updateRegistrationStatus',updatestatus).subscribe((res:any)=>{
        this.getstatus = res['status'];
        if(this.getstatus == 'Sucess')
        {
          alert('Registration Status Updated Successfully');
          window.location.reload();
        }
        else{
         {
          alert('Registration Status Not Updated, '+res['Msg']);
           window.location.reload();
         }
        }
       console.log(res);
    
      });
      }
      

      })

  

  //   let updatestatus :{schoolid:any, studentid:any,regstatus:any}={
  //     schoolid:this.schoolid,
  //     studentid:this.abc,
  //     regstatus:this.selectedstatusvalue
  //   }
  
  //   console.log(updatestatus);
    
  //  this.service.post('/School/updateRegistrationStatus',updatestatus).subscribe((res:any)=>{
  //   this.getstatus = res['status'];
  //   if(this.getstatus == 'Sucess')
  //   {
  //     alert('Registration Status Updated Successfully');
  //     //window.location.reload();
  //   }
  //   else{
  //    {
  //      alert('Registration Status Not Updated');
  //      //window.location.reload();
  //    }
  //   }
  //  console.log(res);

  // });


  }

}
// Export to excel file

exportexcel():void 
{
   /* table id is passed over here */   
   let element = document.getElementById('excel-table'); 
 
  
   const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

   /* generate workbook and add the worksheet */
   const wb: XLSX.WorkBook = XLSX.utils.book_new();
   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

   /* save to file */
   XLSX.writeFile(wb, this.fileName);

  
  
}

}
