import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SchoolserviceService } from '../schoolservice.service';
import { NavbarService } from '../default/navbar/navbar.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-update-stud-status-boardadmin',
  templateUrl: './update-stud-status-boardadmin.component.html',
  styleUrls: ['./update-stud-status-boardadmin.component.scss']
})
export class UpdateStudStatusBoardadminComponent implements OnInit {
  role_type: any;
  selctform !: FormGroup;
  addprimaryform !: FormGroup;
  studentprofileform !: FormGroup;
  issubmitted = false;
  issubmitted1 = false;
  showdiv: boolean=false;
  userData:any;
  userData2:any;
  constructor(public router:Router,public service:SchoolserviceService,public nav:NavbarService,public formbuilder:FormBuilder) {
    this.addprimaryform = this.formbuilder.group({
      username:['',Validators.required],
    });
    this.studentprofileform = this.formbuilder.group({
      stname:[''],
      ftname:[''],
      mtname:[''],
      dob:[''],
      gender:[''],
      email:[''],
      mobile_no:[''],
      caste:[''],
      stud_status:[''],
      scode:[''],
      U_stud_status:['',Validators.required],
    })
  }
  
  get addpersonal1() { return this.addprimaryform.controls; }
  get personal() { return this.studentprofileform.controls; }
  ngOnInit(): void {
    this.role_type =  sessionStorage.getItem('role_type');
    if(this.role_type == 'boardadmin')
    {
      this.nav.hide();
      this.nav.dhide();
      this.nav.adshow();
      this.nav.aduserhide();
      this.nav.asdhide();
      this.nav.studhide();
      this.nav.XIstudhide();
    }
    else
    {
      this.nav.hide();
      this.nav.dhide();
      this.nav.adhide();
      this.nav.adusershow();
      this.nav.asdhide();
      this.nav.studhide();
      this.nav.XIstudhide();
    }
    
  }
  Onsearchcandidate()
  {
            this.issubmitted =true;
            if (this.addprimaryform.invalid) {
            
              alert("Please Fill All Details");
              return;
          
            }
            let usernm :{username:any}={
              
              username:this.addprimaryform.controls['username'].value,
        
              
            }
          this.service.post('/BoardApi/change_stud_status_view',usernm).subscribe((res:any)=>{
      
            if(res['Status'] == 'Success')
            {
              // alert(res.Msg);
              this.showdiv=true;
              this.userData= res['Data'];
              this.userData2= res['Data2'];
  
                      this.studentprofileform.controls['stname'].setValue(this.userData[0].stname);
                      this.studentprofileform.controls['ftname'].setValue(this.userData[0].ftname);
                      this.studentprofileform.controls['mtname'].setValue(this.userData[0].mtname);
                      this.studentprofileform.controls['gender'].setValue(this.userData[0].gender);
                      this.studentprofileform.controls['dob'].setValue(this.userData[0].dob);
                      this.studentprofileform.controls['email'].setValue(this.userData[0].email);
                      this.studentprofileform.controls['mobile_no'].setValue(this.userData[0].mobile_no);
                      this.studentprofileform.controls['caste'].setValue(this.userData[0].caste);
                      this.studentprofileform.controls['stud_status'].setValue(this.userData2[0].studentstatus);
                      this.studentprofileform.controls['scode'].setValue(this.userData2[0].schoolid);
  
            }
            else{
              alert(res.Msg);
              window.location.reload();
            }
          });
   }
   Onsavestatus(){
    this.issubmitted1 =true;
    if (this.studentprofileform.invalid) {
    
      alert("Please Fill Required Details");
      return;
  
    }
    let c=confirm("Are you sure to change status! Click ok to Change.");
    if(c==true){
      let userinfo :{username:any, updated_staus:any}={
              
        username:this.addprimaryform.controls['username'].value,
        updated_staus: this.studentprofileform.controls['U_stud_status'].value,    
        
      }
    this.service.post('/BoardApi/change_stud_status',userinfo).subscribe((res:any)=>{
    
          if(res['Status'] == 'Success')
          {
            alert(res.Msg);
            window.location.reload();
          }
          else{
            alert(res.Msg);
            window.location.reload();
          }
        });
    }

   }
   clearform(){
    this.addprimaryform.reset;
    this.showdiv=false;
   }
}
