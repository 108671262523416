<div style="margin-top: 2rem;"></div>
<div class="container">
    <div class="dashboardCont">
        <div class="row" *ngIf="standard =='9th' ; else geteleventh">
                <div class="col-sm-6" *ngIf="enrolid !='undefined' ; else enrolpage">
                    <a  href="" [ngClass]="active911 ? 'disabled' : 'null'"  [routerLink]="['/schooldashboard']"  style="text-decoration: none; ">
                                <div class="card cardCont cardcustom" >
                                    <button type="button" [attr.disabled]="active911?true:null" class="btn primary cardbtn">Click Here for 9th</button>
                                </div>
                    </a>
                </div>
                <ng-template #enrolpage >
                    <div class="col-sm-6">
                        <a  href="" [ngClass]="active911 ? 'disabled' : 'null'"  [routerLink]="['/School_Enroll_Students']"  style="text-decoration: none; ">
                                    <div class="card cardCont cardcustom" >
                                        <button type="button" [attr.disabled]="active911?true:null" class="btn primary cardbtn">Click Here for 9th</button>
                                    </div>
                        </a>
                    </div>
                </ng-template>


                <div class="col-sm-6" *ngIf="enrolid !='undefined' ; else alertmsg">
                    <a [ngClass]="active1012 ? 'disabled' : 'null'" href="https://service1.gbshse.in/school_panel_10_12"  style="text-decoration: none; color: #05a7c5c2;">
                    <!-- <a [ngClass]="active1012 ? 'disabled' : 'null'" href="https://ztstservice.gbshse.in/school_panel_10_12"  style="text-decoration: none; color: #05a7c5c2;"> -->
                    <!-- <a href="https://service1.gbshse.in/school_panel_10_12"  style="text-decoration: none; color: #05a7c5c2;"> -->
                        <div class="card cardCont  cardcustom">
                            <button type="button" [attr.disabled]="active1012?true:null" class="btn primary cardbtn">Click Here for 10th</button>
                       </div>
                    </a>
                </div>
                <ng-template #alertmsg>
                    <div class="col-sm-6" >
                        <a  [ngClass]="active1012 ? 'disabled' : 'null'" style="text-decoration: none; color: #05a7c5c2;">
                            <div class="card cardCont  cardcustom">
                                <button [attr.disabled]="active1012?true:null" type="button" class="btn primary cardbtn" disabled>Click Here for 10th</button>
                           </div>
                           <div style="font-size: 14px; font-weight: 650; color: brown;"> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                            *Note:Please Enrol for 9th standard First</div>
                        </a>
                    </div>
                </ng-template>
                
        </div>
        <!-- for 11th & 12th -->
        <ng-template #geteleventh>
                <div class="row">
                    <div class="col-sm-6" *ngIf="enrolid !='undefined' ; else enrolpage">
                        <a href="" [ngClass]="active911 ? 'disabled' : 'null'"   [routerLink]="['/schooldashboard']"  style="text-decoration: none;">
                                <div class="card cardCont  cardcustom">
                                    <button type="button" [attr.disabled]="active911?true:null" class="btn primary cardbtn">Click Here for 11th</button>
                                </div>
                        </a>
                    </div>
                    <ng-template #enrolpage >
                        <div class="col-sm-6">
                            <a  href="" [ngClass]="active911 ? 'disabled' : 'null'"  [routerLink]="['/School_Enroll_Students']"  style="text-decoration: none; ">
                                        <div class="card cardCont cardcustom" >
                                            <button type="button"  [attr.disabled]="active911?true:null" class="btn primary cardbtn">Click Here for 11th</button>
                                        </div>
                            </a>
                        </div>
                    </ng-template>


                    <div class="col-sm-6" *ngIf="enrolid !='undefined' ; else alertmsg">
                        <a [ngClass]="active1012 ? 'disabled' : 'null'"  href="https://service1.gbshse.in/school_panel_10_12"  style="text-decoration: none; color: #05a7c5c2;">
                        <!-- <a [ngClass]="active1012 ? 'disabled' : 'null'"  href="https://ztstservice.gbshse.in/school_panel_10_12"  style="text-decoration: none; color: #05a7c5c2;"> -->
                        <!-- <a  href="https://service1.gbshse.in/school_panel_10_12"  style="text-decoration: none; color: #05a7c5c2;"> -->
                            <div class="card cardCont  cardcustom">
                                <button type="button" [attr.disabled]="active1012?true:null" class="btn primary cardbtn">Click Here for 12th</button>
                            </div>
                        </a>
                    </div>
                    <ng-template #alertmsg>
                        <div class="col-sm-6">
                            <a [ngClass]="active1012 ? 'disabled' : 'null'"   style="text-decoration: none; color: #05a7c5c2;">
                                <div class="card cardCont  cardcustom">
                                    <button type="button" [attr.disabled]="active1012?true:null" class="btn primary cardbtn" disabled>Click Here for 12th</button>
                               </div>
                               <div style="font-size: 14px; font-weight: 650; color: brown;"> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                                *Note:Please Enrol for 11th standard First</div>
                            </a>
                        </div>
                    </ng-template>
                </div>
        </ng-template>
    </div>
</div>