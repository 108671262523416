import { Component, OnInit } from '@angular/core';
import { NavbarService } from '../default/navbar/navbar.service';
@Component({
  selector: 'app-board-enroll-school',
  templateUrl: './board-enroll-school.component.html',
  styleUrls: ['./board-enroll-school.component.scss']
})
export class BoardEnrollSchoolComponent implements OnInit {
  btnEnrollDisable:boolean=true;
  schoolName!: string;
  schoolList:Array<any>=[
    " ",
    "school Name 1",
    "school Name 2 XYZ",
    "school Name 3 PQR",
    "school Name 4 LMN", 
    "school Name 5 LMN",
    "Name of the school 6",
    "Name of the school 7 ABC",
    "Name of the school 8 XYZ",
  ];
  role_type: any;
  constructor(public nav:NavbarService) { }

  ngOnInit(): void {
    this.role_type =  sessionStorage.getItem('role_type');
    if(this.role_type == 'boardadmin')
    {
      this.nav.hide();
      this.nav.dhide();
      this.nav.adshow();
      this.nav.aduserhide();
      this.nav.asdhide();
      this.nav.studhide();
      this.nav.XIstudhide();
    }
    else
    {
      this.nav.hide();
      this.nav.dhide();
      this.nav.adhide();
      this.nav.adusershow();
      this.nav.asdhide();
      this.nav.studhide();
      this.nav.XIstudhide();
    }

    
  }
  enableBtnEnroll(e:any){
    if(e.target.checked){
      this.btnEnrollDisable=false;
    }
    else{
      this.btnEnrollDisable=true;
    }
  }

  
}
