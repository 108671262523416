import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SchoolserviceService } from '../schoolservice.service';
import { DatePipe } from '@angular/common';
import { NavbarService } from '../default/navbar/navbar.service';

@Component({
  selector: 'app-viewschooldetails',
  templateUrl: './viewschooldetails.component.html',
  styleUrls: ['./viewschooldetails.component.css'],
  providers: [DatePipe]
})
export class ViewschooldetailsComponent implements OnInit {
  schoolid!: String;
  schoolName!:String;
  schoolAdd!:String;
  school_ContactNo!:number;
  schoolPayAmt!:number;
  schoolPaySatus!:String;
  schoolPayMode!:String;
  role_type: any;
  principal: any;

  constructor(public formbuilder:FormBuilder,public router:Router, public service:SchoolserviceService,public datepipe: DatePipe,public nav:NavbarService,private _routeParams: ActivatedRoute) {

    
  }

  ngOnInit(): void {

    this.role_type =  sessionStorage.getItem('role_type');
    if(this.role_type == 'boardadmin')
    {
      this.nav.hide();
      this.nav.dhide();
      this.nav.adshow();
      this.nav.aduserhide();
      this.nav.asdhide();
      this.nav.studhide();
      this.nav.XIstudhide();
    }
    else
    {
      this.nav.hide();
      this.nav.dhide();
      this.nav.adhide();
      this.nav.adusershow();
      this.nav.asdhide();
      this.nav.studhide();
      this.nav.XIstudhide();
    }


    var id = this._routeParams.params.subscribe(params => {
      this.schoolid = String(params["schoolid"]);
       console.log("School Id: "+this.schoolid);  
       if (this.schoolid == "new") {
        return;
    }
    else {
         this.getCurrentSchoolDetails();
    }
     
     
  });

  }
  
  getCurrentSchoolDetails(){
    let schoolid :{schoolid:any}={
      schoolid:this.schoolid
    }
    this.service.post('/BoardApi/getSchoolDetails',schoolid).subscribe((currentSchoolData: any) => {
      this.schoolName=currentSchoolData['Data'][0].schoolname;
      this.schoolAdd=currentSchoolData['Data'][0].schooladdress;
      this.principal=currentSchoolData['Data'][0].principal;
      this.school_ContactNo=currentSchoolData['Data'][0].mobileno1;
      
   
})
 this.service.post('/BoardApi/viewPaymentDetailsofSchool',schoolid).subscribe((currentSchoolPayData: any) => {
      console.log(currentSchoolPayData);
      this.schoolPayAmt=currentSchoolPayData['Data'][0].amount;
      this.schoolPaySatus=currentSchoolPayData['Data'][0].status;
      this.schoolPayMode=currentSchoolPayData['Data'][0].payment_type;
 })

  }
}
