import { Component} from '@angular/core';
import { Router } from '@angular/router';
import { ChartType } from 'angular-google-charts';
import { NavbarService } from '../default/navbar/navbar.service';
import { SchoolserviceService } from '../schoolservice.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-schoollanding',
  templateUrl: './schoollanding.component.html',
  styleUrls: ['./schoollanding.component.scss']
})
export class SchoollandingComponent{
  
  schoolid: any;
  standard: any;
  enrolid:any;
  password_status:any ="";
  appsetings: any;
  active911: boolean=true;
  active1012: boolean=true;
  constructor(public nav:NavbarService,public router:Router,public datepipe:DatePipe, public service:SchoolserviceService) { }

  ngOnInit(): void {

    this.nav.show();
    this.nav.asdshow();
    this.nav.asdhide(); //schooladmin
     this.nav.hide();
    this.schoolid = sessionStorage.getItem('schoolid');
    this.standard = sessionStorage.getItem('Standard');
    this.enrolid = sessionStorage.getItem('enrollmentid');
    this.enrolid = sessionStorage.getItem('enrollmentyear');
    //  console.log(this.enrolid);

    if(window.sessionStorage.getItem('schoolid') && window.sessionStorage.getItem('username') && window.sessionStorage.getItem('enrollmentid')){

    
    let enrol:  {schoolid:any}={
      schoolid:window.sessionStorage.getItem('schoolid'),
    }
  this.service.post('/School/schoolpassword_status',enrol).subscribe((res:any)=>{
    if(res['status'] == 'Success')
    {
        this.password_status=res.Data[0].password_status;
        // alert(this.password_status);
        if(this.password_status== "New")
        {
          window.sessionStorage.setItem('status',this.password_status);
          alert('Kindly Change your password to proceed to complete your Application');
          this.router.navigate(['/ontimechangepassword_school']);
        }
    }
    else{
      alert('Not able to process, login again');
       this.logout();
    }
  });
  }//if end
  else{
    alert('Plaese login');
    this.logout();
  }
  this.getappsettings();
  }
 logout(){
    window.sessionStorage.clear();
    // window.location.reload();
    this.router.navigate(['/home']);
  }
   //teja 30may24
   getappsettings()
   {
     let seq = this.service.post('/BoardApi/check_app_status',{});
     seq.subscribe((res:any) => {
      if(res.Status=="Success"){
        this.appsetings = res.Data; 
        for(let i=0;i<this.appsetings.length;i++){
          if(this.appsetings[i].config_name=="app_9_11" && this.appsetings[i].status=="active") {
              this.active911=false;
              // alert("911 active")
            }
          
           if(this.appsetings[i].config_name=="app_9_11" && this.appsetings[i].status=="inactive") {
              this.active911=true;
              // alert("911 inactive")
            } 
           if(this.appsetings[i].config_name=="app_10_12" && this.appsetings[i].status=="active") {
              this.active1012=false;
              // alert("1012 active")
            } 
           if(this.appsetings[i].config_name=="app_10_12" && this.appsetings[i].status=="inactive") {
              this.active1012=true;
              // alert("1012 inactive")
            } 
            
        }  
        if(this.active911){
          sessionStorage.setItem("active911","true");
        }else{
          sessionStorage.setItem("active911","false");
        }
        if(this.active1012){
          sessionStorage.setItem("active1012","true");
        }else{
          sessionStorage.setItem("active1012","false");
        }
       
      }
      else{
        alert("Error for loading");
        window.location.reload();
      }
   });
   }
}
