import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SchoolserviceService } from '../schoolservice.service';
import { NavbarService } from '../default/navbar/navbar.service';
@Component({
  selector: 'app-editcandidatedetails',
  templateUrl: './editcandidatedetails.component.html',
  styleUrls: ['./editcandidatedetails.component.css']
})
export class EditcandidatedetailsComponent implements OnInit {

  studentprofileform: FormGroup;
  captcha: any;
  alphaNums = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  emptyArr:any = [];
  refreshArr :any = [];
  loginuser: any;
  submitted = false;
  studentid:any;
  userData: any;
  photo_upload_success:boolean=false;
  signature_upload_success: boolean = false;
  date: Date | undefined;
  uploadResponse: any;
  isdisable: boolean = false;
  reviewArray!: any[];
  
  subject:any;
  subject1:any;
  subject2:any;
  subject3:any;
  subject4:any;
  subject5:any;
  subject6:any;
  subject7: any;
  subject8: any;
  subject9: any;
  subject10: any;
  subject11: any;
  subject12: any;
  selectedLevel:any;
  studschool: any;
  subjectmap: any;
  validated_subject =false;
  subj_array: any;
  subjectlist: any;
  // searchform: FormGroup;
  // studentid: any;
  loading: boolean = false;
  schoolid: any;
  photoinfo: any;
  signinfo: any;
  msg: any;
  alphmsg:any;
  last_date_11thstudent_regi: any;
  last_date_9thstudent_regi: any;
  latest_date: any;
  dob: any;
  status: any;
  msgs: any;
  boardstatus: any;
  aadharmsg: any;
  conmsgs: any;
  
  username:any;
  studata:any;
  distmsg: any;
  schoolmsg: any;
  pinmsg:any;
  dob_stud: any;
  alltalukas: any;
  
  constructor(public formbuilder:FormBuilder,public router:Router,private _routeParams: ActivatedRoute, public service:SchoolserviceService,public nav:NavbarService) {
    
    this.studentprofileform = formbuilder.group({
      class:[''],
      rollno:[''],
      division:[''],
      stname:['',Validators.required],
      ftname:['',Validators.required],
      mtname:['',Validators.required],
      dob:['',Validators.required],
      gender:['',Validators.required],
      email:['',[Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      mobile_no:['',[Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      alt_mobile_no:[''],
      caste:['',Validators.required],
      street:['',Validators.required],
      locality:[''],
      city:['',Validators.required],
      state:['',Validators.required],
      taluka:['',Validators.required],
      district:['',Validators.required],
      landmark:[''],
      pincode:['',Validators.required],
      house_ownership:[''], 
      internet_coverage:[''],
      distance_coverage:[''],
      access_to_smart_phone:[''],
      access_internet:[''],
      distance_of_school_to_house:[''],
      mode_of_transport:[''],
      security_ques:[''],
      security_ans:[''],
      fathers_qualification:[''],
      fathers_job:[''],
      fathers_annual_income:[''],
      mothers_qualification:[''],
      mothers_job:[''],
      mothers_annual_income:[''],
      photo:[''],
      signature:[''],
      subject_one:['',Validators.required],
      subject_two:['',Validators.required],
      subject_three:['',Validators.required],
      subject_four:['',Validators.required],
      subject_five:['',Validators.required],
      subject_six:['',Validators.required],
      subject_seven:[''],
      subject_eight:[''],
      subject_nine:[''],
      subject_ten:[''],
      subject_eleven:[''],
      subject_twelve:[''],
      subject_thirteen:[''],
      // subject_ten:['',Validators.required],
      // subject_eleven:['',Validators.required],
      // subject_twelve:['',Validators.required],
      // subject_thirteen:['',Validators.required],
      captcha_text:['',Validators.required]

      })
   }

  ngOnInit(): void {
    this.nav.hide();
    this.nav.dhide();
    this.nav.adhide();
    this.nav.asdshow();
    this.nav.studhide();
    this.nav.XIstudhide();
    this.nav.aduserhide();

    this.schoolid = sessionStorage.getItem('schoolid');
    this.username =sessionStorage.getItem('username');
    this.studentprofileform.controls.class.setValue('9th');
    console.log(this.username);

    var id = this._routeParams.params.subscribe(params => {

      var studentid = String(params["studentid"]);
  
      this.studentid = String(params["studentid"]);
       console.log(this.studentid);
      // alert(seat);
      if (studentid == "new") {
          return;
      }
      else {
          this.loading = true;
          
      this.getstudentclassForm();
      this.getstudentform();
      this.getselectedsubject();
      this.getalltalukas();
          this.loading = false;
      }
  });
   
  this.getsubjects();

    for (let i = 1; i <= 6; i++) {
      this.emptyArr.push(this.alphaNums[Math.floor(Math.random() * this.alphaNums.length)]);
      }
       this.captcha = this.emptyArr.join('');
  
       console.log(this.captcha);
       
  }

      // for validation
      get personal() { return this.studentprofileform.controls; }
  // This event listener is stimulated whenever the user press the "Refresh" button
  refresh()
  {
    // form.captcha_text = "";
    // let refreshArr = [];
    for (let j = 1; j <= 7; j++) {
      
    this.refreshArr.push(this.alphaNums[Math.floor(Math.random() * this.alphaNums.length)]);
    // this.emptyArr.splice(0,this.emptyArr.length);
    
    }

    this.captcha = this.refreshArr.join('');
    this.refreshArr.splice(0,this.refreshArr.length)
    // console.log(this.captcha);


  }

  onPhotoSelect(event:any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.studentprofileform.controls.photo.setValue(file);
    }
  }

  onsignatureSelect(event:any)
  { 
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.studentprofileform.controls.signature.setValue(file);
    }

  }

 // for validattion
 allowNumericforpincode(e: { which: any; keyCode: any; }) {		
  const charCode = e.which ? e.which : e.keyCode;
  
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    this.pinmsg = '<span class="pinmsg">OOPs! Only Numbers are allow</span>';
    return false;
  }
  else
  {
    this.pinmsg = '<span class="pinmsg"></span>';
    return true;
  }
}
 allowNumericDigitsOnlyOnKeyUp(e: { which: any; keyCode: any; }) {		
  const charCode = e.which ? e.which : e.keyCode;
  
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    this.msg = '<span class="msg">OOPs! Only Numbers are allow</span>';
  }
  else
  {
    this.msg = '<span class="msg"></span>';
  }
}
allowNumeric(e: { which: any; keyCode: any; }) {		
  const charCode = e.which ? e.which : e.keyCode;


  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    this.conmsgs = '<span class="msg">OOPs! Only Numbers are allow</span>';
  }
  else
  {
    this.conmsgs = '<span class="msg"></span>';
  }
}

allowNumericforaadhar(e: { which: any; keyCode: any; }) {		
  const charCode = e.which ? e.which : e.keyCode;
  
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    this.aadharmsg = '<span class="msg">OOPs! Only Numbers are allow</span>';
  }
  else
  {
    this.aadharmsg = '<span class="msg"></span>';
  }
}

allowNumericDfordistance(e: { which: any; keyCode: any; }) {		
  const charCode = e.which ? e.which : e.keyCode;
  
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    this.distmsg = '<span class="distmsg">OOPs! Only Numbers are allow</span>';
    return false;
  }
  else
  {
    this.distmsg = '<span class="distmsg"></span>';
    return true;
  }
}

allowNumericDigitschool(e: { which: any; keyCode: any; }) {		
  const charCode = e.which ? e.which : e.keyCode;
  
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    this.schoolmsg = '<span class="schoolmsg">OOPs! Only Numbers are allow</span>';
    return false;
  }
  else
  {
    this.schoolmsg = '<span class="schoolmsg"></span>';
    return true;
  }
}

keyPressAlphanumeric(event: { keyCode: number; preventDefault: () => void; }) {

  var inp = String.fromCharCode(event.keyCode);

  if (/[a-zA-Z]/.test(inp)) {
    // return true;
    this.alphmsg = '<span class="msg"></span>';
  } else {
    event.preventDefault();
    // return false;
   
    this.alphmsg = '<span class="msg">OOPs! Only Alphabetic are allow</span>';
  }
}
  // select stream
  // streamChanged(value: any)
  // {
  //   this.streamvalue = value;
  //   console.log(this.streamvalue);
  // }

  // select board

  // boardChanged(value: any)
  // {
  //   this.boardvalue = value;
  //   console.log(this.boardvalue);
  // }

  getstudentclassForm()
  {
      let user:{studentid:any}={
      studentid:this.studentid
    }
    let seq = this.service.post('/StudentApi9/getstudentclassForm',user);
     seq.subscribe((res:any) => {
       if(res.status=="success"){
         this.studschool= res['studschool'];
        
          if(sessionStorage.getItem('schoolid')==this.studschool[0].schoolid){
                this.studentprofileform.controls['rollno'].setValue(this.studschool[0].rollno);
                this.studentprofileform.controls['division'].setValue(this.studschool[0].division);
              }
              else{
                alert("Sorry!! You Can only View/Edit records of Your School!");
                this.router.navigate(['/candidates-details']);
              }
        }
        else{
          alert("The system detected an incorrect operation!!");
          this.router.navigate(['/candidates-details']);
        }            
     });
  }
  getstudentform()
  {
    let user:{studentid:any}={
      studentid:this.studentid
    }
    let seq = this.service.post('/StudentApi9/getstudentForm',user);
     seq.subscribe((res:any) => {
      if(res.status=="success"){    
             this.userData= res['Data'];
            
             this.subjectmap= res['subjectmap'];
            //  console.log(this.userData[0].security_ques);
            // this.studentprofileform.controls['class'].setValue(this.studschool[0].standard);
            // this.studentprofileform.controls['division'].setValue(this.studschool[0].division);
             this.studentprofileform.controls['stname'].setValue(this.userData[0].stname);
             this.studentprofileform.controls['ftname'].setValue(this.userData[0].ftname);
             this.studentprofileform.controls['mtname'].setValue(this.userData[0].mtname);
             //changeDOBformat
             this.userData[0].dob = this.userData[0].dob.split('-');
             if(this.userData[0].dob[0] < 10 && this.userData[0].dob[0][0] != 0){
               this.userData[0].dob[0] = '0'+this.userData[0].dob[0];
             }
             if(this.userData[0].dob[1] < 10 && this.userData[0].dob[1][0] != 0){
               this.userData[0].dob[1] = '0'+this.userData[0].dob[1];
             }
             this.userData[0].dob = this.userData[0].dob[2]+'-'+this.userData[0].dob[1]+'-'+this.userData[0].dob[0];
             console.log( this.userData[0].dob);
             this.studentprofileform.controls['dob'].setValue(this.userData[0].dob);
             //changeDOBformat
            //  this.studentprofileform.controls['dob'].setValue(this.userData[0].dob);
             this.studentprofileform.controls['gender'].setValue(this.userData[0].gender);
             this.studentprofileform.controls['email'].setValue(this.userData[0].email);
             this.studentprofileform.controls['mobile_no'].setValue(this.userData[0].mobile_no);
             this.studentprofileform.controls['alt_mobile_no'].setValue(this.userData[0].alt_mobile_no);
             this.studentprofileform.controls['caste'].setValue(this.userData[0].caste);
             this.studentprofileform.controls['street'].setValue(this.userData[0].street);
             this.studentprofileform.controls['locality'].setValue(this.userData[0].locality);
             this.studentprofileform.controls['city'].setValue(this.userData[0].city);
             this.studentprofileform.controls['state'].setValue(this.userData[0].state);
             this.studentprofileform.controls['taluka'].setValue(this.userData[0].taluka);
             this.studentprofileform.controls['district'].setValue(this.userData[0].district);
             this.studentprofileform.controls['landmark'].setValue(this.userData[0].landmark);
             this.studentprofileform.controls['pincode'].setValue(this.userData[0].pincode);
             this.studentprofileform.controls['house_ownership'].setValue(this.userData[0].house_ownership);
             this.studentprofileform.controls['internet_coverage'].setValue(this.userData[0].internet_coverage);
             this.studentprofileform.controls['distance_coverage'].setValue(this.userData[0].distance_coverage);
             this.studentprofileform.controls['access_to_smart_phone'].setValue(this.userData[0].access_to_smart_phone);
             this.studentprofileform.controls['access_internet'].setValue(this.userData[0].access_internet);
             this.studentprofileform.controls['distance_of_school_to_house'].setValue(this.userData[0].distance_of_school_to_house);
             this.studentprofileform.controls['mode_of_transport'].setValue(this.userData[0].mode_of_transport);
             this.studentprofileform.controls['security_ques'].setValue(this.userData[0].security_ques);
             this.studentprofileform.controls['security_ans'].setValue(this.userData[0].security_ans);
             this.studentprofileform.controls['fathers_qualification'].setValue(this.userData[0].fathers_qualification);
             this.studentprofileform.controls['fathers_job'].setValue(this.userData[0].fathers_job);
             this.studentprofileform.controls['fathers_annual_income'].setValue(this.userData[0].fathers_annual_income);
             this.studentprofileform.controls['mothers_qualification'].setValue(this.userData[0].mothers_qualification);
             this.studentprofileform.controls['mothers_job'].setValue(this.userData[0].mothers_job);
             this.studentprofileform.controls['mothers_annual_income'].setValue(this.userData[0].mothers_annual_income);
           
             this.signinfo = this.userData[0].signature;
             this.photoinfo = this.userData[0].photo;

             this.studentprofileform.controls['signature'].setValue(this.signinfo);
              this.studentprofileform.controls['photo'].setValue(this.photoinfo); 
            
      }
      else{
        alert("The system detected an incorrect operation, \n EitherSpecified Student Is Not Registred With This School \n or Student Data is not available");
        this.router.navigate(['/candidates-details']);
      }

     });
  }
  // get all selected subject from student
  getselectedsubject()
  {
    let user:{studentid:any}={
      studentid:this.studentid
    }
      let seq = this.service.post('/StudentApi9/getstudentselectedsubject',user);
      seq.subscribe((res:any)=>{
        this.subjectmap = res['subjectmap'];
          this.studentprofileform.controls['subject_one'].setValue(this.subjectmap[0].Subject);
             this.studentprofileform.controls['subject_two'].setValue(this.subjectmap[1].Subject);
             this.studentprofileform.controls['subject_three'].setValue(this.subjectmap[2].Subject);
             this.studentprofileform.controls['subject_four'].setValue(this.subjectmap[3].Subject);
             this.studentprofileform.controls['subject_five'].setValue(this.subjectmap[4].Subject);
             this.studentprofileform.controls['subject_six'].setValue(this.subjectmap[5].Subject);
             this.studentprofileform.controls['subject_seven'].setValue(this.subjectmap[6].Subject);
             this.studentprofileform.controls['subject_eight'].setValue(this.subjectmap[7].Subject);
             this.studentprofileform.controls['subject_nine'].setValue(this.subjectmap[8].Subject);
             this.studentprofileform.controls['subject_ten'].setValue(this.subjectmap[9].Subject);
             this.studentprofileform.controls['subject_eleven'].setValue(this.subjectmap[10].Subject);
             this.studentprofileform.controls['subject_twelve'].setValue(this.subjectmap[11].Subject);
             this.studentprofileform.controls['subject_thirteen'].setValue(this.subjectmap[12].Subject);
      })
  }

  // get subjects in dropdown

  getsubjects(){
    let user:{}={
      
    }
    let seq = this.service.post('/StudentApi9/getsubjectlist',user);
    seq.subscribe((res:any) => {
      this.subject = res['Data'];
      this.subject1 =res['Data1'];
      this.subject2 =res['Data2'];
      this.subject3 =res['Data3'];
      this.subject4 =res['Data4'];
      this.subject5 =res['Data5'];
      this.subject6 =res['Data6'];
      this.subject7 =res['Data7'];
      this.subject8 =res['Data8'];
      this.subject9 =res['Data9'];
      this.subject10 =res['Data10'];
      this.subject11 =res['Data11'];
      this.subject12 =res['Data12'];
      // console.log(this.subject);

 });
  }

  onstudentprofilesubmit(){
 
    let seql = this.service.post('/StudentApi11/emailcheck', {email:this.studentprofileform.controls.email.value,standard:"9th"});
    seql
        .subscribe((res:any) => {
          console.log(this.studentid)
          if(res['status'] == 'success'){
            if(res['Data'][0]['studentid'] != this.studentid){
              alert('This email is already exists')
            }
            else{
              this.studentprofilesubmit();
            }
          }
          else{
            this.studentprofilesubmit();
          }
            //  this.submitted=true;
        }, err => {
            alert("Error");
        }); 
  }

  studentprofilesubmit()
  {
    this.changedate();
    var chk=this.isDOBvalid(this.studentprofileform.controls.dob.value);
    if(chk){
      this.submitted = true;
    
      if(this.studentprofileform.controls['subject_seven'].value=="")
      {
        if(this.studentprofileform.controls.subject_eight.value=="" ||
        this.studentprofileform.controls.subject_nine.value=="" ||
        this.studentprofileform.controls.subject_ten.value=="" ||
        this.studentprofileform.controls.subject_thirteen.value==""){
          alert("if Subject 7 is not selected, \nPlease select following : \nSubject 8th and \nSubject 9th and \nSubject 10th and \nSubject 13th");
          return;
        }
  
          // this.studentprofileform.controls['subject_seven'].clearValidators();
          //   this.studentprofileform.controls['subject_ten'].setValidators(Validators.required);                   
          //   this.studentprofileform.controls['subject_eleven'].setValidators(Validators.required);                   
          //   this.studentprofileform.controls['subject_twelve'].setValidators(Validators.required);                   
          //   this.studentprofileform.controls['subject_thirteen'].setValidators(Validators.required);
                              
      }
      else {        
     
        // this.studentprofileform.controls['subject_seven'].setValidators(Validators.required);                   
        // this.studentprofileform.controls['subject_ten'].clearValidators();
        // this.studentprofileform.controls['subject_eleven'].clearValidators();
        // this.studentprofileform.controls['subject_twelve'].clearValidators();
        // this.studentprofileform.controls['subject_thirteen'].clearValidators();
          
            if (this.studentprofileform.invalid) {
              
              alert("Fill All Details");
              return;
  
            }
       }
  
          let chekstud:{studentid:any,schoolid:any,username:any}={
            studentid:this.studentid,
            schoolid:this.schoolid,
            username:this.username
          }
        
          this.service.post('/StudentApi9/testauditTrail',chekstud).subscribe((res:any)=>{
            this.studata = res['Data'];
          });
    
      let user: {studentid:any,stname:any,ftname:any,mtname:any,dob:any,gender:any,email:any,mobile_no:any,alt_mobile_no:any,
        caste:any, street:any,locality:any,city:any,state:any,taluka:any,district:any,landmark:any,pincode:any,
         house_ownership:any,internet_coverage:any,distance_coverage:any,access_to_smart_phone:any,access_internet:any,
         // password:any,c_password:any,
         security_ques:any,security_ans:any,fathers_qualification:any,fathers_job:any,
         fathers_annual_income:any,mothers_qualification:any,mothers_job:any,mothers_annual_income:any,
         distance_of_school_to_house:any,mode_of_transport:any,
        // photo:any,
        // signature:any,
       
      }
        
       = {
            // schoolid:this.schoolid,
            // username:this.username,
            studentid:this.studentid,
            stname:this.studentprofileform.controls.stname.value,
                ftname:this.studentprofileform.controls.ftname.value,
                mtname:this.studentprofileform.controls.mtname.value,
                dob:this.dob_stud,
                gender:this.studentprofileform.controls.gender.value,
                email:this.studentprofileform.controls.email.value,
                mobile_no:this.studentprofileform.controls.mobile_no.value,
                alt_mobile_no:this.studentprofileform.controls.alt_mobile_no.value,
                caste:this.studentprofileform.controls.caste.value,
                street:this.studentprofileform.controls.street.value,
                locality:this.studentprofileform.controls.locality.value,
                city:this.studentprofileform.controls.city.value,
                state:this.studentprofileform.controls.state.value,
                taluka:this.studentprofileform.controls.taluka.value,
                district:this.studentprofileform.controls.district.value,
                landmark:this.studentprofileform.controls.landmark.value,
                pincode:this.studentprofileform.controls.pincode.value,
                house_ownership:this.studentprofileform.controls.house_ownership.value,
                internet_coverage:this.studentprofileform.controls.internet_coverage.value,
                distance_coverage:this.studentprofileform.controls.distance_coverage.value,
                access_to_smart_phone:this.studentprofileform.controls.access_to_smart_phone.value,
                access_internet:this.studentprofileform.controls.access_internet.value,
                distance_of_school_to_house:this.studentprofileform.controls.distance_of_school_to_house.value,
                mode_of_transport:this.studentprofileform.controls.mode_of_transport.value,
                // password:this.studentprofileform.controls.password.value,
                // c_password:this.studentprofileform.controls.c_password.value,
                security_ques:this.studentprofileform.controls.security_ques.value,
                security_ans:this.studentprofileform.controls.security_ans.value,
                fathers_qualification:this.studentprofileform.controls.fathers_qualification.value,
                fathers_job:this.studentprofileform.controls.fathers_job.value,
                fathers_annual_income:this.studentprofileform.controls.fathers_annual_income.value,
                mothers_qualification:this.studentprofileform.controls.mothers_qualification.value,
                mothers_job:this.studentprofileform.controls.mothers_job.value,
                mothers_annual_income:this.studentprofileform.controls.mothers_annual_income.value,
            // photo:this.studentprofileform.controls.photo.value,
            // signature:this.studentprofileform.controls.signature.value,
            
       }
  
       console.log(user);
      //  alert("Information Saved");
      if (this.studentprofileform.controls.captcha_text.value == this.captcha)
      {
      // this.isdisable= true;
       let seq = this.service.post('/StudentApi9/submitstudentformSchool', user);
       seq
           .subscribe(res => {
               console.log(res);
               alert("Information Is Updated Successfully!!");
              //  this.router.navigate(['candidates-details']); 
              window.location.reload();
              
      }, err       => {
               alert("Error");
           });  
  
            //  For Photo and Signature upload
          const formData = new FormData();
          formData.append('photo', this.studentprofileform.controls.photo.value);
          formData.append('signature', this.studentprofileform.controls.signature.value);
          formData.append('id', this.studentid);
          this.photo_upload_success=true;
          this.service.uploadFile(formData).subscribe(
            (res:any) => {
             this.uploadResponse = res;
                console.log(res);
                // this.getstudentform();
            },
            (err: any) => {  
              console.log(err);
            }
          );
  
           
           let subject : { studentid:any,rollno:any,class:any,division:any,subject_one:any,subject_two:any,subject_three:any,subject_four:any,
            subject_five:any,subject_six:any,subject_seven:any,subject_eight:any,
            subject_nine:any,subject_ten:any,subject_eleven:any,subject_twelve:any,subject_thirteen:any} =
           {
                studentid:this.studentid,
                rollno:this.studentprofileform.controls.rollno.value,
                class:this.studentprofileform.controls.class.value,
                division:this.studentprofileform.controls.division.value,
                subject_one:this.studentprofileform.controls.subject_one.value,
                subject_two:this.studentprofileform.controls.subject_two.value,
                subject_three:this.studentprofileform.controls.subject_three.value,
                subject_four:this.studentprofileform.controls.subject_four.value,
                subject_five:this.studentprofileform.controls.subject_five.value,
                subject_six:this.studentprofileform.controls.subject_six.value,
                subject_seven:this.studentprofileform.controls.subject_seven.value,
                subject_eight:this.studentprofileform.controls.subject_eight.value,
                subject_nine:this.studentprofileform.controls.subject_nine.value,
                subject_ten:this.studentprofileform.controls.subject_ten.value,
                subject_eleven:this.studentprofileform.controls.subject_eleven.value,
                subject_twelve:this.studentprofileform.controls.subject_twelve.value,
                subject_thirteen:this.studentprofileform.controls.subject_thirteen.value              
           }
           
  
           console.log(user);
          //  alert("Subject Saved");
           let seql = this.service.post('/StudentApi9/editsavesubject', subject);
           seql
               .subscribe(res => {
                   console.log(res);
       
               }, err => {
                  //  alert("Error");
                  console.log(err);
               }); 
      
    }
    else
    {
      alert('please Check Captcha');
    }
    }else{
      alert("Please enter valid Date, \n For 9th registration, age should be more than 12");
    }
    
}
changedate(){ 
  this.dob_stud = this.studentprofileform.controls.dob.value.split("-").reverse().join("-")
  
}
isDOBvalid(dateString:any) {

  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  // console.log(today);
  //   console.log(birthDate);
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
  {
      age--;
  }
  if(age>=12){
          return true;
      }
      else {
        return false;
      }  
  }
  getalltalukas(){
    let seq = this.service.post('/BoardApi/getTaluka',{});
    seq.subscribe((res:any)=>{
        if(res.status== "Sucess"){
            this.alltalukas=res.Data;
        }
        else{
          alert("An error occurred while fetching data, Reloading page!!");
          window.location.reload();
        }
    })
}

getdistrict(taluka:any){
    this.alltalukas.forEach((elem: { taluka_name: any, taluka_district:any }) => {
        if(elem.taluka_name==taluka.value){
          // alert(elem.taluka_district);
          this.studentprofileform.controls['district'].setValue(elem.taluka_district);
        }
    });
}
setsuboptional(e:any){
  // console.log(e.target.value);
 
  // if(e.target.value==""){
  //       this.studentprofileform.controls['subject_ten'].setValidators(Validators.required);                   
  //       this.studentprofileform.controls['subject_eleven'].setValidators(Validators.required);                   
  //       this.studentprofileform.controls['subject_twelve'].setValidators(Validators.required);                   
  //       this.studentprofileform.controls['subject_thirteen'].setValidators(Validators.required);                   
  // }
  // else {        
  //       this.studentprofileform.controls['subject_ten'].clearValidators();
  //       this.studentprofileform.controls['subject_eleven'].clearValidators();
  //       this.studentprofileform.controls['subject_twelve'].clearValidators();
  //       this.studentprofileform.controls['subject_thirteen'].clearValidators();
  //    }
}
}

