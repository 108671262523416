import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SchoolserviceService } from '../schoolservice.service';
import { DatePipe } from '@angular/common';
import { NavbarService } from '../default/navbar/navbar.service';
import { Router, ActivatedRoute } from '@angular/router';
import{schoolPayTransList} from'./schoolPayTransList';

@Component({
  selector: 'app-schoolpaymentlist',
  templateUrl: './schoolpaymentlist.component.html',
  styleUrls: ['./schoolpaymentlist.component.scss'],
  providers: [DatePipe]
})
export class SchoolpaymentlistComponent implements OnInit {
  schoolid!:String;
  schoolName!:String;
  totalNoOfTrans!:Number;
  totalAmt:any=0;
  allPayTrans!:schoolPayTransList[];
  role_type: any;
  constructor(public formbuilder:FormBuilder,public router:Router, public service:SchoolserviceService,public datepipe: DatePipe,public nav:NavbarService,private _routeParams: ActivatedRoute) {

    
  }

  ngOnInit(): void {

    this.role_type =  sessionStorage.getItem('role_type');
    if(this.role_type == 'boardadmin')
    {
      this.nav.hide();
      this.nav.dhide();
      this.nav.adshow();
      this.nav.aduserhide();
      this.nav.asdhide();
      this.nav.studhide();
      this.nav.XIstudhide();
    }
    else
    {
      this.nav.hide();
      this.nav.dhide();
      this.nav.adhide();
      this.nav.adusershow();
      this.nav.asdhide();
      this.nav.studhide();
      this.nav.XIstudhide();
    }

    var id = this._routeParams.params.subscribe(params => {
      this.schoolid = String(params["schoolid"]); 
      this.schoolName = String(params["schoolName"]);
       console.log("School Id: "+this.schoolid);  
      
    this.getAllTransactionList();
          
          
    });

  }
  getAllTransactionList(){
    
    let schoolId: {schoolid:any } = {
      schoolid:this.schoolid
    }
    
    this.service.post('/BoardApi/viewPaymentDetailsofSchool',schoolId).subscribe((allPays: any) => {
        console.log(allPays.Data);
       this.allPayTrans=allPays.Data; 
        this.totalNoOfTrans=this.allPayTrans.length;
        console.log();
        for(let i=0;i<=this.allPayTrans.length;i++){
          if(this.allPayTrans[i].amount!=""){
              this.totalAmt=parseInt(this.totalAmt)+parseInt(this.allPayTrans[i].amount);
          }
        }
    })
}

}
