import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SchoolserviceService } from '../schoolservice.service';
import { DatePipe } from '@angular/common';
import { NavbarService } from '../default/navbar/navbar.service';

@Component({
  selector: 'app-boarduser',
  templateUrl: './boarduser.component.html',
  styleUrls: ['./boarduser.component.scss'],
  providers: [DatePipe]
})
export class BoarduserComponent implements OnInit {
  boarduserform: FormGroup;
  submitted = false;
  newUserSatatus!:String;
  role_type: any;
  constructor(public formbuilder:FormBuilder,public router:Router, public service:SchoolserviceService,public datepipe: DatePipe,public nav:NavbarService) {

    this.boarduserform = formbuilder.group({
      employee_id:['',Validators.required],
      board_user_fname :['',Validators.required],
      username:['',Validators.required]
      //password:['', Validators.required]

    })
   }

  ngOnInit(): void {
    this.role_type =  sessionStorage.getItem('role_type');
    if(this.role_type == 'boardadmin')
    {
      this.nav.hide();
      this.nav.dhide();
      this.nav.adshow();
      this.nav.aduserhide();
      this.nav.asdhide();
      this.nav.studhide();
      this.nav.XIstudhide();
    }
    else
    {
      this.nav.hide();
      this.nav.dhide();
      this.nav.adhide();
      this.nav.adusershow();
      this.nav.asdhide();
      this.nav.studhide();
      this.nav.XIstudhide();
    }


  }

  get personal() { 
    return this.boarduserform.controls; }

  onsubmit(){
    console.log(this.boarduserform.value.board_user_fname);
    console.log(this.boarduserform.value);
    
    this.submitted = true;
    if (this.boarduserform.invalid) {
      
      alert("Fill All Deatils");
      return;

    }
    else{
      this.saveNewBoardUser();
    }

  }
  saveNewBoardUser(){
    let newBoardUser:{fullname:any,employeeid:any,username:any}={
      fullname:this.boarduserform.value.board_user_fname,
      employeeid:this.boarduserform.value.employee_id,      
      username:this.boarduserform.value.username
    }
    this.service.post('/BoardApi/addNewboarduser',newBoardUser).subscribe((res: any) => {
      this.newUserSatatus= res['status'];
      if(this.newUserSatatus == 'Success')
      {
        alert('Data Submitted');
        this.boarduserform.reset();
        this.submitted = false;      
      }
      else{
        // alert('Data can not be inserted this time');
        alert(res.Data);
      }
  })
  }
}
