import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SchoolserviceService } from '../schoolservice.service';
import { DatePipe } from '@angular/common';
import { NavbarService } from '../default/navbar/navbar.service';
import { Table } from 'primeng/table';
import { PrimeNGConfig } from 'primeng/api';
import { Schools } from './schools';
@Component({
  selector: 'app-viewschools11',
  templateUrl: './viewschools11.component.html',
  styleUrls: ['./viewschools11.component.scss'],
  providers: [DatePipe]
})
export class Viewschools11Component implements OnInit {

  loading: boolean = true;
  schooldetails!: Schools[];
  currStandard9th!:any;
  currStandard11th!:any;
  std!:any;
  
  selectedSchooldetails!: Schools[];
  @ViewChild('dt')
  table!: Table;
  role_type: any;

  constructor(public formbuilder:FormBuilder,public router:Router, public service:SchoolserviceService,public datepipe: DatePipe,public nav:NavbarService,private primengConfig: PrimeNGConfig,private _routeParams: ActivatedRoute) { }

  ngOnInit(): void {
   
    this.role_type =  sessionStorage.getItem('role_type');
    if(this.role_type == 'boardadmin')
    {
      this.nav.hide();
      this.nav.dhide();
      this.nav.adshow();
      this.nav.aduserhide();
      this.nav.asdhide();
      this.nav.studhide();
      this.nav.XIstudhide();
    }
    else
    {
      this.nav.hide();
      this.nav.dhide();
      this.nav.adhide();
      this.nav.adusershow();
      this.nav.asdhide();
      this.nav.studhide();
      this.nav.XIstudhide();
    }



// primng table
this.primengConfig.ripple = true;

    var currStandard = this._routeParams.params.subscribe(params => {
      this.std = String(params["stand"]);
      
  });
  this.getAllSchoolData();

  }
  
  getEventValue($event:any) :string {
  return $event.target.value;
  }
  
  getAllSchoolData(){
    let schoolId: { } = {
     
    }  
    this.service.post('/BoardApi/get11thSchools',schoolId).subscribe((allSchool: any) => {
      console.log("11th");
      console.log(allSchool);
     this.schooldetails=allSchool.Data;
     this.loading = false;
  })
  
  }
}
