import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SchoolserviceService } from '../schoolservice.service';
import { DatePipe } from '@angular/common';
import { NavbarService } from '../default/navbar/navbar.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-viewyearwise9thstudents',
  templateUrl: './viewyearwise9thstudents.component.html',
  styleUrls: ['./viewyearwise9thstudents.component.scss']
})
export class Viewyearwise9thstudentsComponent implements OnInit {

  studID:any;
  userData: any;
  studentprofileform: FormGroup;
  imgstr = Image;
  isdisabled : boolean = true;
  subjectmap: any;
  studschool: any;
  subject:any;
  subject1:any;
  subject2:any;
  subject3:any;
  subject4:any;
  subject5:any;
  subject6:any;
  imgstring: any;
  schoolid: any;
  loading: boolean = false;
  sign: any;
  photoinfo: any;
  signinfo: any;
  subject7: any;
  subject8: any;
  subject9: any;
  subject10: any;
  subject11: any;
  subject12: any;
  subject13: any;
    constructor(public formbuilder:FormBuilder,public router:Router,private _routeParams: ActivatedRoute, public service:SchoolserviceService,public datepipe: DatePipe,public nav:NavbarService,private sanitizer: DomSanitizer) 
  { 
    this.studentprofileform = formbuilder.group({
      class:['',Validators.required],
      division:['',Validators.required],
      stname:['',Validators.required],
      ftname:['',Validators.required],
      mtname:['',Validators.required],
      dob:['',Validators.required],
      gender:['',Validators.required],
      email:['',Validators.required],
      mobile_no:['',Validators.required],
      alt_mobile_no:[''],
      caste:[''],
      street:['',Validators.required],
      locality:['',Validators.required],
      city:['',Validators.required],
      state:['',Validators.required],
      taluka:['',Validators.required],
      district:['',Validators.required],
      landmark:['',Validators.required],
      pincode:['',Validators.required],
      house_ownership:['',Validators.required],
      internet_coverage:['',Validators.required],
      distance_coverage:['',Validators.required],
      access_to_smart_phone:['',Validators.required],
      access_internet:['',Validators.required],
      distance_of_school_to_house:[''],
      mode_of_transport:[''],
      security_ques:['',Validators.required],
      security_ans:['',Validators.required],
      fathers_qualification:['',Validators.required],
      fathers_job:['',Validators.required],
      fathers_annual_income:['',Validators.required],
      mothers_qualification:['',Validators.required],
      mothers_job:['',Validators.required],
      mothers_annual_income:['',Validators.required],
      photo:['',Validators.required],
      signature:['',Validators.required],
      subject_one:['',Validators.required],
      subject_two:['',Validators.required],
      subject_three:['',Validators.required],
      subject_four:['',Validators.required],
      subject_five:['',Validators.required],
      subject_six:['',Validators.required],
      subject_seven:['',Validators.required],
      subject_eight:['',Validators.required],
      subject_nine:['',Validators.required],
      subject_ten:['',Validators.required],
      subject_eleven:['',Validators.required],
      subject_twelve:['',Validators.required],
      subject_thirteen:['',Validators.required],
      captcha_text:['',Validators.required]
      })
  }

  ngOnInit(): void {

    this.schoolid = sessionStorage.getItem('schoolid');
    this.nav.hide();
    this.nav.dhide();
    this.nav.adhide();
    this.nav.asdshow();
    this.nav.studhide();
    this.nav.XIstudhide();
    this.nav.aduserhide();
    
    this.studID = sessionStorage.getItem('studentid');
    // console.log(this.studID);
    var id = this._routeParams.params.subscribe(params => {

      var studID = String(params["studentid"]);
  
      this.studID = String(params["studentid"]);
       console.log(this.studID);
      // alert(seat);
      if (studID == "new") {
          return;
      }
      else {
          this.loading = true;
          
          this.getstudentform();
          this.getstudentclassForm();
          this.getselectedsubject();
          this.loading = false;
      }
  });

  this.getsubjects();
  }

  getstudentform()
  {
    let user:{studentid:any}={
      studentid:this.studID
    }
    let seq = this.service.post('/StudentApi9/getstudentForm',user);
     seq.subscribe((res:any) => {
           
      this.userData= res['Data'];
            
      this.subjectmap= res['subjectmap'];
     //  console.log(this.userData[0].security_ques);
     // this.studentprofileform.controls['class'].setValue(this.studschool[0].standard);
     // this.studentprofileform.controls['division'].setValue(this.studschool[0].division);
      this.studentprofileform.controls['stname'].setValue(this.userData[0].stname);
      this.studentprofileform.controls['ftname'].setValue(this.userData[0].ftname);
      this.studentprofileform.controls['mtname'].setValue(this.userData[0].mtname);
      this.studentprofileform.controls['dob'].setValue(this.userData[0].dob);
      this.studentprofileform.controls['gender'].setValue(this.userData[0].gender);
      this.studentprofileform.controls['email'].setValue(this.userData[0].email);
      this.studentprofileform.controls['mobile_no'].setValue(this.userData[0].mobile_no);
      this.studentprofileform.controls['alt_mobile_no'].setValue(this.userData[0].alt_mobile_no);
      this.studentprofileform.controls['caste'].setValue(this.userData[0].caste);
      this.studentprofileform.controls['street'].setValue(this.userData[0].street);
      this.studentprofileform.controls['locality'].setValue(this.userData[0].locality);
      this.studentprofileform.controls['city'].setValue(this.userData[0].city);
      this.studentprofileform.controls['state'].setValue(this.userData[0].state);
      this.studentprofileform.controls['taluka'].setValue(this.userData[0].taluka);
      this.studentprofileform.controls['district'].setValue(this.userData[0].district);
      this.studentprofileform.controls['landmark'].setValue(this.userData[0].landmark);
      this.studentprofileform.controls['pincode'].setValue(this.userData[0].pincode);
      this.studentprofileform.controls['house_ownership'].setValue(this.userData[0].house_ownership);
      this.studentprofileform.controls['internet_coverage'].setValue(this.userData[0].internet_coverage);
      this.studentprofileform.controls['distance_coverage'].setValue(this.userData[0].distance_coverage);
      this.studentprofileform.controls['access_to_smart_phone'].setValue(this.userData[0].access_to_smart_phone);
      this.studentprofileform.controls['access_internet'].setValue(this.userData[0].access_internet);
      this.studentprofileform.controls['distance_of_school_to_house'].setValue(this.userData[0].distance_of_school_to_house);
      this.studentprofileform.controls['mode_of_transport'].setValue(this.userData[0].mode_of_transport);
      this.studentprofileform.controls['security_ques'].setValue(this.userData[0].security_ques);
      this.studentprofileform.controls['security_ans'].setValue(this.userData[0].security_ans);
      this.studentprofileform.controls['fathers_qualification'].setValue(this.userData[0].fathers_qualification);
      this.studentprofileform.controls['fathers_job'].setValue(this.userData[0].fathers_job);
      this.studentprofileform.controls['fathers_annual_income'].setValue(this.userData[0].fathers_annual_income);
      this.studentprofileform.controls['mothers_qualification'].setValue(this.userData[0].mothers_qualification);
      this.studentprofileform.controls['mothers_job'].setValue(this.userData[0].mothers_job);
      this.studentprofileform.controls['mothers_annual_income'].setValue(this.userData[0].mothers_annual_income);
      // this.studentprofileform.controls['boardname'].setValue(this.userData[0].boardname);
      // this.studentprofileform.controls['otherboardname'].setValue(this.userData[0].otherboardname);
      // this.studentprofileform.controls['eligibilitynumber'].setValue(this.userData[0].eligibilitynumber);
    
      var photo_upload_path=this.userData[0].photo;
      this.photoinfo = this.userData[0].photo;
     //  photo_upload_path =photo_upload_path.substr(42,photo_upload_path.length);
     this.studentprofileform.controls['photo'].setValue(photo_upload_path); 
     console.log(this.imgstring);
      var signature_upload_path=this.userData[0].signature;
      this.signinfo = this.userData[0].signature;
      //  signature_upload_path =signature_upload_path.substr(42,signature_upload_path.length);
      this.studentprofileform.controls['signature'].setValue(signature_upload_path);
      // var marksheet_upload_path=this.userData[0].marksheet;
      // //  signature_upload_path =signature_upload_path.substr(42,signature_upload_path.length);
      // this.studentprofileform.controls['marksheet'].setValue(marksheet_upload_path);


     });
  }

  // get class and division
  getstudentclassForm()
  {
    let user:{studentid:any}={
      studentid:this.studID
    }
    let seq = this.service.post('/StudentApi9/getstudentclassForm',user);
     seq.subscribe((res:any) => {
            
            this.studschool= res['studschool'];
             this.studentprofileform.controls['class'].setValue(this.studschool[0].standard);
            this.studentprofileform.controls['division'].setValue(this.studschool[0].division);
     });
  }

    // get all selected subject from student
    getselectedsubject()
    {
      let user:{studentid:any}={
        studentid:this.studID
      }
        let seq = this.service.post('/StudentApi9/getstudentselectedsubject',user);
        seq.subscribe((res:any)=>{
          this.subjectmap = res['subjectmap'];
              this.studentprofileform.controls['subject_one'].setValue(this.subjectmap[0].Subject);
               this.studentprofileform.controls['subject_two'].setValue(this.subjectmap[1].Subject);
               this.studentprofileform.controls['subject_three'].setValue(this.subjectmap[2].Subject);
               this.studentprofileform.controls['subject_four'].setValue(this.subjectmap[3].Subject);
               this.studentprofileform.controls['subject_five'].setValue(this.subjectmap[4].Subject);
               this.studentprofileform.controls['subject_six'].setValue(this.subjectmap[5].Subject);
               this.studentprofileform.controls['subject_seven'].setValue(this.subjectmap[6].Subject);
             this.studentprofileform.controls['subject_eight'].setValue(this.subjectmap[7].Subject);
             this.studentprofileform.controls['subject_nine'].setValue(this.subjectmap[8].Subject);
             this.studentprofileform.controls['subject_ten'].setValue(this.subjectmap[9].Subject);
             this.studentprofileform.controls['subject_eleven'].setValue(this.subjectmap[10].Subject);
             this.studentprofileform.controls['subject_twelve'].setValue(this.subjectmap[10].Subject);
             this.studentprofileform.controls['subject_thirteen'].setValue(this.subjectmap[10].Subject);
        })
    }

    
      // get subjects in dropdown

  getsubjects(){
    let user:{}={
      
    }
    let seq = this.service.post('/StudentApi9/getsubjectlist',user);
     seq.subscribe((res:any) => {
      this.subject = res['Data'];
      this.subject1 =res['Data1'];
      this.subject2 =res['Data2'];
      this.subject3 =res['Data3'];
      this.subject4 =res['Data4'];
      this.subject5 =res['Data5'];
      this.subject6 =res['Data6'];
      this.subject7 =res['Data7'];
      this.subject8 =res['Data8'];
      this.subject9 =res['Data9'];
      this.subject10 =res['Data10'];
      this.subject11 =res['Data11'];
      this.subject12 =res['Data12'];
      this.subject13 =res['Data13'];

     });
  }

}
