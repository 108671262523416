import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild,Compiler } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NavbarService } from '../default/navbar/navbar.service';
import { SchoolserviceService } from '../schoolservice.service';

@Component({
  selector: 'app-school-enroll-students',
  templateUrl: './school-enroll-students.component.html',
  styleUrls: ['./school-enroll-students.component.scss']
})
export class SchoolEnrollStudentsComponent implements OnInit {
  selctform !: FormGroup;
  addprimaryform !: FormGroup;
  importdataform!: FormGroup;
  fileToUpload!: File;
  schoolid: any;
  filesubmitted =false;
  schoolsubmitted = false;
  submitted = false;
  schooldata: any;
  schooldata1: any;
  studentsingledata: any;
  showDiv = {
    previous:false,
    next:false,
  }
  scode: any;
  enrollmnt: any;
  enrollment: any;
  filename ='importexcel.xlsx';
  date_ob = new Date();
  academicyears: any;
  postyear: any;
  preyear: any;
  enrollrecord: any;
  enrollstatus: any;
  standard:any;
  newenrollmnt:any;
  newenrollmntid:any;
  streamvalue: any;
  studdob: any;
  coursesall: any;
  tstcourse: any;
  actionselectedsingle: boolean=false;
  actionselectedbulk: boolean=false;
  validdate9th=12;
  validdate11th=14;
  constructor(public formbuilder:FormBuilder,public router:Router,public datepipe: DatePipe, public service:SchoolserviceService,public nav:NavbarService) {
  this.importdataform = this.formbuilder.group({
      principal:['',Validators.required],
      year:['',Validators.required],
      noofstudents:['',Validators.required]
  });
    this.selctform = this.formbuilder.group({
      // stud_numbers :['']
      file:['',Validators.required]
    });
    this.addprimaryform = this.formbuilder.group({
      stname:['',Validators.required],
      ftname:['',Validators.required],
      mtname:['',Validators.required],
      rollno:['',Validators.required],
      dob:['',Validators.required],
      standard:['',Validators.required],
      division:['',Validators.required],
      stream:[''],
      course:[''],
      gender:['',Validators.required],

    })
   }

  ngOnInit(): void {

      this.schoolid = sessionStorage.getItem('schoolid');
      this.standard = sessionStorage.getItem('Standard');
      this.scode = sessionStorage.getItem('scode');
      this.enrollmnt = sessionStorage.getItem('enrollmentyear');
      this.enrollment = sessionStorage.getItem('enrollment');
      console.log(this.scode);
    this.nav.hide();
    this.nav.dhide();
    this.nav.adhide();
    this.nav.asdshow();
    this.nav.studhide();
    this.nav.XIstudhide();
    this.nav.aduserhide();

    this.getcourses();
    
      // let enrol:  {schoolid:any}={
      //   schoolid:this.schoolid,
       
      // } //old 
      //changed 26-10-23 teja
      let enrol:  {schoolid:any,standard:any}={
        schoolid:this.schoolid,
        standard:sessionStorage.getItem('Standard')
      }
    this.service.post('/School/enrollmentdetails',enrol).subscribe((res:any)=>{

      if(res['status'] == 'Success')
      {
        this.showDiv.previous = !this.showDiv.previous;

          this.showDiv.next = false;

      }
      else{
        this.showDiv.previous = false;
        this.showDiv.next = true;
      }

      // const data = 'some text';
    // const blob = new Blob(, { type: 'application/vnd.ms-excel' });
    //   window.URL.createObjectURL(blob);


    })

     

        // For Acadmic year
        var month = this.date_ob.getMonth()+1;
        var year = this.date_ob.getFullYear();
        console.log(month +',,,,,,' + year);
        if(month >= 9){
          this.postyear = year+1;
          this.academicyears = year + "-"+ this.postyear;
      }
      else if(month <= 8)
      {
          this.preyear = year-1;
          this.academicyears = this.preyear + "-"+year;
      }
  }

  streamChanged(value: any)
  {
    
    this.streamvalue = value;
    // console.log(this.streamvalue);

    if(this.streamvalue != 'Vocational')
    {
      this.tstcourse = '0';
      console.log(this.tstcourse);
    }    

  }
  courseChanged(value: any)
  {
    
    this.streamvalue = value;
    // console.log(this.streamvalue);

    if(this.streamvalue != 'Vocational')
    {
      this.tstcourse = '0';
      console.log(this.tstcourse);
    }
    else
    {
      this.tstcourse = this.addprimaryform.controls.course.value;
      console.log(this.tstcourse);
    }

  }

   // for validation
   get personal() { return this.selctform.controls; }
   get importdata() { return this.importdataform.controls; }
   get addpersonal() { return this.addprimaryform.controls; }
  // Bulk Upload

handleFileInput(event:any){
  console.log(event)
  //this.file=file.name

  this.fileToUpload = event.target.files.item(0);

  console.log(this.fileToUpload);

}

upload()
{
  this.filesubmitted = true;
  if (this.selctform.invalid) {
      
    alert("Please Select file For Upload");
    return;

  }
   const formdata = new FormData();
   
   formdata.append("schoolid",this.schoolid);
   formdata.append("standard",this.standard);
   formdata.append("scode",this.scode);
   formdata.append("upload_file", this.fileToUpload);

   this.service.Excelimport(formdata).subscribe((res:any)=>{
      // console.log(data);
    
      if(res['status'] == 'Success')
      {
        var msg = res['Msg'];
        alert(msg);
        window.location.reload();
      }
      else if(res['status'] == 'duplicate')
      {
        let msg = res['Msg'] + res['Data'];
        alert(msg);
        window.location.reload();
      }
      else if(res['status'] == 'missing')
      {
        let msg = res['Msg'];
        alert(msg);
        window.location.reload();
      }
      else if(res['status'] == 'Failed')
      {
        let msg = res['Msg'];
        alert(msg);
        window.location.reload();
      }
      else{
        alert('File Not Upload');
        window.location.reload();
      }

   });
}

Onaddcandidate()
{
     
    var chk=this.isDOBvalid(this.addprimaryform.controls.dob.value);
    if(chk){//if DOB is valid : 12year for 9th and 14year for 11th
      this.submitted =true;
          if (this.addprimaryform.invalid) {
          
            alert("Please Fill All Details");
            return;
        
          }
      
        
          this.studdob = this.datepipe.transform(this.addprimaryform.controls.dob.value, 'dd-MM-yyyy');;
          let school :{schoolid:any,scode:any,stname:any,rollno:any,ftname:any,mtname:any,dob:any,
            class:any,division:any,stream:any,course:any,gender:any}={
            schoolid:this.schoolid,
            scode:this.scode,
            stname:this.addprimaryform.controls.stname.value,
            rollno:this.addprimaryform.controls.rollno.value,
            ftname:this.addprimaryform.controls.ftname.value,
            mtname:this.addprimaryform.controls.mtname.value,
            dob:this.studdob,
            class:this.standard,
            gender:this.addprimaryform.controls.gender.value,
            division:this.addprimaryform.controls.division.value,
            stream:this.addprimaryform.controls.stream.value,
            course:this.tstcourse,
          }
      
          this.service.post('/School/addSingleStudent',school).subscribe((res:any)=>{
            this.studentsingledata = res['status'];
            if(this.studentsingledata == 'Success')
            {
              alert('Student successfully created');
              this.showDiv.previous = !this.showDiv.previous;
              this.showDiv.next = false;
              window.location.reload();
            }
            else if(this.studentsingledata == 'Failed'){
              let msg = res['Msg'];
              alert(msg);
              // window.location.reload();
            }
            else if(this.studentsingledata == 'False')
            {
              let msg = res['Msg'];
              alert(msg);
            }
            else
            {
              
              alert('Not Inserted');
            }
          }, err =>{
            alert('Error');
          });
    }
    else{
      if(this.standard=="11th"){
        alert("Please enter valid Date, \n For "+this.standard+" registration, age should be more than "+this.validdate11th);
      }else if(this.standard=="9th"){
        alert("Please enter valid Date, \n For "+this.standard+" registration, age should be more than "+this.validdate9th);
      }
    }
    

}

onsubmit()
{
  this.schoolsubmitted = true;
  if(this.importdataform.invalid)
  {
      alert('Please Fill All Details');
      return;
  }
  

  let schoolinfo :{schoolid:any,principal:any,year:any,noofcandidates:any,standard:any}={
    schoolid:this.schoolid,
    principal:this.importdataform.controls.principal.value,
    year:this.importdataform.controls.year.value,
    noofcandidates:this.importdataform.controls.noofstudents.value,
    standard:this.standard
  }
console.log(schoolinfo);
  this.service.post('/School/importDetails',schoolinfo).subscribe((res:any)=>{
    this.schooldata = res['status'];
    if(this.schooldata == 'success')
    {
      alert('Data Submitted');

      let schoolinfo1 :{schoolid:any,standard:any}={
        schoolid:this.schoolid,
        standard:this.standard
      }
      this.service.post('/School/fetch_new_enrol',schoolinfo1).subscribe((res:any)=>{
        if(res['status'] == 'Success')
        {
          this.schooldata1=res.enrollment[0];
          this.newenrollmnt=this.schooldata1.enrollmentyear;
          this.newenrollmntid=this.schooldata1.enrollmentid;
          sessionStorage.removeItem('enrollmentyear');
          sessionStorage.removeItem('enrollmentid');

          sessionStorage.setItem('enrollmentyear',this.newenrollmnt);
          sessionStorage.setItem('enrollmentid',this.newenrollmntid);
          // sessionStorage.setItem('enrollment',this.enrollment);
          this.router.navigate(['/schoollanding']);
        }
        else{
          alert(res['Msg']);
          window.sessionStorage.clear();
          this.router.navigate(['/home']);
        }
      });

      // this.showDiv.previous = !this.showDiv.previous;
      // this.showDiv.next = false;
      // window.location.reload();
    }
    else{
      alert('Not Inserted');
    }
  }, err =>{
    alert('Error');
  });

}


  // get all course
  getcourses()
  {
    let corse:{}={
  
    }
    let seq = this.service.post('/StudentApi11/getallcourses',corse);
    seq.subscribe((res:any) => {
      this.coursesall = res.Data;
      
  
  });
  }
  
  checkactiontaken(sel:any){
      // alert(sel.value);
      if(sel.value=="single"){
       this.actionselectedsingle=true;
       this.actionselectedbulk=false;
      }else{
        alert("For Bulk Upload: Please download excel template,\n Use same FORMAT to fill data, then upload excel")
        this.actionselectedsingle=false;
       this.actionselectedbulk=true;

      }
  }
 isDOBvalid(dateString:any) {
  // alert(dateString);
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
  {
      age--;
  }
      if(this.standard=="11th" && age>=14){
        return true;
      }
      else if(this.standard=="9th" && age>=12){
        return true;
      }
      else {
        return false;
      }  
  }
}
