<div class="container login-container mb-3" >
  <br>
  <div class="card border-dark mb-3">
      <h5 class="card-header pl-2">Edit Candidate Application <i [routerLink]="['/candidates-details']" class="bi bi-x-square-fill btnClose"></i></h5>
     
      <div class="card-body text-dark ">
        <div  class="justify-content-md-center">
         
         
          <form role="form" [formGroup]= "studentprofileform" >
            
            <figcaption class="blockquote" style="color: #0062cc;">
              <cite title="Source Title"><b>- Personal Information</b></cite>
             </figcaption> <hr>

              <div  class="justify-content-md-center">
                 <div class="form-group row mb-3">
                  <label for="staticname" class="col-sm-2 col-form-label">Class<span class="star">*</span></label>
                  <div class="col-sm-2">
                    <input type="text" formControlName="class" class="form-control" id="staticName"  readonly>
                  </div> 
                  
                     <label for="staticname" class="col-sm-2 col-form-label">Roll no<span class="star">*</span></label>
                     <div class="col-sm-2">
                       <input type="text" formControlName="rollno" value ="9th" class="form-control" id="staticName" readonly >
                     </div>
                    
                     <label for="staticname" class="col-sm-2 col-form-label">Division<span class="star">*</span></label>
                     <div class="col-sm-2">
                       <input type="text"  class="form-control" readonly formControlName="division" id="staticName"  value ="A" readonly>
                       
                     </div>
                     
                   </div>
                   <div class="form-group row mb-3">
                     
                     <label for="staticname" class="col-sm-2 col-form-label">Candidate's Name<span class="star">*</span></label>
                     <div class="col-sm-5">
                       <input type="text" formControlName="stname" class="form-control" id="staticName" 
                       [ngClass]="{ 'is-invalid': submitted && personal.stname.errors }">
                       <div *ngIf="submitted && personal.stname.errors" class="invalid-feedback">
                        <div *ngIf="personal.stname.errors.required">
                          Candidate Name Required.
                       </div> 
                     </div>
                     </div>
                   </div>
                   <div class="form-group row mb-3">
                     <label for="staticfathername" class="col-sm-2 col-form-label">Father's Name<span class="star">*</span></label>
                     <div class="col-sm-5">
                       <input type="text" formControlName="ftname" class="form-control" id="inputfathername" 
                       [ngClass]="{ 'is-invalid': submitted && personal.ftname.errors }">
                       <div *ngIf="submitted && personal.ftname.errors" class="invalid-feedback">
                        <div *ngIf="personal.ftname.errors.required">
                          Father Name Is Required.
                       </div> 
                     </div>
                     </div>
                   </div>
                   <div class="form-group row mb-3">
                     <label for="staticmothername" class="col-sm-2 col-form-label">Mother's Name<span class="star">*</span></label>
                     <div class="col-sm-5">
                       <input type="text" formControlName="mtname" class="form-control" id="inputmothername" 
                       [ngClass]="{ 'is-invalid': submitted && personal.mtname.errors }">
                       <div *ngIf="submitted && personal.mtname.errors" class="invalid-feedback">
                        <div *ngIf="personal.mtname.errors.required">
                          Mother Name Is Required.
                       </div> 
                     </div>
                      </div>
                   </div>
                   <div class="form-group row mb-3">
                     <label for="staticdob" class="col-sm-2 col-form-label">Date Of Birth<span class="star">*</span></label>
                     <div class="col-sm-5">
                       <input type="date" formControlName="dob" class="form-control" id="inputdob" 
                       [ngClass]="{ 'is-invalid': submitted && personal.dob.errors }">
                       <div *ngIf="submitted && personal.dob.errors" class="invalid-feedback">
                        <div *ngIf="personal.dob.errors.required">
                          Date Of Birth Is Required.
                       </div> 
                     </div> 
                       </div>
                     </div>
                   </div>
                   <div class="form-group row mb-3">
                     <label for="staticgender" class="col-sm-2 col-form-label">Gender<span class="star">*</span></label>
                     <div class="col-sm-5">
                         <select id="inputgender"  formControlName="gender" class="form-select"
                          [ngClass]="{ 'is-invalid': submitted && personal.gender.errors }">
                             <option selected value="">Select Gender</option>
                             <option value="Male">Male</option>
                             <option value="Female">Female</option>
                             <!-- <option value="Transe_gender">Transe Gender</option> -->
                           </select>
                           <div *ngIf="submitted && personal.gender.errors" class="invalid-feedback">
                            <div *ngIf="personal.gender.errors.required">
                              Select Gender.
                           </div> 
                         </div>
                     </div>
                   </div>
                   <div class="form-group row mb-3">
                    <label for="staticEmail" class="col-sm-2 col-form-label">Email</label>
                    <div class="col-sm-5">
                      <input type="email" formControlName="email" class="form-control" id="staticEmail" [ngClass]="{ 'is-invalid': submitted && personal.email.errors }" 
                      >
                               <div *ngIf="submitted && personal.email.errors" class="invalid-feedback">
                                <div *ngIf="personal.email.errors.required">
                                  Email Id Is Required.
                                </div>
                                <div *ngIf="personal.email.errors.email">Email must be a valid email address</div>
                              </div>
                       
                      </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label for="staticEmail" class="col-sm-2 col-form-label"> Mobile Number</label>
                    <div class="col-sm-5">
                      <input type="text" formControlName="mobile_no" class="form-control" id="staticEmail" [ngClass]="{ 'is-invalid': submitted && personal.mobile_no.errors }"
                      (keypress)="allowNumericDigitsOnlyOnKeyUp($event)">
                               <div *ngIf="submitted && personal.mobile_no.errors" class="invalid-feedback">
                                <div *ngIf="personal.mobile_no.errors.required">
                                    Mobile Number Is Required.
                                </div> 
                                <div *ngIf="personal.mobile_no.errors.pattern">Mobile number is not 10 digit</div>
                              </div>
                              <div [innerHTML]="msg" style="color: rgb(253, 0, 0);"></div>
                     
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label for="staticEmail" class="col-sm-2 col-form-label">Alternate Contact Number</label>
                    <div class="col-sm-5">
                      <input type="text" formControlName="alt_mobile_no" class="form-control" id="staticEmail"   (keypress)="allowNumeric($event)">
                      <!-- <div *ngIf="personal.alt_mobile_no.errors.pattern">Mobile number is not 10 digit</div> -->
                      <div [innerHTML]="conmsgs" style="color: rgb(253, 0, 0);"></div>
                    </div>
                  </div>
                   <div class="form-group row mb-3">
                    <label for="staticcaste" class="col-sm-2 col-form-label">Caste<span class="star">*</span></label>
                    <div class="col-sm-5">
                        <select id="inputcaste" formControlName="caste" class="form-select" 
                        [ngClass]="{ 'is-invalid': submitted && personal.caste.errors }">
                            <option  selected>Select Caste</option>
                             <option value="General">General</option>
                              <option value="OBC">OBC</option>
                              <option value="SC">SC</option>
                              <option value="ST">ST</option>
                            <!-- <option value="East_Indians">East Indians</option>
                            <option value="Goan_Catholics">Goan Catholics</option>
                            <option value="Karwari_Catholics">Karwari Catholics</option>
                            <option value="Koli_Christians">Koli Christians</option>
                            <option value="Mangalorean_Catholics">Mangalorean Catholics</option> -->
                          </select>
                          <div *ngIf="submitted && personal.caste.errors" class="invalid-feedback">
                            <div *ngIf="personal.caste.errors.required">
                              Caste Is Required.
                           </div> 
                         </div>
                    </div>
                  </div>
                   <figcaption class="blockquote" style="color: #0062cc;">
                     <cite title="Source Title"><b>- Address Information</b></cite>
                  </figcaption> <hr>
                  <div class="form-group row mb-3">
                   <label for="staticEmail" class="col-sm-2 col-form-label">Street<span class="star">*</span></label>
                   <div class="col-sm-5">
                     <input type="text" formControlName="street" class="form-control" id="staticEmail"
                     [ngClass]="{ 'is-invalid': submitted && personal.street.errors }">
                     <div *ngIf="submitted && personal.street.errors" class="invalid-feedback">
                      <div *ngIf="personal.street.errors.required">
                        Street Is Required.
                     </div> 
                   </div>
                    </div>
                 </div>
                 <div class="form-group row mb-3">
                   <label for="staticEmail" class="col-sm-2 col-form-label">Locality</label>
                   <div class="col-sm-5">
                     <input type="text" formControlName="locality" class="form-control" id="staticEmail"
                     [ngClass]="{ 'is-invalid': submitted && personal.locality.errors }">
                     <div *ngIf="submitted && personal.locality.errors" class="invalid-feedback">
                      <div *ngIf="personal.locality.errors.required">
                        Locality Is Required.
                     </div> 
                   </div>
                     
                   </div>
                 </div>
                 <div class="form-group row mb-3">
                   <label for="staticAddrArea" class="col-sm-2 col-form-label">City/Town/Village<span class="star">*</span></label>
                   <div class="col-sm-5">
                     <input type="text" formControlName="city" class="form-control" id="staticAddrArea" 
                     [ngClass]="{ 'is-invalid': submitted && personal.city.errors }">
                     <div *ngIf="submitted && personal.city.errors" class="invalid-feedback">
                      <div *ngIf="personal.city.errors.required">
                        City Is Required.
                     </div> 
                   </div>
                     
                   </div>
                 </div>
                 <div class="form-group row mb-3">
                   <label for="staticEmail" class="col-sm-2 col-form-label">State<span class="star">*</span></label>
                   <div class="col-sm-5">
                     <input type="text" formControlName="state" class="form-control" id="staticEmail"	  
                      [ngClass]="{ 'is-invalid': submitted && personal.state.errors }">
                      <div *ngIf="submitted && personal.state.errors" class="invalid-feedback">
                        <div *ngIf="personal.state.errors.required">
                          State Is Required.
                       </div> 
                     </div>
                     
                   </div>
                 </div>
                 <div class="form-group row mb-3">
                   <label for="staticEmail" class="col-sm-2 col-form-label">Taluka<span class="star">*</span></label>
                   <div class="col-sm-3">
                    <select id="inputgender" formControlName="taluka" class="form-select"
                    [ngClass]="{ 'is-invalid': submitted && personal.taluka.errors }" (change)="getdistrict($event.target)">
                  <option selected  value="" disabled >Select Taluka</option>
                  <option *ngFor="let t of alltalukas" [value]="t.taluka_name">{{t.taluka_name}}</option>
                </select>
                      <div *ngIf="submitted && personal.taluka.errors" class="invalid-feedback">
                        <div *ngIf="personal.taluka.errors.required">
                         Taluka Is Required.
                       </div> 
                     </div>
                    </div>
                    <label for="staticEmail" class="col-sm-2 col-form-label">District<span class="star">*</span></label>
                    <div class="col-sm-3">
                      <input type="text" formControlName="district" class="form-control" id="staticEmail" 	   
                      [ngClass]="{ 'is-invalid': submitted && personal.district.errors }" readonly>
                      <div *ngIf="submitted && personal.district.errors" class="invalid-feedback">
                        <div *ngIf="personal.district.errors.required">
                          District Is Required.
                       </div> 
                     </div> 
                    </div>
                 </div>
                 <div class="form-group row mb-3">
                   <label for="staticEmail" class="col-sm-2 col-form-label">Landmark</label>
                   <div class="col-sm-3">
                     <input type="text" formControlName="landmark" class="form-control" id="staticEmail">
                   </div>
                   <label for="staticEmail" class="col-sm-2 col-form-label">Pincode<span class="star">*</span></label>
                   <div class="col-sm-3">
                    <input type="text" formControlName="pincode" class="form-control" id="staticEmail" 	 
                      [ngClass]="{ 'is-invalid': submitted && personal.pincode.errors }"
                    (keypress)="allowNumericforpincode($event)">
                    <div *ngIf="submitted && personal.pincode.errors" class="invalid-feedback">
                      <div *ngIf="personal.pincode.errors.required">
                        Pincode Is Required.
                     </div> 
                   </div>
                    <div [innerHTML]="pinmsg" style="color: rgb(253, 0, 0);"></div>
                    
                   </div>
                 </div>
                 <div class="form-group row mb-3">
                   <label for="staticEmail" class="col-sm-2 col-form-label">House OwnerShip</label>
                   <div class="col-sm-1" style="margin-top: 9px;margin-left: 25px;">
                       <input class="form-check-input col-sm-3" type="radio" formControlName="house_ownership" name="house_ownership" id="gridRadios1" value="Own" > Own
                   </div>
                   <div class="col-sm-1" style="margin-top: 9px;margin-left: 25px;">
                       <input class="form-check-input col-sm-" type="radio" formControlName="house_ownership" name="house_ownership" id="gridRadios2" value="Rent" > Rent
                 
                   </div>
                </div>
                 <div class="hidden form-group row mb-3">
                   <label for="staticEmail" class="col-sm-2 col-form-label">Internet Coverage</label>
                   <div class="col-sm-1" style="margin-top: 9px;margin-left: 25px;">
                       <input class="form-check-input col-sm-3" type="radio" formControlName="internet_coverage" name="internet_coverage" id="gridRadios1" value="Yes" > Yes
                   </div>
                   <div class="col-sm-1" style="margin-top: 9px;margin-left: 25px;">
                       <input class="form-check-input col-sm-" type="radio" formControlName="internet_coverage" name="internet_coverage" id="gridRadios2" value="No" > No
                 
                   </div>
                </div>
                <div class="hidden form-group row mb-3">
                  <label for="staticEmail" class="col-sm-2 col-form-label">Distance To Coverage (in Kms)</label>
                  <div class="col-sm-5">
                      <input type="text" class="form-control" formControlName="distance_coverage" id="staticEmail" restrict-to="[0-9]" (keypress)="allowNumericDfordistance($event)">
                      <div [innerHTML]="distmsg" style="color: rgb(253, 0, 0);"></div>
                   </div>
                 
               </div>
               <div class="form-group row mb-3"  >
                <label for="staticname" class="col-sm-2 col-form-label">Distance of School from House (in Kms)</label>
                  <div class="col-sm-5">
                    <input type="text"  class="form-control" formControlName="distance_of_school_to_house" id="staticName" restrict-to="[0-9]" (keypress)="allowNumericDigitschool($event)">
                    <div [innerHTML]="distmsg" style="color: rgb(253, 0, 0);"></div>
                  </div>
                   
               </div>
                <div class="form-group row mb-3"  >
                 <label for="staticname" class="col-sm-2 col-form-label">Mode of Transport</label>
                 <div class="col-sm-5">
                   <select id="inputgender" formControlName="mode_of_transport" class="form-select">
                     <option selected disabled>Select Mode Of Transport</option>
                     <option value="Walking">Walking</option>
                     <option value="Private_Vehicle">Private Vehicle</option>
                     <option value="Auto_Rikshaw">Auto Rickshaw</option>
                     <option value="Public_Transport">Public Transport</option>
                   </select>
                 </div>
                
                </div>
                 <div class="form-group row mb-3">
                   <label for="staticEmail" class="col-sm-2 col-form-label">Access to Smart Phone</label>
                   <div class="col-sm-1" style="margin-top: 9px;margin-left: 25px;">
                       <input class="form-check-input col-sm-3" type="radio" formControlName="access_to_smart_phone" name="access_smartphone" id="gridRadios1" value="Yes" > Yes
                   </div>
                   <div class="col-sm-1" style="margin-top: 9px;margin-left: 25px;">
                       <input class="form-check-input col-sm-3" type="radio" formControlName="access_to_smart_phone" name="access_smartphone" id="gridRadios2" value="No" > No
                 
                   </div>
                </div>
                <div class="form-group row mb-3">
                   <label for="staticEmail" class="col-sm-2 col-form-label">Access to Internet</label>
                   <div class="col-sm-1" style="margin-top: 9px;margin-left: 25px;">
                       <input class="form-check-input col-sm-3" type="radio" formControlName="access_internet" name="access_internet" id="gridRadios1" value="Yes" > Yes
                   </div>
                   <div class="col-sm-1" style="margin-top: 9px;margin-left: 25px;">
                       <input class="form-check-input col-sm-3" type="radio" formControlName="access_internet" name="access_internet" id="gridRadios2" value="No" > No
                 
                   </div>
                </div>
               
                   <figcaption class="blockquote" style="color: #0062cc;">
                     <cite title="Source Title"><b>- Other Information</b></cite>
                  </figcaption> <hr>

                  <div class="form-group row mb-3">
                   <label for="staticEmail" class="col-sm-2 col-form-label">Father's Qualification</label>
                   <div class="col-sm-5">
                       <input type="text" class="form-control" formControlName="fathers_qualification" id="staticEmail" >
                    </div>
                   
                 </div>
                 <div class="form-group row mb-3">
                    <label for="staticEmail" class="col-sm-2 col-form-label">Father's Job</label>
                   <div class="col-sm-5">
                       <input type="text" class="form-control" formControlName="fathers_job" id="staticEmail" >
                    </div>
                   
                 </div>
                 <div class="form-group row mb-3">
                   <label for="staticEmail" class="col-sm-2 col-form-label">Father's Annual Income</label>
                   <div class="col-sm-5">
                       <input type="number" class="form-control" formControlName="fathers_annual_income" id="staticEmail" >
                    </div>
                   
                 </div>
                 <div class="form-group row mb-3">
                    <label for="staticEmail" class="col-sm-2 col-form-label">Mother's Qualification</label>
                   <div class="col-sm-5">
                       <input type="text" class="form-control" formControlName="mothers_qualification" id="staticEmail" >
                    </div>
                  
                 </div>
                 <div class="form-group row mb-3">
                   <label for="staticEmail" class="col-sm-2 col-form-label">Mother's Job</label>
                   <div class="col-sm-5">
                       <input type="text" class="form-control" formControlName="mothers_job" id="staticEmail">
                    </div>
                  
                 </div>
                 <div class="form-group row mb-3">
                    <label for="staticEmail" class="col-sm-2 col-form-label">Mother's Annual Income</label>
                   <div class="col-sm-5">
                       <input type="number" class="form-control" formControlName="mothers_annual_income" id="staticEmail" >
                    </div>
                
                 </div>
                   <figcaption class="blockquote" style="color: #0062cc;">
                     <cite title="Source Title"><b>- Academic Information </b></cite>
                  </figcaption> <hr>
                  <div class="form-group row mb-3">
                   <label for="subject_one" class="col-sm-2 col-form-label">Subject 1<span class="star">*</span></label>
                   <div class="col-sm-5">
                       <select id="subject_one" formControlName="subject_one"  class="form-select" name="subject_one"
                        [ngClass]="{ 'is-invalid': submitted && personal.subject_one.errors }">
                           <option selected disabled value="">Select </option>
                           <option *ngFor="let sub of subject" [value]="sub.subject_code" >{{sub.subjectname}}</option>
                           <!-- <option [value]="sub.position">Marathi</option> -->
                         </select>
                         <div *ngIf="submitted && personal.subject_one.errors" class="invalid-feedback">
                          <div *ngIf="personal.subject_one.errors.required">
                            subject 1 Is Required.
                         </div> 
                       </div>
                   </div>
                 </div>
                 <div class="form-group row mb-3">
                   <label for="subject_two" class="col-sm-2 col-form-label">Subject 2<span class="star">*</span></label>
                   <div class="col-sm-5">
                       <select id="subject_two" formControlName="subject_two" class="form-select" 
                       [ngClass]="{ 'is-invalid': submitted && personal.subject_two.errors }">
                        
                           <option selected disabled value="">Select </option>
                           <option *ngFor="let sub1 of subject1" [value]="sub1.subject_code">{{sub1.subjectname}}</option>
                           <!-- <option value="Marathi">Marathi</option> -->
                         </select>
                         <div *ngIf="submitted && personal.subject_two.errors" class="invalid-feedback">
                          <div *ngIf="personal.subject_two.errors.required">
                            subject 2 Is Required.
                         </div> 
                       </div>
                   </div>
                 </div>
                 <div class="form-group row mb-3">
                   <label for="subject_three" class="col-sm-2 col-form-label">Subject 3<span class="star">*</span></label>
                   <div class="col-sm-5">
                       <select id="subject_three" formControlName="subject_three" class="form-select"
                       [ngClass]="{ 'is-invalid': submitted && personal.subject_three.errors }">
                           <option selected disabled value="">Select </option>
                           <option *ngFor="let sub2 of subject2" [value]="sub2.subject_code">{{sub2.subjectname}}</option>
                           <!-- <option value="Marathi">Soc Sci</option> -->
                         </select>
                         <div *ngIf="submitted && personal.subject_three.errors" class="invalid-feedback">
                          <div *ngIf="personal.subject_three.errors.required">
                            subject 3 Is Required.
                         </div> 
                       </div>
                   </div>
                 </div>
                 <div class="form-group row mb-3">
                   <label for="subject_four" class="col-sm-2 col-form-label">Subject 4<span class="star">*</span></label>
                   <div class="col-sm-5">
                       <select id="subject_four" formControlName="subject_four" class="form-select"
                       [ngClass]="{ 'is-invalid': submitted && personal.subject_four.errors }">
                           <option selected disabled value="">Select </option>
                           <option *ngFor="let sub3 of subject3" [value]="sub3.subject_code">{{sub3.subjectname}}</option>
                           <!-- <option value="Marathi">Marathi</option> -->
                         </select>
                         <div *ngIf="submitted && personal.subject_four.errors" class="invalid-feedback">
                          <div *ngIf="personal.subject_four.errors.required">
                            subject 4 Is Required.
                         </div> 
                       </div>
                   </div>
                 </div>
                 <div class="form-group row mb-3">
                   <label for="subject_five" class="col-sm-2 col-form-label">Subject 5<span class="star">*</span></label>
                   <div class="col-sm-5">
                       <select id="subject_five" formControlName="subject_five" class="form-select" 
                       [ngClass]="{ 'is-invalid': submitted && personal.subject_five.errors }">
                           <option selected disabled value="">Select </option>
                           <option *ngFor="let sub4 of subject4" [value]="sub4.subject_code">{{sub4.subjectname}}</option>
                           <!-- <option value="Marathi">Marathi</option> -->
                         </select>
                         <div *ngIf="submitted && personal.subject_five.errors" class="invalid-feedback">
                          <div *ngIf="personal.subject_five.errors.required">
                            subject 5 Is Required.
                         </div> 
                       </div>
                   </div>
                 </div>
                 <div class="form-group row mb-3">
                   <label for="subject_six" class="col-sm-2 col-form-label">Subject 6<span class="star">*</span></label>
                   <div class="col-sm-5">
                       <select id="subject_six" formControlName="subject_six" class="form-select"
                       [ngClass]="{ 'is-invalid': submitted && personal.subject_six.errors }">
                           <option selected disabled value ="">Select </option>
                           <option *ngFor="let sub5 of subject5" [value]="sub5.subject_code">{{sub5.subjectname}}</option>
                           <!-- <option value="Marathi">Marathi</option> -->
                         </select>
                         <div *ngIf="submitted && personal.subject_six.errors" class="invalid-feedback">
                          <div *ngIf="personal.subject_six.errors.required">
                            subject 6 Is Required.
                         </div> 
                       </div>
                   </div>
                 </div>
                
                <div class="form-group row mb-3">
                  <label for="subject_seven" class="col-sm-2 col-form-label">Subject 7</label>
                  <div class="col-sm-5">
                      <select id="subject_seven" formControlName="subject_seven" class="form-select"
                      (change)="setsuboptional($event)"
                      [ngClass]="{ 'is-invalid': submitted && personal.subject_seven.errors }">
                          <option selected disabled value ="">Select </option>
                          <option *ngFor="let sub6 of subject6" [value]="sub6.subject_code">{{sub6.subjectname}}</option>
                          <!-- <option value="Marathi">Marathi</option> -->
                        </select>
                        <div *ngIf="submitted && personal.subject_seven.errors" class="invalid-feedback">
                          <div *ngIf="personal.subject_seven.errors.required">
                            subject 7 Is Required.
                         </div> 
                       </div>
                  </div>
                </div>
                <div class="form-group row mb-3">
                  <label for="subject_eight" class="col-sm-2 col-form-label">Subject 8</label>
                  <div class="col-sm-5">
                      <select id="subject_eight" formControlName="subject_eight" class="form-select"
                      [ngClass]="{ 'is-invalid': submitted && personal.subject_eight.errors }">
                          <option selected disabled value ="">Select </option>
                          <option *ngFor="let sub7 of subject7" [value]="sub7.subject_code">{{sub7.subjectname}}</option>
                          <!-- <option value="Marathi">Marathi</option> -->
                        </select>
                        <div *ngIf="submitted && personal.subject_eight.errors" class="invalid-feedback">
                          <div *ngIf="personal.subject_eight.errors.required">
                            subject 8 Is Required.
                         </div> 
                       </div>
                  </div>
                </div>
                <div class="form-group row mb-3">
                  <label for="subject_nine" class="col-sm-2 col-form-label">Subject 9</label>
                  <div class="col-sm-5">
                      <select id="subject_nine" formControlName="subject_nine" class="form-select" 
                      [ngClass]="{ 'is-invalid': submitted && personal.subject_nine.errors }">
                          <option selected disabled value ="">Select </option>
                          <option *ngFor="let sub8 of subject8" [value]="sub8.subject_code">{{sub8.subjectname}}</option>
                          <!-- <option value="Marathi">Marathi</option> -->
                        </select>
                        <div *ngIf="submitted && personal.subject_nine.errors" class="invalid-feedback">
                          <div *ngIf="personal.subject_nine.errors.required">
                            subject 9 Is Required.
                         </div> 
                       </div>
                  </div>
                </div>
                <div class="form-group row mb-3">
                  <label for="subject_ten" class="col-sm-2 col-form-label">Subject 10</label>
                  <div class="col-sm-5">
                      <select id="subject_ten" formControlName="subject_ten" class="form-select"
                      [ngClass]="{ 'is-invalid': submitted && personal.subject_ten.errors }">
                          <option selected value ="">Select </option>
                          <option *ngFor="let sub9 of subject9" [value]="sub9.subject_code">{{sub9.subjectname}}</option>
                          <!-- <option value="Marathi">Marathi</option> -->
                        </select>
                        <div *ngIf="submitted && personal.subject_ten.errors" class="invalid-feedback">
                          <div *ngIf="personal.subject_ten.errors.required">
                            subject 10 Is Required.
                         </div> 
                       </div>
                  </div>
                </div>
                <div class="form-group row mb-3">
                  <label for="subject_eleven" class="col-sm-2 col-form-label">Subject 11</label>
                  <div class="col-sm-5">
                      <select id="subject_eleven" formControlName="subject_eleven" class="form-select"
                      [ngClass]="{ 'is-invalid': submitted && personal.subject_eleven.errors }">
                          <option selected disabled value ="">Select </option>
                          <option *ngFor="let sub10 of subject10" [value]="sub10.subject_code">{{sub10.subjectname}}</option>
                          <!-- <option value="Marathi">Marathi</option> -->
                        </select>
                        <div *ngIf="submitted && personal.subject_eleven.errors" class="invalid-feedback">
                          <div *ngIf="personal.subject_eleven.errors.required">
                            subject 11 Is Required.
                         </div> 
                       </div>
                  </div>
                </div>
                <div class="form-group row mb-3">
                  <label for="subject_twelve" class="col-sm-2 col-form-label">Subject 12</label>
                  <div class="col-sm-5">
                      <select id="subject_twelve" formControlName="subject_twelve" class="form-select"
                      [ngClass]="{ 'is-invalid': submitted && personal.subject_twelve.errors }">
                          <option selected disabled  value="">Select </option>
                          <option *ngFor="let sub11 of subject11" [value]="sub11.subject_code">{{sub11.subjectname}}</option>
                          <!-- <option value="Marathi">Marathi</option> -->
                        </select>
                        <div *ngIf="submitted && personal.subject_twelve.errors" class="invalid-feedback">
                          <div *ngIf="personal.subject_twelve.errors.required">
                            subject 12 Is Required.
                         </div> 
                       </div>
                  </div>
                </div>
                <div class="form-group row mb-3">
                  <label for="subject_thirteen" class="col-sm-2 col-form-label">Subject 13</label>
                  <div class="col-sm-5">
                      <select id="subject_thirteen" formControlName="subject_thirteen" class="form-select"
                      [ngClass]="{ 'is-invalid': submitted && personal.subject_thirteen.errors }">
                          <option selected disabled value="">Select </option>
                          <option *ngFor="let sub12 of subject12" [value]="sub12.subject_code">{{sub12.subjectname}}</option>
                          <!-- <option value="Marathi">Marathi</option> -->
                        </select>
                        <div *ngIf="submitted && personal.subject_thirteen.errors" class="invalid-feedback">
                          <div *ngIf="personal.subject_thirteen.errors.required">
                            subject 13 Is Required.
                         </div> 
                       </div>
                  </div>
                </div>
                 <!-- <figcaption class="blockquote" style="color: #0062cc;">
                   <cite title="Source Title"><b>- Upload Photo </b></cite>
                </figcaption> <hr>
                <div class="form-group row mb-3">
                 <label for="staticEmail" class="col-sm-2 col-form-label">Photo</label>
                <div class="col-sm-5">
                    <input type="file" accept="image/png, image/jpeg" class="form-control" id="photo" formControlName="photo"
                    (change)="onPhotoSelect($event)"  [ngClass]="{ 'is-invalid': submitted && personal.photo.errors }">
                    <div *ngIf="submitted && personal.photo.errors" class="invalid-feedback">
                      <div *ngIf="personal.photo.errors.required">
                        Photo Is Required.
                     </div> 
                   </div>
                     <div *ngIf="photoinfo == null; else showpic">
                    </div>
                    <ng-template #showpic>
                      <button type="button" class="btn btn-success mt-3" data-toggle="modal" data-target="#photoModal">
                        View Uploaded Photo
                      </button>
                      <br><sub>Photo is uploaded by Candidate, You can change if needed.</sub>
                      modal start
                        <div class="modal fade" data-backdrop="static" id="photoModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div class="modal-dialog" role="document">
                            <div class="modal-content">
                               <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Uploaded Photo</h5>
                                
                              </div>
                              <div class="modal-body mx-auto viewimgmodalbody">
                                  <img [src]="photoinfo">
                              </div>
                              <div class="modal-footer">
                                <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
                              </div>
                            </div>
                          </div>
                        </div> 
                      modal end
                    </ng-template>
                   </div> 
                    
                  </div>
              -->
             
                   <figcaption class="blockquote" style="color: #0062cc;">
                     <cite title="Source Title"><b>-  Security Details</b></cite>
                  </figcaption> <hr>
                 
                  <div class="form-group row mb-3">
                     <label for="staticEmail" class="col-sm-2 col-form-label">Security Pin</label>
                     <span class="col-sm-3 captcha" id="captcha" style="background-image:url('/assets/img/brand/dot.jpg');background-repeat: no-repeat;width:'200px'">{{captcha}} </span>
                     <span class="col-sm-2"> <a (click)="refresh()"> <img src="./assets/img/brand/refresh.png" height="50px" width="50px"></a></span>
                    
                  </div>
                  <div class="form-group row mb-3">
                     <label for="staticEmail" class="col-sm-2 col-form-label">Enter Security Pin<span class="star">*</span></label>
                    <div class="col-sm-5">
                        <input type="text" formControlName="captcha_text" class="form-control" id="captcha_text" [ngClass]="{ 'is-invalid': submitted && personal.captcha_text.errors }"  >
                    
                        <div *ngIf="submitted && personal.captcha_text.errors" class="invalid-feedback">
                          <div *ngIf="personal.captcha_text.errors.required">
                             captcha Is Required.
                          </div> 
                         
                        </div>
                      </div>
                    
                  </div>
                  <!-- <div class="form-group row mb-3">
                   <label for="staticEmail" class="col-sm-2 col-form-label"></label>
                     <input type="button" class="btnSubmit btn btn-success"  value="Save Changes" (click)="savedata()" /> &nbsp;
                  
                     <input type="button" class="btnSubmit btn btn-primary"  value="Submit" (click)="onstudentprofilesubmit()" [disabled] = 'isdisable'/> 
                   
                     
                  </div> -->
                  <div class="form-group row mb-3 btnGrp">
                       
                    <input type="button" class="btnSubmit btn btn-primary"  value="Save Changes" (click)="onstudentprofilesubmit()"  /> &nbsp;
                    <input type="button" class="btnSubmit btn btn-secondary" value="Cancel"  [routerLink]="['/candidates-details']"/>
                    <!-- (click)="onstudentprofilesubmit(studentprofileform.value)" -->
                 </div>
                </form>
              </div>      
         
          
        </div>       
      </div>
   
    <div class="row mb-3"></div>
</div>