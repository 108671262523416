import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NavbarService } from '../default/navbar/navbar.service';
import { SchoolserviceService } from '../schoolservice.service';
import {NgbModal, ModalDismissReasons, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-onetimechangepassword',
  templateUrl: './onetimechangepassword.component.html',
  styleUrls: ['./onetimechangepassword.component.scss']
})
export class OnetimechangepasswordComponent implements OnInit {
  @ViewChild('content') content: any;
  changpwdform: FormGroup;
 
  captcha: any;
  alphaNums = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
   emptyArr:any = [];
   refreshArr :any = [];
  loginuser: any;
  isloggin: any;
  username: any;
  studID: any;
  std_enroll_id: any;
  enrolldata: any;
  modalOption: NgbModalOptions = {};
  submission_status: any;
  standard:any;

  constructor(public formbuilder:FormBuilder,public router:Router, public service:SchoolserviceService,public nav:NavbarService,private modalService: NgbModal) {
    this.changpwdform = this.formbuilder.group({
      username:[''],
      password :[''],
      newpassword:[''],
      captcha_text:['']
    })
   
   }
 
  ngOnInit(): void {

   this.username = sessionStorage.getItem('username');
    // this.std_enroll_id = '1';
    this.studID = sessionStorage.getItem('studentid');
    this.isloggin = sessionStorage.getItem('status');
    // this.standard = sessionStorage.getItem('standard');

    console.log(this.isloggin)
    let enroll :{studentid:any}={
      studentid:this.studID
    }
    let seq =this.service.post('/StudentApi9/enrollmenetid',enroll);
    seq.subscribe((res:any) =>{
      this.enrolldata = res['Data'];
      this.standard = this.enrolldata;
     console.log(this.standard);

     if(this.standard == '9th')
     {
       this.nav.dhide();
       this.nav.studshow(); 
       this.nav.XIstudhide();
       this.nav.aduserhide();
       this.nav.hide();
       this.nav.adhide();
       this.nav.asdhide();
 
     }
     else
     {
       this.nav.dhide();
       this.nav.studhide(); 
       this.nav.XIstudshow();
       this.nav.aduserhide();
       this.nav.hide();
       this.nav.adhide();
       this.nav.asdhide();
     }

  
    });

    // console.log(this.std_enroll_id );
    // This loop generates a random string of 7 characters using alphaNums
    // Further this string is displayed as a CAPTCHA

    for (let i = 1; i <= 5; i++) {
    this.emptyArr.push(this.alphaNums[Math.floor(Math.random() * this.alphaNums.length)]);
    }
     this.captcha = this.emptyArr.join('');

    //  console.log(this.captcha);

    
  }

  ngAfterViewInit() {
    
    if(this.isloggin == 'New')
    {
      this.router.onSameUrlNavigation = 'reload'; 
      this.openModal();
    }
    else
    {
      this.router.onSameUrlNavigation = 'reload';  
    }
  }
  openModal(){

    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    this.modalService.open(this.content, this.modalOption);
  }
// This event listener is stimulated whenever the user press the "Refresh" button
  refresh()
  {
    // form.captcha_text = "";
    // let refreshArr = [];
    for (let j = 1; j <= 5; j++) {
      
    this.refreshArr.push(this.alphaNums[Math.floor(Math.random() * this.alphaNums.length)]);
    // this.emptyArr.splice(0,this.emptyArr.length);
    
    }

    this.captcha = this.refreshArr.join('');
    this.refreshArr.splice(0,this.refreshArr.length)
    // console.log(this.captcha);


  }

  ONChangepassword(form: {username:any, password: any; newpassword: any; captcha_text:any; })
  {
    let login_para ={
      "username": this.username,
      "password":form.password,
      "newpassword":form.newpassword
     }

     console.log(login_para);
     if (form.captcha_text == this.captcha)
     {
     this.service.post('/StudentApi9/changepassword',login_para).subscribe((res:any)=>{

        if(res['status'] == 'success')
        {
             let message = res['Msg'];

             alert(message);
             this.modalService.dismissAll(); 
            //  this.router.navigate(['/studentprofile']);
            let enroll :{studentid:any}={
              studentid:this.studID
            }
            let seq =this.service.post('/StudentApi9/enrollmenetid',enroll);
            seq.subscribe((res:any) =>{
              this.enrolldata = res['Data'];
              this.std_enroll_id = this.enrolldata;
             console.log(this.enrolldata);
                
                  if(this.std_enroll_id == '9th')
                  {
                    // this.router.onSameUrlNavigation = 'reload'; 
                    // this.router.navigate(['/studentprofile']);
                    this.router.navigate(['/studentchklanding']);
                    // window.location.reload();
                  }
                  else{
                    // this.router.onSameUrlNavigation = 'reload'; 
                    // this.router.navigate(['/candidateprofile']);
                    this.router.navigate(['/elevenstudents']);
                    // window.location.reload();
                  }
                
            });
            
        }
        else{
            let message = res['Msg'];

             alert(message);
        }


     });
    }
    else
    {
      alert('check captcha');
    }
    

  }

  logout(){
    console.log("Hit!")
    window.sessionStorage.clear();
    // window.location.reload();
    this.modalService.dismissAll(); 
    this.router.navigate(['/home']);
  }

}
