import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SchoolserviceService } from '../schoolservice.service';
import { NavbarService } from '../default/navbar/navbar.service';



@Component({
  selector: 'app-bulk-up-unregistred-schools',
  templateUrl: './bulk-up-unregistred-schools.component.html',
  styleUrls: ['./bulk-up-unregistred-schools.component.scss']
})
export class BulkUpUnregistredSchoolsComponent implements OnInit {
  role_type: any;
  selctform !: FormGroup;
  selctform1 !: FormGroup;
  filesubmitted =false;
  filesubmitted1 =false;
  fileToUpload!: File;
  fileToUpload1!: File;
  actionselectedsingle: boolean=false;
  actionselectedbulk: boolean=false;
  proccessing=false;
  constructor( public service:SchoolserviceService,public nav:NavbarService,public formbuilder:FormBuilder,) { 
    this.selctform = this.formbuilder.group({
      // stud_numbers :['']
      file:['',Validators.required]
    });
    this.selctform1 = this.formbuilder.group({
      // stud_numbers :['']
      file1:['',Validators.required]
    });
  }

  ngOnInit(): void {
    this.role_type =  sessionStorage.getItem('role_type');
    if(this.role_type == 'boardadmin')
    {
      this.nav.hide();
      this.nav.dhide();
      this.nav.adshow();
      this.nav.aduserhide();
      this.nav.asdhide();
      this.nav.studhide();
      this.nav.XIstudhide();
    }
    else
    {
      this.nav.hide();
      this.nav.dhide();
      this.nav.adhide();
      this.nav.adusershow();
      this.nav.asdhide();
      this.nav.studhide();
      this.nav.XIstudhide();
    }

  }
  checkactiontaken(sel:any){
    // alert(sel.value);
    if(sel.value=="single"){
     this.actionselectedsingle=true;
     this.actionselectedbulk=false;
    }else{
      this.actionselectedsingle=false;
     this.actionselectedbulk=true;

    }
}
  get personal() { return this.selctform.controls; }
  get personal1() { return this.selctform1.controls; }
  handleFileInput(event:any){
 this.fileToUpload = event.target.files.item(0);
  
   
  }
  handleFileInput1(event:any){
 this.fileToUpload1 = event.target.files.item(0);
  
   
  }
  upload()
  {
  this.filesubmitted = true;
  if (this.selctform.invalid) {      
    // alert("Please Select file For Upload");
    return;
  }
   const formdata = new FormData();
   formdata.append("upload_file", this.fileToUpload);
   this.proccessing=true;
this.service.post('/BoardApi/unregi_schools_bulk_import_10_12',formdata).subscribe((res:any)=>{
   if(res['status'] == 'Success')
      {
  //       var msg = res['Msg'];
  //       alert(msg);
  //       // window.location.reload();
  //     }
  //     else if(res['status'] == 'duplicate')
  //     {
  //       let msg = res['Msg'] + res['Data'];
  //       alert(msg);
  //       // window.location.reload();
  //     }
  //     else if(res['status'] == 'missing')
  //     {
  //       let msg = res['Msg'];
  //       alert(msg);
  //       // window.location.reload();
  //     }
  //     else if(res['status'] == 'Failed')
  //     {
  //       let msg = res['Msg'];
         alert(""+res['Msg']); 
         this.proccessing=false;
        // window.location.reload();
      }
      else{
        alert(""+res['Msg']);
        this.proccessing=false;
        // window.location.reload();
      }

   });
}
upload_bulk()
  {
 
  this.filesubmitted1 = true;
  if (this.selctform1.invalid) {      
    // alert("Please Select file For Upload");
    return;
  }
   const formdata = new FormData();
   formdata.append("upload_file", this.fileToUpload1);
   this.proccessing=true;
this.service.post('/BoardApi/import_unenrolled_student_10_12',formdata).subscribe((res:any)=>{
   if(res['status'] == 'Success')
      {
  //       var msg = res['Msg'];
  //       alert(msg);
  //       // window.location.reload();
  //     }
  //     else if(res['status'] == 'duplicate')
  //     {
  //       let msg = res['Msg'] + res['Data'];
  //       alert(msg);
  //       // window.location.reload();
  //     }
  //     else if(res['status'] == 'missing')
  //     {
  //       let msg = res['Msg'];
  //       alert(msg);
  //       // window.location.reload();
  //     }
  //     else if(res['status'] == 'Failed')
  //     {
  //       let msg = res['Msg'];
  alert(""+res['Msg']);
  this.proccessing=false;
        // window.location.reload();
      }
      else{
        alert(""+res['Msg']);
        this.proccessing=false;
        // window.location.reload();
      }

   });
}

}
