import { Component, OnInit} from '@angular/core';
import { NavbarService } from '../default/navbar/navbar.service';
import { SchoolserviceService } from '../schoolservice.service';
import{reportData} from'./reportData';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
rptYear!:string;  
rptStd:string="";  
rptCat:string="";  
rptCatClmTitle!:string;  
rptCatClmTitleSmall!:string;  
btnUpdate:boolean=true;
allData!:reportData[];
stdList:String[]=[];
categoryTitle:String[]=[];
dropdownList:String[]=[];
dropdownListCat:String[]=[];
fileName= 'ReportExcelSheet.xlsx';
  role_type: any;
constructor(public service:SchoolserviceService,public nav:NavbarService) { }

  ngOnInit() {
    
    this.role_type =  sessionStorage.getItem('role_type');
    if(this.role_type == 'boardadmin')
    {
      this.nav.hide();
      this.nav.dhide();
      this.nav.adshow();
      this.nav.aduserhide();
      this.nav.asdhide();
      this.nav.studhide();
      this.nav.XIstudhide();
    }
    else
    {
      this.nav.hide();
      this.nav.dhide();
      this.nav.adhide();
      this.nav.adusershow();
      this.nav.asdhide();
      this.nav.studhide();
      this.nav.XIstudhide();
    }
  }
  updateStdList(e:any){
    if(e.target.name=='yearwise' && e.target.value!='Select'){
      //alert("year wise is changed");
      this.dropdownList=["Select Standard","9th","11th"]; 
      this.rptYear=e.target.value;
      if(this.rptCat!=""&&this.rptStd!=""&&this.rptYear!=""){
        this.saveAllVar();
      }
    }
    else{
      this.dropdownList=[];
      this.dropdownListCat=[];
      this.allData=[];
    }
  }
  updateListCat(e:any){
    if(e.target.name=='clswise' && e.target.value!='Select Standard'){
      this.dropdownListCat=["Select Report Category","School Wise","Taluka Wise","District Wise","Caste Wise","Gender Wise","Management Type"];
      this.rptStd=e.target.value;
      if(this.rptCat!="" && this.rptStd!="" && this.rptYear!=""){
        this.saveAllVar();
      }
    }
    else{
      this.dropdownListCat=[];
      this.allData=[];
    }
  }
  checkCat(e:any){   
    if(e.target.value=='Select Report Category'){
      this.btnUpdate=true;
    }
    else{
    this.btnUpdate=false;
    this.rptCat=e.target.value;
    this.saveAllVar()
    }
  }
  saveAllVar(){
    this.rptCatClmTitle=this.rptCat.split(" ")[0];
    this.rptCatClmTitleSmall=this.rptCatClmTitle.toLowerCase();
    this.displayRegisteredCount();    
  }
  displayRegisteredCount(){
    let CatWiseReportDetail:{year:any, standard:any, orderwise:any}={
      year:this.rptYear , 
      standard:this.rptStd,
       orderwise:this.rptCatClmTitleSmall
    }
    this.service.post('/BoardApi/reportData',CatWiseReportDetail).subscribe((CatWiseReport: any) => {
      this.allData= CatWiseReport.Data;
      console.log(this.allData);
  })
  }
  // Export to excel file

exportexcel():void 
{
   /* table id is passed over here */   
   let element = document.getElementById('excel-table');   
   const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
   /* generate workbook and add the worksheet */
   const wb: XLSX.WorkBook = XLSX.utils.book_new();
   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

   /* save to file */
   XLSX.writeFile(wb, this.fileName);

  //  let stud = {
  //    "status" :"Payment Settled"
    
  //  }
  //  console.log(stud);
   
  //  this.service.updateamstatus(stud).subscribe((res:any)=>{
  //   console.log(res);
  //   this.getstatusdata = res;
  //   this.loading = false;
  //   if(res){
  //     let Message = res['Message'];
  //     // alert(Message);
  //     // Swal.fire("claim Submitted Successfully")
  //     Swal.fire(Message);
  //    this.getreports();
  //    location.reload();

  //    // this.modalservice.close();
  //   //  this._Router.navigate(['/new-claim']);
  //   }
  //   else{
  //     let Message = res['Msg'];
  //     Swal.fire(Message)
  //    // this.modalservice.close();
  //   }
  // });
  
}
}