import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SchoolserviceService } from '../schoolservice.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-candidate-reset-password',
  templateUrl: './candidate-reset-password.component.html',
  styleUrls: ['./candidate-reset-password.component.scss']
})
export class CandidateResetPasswordComponent implements OnInit {
  candResetForm: FormGroup;
  submitted = false;
  newUserSatatus!:String;
  studentid: any;

  constructor(public formbuilder:FormBuilder,public router:Router,private _routeParams: ActivatedRoute, public service:SchoolserviceService,public datepipe: DatePipe) { 
    this.candResetForm = formbuilder.group({
      //username:['',Validators.required],
      newpass :['',Validators.required],
      confpass:['',Validators.required]
      //password:['', Validators.required]
    })
  }

  ngOnInit(): void {
    // this.studentid = sessionStorage.getItem('studentid');

    // console.log(this.studentid);

    var id = this._routeParams.params.subscribe(params => {

      this.studentid = String(params["studentid"]);
       console.log(this.studentid);
      // alert(seat);
            
  });
  }

  get personal() { 
    return this.candResetForm.controls; }

  onsubmit(){
    
    this.submitted = true;
    if (this.candResetForm.invalid) {
      
      alert("Fill All Deatils");
      return;

    }
    else{
      this.saveNewPass();
    }

  }
  saveNewPass(){
    let resetpass:{studentid:any,newpassword:any}={
      studentid:this.studentid,
      newpassword:this.candResetForm.controls.newpass.value
    }
    this.service.post('/StudentApi9/forgotPassword',resetpass).subscribe((res: any) => {
      
      if(res['status'] == 'Success')
      {
        alert('Your Password is Changed');
        this.router.navigate(['/home']);
      }
      else
      {
        alert('Not Changed');
      }
  })
  }

}
