import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SchoolserviceService } from '../schoolservice.service';
import { DatePipe } from '@angular/common';
import { NavbarService } from '../default/navbar/navbar.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-elevenstudents',
  templateUrl: './elevenstudents.component.html',
  styleUrls: ['./elevenstudents.component.scss']
})
export class ElevenstudentsComponent implements OnInit {

  studID: any;
  studschool: any;
  status: any;

  constructor(public formbuilder:FormBuilder,public router:Router, public service:SchoolserviceService,public datepipe: DatePipe,public nav:NavbarService,private sanitizer: DomSanitizer) 
  {

  }

  ngOnInit(): void {

    this.studID = sessionStorage.getItem('studentid');
    //  console.log(this.status);
      
      let user:{studentid:any}={
        studentid:this.studID
      }
      let seq = this.service.post('/StudentApi11/getstudentclassForm',user);
       seq.subscribe((res:any) => {
              
              this.studschool= res['studschool'];
              this.status = this.studschool[0].submission_status;
              console.log(this.status);
              if(this.status != "New" && this.status != "Pending")
            {
              // this.router.onSameUrlNavigation = 'reload'; 
              this.router.navigate(['/viewcandidateprofile']);
              // this.router.onSameUrlNavigation = 'reload'; 
  
  
            }
           
               
       });
  }

}
