import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule,ReactiveFormsModule} from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';
import { StudloginComponent } from './studlogin/studlogin.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { CandidateProfileComponent } from './candidate-profile/candidate-profile.component';
import { StudentprofileComponent } from './studentprofile/studentprofile.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import { ViewschoolsComponent } from './viewschools/viewschools.component';
import { ViewschooldetailsComponent } from './viewschooldetails/viewschooldetails.component';
import { ViewschoolCandidatelistComponent } from './viewschool-candidatelist/viewschool-candidatelist.component';
import { EditcandidatedetailsComponent } from './editcandidatedetails/editcandidatedetails.component';
import { BoarduserComponent } from './boarduser/boarduser.component';
import { ViewboardlistComponent } from './viewboardlist/viewboardlist.component';

import { NavbarComponent } from './default/navbar/navbar.component';
import { FooterComponent } from './default/footer/footer.component';
import { CandidatesDetailsComponent } from './candidates-details/candidates-details.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { GoogleChartsModule } from 'angular-google-charts';
import {DataTablesModule} from 'angular-datatables';
import { SchoolEnrollStudentsComponent } from './school-enroll-students/school-enroll-students.component';
import { EditboarduserComponent } from './editboarduser/editboarduser.component';
import { CandidatelandingComponent } from './candidatelanding/candidatelanding.component';
import { ViewstudentprofileComponent } from './viewstudentprofile/viewstudentprofile.component';
import { ViewcandidateprofileComponent } from './viewcandidateprofile/viewcandidateprofile.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { BoardloginComponent } from './boardlogin/boardlogin.component';
import { SchoolpaymentlistComponent } from './schoolpaymentlist/schoolpaymentlist.component';
import { BoarddashboardComponent } from './boarddashboard/boarddashboard.component';

// for primNG Tble
import {TableModule} from 'primeng/table';
import {CalendarModule} from 'primeng/calendar';
import {SliderModule} from 'primeng/slider';
import {DialogModule} from 'primeng/dialog';
import {MultiSelectModule} from 'primeng/multiselect';
import {CheckboxModule} from 'primeng/checkbox';
import {ContextMenuModule} from 'primeng/contextmenu';
import {ButtonModule} from 'primeng/button';
import {ToastModule} from 'primeng/toast';
import {InputTextModule} from 'primeng/inputtext';
import {ProgressBarModule} from 'primeng/progressbar';
import {DropdownModule} from 'primeng/dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReportsComponent } from './reports/reports.component';
import { CandidatesPaymentComponent } from './candidates-payment/candidates-payment.component';
import { PaymentLandingComponent } from './payment-landing/payment-landing.component';
import { BoardEnrollSchoolComponent } from './board-enroll-school/board-enroll-school.component';
import { NgSelect2Module } from 'ng-select2';
import { PageUnderMaintenanceComponent } from './page-under-maintenance/page-under-maintenance.component';
import { UpdatestatusCandidateComponent } from './updatestatus-candidate/updatestatus-candidate.component';
import { SchoolStudReportComponent } from './school-stud-report/school-stud-report.component';
import { Edit11thcandidateComponent } from './edit11thcandidate/edit11thcandidate.component'; //for live search
import { NgxPaginationModule } from 'ngx-pagination';
import { NavbarService } from './default/navbar/navbar.service';
import { AuthGuard } from './auth.guard';
import { SchoolauthGuard } from './schoolauth.guard';
import { SchoolserviceService } from './schoolservice.service';
import { DatePipe } from '@angular/common';
import { SchoolAllyrcandidateComponent } from './school-allyrcandidate/school-allyrcandidate.component';
import { Viewyearwise9thstudentsComponent } from './viewyearwise9thstudents/viewyearwise9thstudents.component';
import { Viewyearwise11thstudentsComponent } from './viewyearwise11thstudents/viewyearwise11thstudents.component';
import { InstructionsComponent } from './instructions/instructions.component';
import { ContactusComponent } from './contactus/contactus.component';
import { ConfNotificationComponent } from './conf-notification/conf-notification.component';
import { ConfLastDateSubComponent } from './conf-last-date-sub/conf-last-date-sub.component';
import { OnetimechangepasswordComponent } from './onetimechangepassword/onetimechangepassword.component';
import { Viewschools11Component } from './viewschools11/viewschools11.component';
import { ViewSchoolRegiDetailsComponent } from './view-school-regi-details/view-school-regi-details.component';
import { ViewUnregistredSchoolsComponent } from './view-unregistred-schools/view-unregistred-schools.component';
import { ExceptionalDatesForSchoolComponent } from './exceptional-dates-for-school/exceptional-dates-for-school.component';
import { CandidateResetPasswordComponent } from './candidate-reset-password/candidate-reset-password.component';
import { StudentformchklandingComponent } from './studentformchklanding/studentformchklanding.component';
import { ElevenstudentsComponent } from './elevenstudents/elevenstudents.component';
import { SplitPipe } from './pipes/split.pipe';
import { SchoollandingComponent } from './schoollanding/schoollanding.component';
import { BulkUpUnregistredSchoolsComponent } from './bulk-up-unregistred-schools/bulk-up-unregistred-schools.component';
import { UpdateStudStatusBoardadminComponent } from './update-stud-status-boardadmin/update-stud-status-boardadmin.component';
import { ChangepasswordSchoolComponent } from './changepassword-school/changepassword-school.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    CandidatesDetailsComponent,
    DashboardComponent,
    SchoolEnrollStudentsComponent,
    StudloginComponent,
    ChangepasswordComponent,
    ForgotPasswordComponent,
    CandidateProfileComponent,
    StudentprofileComponent,
    ViewschoolsComponent,
    ViewschooldetailsComponent,
    ViewschoolCandidatelistComponent,
    EditcandidatedetailsComponent,
    BoarduserComponent,
    ViewboardlistComponent,
    EditboarduserComponent,
    CandidatelandingComponent,
    ViewstudentprofileComponent,
    ViewcandidateprofileComponent,
    ResetpasswordComponent,
    BoardloginComponent,
    SchoolpaymentlistComponent,
    BoarddashboardComponent,
    ReportsComponent,
    CandidatesPaymentComponent,
    PaymentLandingComponent,
    BoardEnrollSchoolComponent,
    PageUnderMaintenanceComponent,
    UpdatestatusCandidateComponent,
    SchoolStudReportComponent,
    Edit11thcandidateComponent,
    SchoolAllyrcandidateComponent,
    Viewyearwise9thstudentsComponent,
    Viewyearwise11thstudentsComponent,
    InstructionsComponent,
    ContactusComponent,
    ConfLastDateSubComponent,
    ConfNotificationComponent,
    OnetimechangepasswordComponent,
    Viewschools11Component,
    ViewSchoolRegiDetailsComponent,
    ViewUnregistredSchoolsComponent,
    ExceptionalDatesForSchoolComponent,
    CandidateResetPasswordComponent,
    StudentformchklandingComponent,
    ElevenstudentsComponent,
    SplitPipe,
    SchoollandingComponent,
    BulkUpUnregistredSchoolsComponent,
    UpdateStudStatusBoardadminComponent,
    ChangepasswordSchoolComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    GoogleChartsModule,//google charts for dashboard
    DataTablesModule,// datatables for view candidates
    // DataTablesModule,
    NgbModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,
    CalendarModule,
		SliderModule,
		DialogModule,
		MultiSelectModule,
    // CheckboxModule,
		ContextMenuModule,
		DropdownModule,
		ButtonModule,
		ToastModule,
    InputTextModule,
    ProgressBarModule,
    NgSelect2Module, //for live search
    NgxPaginationModule, //pagination 
  ],
  providers: [SchoolserviceService,NavbarService,AuthGuard,SchoolauthGuard,DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
