<div class="container">
  <br>
  <div class="card mb-3" *ngIf="showDiv.next">
    <h5 class="card-header">Student</h5>
    <div class="card-body text-dark">
      <!-- <div class="col-sm-12 clsAddCandidate">
        <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter">Add Candidate's Primary Details</button>
      </div> -->
          <form role="form" [formGroup]="importdataform" >
            
            <div  class="justify-content-md-center">
                
              <div class="form-group row mb-3">
                  
                  <label for="staticname" class="col-sm-2 col-form-label">Enter Principal Name</label>
                  <div class="col-sm-5">
                    <input type="text" formControlName="principal" class="form-control" id="staticName" [ngClass]="{ 'is-invalid': schoolsubmitted && importdata.principal.errors }">
                    <div *ngIf="schoolsubmitted && importdata.principal.errors" class="invalid-feedback">
                      <div *ngIf="importdata.principal.errors.required">
                        Principal Name Is Required.
                      </div>
                    </div>
                    
                  </div>
                </div>
                <div class="form-group row mb-3">
                  <label for="staticfathername" class="col-sm-2 col-form-label">Enter Academic Year</label>
                  <div class="col-sm-5">
                    <input type="text" readonly formControlName="year" class="form-control" id="inputyear" [(ngModel)]="academicyears" [ngClass]="{ 'is-invalid': schoolsubmitted && importdata.year.errors }">
                     <div *ngIf="schoolsubmitted && importdata.year.errors" class="invalid-feedback">
                       <div *ngIf="importdata.year.errors.required">
                         Academic Year Is Required.
                       </div>
                     </div>
                 
                  </div>
                </div>
                <div class="form-group row mb-3">
                  <label for="staticmothername" class="col-sm-2 col-form-label">No Of Students</label>
                  <div class="col-sm-5">
                    <input type="text" formControlName="noofstudents" class="form-control" id="inputnoofstudents"  [ngClass]="{ 'is-invalid': schoolsubmitted && importdata.noofstudents.errors }">
                     <div *ngIf="schoolsubmitted && importdata.noofstudents.errors" class="invalid-feedback">
                       <div *ngIf="importdata.noofstudents.errors.required">
                         No Of Students Is Required.
                       </div>
                     </div>
           
                  </div>
                </div>
               
                <div class="form-group row mb-3">
                  <label for="staticEmail" class="col-sm-2 col-form-label"></label>
                    <!-- <input type="button" class="btnSubmit btn btn-success"  value="Save Changes" (click)="savedata()" /> &nbsp; -->
                    <input type="button" class="btnSubmit btn btn-primary"  value="Submit" (click)="onsubmit()" /> 
                 
                 </div>
                
              </div>
               <!-- <br> -->
            
          </form>
    </div>
</div>
    <div class="card mb-3" *ngIf="showDiv.previous">
      <h5 class="card-header">Upload Candidate's Data</h5>
          <div class="row p-5">
              <div class="col-md-4 text-right">
                <label for="staticEmail" class="col-form-label">Select Action: </label>
              </div>
              <div class="col-md-4">
                <select class="form-control" (change)="checkactiontaken($event.target)">
                  <option value="" selected disabled>Single Candidate / Bulk Upload </option>
                  <!-- <option value="single" *ngIf="standard=='11th'" disabled>Add Single Candidate</option>
                  <option value="single" *ngIf="standard=='9th'" >Add Single Candidate</option> -->
                  <!-- <option value="single">Add Single Candidate</option>
                  <option value="bulk" >Bulk Upload</option> --><!--tmprarory block on 3sept 2024  -->
                </select>
              </div>
          </div> 
          <!-- actionselected -->
          <div *ngIf="actionselectedsingle" class="row my-3">
          
            <div class="col-md-12 text-center">
              <button class="btn btn-primary btn-sm" data-toggle="modal" data-target="#exampleModalCenter"> Add Single Candidate </button> &nbsp;
            </div>
        </div>

        <div *ngIf="actionselectedbulk" class="row px-5">
            <div class="col-md-12 text-right">
             
            </div>
            
        <div class="card-body text-dark">
          <div class="row text-center my-3">
            <!--temprarory comment open for year 2024-2025  -->
            <div class="col-sm-12" *ngIf="standard =='9th'; else test">
              Bulk Upload Template: &nbsp;&nbsp;&nbsp;
              <a  class="btn btn-primary btn-sm" href="./assets/img/importexcel.xlsx" download="importexcel.xlsx"> Download Excel Template </a>
            </div>
              <ng-template #test>
                <div class="col-sm-12" >
                 Download Below Excel Template For Bulk Upload : &nbsp;&nbsp;&nbsp;
                 <br><br>
                <a class="btn btn-primary btn-sm" href="./assets/img/importexcelVocationalGoa11th.xlsx" download="importexcel11th.xlsx" >  <b>GoaBoard Vocational</b> Candidate </a>             
                &nbsp;&nbsp;&nbsp;
                <a class="btn btn-primary btn-sm" href="./assets/img/importexcelGeneralGoa11th.xlsx" download="importexcel11th.xlsx"> <b>GoaBoard General</b> Candidate </a>             
                <br><br>
                <a class="btn btn-primary btn-sm" href="./assets/img/importexcel11thVocational.xlsx" download="importexcel11th.xlsx"> <b>Non-GoaBoard Vocational</b> Candidate </a>             
                &nbsp;&nbsp;&nbsp;
                <a class="btn btn-primary btn-sm" href="./assets/img/importexcel11thGeneral.xlsx" download="importexcel11th.xlsx"> <b>Non-GoaBoard General</b> Candidate </a>
              </div>
              </ng-template>
              <!-- upto here -->

              <!-- comment below for year 2024-2025 -->
              <!-- <div class="col-sm-12 " >
                Bulk Upload Template: &nbsp;&nbsp;&nbsp;
                <a *ngIf="standard =='9th'; else test" class="btn btn-primary btn-sm" href="./assets/img/importexcel.xlsx" download="importexcel.xlsx"> Download Excel Template </a>
              
                <ng-template #test>
                  <a class="btn btn-primary btn-sm" href="./assets/img/importexcel11th.xlsx" download="importexcel11th.xlsx"> Download Excel Template </a>             
                </ng-template>
               
              </div> -->
              <!-- upto here -->
             
          </div>
              <form role="form" [formGroup]="selctform" class="mt-5">
                <!-- <div class="row"></div> -->
                <div class="row">
                  <div class="col-md-2"></div>
                <div class="form-group col-md-6">
                  
                  <!-- <label for="no_of_students" class="cls-no-of-student">Enter Number Of Students</label>
                  <input type="text" class="form-control cls-no-of-student" formControlName="stud_numbers"  id="no_of_students" aria-describedby="emailHelp" placeholder="Enter no. of student">
                </div> -->
                <!-- <label for="no_of_students" class="cls-no-of-student">Upload File</label> -->
                  <input type="file" class="form-control cls-no-of-student" formControlName="file"  id="file" aria-describedby="emailHelp" (change)="handleFileInput($event)" placeholder="Upload File" [ngClass]="{ 'is-invalid': filesubmitted && personal.file.errors }">
                  <div *ngIf="filesubmitted && personal.file.errors" class="invalid-feedback">
                    <div *ngIf="personal.file.errors.required">
                      Please Select Xlsx File.
                    </div>
                  </div>
                </div>
                </div>
                <div class="row">
                  <div class="col-md-2"></div>
                <div class="form-group col-md-6">
                <button type="button" class="btn btn-primary btn-sm" (click)="upload()" >Submit</button>
                  <!-- <input type="submit" class="btn btn-primary btn-sm" value="Submit" (click)="upload()" disabled> -->
              </div>
              </div>
              </form>
              <br>
              <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-6">
                  <span><b>Note :</b> User password is defaulted to <b>123456</b>.</span>
                </div>
                <div class="col-sm-4" style="text-align: right;">
                  <!-- <button type="button" class="btn btn-primary btn-sm" (click)="Ondownload()" >Download Excel Template</button> -->
                  <!-- <a [href]="fileUrl" download="assets/img/importexcel.xlsx">DownloadFile</a> -->
                  <!-- <a class="btn btn-primary btn-sm" href="/assets/img/importexcel.xlsx" download="importexcel.xlsx"> Download Excel Template </a> -->
                </div>
              </div>
              
        </div>
		
        </div>
          
    </div>
      <!-- Pup up for add candidates -->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Add Candidate's Details</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form role="form" [formGroup]="addprimaryform">
        <div class="modal-body">
         
            <!-- <h4>Enter Primary details of candidate</h4> -->
                
                <input type="text" class="form-control"  formControlName="stname" placeholder="Student Name" name="modalstname" [ngClass]="{ 'is-invalid': submitted && addpersonal.stname.errors }">
                <div *ngIf="submitted && addpersonal.stname.errors" class="invalid-feedback">
                  <div *ngIf="addpersonal.stname.errors.required">
                    Please Enter Student Name.
                  </div>
                </div>
                <br>
                <input type="text" class="form-control"  formControlName="ftname" placeholder="Student's Father Name" name="modalftname" [ngClass]="{ 'is-invalid': submitted && addpersonal.ftname.errors }">
                <div *ngIf="submitted && addpersonal.ftname.errors" class="invalid-feedback">
                  <div *ngIf="addpersonal.ftname.errors.required">
                    Please Enter Father's Name.
                  </div>
                </div>
                <br>
                <input type="text" class="form-control"  formControlName="mtname" placeholder="Student's Mother Name" name="modalmtname" [ngClass]="{ 'is-invalid': submitted && addpersonal.mtname.errors }">
                <div *ngIf="submitted && addpersonal.mtname.errors" class="invalid-feedback">
                  <div *ngIf="addpersonal.mtname.errors.required">
                    Please Enter Mother's Name.
                  </div>
                </div>
                <br>
                <input type="date" class="form-control"  formControlName="dob" 
                 placeholder="DOB" name="modaldob" 
                 [ngClass]="{ 'is-invalid': submitted && addpersonal.dob.errors }">
                <div *ngIf="submitted && addpersonal.dob.errors" class="invalid-feedback">
                  <div *ngIf="addpersonal.dob.errors.required">
                    <!-- [min]="minDate" [max]="maxDate"  -->
                    Please Enter Date of Birth.
                  </div>
                </div><br> 
                <select name="gender" class="form-select" formControlName="gender">
                  <option value="" disabled selected>Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
                <div *ngIf="submitted && addpersonal.gender.errors" class="invalid-feedback">
                  <div *ngIf="addpersonal.gender.errors.required">
                    Please Enter Gender.
                  </div>
                </div><br>
                <input type="text" class="form-control" formControlName="rollno" placeholder="Roll No" name="modalStudentid"
                [ngClass]="{ 'is-invalid': submitted && addpersonal.rollno.errors }">
                  <div *ngIf="submitted && addpersonal.rollno.errors" class="invalid-feedback">
                    <div *ngIf="addpersonal.rollno.errors.required">
                      Please Enter Roll No.
                    </div>
                  </div><br>
                <input type="text" class="form-control"  formControlName="standard" placeholder="Standard" [(ngModel)]="standard" name="modalStandard" readonly>
                <!-- <div *ngIf="submitted && addpersonal.standard.errors" class="invalid-feedback">
                  <div *ngIf="addpersonal.standard.errors.required">
                    Please Enter Standard No.
                  </div>
                </div> -->
                <br>
                <!-- <input type="text" class="form-control"  formControlName="gender"  placeholder="Gender" name="modaldivision" [ngClass]="{ 'is-invalid': submitted && addpersonal.gender.errors }"> -->
               
                
                <input type="text" class="form-control"  formControlName="division"  placeholder="Division" name="modaldivision" [ngClass]="{ 'is-invalid': submitted && addpersonal.division.errors }">
                <div *ngIf="submitted && addpersonal.division.errors" class="invalid-feedback">
                  <div *ngIf="addpersonal.division.errors.required">
                    Please Enter Division.
                  </div>
                </div><br>
                <div *ngIf="standard == '11th'">
                  
                  <select id="stream" formControlName="stream" class="form-select"  #t  (change)="streamChanged(t.value)">
                    <option hidden value="" disabled selected>Select Stream</option>
                    <option value="Science">Science</option>
                    <option value="Commerce">Commerce</option>
                    <option value="Arts">Arts</option>
                    <option value="Vocational">Vocational</option>
                  </select>
                  <br>
                  <div  *ngIf="t.value == 'Vocational'" >
                            
                     <!-- <input type="text"  class="form-control" formControlName="course" id="staticName" placeholder="course" > -->
                     <select id="course" formControlName="course"  class="form-select" (change)="courseChanged(t.value)"  >
                      <option *ngFor="let course of coursesall" [value]="course.coursecode" >{{course.subject_name}}</option>
                    </select>
                  </div>
                </div>
       
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" (click)="Onaddcandidate()">Add Candidate</button>
        </div>
    </form>
    </div>
  </div>
</div>
</div>