<div class="top_div">
</div>
<div class="container-fluid navBarWraper" >
<!-- style="background-image: url('assets/banner2.jpg');"> -->
  <div class="container">
    <div class="row top_banner">
    <div class="col-md-2 navbar-brand-wrap justify-content-right">
      <a class="navbar-brand" >
        <img src="./assets/board_logo.png" class="img mobimg" width="100px" height="89px">
       </a>
    </div>
      <div class="col-md-10 text-center headingWrap">
      <h3 class="heading-section bighead" id="h3">Goa Board of Secondary & Higher Secondary Education</h3>
      <!-- <h3 class="heading-section smallhead" id="h3">GBSHSE 9TH & 11TH</h3> -->
      <h5 id="h3">Online Registration Portal</h5>
    </div>
  </div>
</div>
</div>

<nav class="navbar-expand-lg navbar-dark" id="navhead" *ngIf="nav.visible" >

  <!-- <button class="navbar-toggler navadj" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
    
  </button> -->
  <div class="navbar-toggler navadj" id="menuToggle" >
    <input type="checkbox" />
      <span></span>
      <span></span>
      <span></span>
      <ul id="menu">
        <li class="nav-item ">
         <a class="nav-link" aria-current="page" [routerLink]="['/home']">Home </a>
        </li>
        <li class="nav-item ">
          <a class="nav-link" aria-current="page" [routerLink]="['/instructions']">Instructions for Registration</a>
        </li>
       
        <li class="nav-item ">
          <a class="nav-link" aria-current="page" [routerLink]="['/contact-us']">Contact Us</a>
        </li>
      
      </ul>
 </div>

  <div class="collapse navbar-collapse" id="navbarSupportedContent" style="background: #061665;
  height: 40px;font-family: Verdana;">
    
    <ul class="navbar-nav ml-auto mb-2 mb-lg-0" >
      <li class="nav-item borderline">
        <a class="nav-link" aria-current="page" [routerLink]="['/home']">Home </a>
      </li>
      <li class="nav-item borderline">
        <a class="nav-link" aria-current="page" [routerLink]="['/instructions']">Instructions for Registration</a>
      </li>
     
      <li class="nav-item borderline">
        <a class="nav-link" aria-current="page" [routerLink]="['/contact-us']">Contact Us</a>
      </li>
    
    </ul>
   
  </div>
  <!-- <div class="container container-fluid" >
  <div class="collapse navbar-collapse" id="navbarSupportedContent" >
    
      <ul class="navbar-nav ml-auto mb-2 mb-lg-0" >
        <li class="nav-item borderline">
          <a class="nav-link" aria-current="page" [routerLink]="['/home']">Home </a>
        </li>
        <li class="nav-item borderline">
          <a class="nav-link" aria-current="page" [routerLink]="['/instructions']">Instructions for Registration</a>
        </li>
       
        <li class="nav-item borderline">
          <a class="nav-link" aria-current="page" [routerLink]="['/contact-us']">Contact Us</a>
        </li>
      
      </ul>
     
    </div>

  </div> -->
</nav>

<nav class="navbar navbar-expand-lg navbar-dark"  *ngIf="nav.dashboard" >
  <div class="container container-fluid">
   
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent" >
    
      <ul class="navbar-nav ml-auto mb-2 mb-lg-0" >
        <!-- <li class="nav-item borderline">
          <a class="nav-link" aria-current="page" [routerLink]="['/home']">Home </a>
        </li> -->
        <li class="nav-item borderline">
          <a class="nav-link" aria-current="page" [routerLink]="['/landing']">Home</a>
        </li>
        
        
        <!-- <li class="nav-item borderline" *ngIf="application_status =='Pending';else checkpwd">
          <a class="nav-link" aria-current="page" [routerLink]="['/studentprofile']" >ABC</a>
          <a class="nav-link" aria-current="page" [routerLink]="['/studentprofile']" > Profile Form</a>
        </li>
        <ng-template #checkpwd>
          <li class="nav-item borderline" *ngIf="studstatus =='New'">
            
        </li>
        </ng-template> 
        <li class="nav-item borderline" *ngIf="application_status =='Submitted'">
          <a class="nav-link" aria-current="page" [routerLink]="['/viewstudentprofile']">MY Application Form</a>
        </li>-->
<!--         
        <li class="nav-item borderline" *ngIf="application_status =='Pending'">

          <a class="nav-link" aria-current="page" [routerLink]="['/studentprofile']" > Profile Form</a>
          
        </li>
        -->
          <li class="nav-item borderline" *ngIf="application_status =='Submitted'">
            <a class="nav-link" aria-current="page" [routerLink]="['/viewstudentprofile']">MY Application Form</a>
          </li>
        
        <li class="nav-item borderline">
          <a class="nav-link" aria-current="page"  [routerLink]="['/changepassword']" >Change Password</a>
        </li>
        
        <li class="nav-item borderline">
          <a class="nav-link" aria-current="page" (click)='logout()'>Logout</a>
        </li>
        
        <!-- <li class="nav-item dropdown" >
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-user"></i> {{userDisplayName}} 
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" >Change Password</a>
            <a class="dropdown-item" href="#">Logout</a>
            
          </div>
        </li> -->
      </ul>
     
    </div>
  </div>
</nav>
<nav class="navbar-expand-lg navbar-dark" id="navhead"  *ngIf="nav.IXthstudent" >
  <!-- <div class="container container-fluid"> -->
   
    <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button> -->
    <div class="navbar-toggler navadj" id="menuToggle" >
      <input type="checkbox" />
        <span></span>
        <span></span>
        <span></span>
        <ul id="menu" >
        
          <li class="nav-item borderline">
            <a class="nav-link" aria-current="page" [routerLink]="['/landing']">Home</a>
          </li>
         
          <li class="nav-item borderline">
            <a class="nav-link" aria-current="page" [routerLink]="['/studentchklanding']" > MY Application Form</a>
          
          </li>
          
          <li class="nav-item borderline">
            <a class="nav-link" aria-current="page" [routerLink]="['/changepassword']">Change Password</a>
          </li>
          
          <li class="nav-item borderline">
            <a class="nav-link" aria-current="page" (click)='logout()' style="color: white;">Logout</a>
          </li>
          <li class="nav-item borderline">
            <a class="nav-link" aria-current="page"  href="#" data-toggle="modal" data-target="#allNotifications" title="View All Notifications">
              <i (click)="shownotificationcandidate()" class="bi bi-bell-fill"></i></a>   
          </li>
          <!-- <li class="nav-item dropdown" >
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="fa fa-user"></i> {{userDisplayName}} 
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" >Change Password</a>
              <a class="dropdown-item" href="#">Logout</a>
              
            </div>
          </li> -->
        </ul>
   </div>
  <div class="collapse navbar-collapse" id="navbarToggleExternalContent" style="background: #061665;
  height: 40px;font-family: Verdana;" >
    
      <ul class="navbar-nav ml-auto mb-2 mb-lg-0" >
        
        <li class="nav-item borderline">
          <a class="nav-link" aria-current="page" [routerLink]="['/landing']">Home</a>
        </li>
       
        <li class="nav-item borderline">
          <a class="nav-link" aria-current="page" [routerLink]="['/studentchklanding']" > MY Application Form</a>
        
        </li>
        
        <li class="nav-item borderline">
          <a class="nav-link" aria-current="page" [routerLink]="['/changepassword']">Change Password</a>
        </li>
        
        <li class="nav-item borderline">
          <a class="nav-link" aria-current="page" (click)='logout()'>Logout</a>
        </li>
        <li class="nav-item borderline">
          <a class="nav-link" aria-current="page"  href="#" data-toggle="modal" data-target="#allNotifications" title="View All Notifications">
            <i (click)="shownotificationcandidate()" class="bi bi-bell-fill"></i></a>   
        </li>
        <!-- <li class="nav-item dropdown" >
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-user"></i> {{userDisplayName}} 
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" >Change Password</a>
            <a class="dropdown-item" href="#">Logout</a>
            
          </div>
        </li> -->
      </ul>
     
    </div>
  <!-- </div> -->
</nav>

<nav class="navbar-expand-lg navbar-dark" id="navhead" *ngIf="nav.XIthstudent" >
  <!-- <div class="container container-fluid"> -->
   
    <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button> -->

    <div class="navbar-toggler navadj" id="menuToggle" >
      <input type="checkbox" />
        <span></span>
        <span></span>
        <span></span>
        <ul id="menu" >
        
          <li class="nav-item borderline">
            <a class="nav-link" aria-current="page" [routerLink]="['/landing']">Home</a>
          </li>
         
          <li class="nav-item borderline">
            <a class="nav-link" aria-current="page" [routerLink]="['/elevenstudents']">MY Application Form </a>
          </li>
          
          <li class="nav-item borderline">
            <a class="nav-link" aria-current="page" [routerLink]="['/changepassword']">Change Password</a>
          </li>
          
          <li class="nav-item borderline">
            <a class="nav-link" aria-current="page" (click)='logout()'>Logout</a>
          </li>
          <li class="nav-item borderline">
            <a class="nav-link" aria-current="page"  href="#" data-toggle="modal" data-target="#allNotifications" title="View All Reminders">
              <i (click)="shownotificationcandidate()" class="bi bi-bell-fill"></i></a>   
          </li>
          <!-- <li class="nav-item dropdown" >
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="fa fa-user"></i> {{userDisplayName}} 
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" >Change Password</a>
              <a class="dropdown-item" href="#">Logout</a>
              
            </div>
          </li> -->
        </ul>
   </div>
<div class="collapse navbar-collapse" id="navbarToggleExternalContent" style="background: #061665;
  height: 40px;font-family: Verdana;" >
    
      <ul class="navbar-nav ml-auto mb-2 mb-lg-0" >
        
        <li class="nav-item borderline">
          <a class="nav-link" aria-current="page" [routerLink]="['/landing']">Home</a>
        </li>
       
        <li class="nav-item borderline">
          <a class="nav-link" aria-current="page" [routerLink]="['/elevenstudents']">MY Application Form </a>
        </li>
        
        <li class="nav-item borderline">
          <a class="nav-link" aria-current="page" [routerLink]="['/changepassword']">Change Password</a>
        </li>
        
        <li class="nav-item borderline">
          <a class="nav-link" aria-current="page" (click)='logout()'>Logout</a>
        </li>
        <li class="nav-item borderline">
          <a class="nav-link" aria-current="page"  href="#" data-toggle="modal" data-target="#allNotifications" title="View All Reminders">
            <i (click)="shownotificationcandidate()" class="bi bi-bell-fill"></i></a>   
        </li>
        <!-- <li class="nav-item dropdown" >
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-user"></i> {{userDisplayName}} 
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" >Change Password</a>
            <a class="dropdown-item" href="#">Logout</a>
            
          </div>
        </li> -->
      </ul>
     
    </div>
  <!-- </div> -->
</nav>
<nav class="navbar navbar-expand-lg navbar-dark" *ngIf="nav.boardadmin" >
  <div class="container container-fluid" >
   
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent" >
    
      <ul class="navbar-nav ml-auto mb-2 mb-lg-0" >
        <li class="nav-item borderline">
          <a class="nav-link" aria-current="page" [routerLink]="['/boarddashboard']">Dashboard </a>
        </li>
        <!-- <li class="nav-item borderline">
          <a class="nav-link" aria-current="page" [routerLink]="['/schoolEnroll']">Add School</a>
        </li> -->              
        <li class="nav-item dropdown borderline">        
          <a  class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"> View Schools</a>
          <div class="dropdown-menu">
            <a class="dropdown-item" [routerLink]="['/viewSchools9']">View 9th Schools</a>
            <a class="dropdown-item" [routerLink]="['/viewSchools11']">View 11th Schools</a>     
            <a class="dropdown-item" [routerLink]="['/viewUnregistredSchools']">View Unregistered Schools</a>                 
          </div>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" aria-current="page" [routerLink]="['/boarduserlist']">Manage Board Users</a>
        </li> -->
        <li class="nav-item dropdown borderline">
  
          <a  class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"> Manage Board Users</a>
          <div class="dropdown-menu">
            <a class="dropdown-item" [routerLink]="['/createboarduser']">Create Board Users</a>
            <a class="dropdown-item" [routerLink]="['/boarduserlist']" >Board User List</a>
            <a class="dropdown-item" [routerLink]="['/reset_password']" >Reset Password</a>
          
          </div>
        </li>
        <li class="nav-item dropdown borderline">
  
          <a  class="nav-link dropdown-toggle "  data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Unregistered Schools/Candidates</a>
          <div class="dropdown-menu">
            <a class="dropdown-item" [routerLink]="['/bulk_uploads']">Bulk Uploads</a>
            <a class="dropdown-item"  [routerLink]="['/update_stud_status']" >Update Student Status</a>
          
          </div>
        </li>
        <li class="nav-item dropdown borderline">
  
          <a  class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Settings</a>
          <div class="dropdown-menu">
            <a class="dropdown-item" [routerLink]="['/conf_last_date_sub']">Configure Last Date Of Submission</a>
            <a class="dropdown-item" [routerLink]="['/exceptionalDates']" >Set Exceptional Last Date For School</a><a class="dropdown-item" [routerLink]="['/conf_notification']">Configure Notifications,Reminders & Messages</a>
          </div>
        </li>
        <!-- <li class="nav-item borderline">
          <a class="nav-link" aria-current="page" [routerLink]="['/reports']">Analytics and Reports</a>
        </li> -->
        <li class="nav-item dropdown borderline">
          <a  class="nav-link dropdown-toggle text-secondary" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" >Analytics and Reports</a>
          <!-- <a  class="nav-link dropdown-toggle text-secondary" [class.disabled]="true" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" >Analytics and Reports</a> -->
          <div class="dropdown-menu">
            <a class="dropdown-item" [routerLink]="['/schoolregistredcount']">Registred Students Schoolwise</a>
            <a class="dropdown-item" [routerLink]="['/reports']">Report</a>
          </div>
        </li>
        <li class="nav-item borderline">
          <a class="nav-link" aria-current="page" (click)='boardlogout()' >Logout</a>
        </li>
      </ul>
     
    </div>
  </div>
</nav>
<nav class="navbar navbar-expand-lg navbar-dark" *ngIf="nav.boarduser" >
  <div class="container container-fluid" >
   
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent" >
    
      <ul class="navbar-nav ml-auto mb-2 mb-lg-0" >
        <li class="nav-item borderline">
          <a class="nav-link" aria-current="page" [routerLink]="['/boarddashboard']">Dashboard </a>
        </li>
        <!-- <li class="nav-item borderline">
          <a class="nav-link" aria-current="page" [routerLink]="['/schoolEnroll']">Add School</a>
        </li> -->              
        <li class="nav-item dropdown borderline">        
          <a  class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"> View Schools</a>
          <div class="dropdown-menu">
            <a class="dropdown-item" [routerLink]="['/viewSchools9']">View 9th Schools</a>
            <a class="dropdown-item" [routerLink]="['/viewSchools11']">View 11th Schools</a>                 
          </div>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" aria-current="page" [routerLink]="['/boarduserlist']">Manage Board Users</a>
        </li> -->
        <!-- <li class="nav-item dropdown borderline">
  
          <a  class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"> Manage Board Users</a>
          <div class="dropdown-menu">
            <a class="dropdown-item" [routerLink]="['/createboarduser']">Create Board Users</a>
            <a class="dropdown-item" [routerLink]="['/boarduserlist']" >Board User List</a>
            <a class="dropdown-item" [routerLink]="['/reset_password']" >Reset Password</a>
          
          </div>
        </li> -->
        
        <!-- <li class="nav-item borderline">
          <a class="nav-link" aria-current="page" [routerLink]="['/reports']">Analytics and Reports</a>
        </li> -->
        <li class="nav-item dropdown borderline">
  
          <a  class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Settings</a>
          <div class="dropdown-menu">
            <a class="dropdown-item" [routerLink]="['/conf_notification']">Configure Notifications,Reminders & Messages</a>
          </div>
        </li>
        <li class="nav-item dropdown borderline">
  
          <a  class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Analytics and Reports</a>
          <div class="dropdown-menu">
            <a class="dropdown-item" [routerLink]="['/schoolregistredcount']">Registred Students Schoolwise</a>
            <a class="dropdown-item" [routerLink]="['/reports']">Report</a>
          </div>
        </li>
        <li class="nav-item borderline">
          <a class="nav-link" aria-current="page" (click)='boardlogout()'>Logout</a>
        </li>
      </ul>
     
    </div>
  </div>
</nav>
<nav class="navbar navbar-expand-lg navbar-dark" *ngIf="nav.schooladmin" >
  <div class="container-fluid" >
   
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent" >
    
      <ul class="navbar-nav ml-auto mb-2 mb-lg-0" >
        <li class="nav-item borderline">
          <a class="nav-link" aria-current="page" [routerLink]="['/schoollanding']">Home</a> <!-- <i class="bi bi-house-fill"></i> -->
        </li>
        <li class="nav-item borderline">
          <a class="nav-link" aria-current="page" [routerLink]="['/schooldashboard']">Dashboard </a>
        </li>
        <li class="nav-item borderline">
          <a class="nav-link" aria-current="page" [routerLink]="['/School_Enroll_Students']">Create Candidate Logins</a>
        </li>
        <!-- <li class="nav-item borderline">
          <a class="nav-link" aria-current="page" [routerLink]="['/candidates-details']">View All Candidates</a>
        </li> -->
        <li class="nav-item dropdown borderline">
  
          <a  class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Candidates Info</a>
          <div class="dropdown-menu">
            <a class="dropdown-item" [routerLink]="['/candidates-details']">View All Candidates</a>
            <a class="dropdown-item" [routerLink]="['/Allcandidates']" >Historical Data of candidates</a>
            <!-- <a class="dropdown-item" [routerLink]="['/conf_notification']">Configure Notifications & Reminders</a> -->
          </div>
        </li>
        <li class="nav-item borderline">
          <a class="nav-link" aria-current="page" [routerLink]="['/Update_CandidateStatus']"> Update Candidate Status</a>
        </li>
        
        <li class="nav-item borderline">
          <a class="nav-link" aria-current="page" [routerLink]="['/candidates-payment']"> Payment</a>
        </li>
        <li  class="nav-item borderline">
          <a class="nav-link" aria-current="page"  [routerLink]="['/studentreports']" > Reports</a>
          <!-- <a class="nav-link" aria-current="page"  [routerLink]="['/studentreports']" [class.disabled]="true"> Reports</a> -->
          <!-- [class.disabled]="true" -->
          
        </li>
        <!-- <li class="nav-item dropdown borderline">
  
          <a  class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"><i class="bi bi-person-circle"></i></a>
          <div class="dropdown-menu">
            <a class="dropdown-item" aria-current="page" (click)='logout()'> <i class="bi bi-exclamation-circle-fill"></i> Logout</a>
            <a class="dropdown-item" (click)="showallnotifications()" aria-current="page" href="#" data-toggle="modal" data-target="#allNotifications" title="View All Notifications">
              <i  class="bi bi-bell-fill"></i> Notifications</a>
          </div>
        </li> -->
        <li  class="nav-item borderline">
          <a class="nav-link" aria-current="page" (click)="showallnotifications()" aria-current="page" href="#" data-toggle="modal" data-target="#allNotifications" title="View All Notifications">
            <i  class="bi bi-bell-fill"></i> Notifications</a>
          
        </li>
        <li  class="nav-item borderline">
          <a class="nav-link" aria-current="page" (click)='logout()'> <i class="bi bi-exclamation-circle-fill"></i> Logout</a>
          
        </li>
        
        <!-- <li class="nav-item borderline">
          <a class="nav-link" aria-current="page" (click)='logout()'>Logout</a>
        </li> -->
        
      </ul>
     
    </div>
  </div>
</nav>

<div class="modal fade justify-content-center" id="allNotifications" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{target_audience}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closebutton>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <div class="notificationWraper border" *ngFor="let item of AllNoticArray; let i = index">
            <!-- <div class="notificationTitle">{{i+1}}</div> -->
            <div class="notiDateTime"> {{item.date|date:'d/M/y'}} Time: {{item.date|date:'shortTime'}}</div>  
            <div class="notificationTitle">{{item.title}}</div>
            <div class="notificationTxt">{{item.message}}</div>            
          </div>
      </div>
      <div class="modal-footer">
        <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button> -->
      </div>
    </div>
  </div>
</div>

<div class="modal fade justify-content-center" id="allmessages" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{target_audience}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closebutton>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <div class="notificationWraper border" *ngFor="let item of AllNoticArray; let i = index">
            <!-- <div class="notificationNo">{{i+1}}</div> -->
            <div class="notiDateTime">{{item.date | date: 'd/M/y'}} Time:{{item.date | date: 'shortTime'}}</div>
            <div class="notificationTitle">{{item.title}}</div>
            <div class="notificationTxt">{{item.message}}</div>           
          </div>
      </div>
      <div class="modal-footer">
        <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button> -->
      </div>
    </div> <!-- end of msgModel-->
</div>   <!--end of container for model--> 

</div>