
<div class="container-fluied">
  
  <div class="clsUpdateStatus row mt3 ">  
      <div class="col-sm-10 optionWrap1">
        
      </div>       
    <!-- <div class="col-sm-1 optionWrap2">
      <button type="button" class="btn btn-primary btn-sm" (click)="printPage()" ><i class="bi bi-printer-fill"></i> Print</button>
    </div> -->
    <div class="col-sm-1 optionWrap2">
      <button type="button" [attr.disabled]="proccessing?true:null" class="btn btn-primary btn-sm" (click)="exportexcel()" ><i class="bi bi-printer-fill"></i> Download PDF</button>
      <!-- <button type="button" [attr.disabled]="true" class="btn btn-primary btn-sm" (click)="exportexcel()" ><i class="bi bi-printer-fill"></i> Download PDF</button> -->
    </div>
    <!-- <div class="col-sm-1 optionWrap2">
      <button type="button" class="btn btn-primary btn-sm" (click)="exportexcel()" ><i class="bi bi-printer-fill"></i> Download PDF</button>
    </div> -->
    <div class="col-sm-1"></div>
  </div>
  <!-- datatable -->
  
    <div class="row mx-2">
      <div class="col-sm-12 tablescroll" >
  <table id="excel-table1" style="width:100%" class="table table-bordered nowrap table-sm row-border hover clsTable" datatable [dtOptions]="dtOptions"
  [dtTrigger]="dtTrigger">   
  <thead>
    <tr class="headerRow">
      
      <th>Student Id</th>
      <th ><span class="multilinecell">Full name</span><span class="multilinecell">Mother's name</span><span class="multilinecell">Father's name</span></th>
      <th><span class="multilinecell">Sex</span><span class="multilinecell">CAT</span></th>
      <th>Sub Code</th>
      <th>Sub Name</th>
      <th>SAS Sub Code</th>
      <th>SAS Sub Name</th>
      <th>Med</th>
      <th>Year</th>
      <th ><span class="multilinecell">D.O.B</span><span class="multilinecell">Student Status</span>
        <!-- <span class="multilinecell">Minority</span> -->
      </th>
    </tr>
  </thead>
  <tbody *ngIf="studavailable">
   <tr *ngFor="let group of candidate">
          <td>{{group.stud_data.studentid}}</td>
          <td><span class="multilinecell">{{(group.stud_data.STUD_Name | split:"\n")[0]}}</span><span class="multilinecell">{{(group.stud_data.STUD_Name | split:"\n")[2]}}</span><span class="multilinecell">{{(group.stud_data.STUD_Name | split:"\n")[1]}}</span></td>
          <td><span class="multilinecell">{{(group.stud_data.Stud_Details | split:"\n")[1]}}</span><span class="multilinecell">{{(group.stud_data.Stud_Details | split:"\n")[0]}}</span></td>
          <td><span *ngFor="let s of group.stud_sub_data; let i = index" class="multilinecell"><span *ngIf="s.subject_position<8">{{s.subject_code}}</span></span></td>
          <td><span *ngFor="let s of group.stud_sub_data; let i = index" class="multilinecell"><span *ngIf="s.subject_position<8">{{s.subjectname}}</span></span></td>
          <td><span *ngFor="let s of group.stud_sas_sub_data; let i = index" class="multilinecell"><span *ngIf="s.subject_position>7">{{s.subject_code}}</span></span></td>
          <td><span *ngFor="let s of group.stud_sas_sub_data; let i = index" class="multilinecell"><span *ngIf="s.subject_position>7">{{s.subjectname}}</span></span></td>
          <td>{{group.stud_data.acc_year}}</td>
          <td>{{group.stud_data.medium}}</td>
          <td><span class="multilinecell">{{group.stud_data.dob}}</span><span class="multilinecell">{{group.stud_data.studentstatus}}</span> </td>
        </tr>
  </tbody>
  <tbody *ngIf="!studavailable">
    <tr>
      <td colspan="10" class="text-center alert alert-success">NO STUDENTS ADDED YET FOR CURRENT ACADAMIC YEAR!!</td>
    </tr>
  </tbody>
</table>

</div>
</div>
<div class="row mx-2"></div>
<div class="row mx-2"></div>
<div *ngIf="proccessing" class="loadingcont">
   <div *ngIf="tout" class="alert alert-primary mx-auto" style="margin-top: 16rem;width:22rem">Generating Report usually takes more time, please wait!!, </div>
    <div class="spinner"></div>
</div>
   
</div>



