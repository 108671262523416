import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SchoolserviceService } from '../schoolservice.service';
import { DatePipe } from '@angular/common';
import { NavbarService } from '../default/navbar/navbar.service';

@Component({
  selector: 'app-studentprofile',
  templateUrl: './studentprofile.component.html',
  styleUrls: ['./studentprofile.component.scss'],
  providers: [DatePipe]
})
export class StudentprofileComponent implements OnInit {
  studentprofileform: FormGroup;
  captcha: any;
  alphaNums = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  emptyArr:any = [];
  refreshArr :any = [];
  loginuser: any;
  submitted = false;
  studID:any;
  userData: any;
  photo_upload_success:boolean=false;
  signature_upload_success: boolean = false;
  date !: Date;
  uploadResponse: any;
  isdisable: boolean = false;
  reviewArray!: any[];

  subject:any;
  subject1:any;
  subject2:any;
  subject3:any;
  subject4:any;
  subject5:any;
  subject6:any;
  subject7:any;
  subject8:any;
  subject9:any;
  subject10:any;
  subject11:any;
  subject12:any;
  selectedLevel:any;
  studschool: any;
  subjectmap: any;
  validated_subject =false;
  subj_array: any;
  subjectlist: any;
  msg: any;
  phonemsg:any;
  alphmsg:any;
  photoinfo: any;
  signinfo: any;
  last_date_11thstudent_regi: any;
  last_date_9thstudent_regi: any;
  schoolid: any;
  latest_date:any;
  status: any;
  conmsg: any;
  aadharmsg: any;
  lastdate: any;
  lastdate9th: any;
  schoolmsg: any;
  distmsg: any;
  alltalukas: any;

  constructor(public formbuilder:FormBuilder,public router:Router, public service:SchoolserviceService,public datepipe: DatePipe,public nav:NavbarService) {
    this.studentprofileform = formbuilder.group({
      rollno:[''],
      division:[''],
      class:[''],
      stname:[''],
      ftname:[''],
      mtname:[''],
      dob:['',Validators.required],
      gender:['',Validators.required],
      email:['',[Validators.required,Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      mobile_no:['',[Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      alt_mobile_no:[''],
      caste:['',Validators.required],
      street:['',Validators.required],
      locality:[''],
      city:['',Validators.required],
      state:['',Validators.required],
      taluka:['',Validators.required],
      district:['',Validators.required],
      landmark:[''],
      pincode:['',[Validators.required,Validators.pattern("^[0-9]*$")]],
      house_ownership:[''], 
      internet_coverage:[''],
      distance_coverage:[''],
      access_to_smart_phone:[''],
      access_internet:[''],
      distance_of_school_to_house:[''],
      mode_of_transport:[''],
      security_ques:['',Validators.required],
      security_ans:['',Validators.required],
      fathers_qualification:[''],
      fathers_job:[''],
      fathers_annual_income:[''],
      mothers_qualification:[''],
      mothers_job:[''],
      mothers_annual_income:[''],
      photo:[''],
      signature:[''],
      subject_one:['',Validators.required],
      subject_two:['',Validators.required],
      subject_three:['',Validators.required],
      subject_four:['',Validators.required],
      subject_five:['',Validators.required],
      subject_six:['',Validators.required],
      subject_seven:[''],
      subject_eight:[''],
      subject_nine:[''],
      subject_ten:[''],
      subject_eleven:[''],
      subject_twelve:[''],
      subject_thirteen:[''],
      captcha_text:['',Validators.required]

      })
   }

   ngOnInit(): void {
    this.studID = sessionStorage.getItem('studentid');
    this.schoolid = sessionStorage.getItem('schoolid');
    this.studentprofileform.controls.class.setValue('9th');
    // this.status = sessionStorage.getItem("submission_status");
    // console.log(this.status);
    // if(this.status != "New" && this.status != "Pending")
    // {
    //    this.router.onSameUrlNavigation = 'reload'; 
    //   this.router.navigate(['/viewstudentprofile']);
    // }
    
    // else
    // {
    //   this.router.onSameUrlNavigation = 'reload'; 
    //   this.router.navigate(['/studentprofile']);
    // }
   this.nav.hide();
    this.nav.dhide();
    this.nav.adhide();
    this.nav.asdhide();
    this.nav.XIstudhide();
    this.nav.studshow();
    this.nav.aduserhide();

      this.schoolid = sessionStorage.getItem('schoolid');
      this.date=new Date();
      let latest_date =this.datepipe.transform(this.date, 'MM/dd/yyyy');
      console.log(latest_date);
      console.log(this.studID);
    if(this.studID)
    {
      this.getstudentform();
      this.getstudentclassForm();
      this.getselectedsubject();
    }
    // This loop generates a random string of 7 characters using alphaNums
    // Further this string is displayed as a CAPTCHA
    for (let i = 1; i <= 5; i++) {
    this.emptyArr.push(this.alphaNums[Math.floor(Math.random() * this.alphaNums.length)]);
    }
     this.captcha = this.emptyArr.join('');

    //  console.log(this.captcha);
    this.getsubjects();

    let schoolinfo :{schoolid:any}={
      schoolid:this.schoolid
    }
    this.service.post('/School/viewlastdateforCandidates',schoolinfo).subscribe((res: any)=>{

      //last dates for 11th candidates
       this.last_date_11thstudent_regi = res['Data'][1].lastdate;
       this.lastdate = this.datepipe.transform(this.last_date_11thstudent_regi, 'MM/dd/yyyy');
      //last dates for 9th students
      this.last_date_9thstudent_regi = res['Data'][0].lastdate;
      this.lastdate9th = this.datepipe.transform(this.last_date_9thstudent_regi, 'dd/MM/yyyy');
      console.log(this.lastdate9th)
      var latest_day = new Date(this.date).getDate();
      var lastdate_day = new Date(this.last_date_9thstudent_regi).getDate();
      var latest_month = new Date(this.date).getMonth()+1;
      var lastdate_month = new Date(this.last_date_9thstudent_regi).getMonth()+1;

      console.log('latest_day --- ' + latest_day +'lastdate_day ---- '+lastdate_day + 'latest_month --- '+
      latest_month + 'lastdate_month --- ' + lastdate_month);
      if(latest_month >= lastdate_month)
      if(lastdate_month >= latest_month)
      if( latest_day > lastdate_day)
      // if( this.latest_date > this.lastdate9th)
      {
        this.isdisable= true;
        alert('Form Submission Date Is Lapsed');
      }
      else
      {
        this.isdisable= false;
      }
      
      });
      this.studentprofileform.controls.gender.disable();
      this.getalltalukas()
  }


// This event listener is stimulated whenever the user press the "Refresh" button
  refresh()
  {
    // form.captcha_text = "";
    // let refreshArr = [];
    for (let j = 1; j <= 5; j++) {
      
    this.refreshArr.push(this.alphaNums[Math.floor(Math.random() * this.alphaNums.length)]);
    // this.emptyArr.splice(0,this.emptyArr.length);
    
    }

    this.captcha = this.refreshArr.join('');
    this.refreshArr.splice(0,this.refreshArr.length)
    // console.log(this.captcha);


  }

  get personal() { return this.studentprofileform.controls; }

  selected(event:any)
  {
    this.selectedLevel = event.target.value;
    alert(this.selectedLevel)
  }

  onPhotoSelect(event:any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      // this.studentprofileform.controls.photo.setValue(file);

      const fsize = file.size;
      console.log(fsize);
                const photofile = Math.round((fsize / 1024));
                // The size of the file.
                if (photofile >= 2048) {
                    alert(
                      "File too Big, please select a file less than 2mb");
                } else {
                  this.studentprofileform.controls.photo.setValue(file);
                }

    }
  }

  onsignatureSelect(event:any)
  { 
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      // this.studentprofileform.controls.signature.setValue(file);

      const fsize = file.size;
      // console.log(fsize);
                const signfile = Math.round((fsize / 1024));
                // The size of the file.
                if (signfile >= 2048) {
                    alert(
                      "File too Big, please select a file less than 4mb");
                }  else {
                  this.studentprofileform.controls.signature.setValue(file);
                }
    }

  }
  allowmobilenumber(e: { which: any; keyCode: any; }) {		
		const charCode = e.which ? e.which : e.keyCode;
		
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			this.phonemsg = '<span class="msg">OOPs! Only Numbers are allow</span>';
		}
    else
    {
      this.phonemsg = '<span class="msg"></span>';
    }
  }
  // allowNumericDigitsOnlyOnKeyUp(e: { which: any; keyCode: any; }) {		
	// 	const charCode = e.which ? e.which : e.keyCode;
		
	// 	if (charCode > 31 && (charCode < 48 || charCode > 57)) {
	// 		this.msg = '<span class="msg">OOPs! Only Numbers are allow</span>';
	// 	}
  //   else
  //   {
  //     this.msg = '<span class="msg"></span>';
  //   }
  // }
  allowNumericDigitsOnlyOnKeyUp(e: { which: any; keyCode: any; }) {		
    const charCode = e.which ? e.which : e.keyCode;
    
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      this.msg = '<span class="msg">OOPs! Only Numbers are allow</span>';
      return false;
    }
    else
    {
      this.msg = '<span class="msg"></span>';
      return true;
    }
  }
  allowNumericforaltrcontact(e: { which: any; keyCode: any; }) {		
		const charCode = e.which ? e.which : e.keyCode;
		
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			this.conmsg = '<span class="msg">OOPs! Only Numbers are allow</span>';
		}
    else
    {
      this.conmsg = '<span class="msg"></span>';
    }
  }

  allowNumericDfordistance(e: { which: any; keyCode: any; }) {		
		const charCode = e.which ? e.which : e.keyCode;
		
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			this.distmsg = '<span class="distmsg">OOPs! Only Numbers are allow</span>';
      return false;
		}
    else
    {
      this.distmsg = '<span class="distmsg"></span>';
      return true;
    }
  }

  allowNumericDigitschool(e: { which: any; keyCode: any; }) {		
		const charCode = e.which ? e.which : e.keyCode;
		
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			this.schoolmsg = '<span class="schoolmsg">OOPs! Only Numbers are allow</span>';
      return false;
		}
    else
    {
      this.schoolmsg = '<span class="schoolmsg"></span>';
      return true;
    }
  }

  keyPressAlphanumeric(event: { keyCode: number; preventDefault: () => void; }) {

    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z]/.test(inp)) {
      // return true;
      this.alphmsg = '<span class="msg"></span>';
    } else {
      event.preventDefault();
      // return false;
     
      this.alphmsg = '<span class="msg">OOPs! Only Alphabetic are allow</span>';
    }
  }
  onsavedata(){
    let seql = this.service.post('/StudentApi11/emailcheck', {email:this.studentprofileform.controls.email.value,standard:"9th"});
    seql
        .subscribe((res:any) => {
          if(res['status'] == 'success'){
            if(res['Data'][0]['studentid'] != this.studID){
              alert('This email is already exists')
            }
            else{
              this.savedata();
            }
          }
          else{
            this.savedata();
          }
            //  this.submitted=true;
        }, err => {
            alert("Error");
        }); 
  }
  savedata(){

    let subject : {  subject_one:any,subject_two:any,subject_three:any,subject_four:any,
      subject_five:any,subject_six:any,subject_seven:any,subject_eight:any,
      subject_nine:any,subject_ten:any,subject_eleven:any,subject_twelve:any,subject_thirteen:any} =
     {
         
          subject_one:this.studentprofileform.controls.subject_one.value,
          subject_two:this.studentprofileform.controls.subject_two.value,
          subject_three:this.studentprofileform.controls.subject_three.value,
          subject_four:this.studentprofileform.controls.subject_four.value,
          subject_five:this.studentprofileform.controls.subject_five.value,
          subject_six:this.studentprofileform.controls.subject_six.value,
          subject_seven:this.studentprofileform.controls.subject_seven.value,
          subject_eight:this.studentprofileform.controls.subject_eight.value,
          subject_nine:this.studentprofileform.controls.subject_nine.value,
          subject_ten:this.studentprofileform.controls.subject_ten.value,
          subject_eleven:this.studentprofileform.controls.subject_eleven.value,
          subject_twelve:this.studentprofileform.controls.subject_twelve.value,
          subject_thirteen:this.studentprofileform.controls.subject_thirteen.value
     }
     this.subj_array=[];
     this.subjectlist = [subject.subject_one,subject.subject_two,subject.subject_three,subject.subject_four,subject.subject_five,subject.subject_six,
    subject.subject_seven,subject.subject_eight,subject.subject_nine,subject.subject_ten,subject.subject_eleven];

     let sorted_arr = this.subjectlist.slice().sort();
     for (let i = 0; i < sorted_arr.length - 1; i++) {
      if(sorted_arr[i]!=""){
      if (sorted_arr[i + 1] == sorted_arr[i]) {
        this.subj_array.push(sorted_arr[i]);//push nonunique subject to array
      }
    }
      console.log(this.subj_array);
      
    }

    if(this.subj_array.length == 0)
    {
      let subject : { class:any, studentid:any,rollno:any,division:any,subject_one:any,subject_two:any,subject_three:any,subject_four:any,
        subject_five:any,subject_six:any,subject_seven:any,subject_eight:any,
        subject_nine:any,subject_ten:any,subject_eleven:any,subject_twelve:any,subject_thirteen:any,submission_status:any} =
       {
            studentid:this.studID,
            class: this.studentprofileform.controls.class.value,
            rollno:this.studentprofileform.controls.rollno.value,
            division:this.studentprofileform.controls.division.value,
            subject_one:this.studentprofileform.controls.subject_one.value,
            subject_two:this.studentprofileform.controls.subject_two.value,
            subject_three:this.studentprofileform.controls.subject_three.value,
            subject_four:this.studentprofileform.controls.subject_four.value,
            subject_five:this.studentprofileform.controls.subject_five.value,
            subject_six:this.studentprofileform.controls.subject_six.value,
            subject_seven:this.studentprofileform.controls.subject_seven.value,
            subject_eight:this.studentprofileform.controls.subject_eight.value,
            subject_nine:this.studentprofileform.controls.subject_nine.value,
            subject_ten:this.studentprofileform.controls.subject_ten.value,
            subject_eleven:this.studentprofileform.controls.subject_eleven.value,
            subject_twelve:this.studentprofileform.controls.subject_twelve.value,
            subject_thirteen:this.studentprofileform.controls.subject_thirteen.value,
            submission_status:'Pending'
       }
        // alert(subject);
     let seql = this.service.post('/StudentApi9/savesubject', subject);
     seql
         .subscribe(res => {
             console.log(res);
             this.validated_subject=true;
            //  this.submitted=true;
         }, err => {
             alert("Error");
         }); 

         

         
    let user: {studentid:any,stname:any,ftname:any,mtname:any,dob:any,gender:any,email:any,mobile_no:any,alt_mobile_no:any,
      caste:any,street:any,locality:any,city:any,state:any,taluka:any,district:any,landmark:any,pincode:any,
      house_ownership:any,internet_coverage:any,distance_coverage:any,access_to_smart_phone:any,access_internet:any,
      // password:any,c_password:any,
      security_ques:any,security_ans:any,fathers_qualification:any,fathers_job:any,
      fathers_annual_income:any,mothers_qualification:any,mothers_job:any,mothers_annual_income:any,
      distance_of_school_to_house:any,mode_of_transport:any,
      // photo:any,
      // signature:any,
     
      application_status:any,
      
    }  = {
          studentid:this.studID,
          stname:this.studentprofileform.controls.stname.value,
          ftname:this.studentprofileform.controls.ftname.value,
          mtname:this.studentprofileform.controls.mtname.value,
          dob:this.studentprofileform.controls.dob.value,
          gender:this.studentprofileform.controls.gender.value,
          email:this.studentprofileform.controls.email.value,
          mobile_no:this.studentprofileform.controls.mobile_no.value,
          alt_mobile_no:this.studentprofileform.controls.alt_mobile_no.value,
          caste:this.studentprofileform.controls.caste.value,
          street:this.studentprofileform.controls.street.value,
          locality:this.studentprofileform.controls.locality.value,
          city:this.studentprofileform.controls.city.value,
          state:this.studentprofileform.controls.state.value,
          taluka:this.studentprofileform.controls.taluka.value,
          district:this.studentprofileform.controls.district.value,
          landmark:this.studentprofileform.controls.landmark.value,
          pincode:this.studentprofileform.controls.pincode.value,
          house_ownership:this.studentprofileform.controls.house_ownership.value,
          internet_coverage:this.studentprofileform.controls.internet_coverage.value,
          distance_coverage:this.studentprofileform.controls.distance_coverage.value,
          access_to_smart_phone:this.studentprofileform.controls.access_to_smart_phone.value,
          access_internet:this.studentprofileform.controls.access_internet.value,
          distance_of_school_to_house:this.studentprofileform.controls.distance_of_school_to_house.value,
          mode_of_transport:this.studentprofileform.controls.mode_of_transport.value,
          // password:this.studentprofileform.controls.password.value,
          // c_password:this.studentprofileform.controls.c_password.value,
          security_ques:this.studentprofileform.controls.security_ques.value,
          security_ans:this.studentprofileform.controls.security_ans.value,
          fathers_qualification:this.studentprofileform.controls.fathers_qualification.value,
          fathers_job:this.studentprofileform.controls.fathers_job.value,
          fathers_annual_income:this.studentprofileform.controls.fathers_annual_income.value,
          mothers_qualification:this.studentprofileform.controls.mothers_qualification.value,
          mothers_job:this.studentprofileform.controls.mothers_job.value,
          mothers_annual_income:this.studentprofileform.controls.mothers_annual_income.value,
          // photo:this.studentprofileform.controls.photo.value,
          // signature:this.studentprofileform.controls.signature.value,
        
          application_status:"Pending",
          
     }

    //  console.log(user);
     alert("Information Saved");
     window.location.reload();
     let seq = this.service.post('/StudentApi9/savecandidatedata', user);
     seq
         .subscribe(res => {
             console.log(res);
 
         }, err => {
             alert("Error");
         });  

        //  For Photo and Signature upload
        const formData = new FormData();
        formData.append('photo', this.studentprofileform.controls.photo.value);
        formData.append('signature', this.studentprofileform.controls.signature.value);
        formData.append('id', this.studID);
        this.photo_upload_success=true;
        this.service.uploadFile(formData).subscribe(
          (res:any) => {
           this.uploadResponse = res;
              console.log(res);
              // this.getstudentform();
          },
          (err: any) => {  
            console.log(err);
          }
        );
        
       
      }
      // else if(this.subj_array == ',,,,' || this.subj_array == ',,,,,,,,,')
      else if(this.subj_array == '' || this.subj_array == '')
      {
        
        let user: {studentid:any,stname:any,ftname:any,mtname:any,dob:any,gender:any,email:any,mobile_no:any,alt_mobile_no:any,
          caste:any,street:any,locality:any,city:any,state:any,taluka:any,district:any,landmark:any,pincode:any,
          house_ownership:any,internet_coverage:any,distance_coverage:any,access_to_smart_phone:any,access_internet:any,
          // password:any,c_password:any,
          security_ques:any,security_ans:any,fathers_qualification:any,fathers_job:any,
          fathers_annual_income:any,mothers_qualification:any,mothers_job:any,mothers_annual_income:any,
          distance_of_school_to_house:any,mode_of_transport:any,
          // photo:any,
          // signature:any,
         
          application_status:any,
         
          
        }  = {
              studentid:this.studID,
              stname:this.studentprofileform.controls.stname.value,
              ftname:this.studentprofileform.controls.ftname.value,
              mtname:this.studentprofileform.controls.mtname.value,
              dob:this.studentprofileform.controls.dob.value,
              gender:this.studentprofileform.controls.gender.value,
              email:this.studentprofileform.controls.email.value,
              mobile_no:this.studentprofileform.controls.mobile_no.value,
              alt_mobile_no:this.studentprofileform.controls.alt_mobile_no.value,
              caste:this.studentprofileform.controls.caste.value,
              street:this.studentprofileform.controls.street.value,
              locality:this.studentprofileform.controls.locality.value,
              city:this.studentprofileform.controls.city.value,
              state:this.studentprofileform.controls.state.value,
              taluka:this.studentprofileform.controls.taluka.value,
              district:this.studentprofileform.controls.district.value,
              landmark:this.studentprofileform.controls.landmark.value,
              pincode:this.studentprofileform.controls.pincode.value,
              house_ownership:this.studentprofileform.controls.house_ownership.value,
              internet_coverage:this.studentprofileform.controls.internet_coverage.value,
              distance_coverage:this.studentprofileform.controls.distance_coverage.value,
              access_to_smart_phone:this.studentprofileform.controls.access_to_smart_phone.value,
              access_internet:this.studentprofileform.controls.access_internet.value,
              distance_of_school_to_house:this.studentprofileform.controls.distance_of_school_to_house.value,
              mode_of_transport:this.studentprofileform.controls.mode_of_transport.value,
              // password:this.studentprofileform.controls.password.value,
              // c_password:this.studentprofileform.controls.c_password.value,
              security_ques:this.studentprofileform.controls.security_ques.value,
              security_ans:this.studentprofileform.controls.security_ans.value,
              fathers_qualification:this.studentprofileform.controls.fathers_qualification.value,
              fathers_job:this.studentprofileform.controls.fathers_job.value,
              fathers_annual_income:this.studentprofileform.controls.fathers_annual_income.value,
              mothers_qualification:this.studentprofileform.controls.mothers_qualification.value,
              mothers_job:this.studentprofileform.controls.mothers_job.value,
              mothers_annual_income:this.studentprofileform.controls.mothers_annual_income.value,
              // photo:this.studentprofileform.controls.photo.value,
              // signature:this.studentprofileform.controls.signature.value,
            
              application_status:"Pending",
                    
         }
    
         console.log(user);
         alert("Information Saved");
         window.location.reload();
         let seq = this.service.post('/StudentApi9/savecandidatedata', user);
         seq
             .subscribe(res => {
                 console.log(res);
     
             }, err => {
                 alert("Error");
             });  
    
            //  For Photo and Signature upload
            const formData = new FormData();
            formData.append('photo', this.studentprofileform.controls.photo.value);
            formData.append('signature', this.studentprofileform.controls.signature.value);
            formData.append('id', this.studID);
            this.photo_upload_success=true;
            this.service.uploadFile(formData).subscribe(
              (res:any) => {
               this.uploadResponse = res;
                  console.log(res);
                  // this.getstudentform();
              },
              (err: any) => {  
                console.log(err);
              }
            );
      }
      else
      {
        alert(this.subj_array+ " has been selected multiple times from provided options. Please correct the selection and click Save")
        this.validated_subject=false;
      }
       

    }
    onstudentprofilesubmit(){
      let seql = this.service.post('/StudentApi11/emailcheck', {email:this.studentprofileform.controls.email.value,standard:"11th"});
      seql
          .subscribe((res:any) => {
            console.log(this.studID)
            if(res['status'] == 'success'){
              if(res['Data'][0]['studentid'] != this.studID){
                alert('This email is already exists')
              }
              else{
                this.studentprofilesubmit();
              }
            }
            else{
              this.studentprofilesubmit();
            }
              //  this.submitted=true;
          }, err => {
              alert("Error");
          }); 
    }


  studentprofilesubmit()
  {
    this.submitted = true; 
    if(this.studentprofileform.controls['subject_seven'].value=="")
    {
        if(this.studentprofileform.controls.subject_eight.value=="" ||
        this.studentprofileform.controls.subject_nine.value=="" ||
        this.studentprofileform.controls.subject_ten.value=="" ||
        this.studentprofileform.controls.subject_thirteen.value==""){
          alert("if Subject 7 is not selected, \nPlease select following : \nSubject 8th and \nSubject 9th and \nSubject 10th and \nSubject 13th");
          return;
        }

        // this.studentprofileform.controls['subject_seven'].clearValidators();
        //   this.studentprofileform.controls['subject_ten'].setValidators(Validators.required);                   
        //   this.studentprofileform.controls['subject_eleven'].setValidators(Validators.required);                   
        //   this.studentprofileform.controls['subject_twelve'].setValidators(Validators.required);                   
        //   this.studentprofileform.controls['subject_thirteen'].setValidators(Validators.required);
                            
    }
    else {        
   
      // this.studentprofileform.controls['subject_seven'].setValidators(Validators.required);                   
      // this.studentprofileform.controls['subject_ten'].clearValidators();
      // this.studentprofileform.controls['subject_eleven'].clearValidators();
      // this.studentprofileform.controls['subject_twelve'].clearValidators();
      // this.studentprofileform.controls['subject_thirteen'].clearValidators();
        
          
     }
    // var chk=this.isDOBvalid();
  
    // if(chk==false){
    //     alert("Your Date Of Birth  is not as per criteria, \nFor 9th, age should be greater than 12 years, \n Please Contact School Admin, and correct your Date Of Birth.");
    //     return;
    // }
    if (this.studentprofileform.invalid) {
                
                alert("Fill All Details");
                return;

              }
    let subject : {  subject_one:any,subject_two:any,subject_three:any,subject_four:any,
      subject_five:any,subject_six:any,subject_seven:any,subject_eight:any,
      subject_nine:any,subject_ten:any,subject_eleven:any,subject_twelve:any,subject_thirteen:any} =
     {
         
          subject_one:this.studentprofileform.controls.subject_one.value,
          subject_two:this.studentprofileform.controls.subject_two.value,
          subject_three:this.studentprofileform.controls.subject_three.value,
          subject_four:this.studentprofileform.controls.subject_four.value,
          subject_five:this.studentprofileform.controls.subject_five.value,
          subject_six:this.studentprofileform.controls.subject_six.value,
          subject_seven:this.studentprofileform.controls.subject_seven.value,
          subject_eight:this.studentprofileform.controls.subject_eight.value,
          subject_nine:this.studentprofileform.controls.subject_nine.value,
          subject_ten:this.studentprofileform.controls.subject_ten.value,
          subject_eleven:this.studentprofileform.controls.subject_eleven.value,
          subject_twelve:this.studentprofileform.controls.subject_twelve.value,
          subject_thirteen:this.studentprofileform.controls.subject_thirteen.value
        }
     this.subj_array=[];
     this.subjectlist = [subject.subject_one,subject.subject_two,subject.subject_three,subject.subject_four,subject.subject_five,subject.subject_six,
    subject.subject_seven,subject.subject_eight,subject.subject_nine,subject.subject_ten,subject.subject_eleven,subject.subject_twelve,subject.subject_thirteen];

     let sorted_arr = this.subjectlist.slice().sort();
     for (let i = 0; i < sorted_arr.length - 1; i++) {
      if(sorted_arr[i]!=""){
      if (sorted_arr[i + 1] == sorted_arr[i]) {
        this.subj_array.push(sorted_arr[i]);//push nonunique subject to array
      }
    }
      
    }

    if(this.subj_array.length == 0)
    {
      let subject : { class:any, studentid:any,rollno:any,division:any,subject_one:any,subject_two:any,subject_three:any,subject_four:any,
        subject_five:any,subject_six:any,subject_seven:any,subject_eight:any,
        subject_nine:any,subject_ten:any,subject_eleven:any,subject_twelve:any,subject_thirteen:any,submission_status:any} =
       {
            studentid:this.studID,
            class: this.studentprofileform.controls.class.value,
            rollno:this.studentprofileform.controls.rollno.value,
            division:this.studentprofileform.controls.division.value,
            subject_one:this.studentprofileform.controls.subject_one.value,
            subject_two:this.studentprofileform.controls.subject_two.value,
            subject_three:this.studentprofileform.controls.subject_three.value,
            subject_four:this.studentprofileform.controls.subject_four.value,
            subject_five:this.studentprofileform.controls.subject_five.value,
            subject_six:this.studentprofileform.controls.subject_six.value,
            subject_seven:this.studentprofileform.controls.subject_seven.value,
            subject_eight:this.studentprofileform.controls.subject_eight.value,
            subject_nine:this.studentprofileform.controls.subject_nine.value,
            subject_ten:this.studentprofileform.controls.subject_ten.value,
            subject_eleven:this.studentprofileform.controls.subject_eleven.value,
            subject_twelve:this.studentprofileform.controls.subject_twelve.value,
            subject_thirteen:this.studentprofileform.controls.subject_thirteen.value,
            submission_status:'Submitted'
       }
        // alert("Subject Saved");
     let seql = this.service.post('/StudentApi9/savesubject', subject);
     seql
         .subscribe(res => {
             console.log(res);
             this.validated_subject=true;
             this.submitted=true;
         }, err => {
             alert("Error");
         }); 
       
     
         let user: {studentid:any,stname:any,ftname:any,mtname:any,dob:any,gender:any,email:any,mobile_no:any,alt_mobile_no:any,
         caste:any, street:any,locality:any,city:any,state:any,taluka:any,district:any,landmark:any,pincode:any,
          house_ownership:any,internet_coverage:any,distance_coverage:any,access_to_smart_phone:any,access_internet:any,
          // password:any,c_password:any,
          security_ques:any,security_ans:any,fathers_qualification:any,fathers_job:any,
          fathers_annual_income:any,mothers_qualification:any,mothers_job:any,mothers_annual_income:any,
          distance_of_school_to_house:any,mode_of_transport:any,
          // photo:any,
          // signature:any,
          application_status:any,date:any
          
        }
          
         = {
              studentid:this.studID,
              stname:this.studentprofileform.controls.stname.value,
              ftname:this.studentprofileform.controls.ftname.value,
              mtname:this.studentprofileform.controls.mtname.value,
              dob:this.studentprofileform.controls.dob.value,
              gender:this.studentprofileform.controls.gender.value,
              email:this.studentprofileform.controls.email.value,
              mobile_no:this.studentprofileform.controls.mobile_no.value,
              alt_mobile_no:this.studentprofileform.controls.alt_mobile_no.value,
              caste:this.studentprofileform.controls.caste.value,
              street:this.studentprofileform.controls.street.value,
              locality:this.studentprofileform.controls.locality.value,
              city:this.studentprofileform.controls.city.value,
              state:this.studentprofileform.controls.state.value,
              taluka:this.studentprofileform.controls.taluka.value,
              district:this.studentprofileform.controls.district.value,
              landmark:this.studentprofileform.controls.landmark.value,
              pincode:this.studentprofileform.controls.pincode.value,
              house_ownership:this.studentprofileform.controls.house_ownership.value,
              internet_coverage:this.studentprofileform.controls.internet_coverage.value,
              distance_coverage:this.studentprofileform.controls.distance_coverage.value,
              access_to_smart_phone:this.studentprofileform.controls.access_to_smart_phone.value,
              access_internet:this.studentprofileform.controls.access_internet.value,
              distance_of_school_to_house:this.studentprofileform.controls.distance_of_school_to_house.value,
              mode_of_transport:this.studentprofileform.controls.mode_of_transport.value,
              // password:this.studentprofileform.controls.password.value,
              // c_password:this.studentprofileform.controls.c_password.value,
              security_ques:this.studentprofileform.controls.security_ques.value,
              security_ans:this.studentprofileform.controls.security_ans.value,
              fathers_qualification:this.studentprofileform.controls.fathers_qualification.value,
              fathers_job:this.studentprofileform.controls.fathers_job.value,
              fathers_annual_income:this.studentprofileform.controls.fathers_annual_income.value,
              mothers_qualification:this.studentprofileform.controls.mothers_qualification.value,
              mothers_job:this.studentprofileform.controls.mothers_job.value,
              mothers_annual_income:this.studentprofileform.controls.mothers_annual_income.value,
              // photo:this.studentprofileform.controls.photo.value,
              // signature:this.studentprofileform.controls.signature.value,
              
              application_status:"Submitted",
              
              // is_loggin:'1',
              date: this.latest_date,
              }
    
        //  console.log(user);
        //  alert("Information Saved");
       
        this.isdisable= true;
         let seq = this.service.post('/StudentApi9/submitstudentform', user);
         seq
             .subscribe(res => {
                //  console.log(res);
                 alert("Congratulations, your form has been submitted successfully!!");
                //  this.router.onSameUrlNavigation = 'reload'; 
                 this.router.navigate(['/viewstudentprofile']); 
                // this.router.navigate(['/studentchklanding']);
                //  window.location.reload();
                
        }, err       => {
                 alert("Error");
             });  
    
              //  For Photo and Signature upload
            const formData = new FormData();
            formData.append('photo', this.studentprofileform.controls.photo.value);
            formData.append('signature', this.studentprofileform.controls.signature.value);
            formData.append('id', this.studID);
            this.photo_upload_success=true;
            this.isdisable= true;
            this.service.uploadFile(formData).subscribe(
              (res:any) => {

               this.uploadResponse = res;
                  console.log(res);
                  // this.getstudentform();
              },
              (err: any) => {  
                console.log(err);
              }
            );
    
       
      }
      else
      {
        alert(this.subj_array+ " has been selected multiple times from provided options. Please correct the selection and click Save")
        this.validated_subject=false;
      }
    
  }

  getstudentclassForm()
  {
    let user:{studentid:any}={
      studentid:this.studID
    }
    let seq = this.service.post('/StudentApi9/getstudentclassForm',user);
     seq.subscribe((res:any) => {
            
            this.studschool= res['studschool'];
             this.studentprofileform.controls['rollno'].setValue(this.studschool[0].rollno);
            this.studentprofileform.controls['division'].setValue(this.studschool[0].division);
     });
  }
  getstudentform()
  {
    let user:{studentid:any}={
      studentid:this.studID
    }
    let seq = this.service.post('/StudentApi9/getstudentForm',user);
     seq.subscribe((res:any) => {
            
             this.userData= res['Data'];
            
             this.subjectmap= res['subjectmap'];
            //  console.log(this.userData[0].security_ques);
            // this.studentprofileform.controls['class'].setValue(this.studschool[0].standard);
            // this.studentprofileform.controls['division'].setValue(this.studschool[0].division);
             this.studentprofileform.controls['stname'].setValue(this.userData[0].stname);
             this.studentprofileform.controls['ftname'].setValue(this.userData[0].ftname);
             this.studentprofileform.controls['mtname'].setValue(this.userData[0].mtname);
             this.studentprofileform.controls['dob'].setValue(this.userData[0].dob);
             this.studentprofileform.controls['gender'].setValue(this.userData[0].gender);
             this.studentprofileform.controls['email'].setValue(this.userData[0].email);
             this.studentprofileform.controls['mobile_no'].setValue(this.userData[0].mobile_no);
             this.studentprofileform.controls['alt_mobile_no'].setValue(this.userData[0].alt_mobile_no);
             this.studentprofileform.controls['caste'].setValue(this.userData[0].caste);
             this.studentprofileform.controls['street'].setValue(this.userData[0].street);
             this.studentprofileform.controls['locality'].setValue(this.userData[0].locality);
             this.studentprofileform.controls['city'].setValue(this.userData[0].city);
             this.studentprofileform.controls['state'].setValue(this.userData[0].state);
             this.studentprofileform.controls['taluka'].setValue(this.userData[0].taluka);
             this.studentprofileform.controls['district'].setValue(this.userData[0].district);
             this.studentprofileform.controls['landmark'].setValue(this.userData[0].landmark);
             this.studentprofileform.controls['pincode'].setValue(this.userData[0].pincode);
             this.studentprofileform.controls['house_ownership'].setValue(this.userData[0].house_ownership);
             this.studentprofileform.controls['internet_coverage'].setValue(this.userData[0].internet_coverage);
             this.studentprofileform.controls['distance_coverage'].setValue(this.userData[0].distance_coverage);
             this.studentprofileform.controls['access_to_smart_phone'].setValue(this.userData[0].access_to_smart_phone);
             this.studentprofileform.controls['access_internet'].setValue(this.userData[0].access_internet);
             this.studentprofileform.controls['distance_of_school_to_house'].setValue(this.userData[0].distance_of_school_to_house);
             this.studentprofileform.controls['mode_of_transport'].setValue(this.userData[0].mode_of_transport);
             this.studentprofileform.controls['security_ques'].setValue(this.userData[0].security_ques);
             this.studentprofileform.controls['security_ans'].setValue(this.userData[0].security_ans);
             this.studentprofileform.controls['fathers_qualification'].setValue(this.userData[0].fathers_qualification);
             this.studentprofileform.controls['fathers_job'].setValue(this.userData[0].fathers_job);
             this.studentprofileform.controls['fathers_annual_income'].setValue(this.userData[0].fathers_annual_income);
             this.studentprofileform.controls['mothers_qualification'].setValue(this.userData[0].mothers_qualification);
             this.studentprofileform.controls['mothers_job'].setValue(this.userData[0].mothers_job);
             this.studentprofileform.controls['mothers_annual_income'].setValue(this.userData[0].mothers_annual_income);
           
             var photo_upload_path=this.userData[0].photo;
            //  photo_upload_path =photo_upload_path.substr(42,photo_upload_path.length);
            this.photoinfo = this.userData[0].photo;
            this.studentprofileform.controls['photo'].setValue(photo_upload_path); 
             var signature_upload_path=this.userData[0].signature;
             this.signinfo = this.userData[0].signature;
             //  signature_upload_path =signature_upload_path.substr(42,signature_upload_path.length);
             this.studentprofileform.controls['signature'].setValue(signature_upload_path);
            


     });
  }
  // get all selected subject from student
  getselectedsubject()
  {
    let user:{studentid:any}={
      studentid:this.studID
    }
      let seq = this.service.post('/StudentApi9/getstudentselectedsubject',user);
      seq.subscribe((res:any)=>{
        this.subjectmap = res['subjectmap'];
          this.studentprofileform.controls['subject_one'].setValue(this.subjectmap[0].Subject);
             this.studentprofileform.controls['subject_two'].setValue(this.subjectmap[1].Subject);
             this.studentprofileform.controls['subject_three'].setValue(this.subjectmap[2].Subject);
             this.studentprofileform.controls['subject_four'].setValue(this.subjectmap[3].Subject);
             this.studentprofileform.controls['subject_five'].setValue(this.subjectmap[4].Subject);
             this.studentprofileform.controls['subject_six'].setValue(this.subjectmap[5].Subject);
             this.studentprofileform.controls['subject_seven'].setValue(this.subjectmap[6].Subject);
             this.studentprofileform.controls['subject_eight'].setValue(this.subjectmap[7].Subject);
             this.studentprofileform.controls['subject_nine'].setValue(this.subjectmap[8].Subject);
             this.studentprofileform.controls['subject_ten'].setValue(this.subjectmap[9].Subject);
             this.studentprofileform.controls['subject_eleven'].setValue(this.subjectmap[10].Subject);
             this.studentprofileform.controls['subject_twelve'].setValue(this.subjectmap[11].Subject);
             this.studentprofileform.controls['subject_thirteen'].setValue(this.subjectmap[12].Subject);
      })
  }

  // get subjects in dropdown

  getsubjects(){
    let user:{}={
      
    }
    let seq = this.service.post('/StudentApi9/getsubjectlist',user);
     seq.subscribe((res:any) => {
          this.subject = res['Data'];
          this.subject1 =res['Data1'];
          this.subject2 =res['Data2'];
          this.subject3 =res['Data3'];
          this.subject4 =res['Data4'];
          this.subject5 =res['Data5'];
          this.subject6 =res['Data6'];
          this.subject7 =res['Data7'];
          this.subject8 =res['Data8'];
          this.subject9 =res['Data9'];
          this.subject10 =res['Data10'];
          this.subject11 =res['Data11'];
          this.subject12 =res['Data12'];
          // console.log(this.subject);

     });
  }

  // set subject optional if sub 7 is opted
  setsuboptional(e:any){
      // console.log(e.target.value);
     
      // if(e.target.value==""){
      //       this.studentprofileform.controls['subject_ten'].setValidators(Validators.required);                   
      //       this.studentprofileform.controls['subject_eleven'].setValidators(Validators.required);                   
      //       this.studentprofileform.controls['subject_twelve'].setValidators(Validators.required);                   
      //       this.studentprofileform.controls['subject_thirteen'].setValidators(Validators.required);                   
      // }
      // else {        
      //       this.studentprofileform.controls['subject_ten'].clearValidators();
      //       this.studentprofileform.controls['subject_eleven'].clearValidators();
      //       this.studentprofileform.controls['subject_twelve'].clearValidators();
      //       this.studentprofileform.controls['subject_thirteen'].clearValidators();
      //    }
  }
  isDOBvalid() {
    var today = new Date();
    var birthDate = new Date(this.studentprofileform.controls.dob.value.split("-").reverse().join("-"));
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    // console.log(today);
    // console.log(birthDate);
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
    {
        age--;
    }
        if(age>=12){
          return true;
        }
        else {
          return false;
        }
    }
    getalltalukas(){
      let seq = this.service.post('/BoardApi/getTaluka',{});
      seq.subscribe((res:any)=>{
          if(res.status== "Sucess"){
              this.alltalukas=res.Data;
          }
          else{
            alert("An error occurred while fetching data, Reloading page!!");
            window.location.reload();
          }
      })
  }
  
  getdistrict(taluka:any){
      this.alltalukas.forEach((elem: { taluka_name: any, taluka_district:any }) => {
          if(elem.taluka_name==taluka.value){
            // alert(elem.taluka_district);
            this.studentprofileform.controls['district'].setValue(elem.taluka_district);
          }
      });
  }
}
