import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CandidatesDetailsComponent } from './candidates-details/candidates-details.component';
import { BoarduserComponent } from './boarduser/boarduser.component';
import { CandidateProfileComponent } from './candidate-profile/candidate-profile.component';
import { ChanLastDateSubComponent } from './chan-last-date-sub/chan-last-date-sub.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { ConfLastDateSubComponent } from './conf-last-date-sub/conf-last-date-sub.component';
import { ConfNotificationComponent } from './conf-notification/conf-notification.component';
import { SchoolEnrollStudentsComponent } from './school-enroll-students/school-enroll-students.component';
import { StudentprofileComponent } from './studentprofile/studentprofile.component';
import { StudloginComponent } from './studlogin/studlogin.component';
import { ViewboardlistComponent } from './viewboardlist/viewboardlist.component';
import { ViewschooldetailsComponent } from './viewschooldetails/viewschooldetails.component';
import { ViewschoolsComponent } from './viewschools/viewschools.component';
import { EditboarduserComponent } from './editboarduser/editboarduser.component';
import { CandidatelandingComponent } from './candidatelanding/candidatelanding.component';
import { ViewstudentprofileComponent } from './viewstudentprofile/viewstudentprofile.component';
import { ViewcandidateprofileComponent } from './viewcandidateprofile/viewcandidateprofile.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { BoardloginComponent } from './boardlogin/boardlogin.component';
import { SchoolpaymentlistComponent } from './schoolpaymentlist/schoolpaymentlist.component';
import { DatatableComponent } from './datatable/datatable.component';
import { EditcandidatedetailsComponent } from './editcandidatedetails/editcandidatedetails.component';
import { BoarddashboardComponent } from './boarddashboard/boarddashboard.component';
import { ReportsComponent } from './reports/reports.component';
import { CandidatesPaymentComponent } from './candidates-payment/candidates-payment.component';
import { PaymentLandingComponent } from './payment-landing/payment-landing.component';
import { BoardEnrollSchoolComponent } from './board-enroll-school/board-enroll-school.component';
import { PageUnderMaintenanceComponent } from './page-under-maintenance/page-under-maintenance.component';
import { AuthGuard } from './auth.guard';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SchoolauthGuard } from './schoolauth.guard';
import { UpdatestatusCandidateComponent } from './updatestatus-candidate/updatestatus-candidate.component';
import { SchoolStudReportComponent } from './school-stud-report/school-stud-report.component';
import { Edit11thcandidateComponent } from './edit11thcandidate/edit11thcandidate.component';
import { SchoolAllyrcandidateComponent } from './school-allyrcandidate/school-allyrcandidate.component';
import { Viewyearwise9thstudentsComponent } from './viewyearwise9thstudents/viewyearwise9thstudents.component';
import { Viewyearwise11thstudentsComponent } from './viewyearwise11thstudents/viewyearwise11thstudents.component';
import { InstructionsComponent } from './instructions/instructions.component';
import { ContactusComponent } from './contactus/contactus.component';
import { OnetimechangepasswordComponent } from './onetimechangepassword/onetimechangepassword.component';
import { ViewschoolCandidatelistComponent } from './viewschool-candidatelist/viewschool-candidatelist.component';

import { Viewschools11Component } from './viewschools11/viewschools11.component';
import { ViewSchoolRegiDetailsComponent } from './view-school-regi-details/view-school-regi-details.component';
import { BoardauthGuard } from './boardauth.guard';
import { ViewUnregistredSchoolsComponent } from './view-unregistred-schools/view-unregistred-schools.component';
import { ExceptionalDatesForSchoolComponent } from './exceptional-dates-for-school/exceptional-dates-for-school.component';
import { CandidateResetPasswordComponent } from './candidate-reset-password/candidate-reset-password.component';
import { StudentformchklandingComponent } from './studentformchklanding/studentformchklanding.component';
import { ElevenstudentsComponent } from './elevenstudents/elevenstudents.component';
import { SchoollandingComponent } from './schoollanding/schoollanding.component';
import { BulkUpUnregistredSchoolsComponent } from './bulk-up-unregistred-schools/bulk-up-unregistred-schools.component';
import { UpdateStudStatusBoardadminComponent } from './update-stud-status-boardadmin/update-stud-status-boardadmin.component';
import { ChangepasswordSchoolComponent } from './changepassword-school/changepassword-school.component';



const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  { path: 'instructions', component: InstructionsComponent, },
  { path: 'contact-us', component: ContactusComponent, },
  {path:'schooldashboard', component: DashboardComponent,canActivate:[SchoolauthGuard]},
  {path:'home', component: StudloginComponent},
  {path:'boarddashboard', component: BoarddashboardComponent,canActivate:[BoardauthGuard]},
  {path:'boardlogin', component: BoardloginComponent},
  {path:'landing', component: CandidatelandingComponent,canActivate:[AuthGuard]},
  {path:'candidates-details', component:CandidatesDetailsComponent,canActivate:[SchoolauthGuard]},
  {path:'Update_CandidateStatus', component:UpdatestatusCandidateComponent,canActivate:[SchoolauthGuard]},
  {path:'School_Enroll_Students', component:SchoolEnrollStudentsComponent,canActivate:[SchoolauthGuard]},
  {path:'studentreports',component:SchoolStudReportComponent,canActivate:[SchoolauthGuard]},
  {path:'edit-candidate/:studentid',component:EditcandidatedetailsComponent,canActivate:[SchoolauthGuard]}, 
  {path:'edit11th-candidate/:studentid',component:Edit11thcandidateComponent,canActivate:[SchoolauthGuard]},
  {path:'candidates-payment',component:CandidatesPaymentComponent,canActivate:[SchoolauthGuard]}, 
  {path:'Allcandidates',component:SchoolAllyrcandidateComponent,canActivate:[SchoolauthGuard]}, 
  {path:'view9thcandidates/:studentid',component:Viewyearwise9thstudentsComponent,canActivate:[SchoolauthGuard]},
  {path:'view11thcandidates/:studentid',component:Viewyearwise11thstudentsComponent,canActivate:[SchoolauthGuard]},
  // {path:'', component:StudloginComponent},
  {path:'forgot-password',component:ForgotPasswordComponent}, 
  {path:'ontimechangepassword', component:OnetimechangepasswordComponent,canActivate:[AuthGuard]},
  {path:'changepassword', component:ChangepasswordComponent,canActivate:[AuthGuard]},
  {path:'reset_password', component:ResetpasswordComponent,canActivate:[BoardauthGuard]},
  {path:'studentprofile', component:StudentprofileComponent,canActivate:[AuthGuard]},
  {path:'candidateprofile', component:CandidateProfileComponent,canActivate:[AuthGuard]},
  {path:'viewSchools9', component:ViewschoolsComponent,canActivate:[BoardauthGuard]},
  {path:'viewSchools11', component:Viewschools11Component,canActivate:[BoardauthGuard]},
  {path:'viewSchooldetails/:schoolid', component:ViewschooldetailsComponent,canActivate:[BoardauthGuard]},
  {path:'viewUnregistredSchools', component:ViewUnregistredSchoolsComponent,canActivate:[BoardauthGuard]},
  {path:'exceptionalDates', component:ExceptionalDatesForSchoolComponent,canActivate:[BoardauthGuard]},
  {path:'showSchoolcandidatelist/:schoolid/:schoolName',component:ViewschoolCandidatelistComponent,canActivate:[BoardauthGuard]},
  {path:'schoolregistredcount',component:ViewSchoolRegiDetailsComponent,canActivate:[BoardauthGuard]},
  {path:'createboarduser', component:BoarduserComponent,canActivate:[BoardauthGuard]},
  {path:"conf_last_date_sub",component:ConfLastDateSubComponent,canActivate:[BoardauthGuard]},
  {path:"chan_last_date_sub",component:ChanLastDateSubComponent,canActivate:[BoardauthGuard]},
  {path:"conf_notification",component:ConfNotificationComponent,canActivate:[BoardauthGuard]},
  {path:'boarduserlist', component:ViewboardlistComponent,canActivate:[BoardauthGuard]},
  {path:'editboarduser/:employeeid', component:EditboarduserComponent,canActivate:[BoardauthGuard]},
  {path:'viewstudentprofile', component:ViewstudentprofileComponent,canActivate:[AuthGuard]},
  {path:'viewcandidateprofile', component:ViewcandidateprofileComponent,canActivate:[AuthGuard]},
  {path:'schoolpaymentlist/:schoolid/:schoolName', component:SchoolpaymentlistComponent},
  {path:'studentchklanding', component:StudentformchklandingComponent,canActivate:[AuthGuard]},
  {path:'elevenstudents', component:ElevenstudentsComponent,canActivate:[AuthGuard]},
  {path:'datatable',component:DatatableComponent},
  {path:'reports',component:ReportsComponent,canActivate:[BoardauthGuard]},
  {path:'payment',component:PaymentLandingComponent,canActivate:[SchoolauthGuard]},
  {path:'candidateresetpass/:studentid', component:CandidateResetPasswordComponent},
  //page_under_maintenance
  {path:'page_under_maintenance', component:PageUnderMaintenanceComponent},
  //landing for 10 12
  {path:'schoollanding', component : SchoollandingComponent , canActivate:[SchoolauthGuard]},
  {path:'bulk_uploads', component:BulkUpUnregistredSchoolsComponent,canActivate:[BoardauthGuard]},
  {path:'update_stud_status', component:UpdateStudStatusBoardadminComponent,canActivate:[BoardauthGuard]},

  {path:'ontimechangepassword_school', component:ChangepasswordSchoolComponent,canActivate:[SchoolauthGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
   
      useHash: true
    
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
