import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SchoolserviceService } from '../schoolservice.service';
import { NavbarService } from '../default/navbar/navbar.service';
import { DatePipe } from '@angular/common'
@Component({
  selector: 'app-edit11thcandidate',
  templateUrl: './edit11thcandidate.component.html',
  styleUrls: ['./edit11thcandidate.component.scss']
})
export class Edit11thcandidateComponent implements OnInit {
  studentprofileform: FormGroup;
  captcha: any;
  alphaNums = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  emptyArr:any = [];
  refreshArr :any = [];
  loginuser: any;
  submitted = false;
  studentid:any;
  userData: any;
  photo_upload_success:boolean=false;
  signature_upload_success: boolean = false;
  date: Date | undefined;
  uploadResponse: any;
  isdisable: boolean = false;
  reviewArray!: any[];
  subject:any;
  subject1:any;
  subject2:any;
  subject3:any;
  subject4:any;
  subject5:any;
  subject6:any;
  selectedLevel:any;
  studschool: any;
  subjectmap: any;
  validated_subject:boolean =false;
  subj_array: any;
  subjectlist: any;
  // searchform: FormGroup;
  // studentid: any;
  loading: boolean = false;
  streamvalue: any;
  boardvalue: any;
  boardinfo: any;
  Stname: any;
  schoolid:any
  checkstream: any;
  photoinfo: any;
  signinfo: any;
  marksinfo: any;

  msg: any;
  alphmsg:any;
  last_date_11thstudent_regi: any;
  last_date_9thstudent_regi: any;
  latest_date: any;
  dob: any;
  status: any;
  msgs: any;
  boardstatus: any;
  aadharmsg: any;
  conmsgs: any;
  subject7: any;
  subject8: any;
  subject9: any;
  subject10: any;
  username: any;
  studata:any; 
   course1 : any;
  course2 : any;
  coursesall:any;
  coursecode: any;
  coursenewname: any;
  coursesubj: any;
  subjcode: any;
  course3: any;
  course4: any;
  course6: any;
  course5: any;
  selesctedcourse: any;
  course: any;
  validated_subjects: boolean = false;
  distmsg: any;
  schoolmsg: any;
  chngcourse: any;
  othercourse: any;
  selectsubj1: any;
  delsubj: any;
  pinmsg: any;
  subject11: any;
  subject12: any;
  tdate: any;
  converteddate: any;
  alltalukas: any;
 
  constructor(public datepipe: DatePipe,public formbuilder:FormBuilder,public router:Router,private _routeParams: ActivatedRoute, public service:SchoolserviceService,public nav:NavbarService) {
    this.studentprofileform = formbuilder.group({
      class:[''],
      rollno:[''],
      division:[''],
      stream:[''],
      course:[''],
      coursename:[''],
      boardname:['',Validators.required],
      otherboardname:[''],
      seat:[''],
      exam:[''],
      stname:['',Validators.required],
      ftname:['',Validators.required],
      mtname:['',Validators.required],
      dob:['',Validators.required],
      gender:['',Validators.required],
      email:['',[Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      mobile_no:['',[Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      alt_mobile_no:[''],
      aadhar:['',[Validators.pattern("^[0-9]{12}$")]],
      caste:['',Validators.required],
      street:['',Validators.required],
      locality:[''],
      city:['',Validators.required],
      state:['',Validators.required],
      taluka:['',Validators.required],
      district:['',Validators.required],
      landmark:[''],
      pincode:['',Validators.required],
      house_ownership:[''], 
      internet_coverage:[''],
      distance_coverage:[''],
      access_to_smart_phone:[''],
      access_internet:[''],
      distance_of_school_to_house:[''],
      mode_of_transport:[''],
      eligibilitynumber:[''],
      marksheet:[''],
      security_ques:[''],
      security_ans:[''],
      fathers_qualification:[''],
      fathers_job:[''],
      fathers_annual_income:[''],
      mothers_qualification:[''],
      mothers_job:[''],
      mothers_annual_income:[''],
      photo:[''],
      signature:[''],
      subject_one:['',Validators.required],
      subject_two:['',Validators.required],
      subject_three:['',Validators.required],
      subject_four:['',Validators.required],
      subject_five:['',Validators.required],
      subject_six:['',Validators.required],
      subject_seven:[''],
      subject_eight:['',Validators.required],
      subject_nine:['',Validators.required],
      subject_ten:['',Validators.required],
      subject_eleven:['',Validators.required],
      captcha_text:['',Validators.required]

      })
   }

  ngOnInit(): void {
    this.nav.hide();
    this.nav.dhide();
    this.nav.adhide();
    this.nav.asdshow();
    this.nav.aduserhide();
    this.nav.studhide();
    this.nav.XIstudhide();


    this.schoolid =sessionStorage.getItem('schoolid');
    this.username =sessionStorage.getItem('username');
    this.studentprofileform.controls.class.setValue('11th');
    console.log(this.username);
    var id = this._routeParams.params.subscribe(params => {

      var studentid = String(params["studentid"]);
  
      this.studentid = String(params["studentid"]);
       console.log(this.studentid);
      // alert(seat);
      if (studentid == "new") {
          return;
      }
      else {
          this.loading = true;
          
      this.getstudentform();
      this.getstudentclassForm();
      this.getselectedsubject();
          this.loading = false;
      }
  });
   
  this.getsubjects();
  this.getcourses();
  this.getalltalukas();

    for (let i = 1; i <= 6; i++) {
      this.emptyArr.push(this.alphaNums[Math.floor(Math.random() * this.alphaNums.length)]);
      }
       this.captcha = this.emptyArr.join('');
        this.studentprofileform.controls.stream.disable();
        this.studentprofileform.controls.course.disable();
       console.log(this.captcha);
  }

    // for validation
    get personal() { return this.studentprofileform.controls; }

  // This event listener is stimulated whenever the user press the "Refresh" button
  refresh()
  {
    // form.captcha_text = "";
    // let refreshArr = [];
    for (let j = 1; j <= 7; j++) {
      
    this.refreshArr.push(this.alphaNums[Math.floor(Math.random() * this.alphaNums.length)]);
    // this.emptyArr.splice(0,this.emptyArr.length);
    
    }

    this.captcha = this.refreshArr.join('');
    this.refreshArr.splice(0,this.refreshArr.length)
    // console.log(this.captcha);


  }

    // select stream
    streamChanged(value: any)
    {
      this.streamvalue = value;
      console.log(this.streamvalue);

      this.getsubjects();
    }
  
    // select board
  
    boardChanged(value: any)
    {
      
      this.boardvalue = value;
    // console.log(this.boardvalue);

    if(this.studentprofileform.controls.boardname.value == 'other'){
      this.studentprofileform.controls['otherboardname'].setValue('');        
      this.studentprofileform.controls['eligibilitynumber'].setValue('');        
      this.studentprofileform.controls['marksheet'].setValue('');
      this.marksinfo=null;
      this.studentprofileform.controls['otherboardname'].setValidators(Validators.required);
      this.studentprofileform.controls['otherboardname'].updateValueAndValidity();                   
      this.studentprofileform.controls['eligibilitynumber'].setValidators(Validators.required); 
      this.studentprofileform.controls['eligibilitynumber'].updateValueAndValidity();                        
      this.studentprofileform.controls['marksheet'].setValidators(Validators.required);  
      this.studentprofileform.controls['marksheet'].updateValueAndValidity();   

      this.studentprofileform.controls['exam'].clearValidators();
      this.studentprofileform.controls['exam'].updateValueAndValidity();
      this.studentprofileform.controls['exam'].reset();
      
      this.studentprofileform.controls['seat'].clearValidators();
      this.studentprofileform.controls['seat'].updateValueAndValidity();
      this.studentprofileform.controls['seat'].reset();
      
      }
      else {    
        this.studentprofileform.controls['exam'].setValue('');
        this.studentprofileform.controls['seat'].setValue('');
        // alert(this.studentprofileform.controls.boardname.value);          
        this.studentprofileform.controls['seat'].setValidators(Validators.required);
        this.studentprofileform.controls['seat'].updateValueAndValidity();
        this.studentprofileform.controls['exam'].setValidators(Validators.required);
        this.studentprofileform.controls['exam'].updateValueAndValidity();
        this.studentprofileform.controls['otherboardname'].clearValidators();
        this.studentprofileform.controls['otherboardname'].updateValueAndValidity();
        this.studentprofileform.controls['eligibilitynumber'].clearValidators();
        this.studentprofileform.controls['eligibilitynumber'].updateValueAndValidity();
        this.studentprofileform.controls['marksheet'].clearValidators();
        this.studentprofileform.controls['marksheet'].updateValueAndValidity();
        this.studentprofileform.controls['otherboardname'].reset();
        this.studentprofileform.controls['eligibilitynumber'].reset();
        this.studentprofileform.controls['marksheet'].reset();
       
      }
    }
  
    // upload photo and signature and marksheet
    onmarksheetSelect(event:any) {
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.studentprofileform.controls.marksheet.setValue(file);
      }
    }
    onPhotoSelect(event:any) {
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.studentprofileform.controls.photo.setValue(file);
      }
    }
  
    onsignatureSelect(event:any)
    { 
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.studentprofileform.controls.signature.setValue(file);
      }
    }

 // for validattion
 allowNumericDigitsOnlyOnKeyUp(e: { which: any; keyCode: any; }) {		
  const charCode = e.which ? e.which : e.keyCode;
  
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    this.msg = '<span class="msg">OOPs! Only Numbers are allow</span>';
  }
  else
  {
    this.msg = '<span class="msg"></span>';
  }
}
allowNumeric(e: { which: any; keyCode: any; }) {		
  const charCode = e.which ? e.which : e.keyCode;


  if (charCode > 31 && (charCode < 48 || charCode > 57)) {

    this.conmsgs = '<span class="msg">OOPs! Only Numbers are allow</span>';
  }
  else
  {
    this.conmsgs = '<span class="msg"></span>';
  }
}

allowNumericforaadhar(e: { which: any; keyCode: any; }) {		
  const charCode = e.which ? e.which : e.keyCode;
  
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    this.aadharmsg = '<span class="msg">OOPs! Only Numbers are allow</span>';
  }
  else
  {
    this.aadharmsg = '<span class="msg"></span>';
  }
}

allowNumericDfordistance(e: { which: any; keyCode: any; }) {		
  const charCode = e.which ? e.which : e.keyCode;
  
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    this.distmsg = '<span class="distmsg">OOPs! Only Numbers are allow</span>';
    return false;
  }
  else
  {
    this.distmsg = '<span class="distmsg"></span>';
    return true;
  }
}

allowNumericforpincode(e: { which: any; keyCode: any; }) {		
  const charCode = e.which ? e.which : e.keyCode;
  
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    this.pinmsg = '<span class="pinmsg">OOPs! Only Numbers are allow</span>';
    return false;
  }
  else
  {
    this.pinmsg = '<span class="pinmsg"></span>';
    return true;
  }
}

allowNumericDigitschool(e: { which: any; keyCode: any; }) {		
  const charCode = e.which ? e.which : e.keyCode;
  
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    this.schoolmsg = '<span class="schoolmsg">OOPs! Only Numbers are allow</span>';
    return false;
  }
  else
  {
    this.schoolmsg = '<span class="schoolmsg"></span>';
    return true;
  }
}

keyPressAlphanumeric(event: { keyCode: number; preventDefault: () => void; }) {

  var inp = String.fromCharCode(event.keyCode);

  if (/[a-zA-Z]/.test(inp)) {
    // return true;
    this.alphmsg = '<span class="msg"></span>';
  } else {
    event.preventDefault();
    // return false;
   
    this.alphmsg = '<span class="msg">OOPs! Only Alphabetic are allow</span>';
  }
}


// get coursecode

changecourse(event:any)
{
   this.chngcourse = event.target.value;

   console.log(this.chngcourse);

  //  if(this.streamvalue != 'Vocational')
  //  {
  //    this.getsubjects();
  //  }
  //  else
  //  {
    //  this.getstudentclassForm();
    this.service.post('/StudentApi11/getSubjectByCoursecode',{coursecode:this.studentprofileform.controls.course.value}).subscribe((res:any) =>{
      this.coursesubj = res['Data'];
      console.log(this.coursesubj)
      this.subjcode = res['status'];
      console.log(this.subjcode);
      // if(this.coursecode == '0')
      // {
      //   this.getsubjects();
      //   this.getselectedsubject();
      // }
      // else{
       this.course1 = this.coursesubj[0].subjectname;
       this.course2 = this.coursesubj[1].subjectname;
       this.course3 = this.coursesubj[2].subjectname;
       this.course4 = this.coursesubj[3].subjectname;
       this.course5 = this.coursesubj[4].subjectname;
       this.course6 = this.coursesubj[5].subjectname;
      // }
     //  console.log(this.subjcode);
    })
   //}

}

onstudentprofilesubmit(){
 
  let seql = this.service.post('/StudentApi11/emailcheck', {email:this.studentprofileform.controls.email.value,standard:"11th"});
  seql
      .subscribe((res:any) => {
        console.log(this.studentid)
        if(res['status'] == 'success'){
          if(res['Data'][0]['studentid'] != this.studentid){
            alert('This email is already exists')
          }
          else{
            this.studentprofilesubmit();
          }
        }
        else{
          this.studentprofilesubmit();
        }
          //  this.submitted=true;
      }, err => {
          alert("Error");
      }); 
}


studentprofilesubmit()
{
  // if(this.studentprofileform.controls.stream.value != 'Vocational')
  //     {
  //       if(confirm('Are You Sure...! You Want Change The Stream'))
  //       {
  //          this.service.post('/StudentApi11/deletesubjects', {studentid:this.studentid}).subscribe((res:any)=>{
  //           this.delsubj = res['Data'];
  //           this.submitdata();
  //          })
  //       }
  //       else
  //       {
  //         this.submitdata();
  //       }
  //     }



  this.submitdata();

}

submitdata()
{
  var chk=this.isDOBvalid();
  if(chk){//if DOB is valid : 12year for 9th and 14year for 11th
        this.changedate();
        // this.boardChanged(this.studentprofileform.controls.boardname.value);
        this.submitted = true;
        // this.date=new Date();
        // let latest_date =this.datepipe.transform(this.date, 'dd-MM-yyyy');
        // console.log(latest_date);
        if (this.studentprofileform.invalid)
          {
        
            alert("Fill All Details");
            return;

          }
        if(this.studentprofileform.controls.captcha_text.value == this.captcha)
        {
    
        if(this.studentprofileform.controls.boardname.value === 'other')
        {
          let subject : {  subject_one:any,subject_two:any,subject_three:any,subject_four:any,
            subject_five:any,subject_six:any,subject_seven:any,subject_eight:any,
            subject_nine:any,subject_ten:any,subject_eleven:any} =
           {
               
                subject_one:this.studentprofileform.controls.subject_one.value,
                subject_two:this.studentprofileform.controls.subject_two.value,
                subject_three:this.studentprofileform.controls.subject_three.value,
                subject_four:this.studentprofileform.controls.subject_four.value,
                subject_five:this.studentprofileform.controls.subject_five.value,
                subject_six:this.studentprofileform.controls.subject_six.value,
                subject_seven:this.studentprofileform.controls.subject_seven.value,
                subject_eight:this.studentprofileform.controls.subject_eight.value,
                subject_nine:this.studentprofileform.controls.subject_nine.value,
                subject_ten:this.studentprofileform.controls.subject_ten.value,
                subject_eleven:this.studentprofileform.controls.subject_eleven.value
           }
           this.subj_array=[];
           this.subjectlist = [subject.subject_one,subject.subject_two,subject.subject_three,subject.subject_four,subject.subject_five,subject.subject_six,
          subject.subject_seven,subject.subject_eight,subject.subject_nine,subject.subject_ten,subject.subject_eleven];
      
           let sorted_arr = this.subjectlist.slice().sort();
           for (let i = 0; i < sorted_arr.length - 1; i++) {
            if (sorted_arr[i + 1] == sorted_arr[i]) {
              this.subj_array.push(sorted_arr[i]);//push nonunique subject to array
            }
      
          }
      
          if(this.subj_array.length == 0)
          {
            let subject : {class:any,rollno:any, division:any, enrollmentid:any, schoolid:any, studentid:any,stream:any,course:any, subject_one:any,subject_two:any,subject_three:any,subject_four:any,
              subject_five:any,subject_six:any,subject_seven:any,subject_eight:any,
              subject_nine:any,subject_ten:any,subject_eleven:any} =
             {
                  rollno:this.studentprofileform.controls.rollno.value, 
                  class:this.studentprofileform.controls.class.value, 
                  division:this.studentprofileform.controls.division.value,
                  enrollmentid:sessionStorage.getItem('enrollmentid'),
                  schoolid:sessionStorage.getItem('schoolid'),
                  studentid:this.studentid,
                  stream:this.studentprofileform.controls.stream.value,
                  course:this.studentprofileform.controls.course.value,
                  subject_one:this.studentprofileform.controls.subject_one.value,
                  subject_two:this.studentprofileform.controls.subject_two.value,
                  subject_three:this.studentprofileform.controls.subject_three.value,
                  subject_four:this.studentprofileform.controls.subject_four.value,
                  subject_five:this.studentprofileform.controls.subject_five.value,
                  subject_six:this.studentprofileform.controls.subject_six.value,
                  subject_seven:this.studentprofileform.controls.subject_seven.value,
                  subject_eight:this.studentprofileform.controls.subject_eight.value,
                  subject_nine:this.studentprofileform.controls.subject_nine.value,
                  subject_ten:this.studentprofileform.controls.subject_ten.value,
                  subject_eleven:this.studentprofileform.controls.subject_eleven.value,
                  
             }
             //  alert("Subject Saved");
           let seql = this.service.post('/StudentApi11/editsavesubject', subject);
           seql
               .subscribe((res:any) => {
                   console.log(res);
                   if(res['status']=='false'){
                    alert(res['Data']);
                   }
                   else{
                    this.validated_subject=true;
                    this.submitted=true;
                   }
                   
               }, err => {
                  console.log(err);
               }); 
      
               let chekstud:{studentid:any,schoolid:any,username:any}={
                studentid:this.studentid,
                schoolid:this.schoolid,
                username:this.username
              }
      
              this.service.post('/StudentApi11/testauditTrail',chekstud).subscribe((res:any)=>{
                this.studata = res['Data'];
              });         
      
      let user: {studentid:any,stname:any,ftname:any,mtname:any,dob:any,gender:any,email:any,mobile_no:any,alt_mobile_no:any,
      aadhar:any,caste:any,street:any,locality:any,city:any,state:any,taluka:any,district:any,landmark:any,pincode:any,
      house_ownership:any,internet_coverage:any,distance_coverage:any,access_to_smart_phone:any,access_internet:any,
      boardname:any,otherboardname:any,eligibilitynumber:any,
      // security_ques:any,security_ans:any,
      fathers_qualification:any,fathers_job:any,
      fathers_annual_income:any,mothers_qualification:any,mothers_job:any,mothers_annual_income:any,
      distance_of_school_to_house:any,mode_of_transport:any,
      // namemismatchflag:any,
      seat:any,
      exam:any
      
      }
      
      = {
        // schoolid:this.schoolid,
        // username:this.username,
        studentid:this.studentid,
        stname:this.studentprofileform.controls.stname.value,
        ftname:this.studentprofileform.controls.ftname.value,
        mtname:this.studentprofileform.controls.mtname.value,
        dob:this.converteddate,
        gender:this.studentprofileform.controls.gender.value,
        email:this.studentprofileform.controls.email.value,
        mobile_no:this.studentprofileform.controls.mobile_no.value,
        alt_mobile_no:this.studentprofileform.controls.alt_mobile_no.value,
        aadhar:this.studentprofileform.controls.aadhar.value,
        caste:this.studentprofileform.controls.caste.value,
        street:this.studentprofileform.controls.street.value,
        locality:this.studentprofileform.controls.locality.value,
        city:this.studentprofileform.controls.city.value,
        state:this.studentprofileform.controls.state.value,
        taluka:this.studentprofileform.controls.taluka.value,
        district:this.studentprofileform.controls.district.value,
        landmark:this.studentprofileform.controls.landmark.value,
        pincode:this.studentprofileform.controls.pincode.value,
        house_ownership:this.studentprofileform.controls.house_ownership.value,
        internet_coverage:this.studentprofileform.controls.internet_coverage.value,
        distance_coverage:this.studentprofileform.controls.distance_coverage.value,
        access_to_smart_phone:this.studentprofileform.controls.access_to_smart_phone.value,
        access_internet:this.studentprofileform.controls.access_internet.value,
        distance_of_school_to_house:this.studentprofileform.controls.distance_of_school_to_house.value,
        mode_of_transport:this.studentprofileform.controls.mode_of_transport.value,
        // password:this.studentprofileform.controls.password.value,
        // c_password:this.studentprofileform.controls.c_password.value,
        // security_ques:this.studentprofileform.controls.security_ques.value,
        // security_ans:this.studentprofileform.controls.security_ans.value,
        fathers_qualification:this.studentprofileform.controls.fathers_qualification.value,
        fathers_job:this.studentprofileform.controls.fathers_job.value,
        fathers_annual_income:this.studentprofileform.controls.fathers_annual_income.value,
        mothers_qualification:this.studentprofileform.controls.mothers_qualification.value,
        mothers_job:this.studentprofileform.controls.mothers_job.value,
        mothers_annual_income:this.studentprofileform.controls.mothers_annual_income.value,
        boardname:this.studentprofileform.controls.boardname.value,
        otherboardname:this.studentprofileform.controls.otherboardname.value,
        eligibilitynumber:this.studentprofileform.controls.eligibilitynumber.value,
        // namemismatchflag:"Yes",
        
        // application_status:"Submitted",
        // is_loggin:'1',
        // date: latest_date
        seat:this.studentprofileform.controls.seat.value,
        exam:this.studentprofileform.controls.exam.value,
      }
      
      console.log(user);
      //  alert("Information Saved");
      
      let seq = this.service.post('/StudentApi11/submitstudentformSchool', user);
      seq
      .subscribe(res => {
          console.log(res);
          alert("Information Updated Successfully");
          //  //window.location.reload();
          // this.router.navigate(['/candidateprofile']);
      }, err       => {
          alert("Error");
      }); 
      
               //  upload documents with marksheet
      const formData = new FormData();
      
      formData.append('photo', this.studentprofileform.controls.photo.value);
      formData.append('id', this.studentid);
      this.photo_upload_success=true;
      this.service.upload11thstudentFile(formData).subscribe(
        (res:any) => {
         this.uploadResponse = res;
            console.log(res);
            // this.getstudentform();
        },
        (err: any) => {  
          console.log(err);
        }
      );
      
      formData.append('signature', this.studentprofileform.controls.signature.value);
      formData.append('id', this.studentid);
      this.signature_upload_success=true;
      this.service.upload11thstudentFile(formData).subscribe(
        (res:any) => {
         // this.uploadResponse = res;
            console.log(res);
            // this.getstudentform();
        },
        (err: any) => {  
          console.log(err);
        }
      );
      
      
       formData.append('marksheet', this.studentprofileform.controls.marksheet.value);
       formData.append('id', this.studentid);
       this.signature_upload_success=true;
       this.service.upload11thstudentFile(formData).subscribe(
         (res:any) => {
          // this.uploadResponse = res;
             console.log(res);
             // this.getstudentform();
         },
         (err: any) => {  
           console.log(err);
         }
       );
      
      
               
      
          }
          // else if(this.subj_array == ',,,,,,,,')
          else if(this.subj_array == '')
          {
          
          
            let user: {studentid:any,stname:any,ftname:any,mtname:any,dob:any,gender:any,email:any,mobile_no:any,alt_mobile_no:any,
              aadhar:any,caste:any,street:any,locality:any,city:any,state:any,taluka:any,district:any,landmark:any,pincode:any,
              house_ownership:any,internet_coverage:any,distance_coverage:any,access_to_smart_phone:any,access_internet:any,
              namemismatchflag:any,
              security_ques:any,security_ans:any,fathers_qualification:any,fathers_job:any,
              fathers_annual_income:any,mothers_qualification:any,mothers_job:any,mothers_annual_income:any,
              distance_of_school_to_house:any,mode_of_transport:any,boardname:any,otherboardname:any,
              eligibilitynumber:any,
              // photo:any,
              // signature:any,
             
              // application_status:any
              seat:any,
              exam:any
            }  = {
                  studentid:this.studentid,
                  stname:this.studentprofileform.controls.stname.value,
                  ftname:this.studentprofileform.controls.ftname.value,
                  mtname:this.studentprofileform.controls.mtname.value,
                  dob:this.converteddate,
                  gender:this.studentprofileform.controls.gender.value,
                  email:this.studentprofileform.controls.email.value,
                  mobile_no:this.studentprofileform.controls.mobile_no.value,
                  alt_mobile_no:this.studentprofileform.controls.alt_mobile_no.value,
                  aadhar:this.studentprofileform.controls.aadhar.value,
                  caste:this.studentprofileform.controls.caste.value,
                  street:this.studentprofileform.controls.street.value,
                  locality:this.studentprofileform.controls.locality.value,
                  city:this.studentprofileform.controls.city.value,
                  state:this.studentprofileform.controls.state.value,
                  taluka:this.studentprofileform.controls.taluka.value,
                  district:this.studentprofileform.controls.district.value,
                  landmark:this.studentprofileform.controls.landmark.value,
                  pincode:this.studentprofileform.controls.pincode.value,
                  house_ownership:this.studentprofileform.controls.house_ownership.value,
                  internet_coverage:this.studentprofileform.controls.internet_coverage.value,
                  distance_coverage:this.studentprofileform.controls.distance_coverage.value,
                  access_to_smart_phone:this.studentprofileform.controls.access_to_smart_phone.value,
                  access_internet:this.studentprofileform.controls.access_internet.value,
                  distance_of_school_to_house:this.studentprofileform.controls.distance_of_school_to_house.value,
                  mode_of_transport:this.studentprofileform.controls.mode_of_transport.value,
                  // password:this.studentprofileform.controls.password.value,
                  // c_password:this.studentprofileform.controls.c_password.value,
                  security_ques:this.studentprofileform.controls.security_ques.value,
                  security_ans:this.studentprofileform.controls.security_ans.value,
                  fathers_qualification:this.studentprofileform.controls.fathers_qualification.value,
                  fathers_job:this.studentprofileform.controls.fathers_job.value,
                  fathers_annual_income:this.studentprofileform.controls.fathers_annual_income.value,
                  mothers_qualification:this.studentprofileform.controls.mothers_qualification.value,
                  mothers_job:this.studentprofileform.controls.mothers_job.value,
                  mothers_annual_income:this.studentprofileform.controls.mothers_annual_income.value,
                  boardname:this.studentprofileform.controls.boardname.value,
                  otherboardname:this.studentprofileform.controls.otherboardname.value,
                  eligibilitynumber:this.studentprofileform.controls.eligibilitynumber.value,
                  // photo:this.studentprofileform.controls.photo.value,
                  // signature:this.studentprofileform.controls.signature.value,
                  namemismatchflag:"Yes",
                  seat:this.studentprofileform.controls.seat.value,
                  exam:this.studentprofileform.controls.exam.value,
                  // application_status:"Pending"
                  
             }
      console.log(user);
      //  alert("Information Saved");
      
      let boarddata :{seat:any,exam:any}={
      seat :this.studentprofileform.controls.seat.value,
      exam:this.studentprofileform.controls.exam.value
      }
      if(boarddata.seat == '')
      {
      let stud: {studentid:any,stname:any,ftname:any,mtname:any,dob:any,gender:any,email:any,mobile_no:any,alt_mobile_no:any,aadhar:any,
        caste:any,street:any,locality:any,city:any,state:any,taluka:any,district:any,landmark:any,pincode:any,
        house_ownership:any,internet_coverage:any,distance_coverage:any,access_to_smart_phone:any,access_internet:any,
        boardname:any,otherboardname:any,eligibilitynumber:any
        security_ques:any,security_ans:any,fathers_qualification:any,fathers_job:any,
        fathers_annual_income:any,mothers_qualification:any,mothers_job:any,mothers_annual_income:any,
        distance_of_school_to_house:any,mode_of_transport:any,
        namemismatchflag:any,
        application_status:any,
        seat:any,
        exam:any        
      }
        
       = {
            studentid:this.studentid,
            stname:this.studentprofileform.controls.stname.value,
            ftname:this.studentprofileform.controls.ftname.value,
            mtname:this.studentprofileform.controls.mtname.value,
            dob:this.converteddate,
            gender:this.studentprofileform.controls.gender.value,
            email:this.studentprofileform.controls.email.value,
            mobile_no:this.studentprofileform.controls.mobile_no.value,
            alt_mobile_no:this.studentprofileform.controls.alt_mobile_no.value,
            aadhar:this.studentprofileform.controls.aadhar.value,
            caste:this.studentprofileform.controls.caste.value,
            street:this.studentprofileform.controls.street.value,
            locality:this.studentprofileform.controls.locality.value,
            city:this.studentprofileform.controls.city.value,
            state:this.studentprofileform.controls.state.value,
            taluka:this.studentprofileform.controls.taluka.value,
            district:this.studentprofileform.controls.district.value,
            landmark:this.studentprofileform.controls.landmark.value,
            pincode:this.studentprofileform.controls.pincode.value,
            house_ownership:this.studentprofileform.controls.house_ownership.value,
            internet_coverage:this.studentprofileform.controls.internet_coverage.value,
            distance_coverage:this.studentprofileform.controls.distance_coverage.value,
            access_to_smart_phone:this.studentprofileform.controls.access_to_smart_phone.value,
            access_internet:this.studentprofileform.controls.access_internet.value,
            distance_of_school_to_house:this.studentprofileform.controls.distance_of_school_to_house.value,
            mode_of_transport:this.studentprofileform.controls.mode_of_transport.value,
            // password:this.studentprofileform.controls.password.value,
            // c_password:this.studentprofileform.controls.c_password.value,
            security_ques:this.studentprofileform.controls.security_ques.value,
            security_ans:this.studentprofileform.controls.security_ans.value,
            fathers_qualification:this.studentprofileform.controls.fathers_qualification.value,
            fathers_job:this.studentprofileform.controls.fathers_job.value,
            fathers_annual_income:this.studentprofileform.controls.fathers_annual_income.value,
            mothers_qualification:this.studentprofileform.controls.mothers_qualification.value,
            mothers_job:this.studentprofileform.controls.mothers_job.value,
            mothers_annual_income:this.studentprofileform.controls.mothers_annual_income.value,
            boardname:this.studentprofileform.controls.boardname.value,
            otherboardname:this.studentprofileform.controls.otherboardname.value,
            eligibilitynumber:this.studentprofileform.controls.eligibilitynumber.value,
            namemismatchflag:"No",
            
            application_status:"Pending",
            // is_loggin:'1',
            seat:this.studentprofileform.controls.seat.value,
            exam:this.studentprofileform.controls.exam.value,
       }
                  // this.isdisable= true;
      
      let seq = this.service.post('/StudentApi11/submitstudentformSchool', stud);
      seq
         .subscribe(res => {
             console.log(res);
             alert("Information Updated Successfully");
            //  //window.location.reload();
         }, err => {
             alert("Error");
         });  
      
         let subject : {class:any,rollno:any, division:any, enrollmentid:any, schoolid:any, studentid:any,stream:any,course:any, subject_one:any,subject_two:any,subject_three:any,subject_four:any,
          subject_five:any,subject_six:any,subject_seven:any,subject_eight:any,
          subject_nine:any,subject_ten:any,subject_eleven:any} =
         {
              rollno:this.studentprofileform.controls.rollno.value, 
              class:this.studentprofileform.controls.class.value, 
              division:this.studentprofileform.controls.division.value,
              enrollmentid:sessionStorage.getItem('enrollmentid'),
              schoolid:sessionStorage.getItem('schoolid'),
              studentid:this.studentid,
              stream:this.studentprofileform.controls.stream.value,
              course:this.studentprofileform.controls.course.value,
              subject_one:this.studentprofileform.controls.subject_one.value,
              subject_two:this.studentprofileform.controls.subject_two.value,
              subject_three:this.studentprofileform.controls.subject_three.value,
              subject_four:this.studentprofileform.controls.subject_four.value,
              subject_five:this.studentprofileform.controls.subject_five.value,
              subject_six:this.studentprofileform.controls.subject_six.value,
              subject_seven:this.studentprofileform.controls.subject_seven.value,
              subject_eight:this.studentprofileform.controls.subject_eight.value,
              subject_nine:this.studentprofileform.controls.subject_nine.value,
              subject_ten:this.studentprofileform.controls.subject_ten.value,
              subject_eleven:this.studentprofileform.controls.subject_eleven.value,
              
         }
          // alert(subject);
       let seql = this.service.post('/StudentApi11/editsavesubject', subject);
       seql
           .subscribe((res:any) => {
               console.log(res);
               if(res['status']=='false'){
                alert(res['Data']);
               }
               else{
                this.validated_subjects=true;
               }
               
              //  this.submitted=true;
           }, err => {
               alert("Error");
           }); 
      }
      else
      {
      let boardseq = this.service.post('/StudentApi11/searchsscrecord', boarddata);
      boardseq.subscribe((res:any)=>{
      this.boardinfo = res['Data'];
      console.log(this.boardinfo);
      this.Stname = this.boardinfo[0].Stname;
      console.log(user.stname);
      if(this.Stname !== user.stname)
      { 
        if(confirm('Enter Name and on certificate name are not matched.Do You Want to Continue Submit Information'))
        {
          // alert('ok');
      
          
          this.isdisable= true;
          
         let seq = this.service.post('/StudentApi11/submitstudentformSchool', user);
         seq
             .subscribe(res => {
                 console.log(res);
                 alert("Information Updated Successfully");
      //  //window.location.reload();
             }, err => {
                 alert("Error");
             });  
      
          
        }
        else
        {
          // alert('not ok');
          let stud: {studentid:any,stname:any,ftname:any,mtname:any,dob:any,gender:any,email:any,mobile_no:any,alt_mobile_no:any,aadhar:any,
            street:any,locality:any,city:any,state:any,taluka:any,district:any,landmark:any,pincode:any,
            house_ownership:any,internet_coverage:any,distance_coverage:any,access_to_smart_phone:any,access_internet:any,
            boardname:any,otherboardname:any,eligibilitynumber:any
            security_ques:any,security_ans:any,fathers_qualification:any,fathers_job:any,
            fathers_annual_income:any,mothers_qualification:any,mothers_job:any,mothers_annual_income:any,
            distance_of_school_to_house:any,mode_of_transport:any,
            namemismatchflag:any,
            seat:any,
            exam:any
            // application_status:any
            
          }
            
           = {
                studentid:this.studentid,
                stname:this.studentprofileform.controls.stname.value,
                ftname:this.studentprofileform.controls.ftname.value,
                mtname:this.studentprofileform.controls.mtname.value,
                dob:this.converteddate,
                gender:this.studentprofileform.controls.gender.value,
                email:this.studentprofileform.controls.email.value,
                mobile_no:this.studentprofileform.controls.mobile_no.value,
                alt_mobile_no:this.studentprofileform.controls.alt_mobile_no.value,
                aadhar:this.studentprofileform.controls.aadhar.value,
                street:this.studentprofileform.controls.street.value,
                locality:this.studentprofileform.controls.locality.value,
                city:this.studentprofileform.controls.city.value,
                state:this.studentprofileform.controls.state.value,
                taluka:this.studentprofileform.controls.taluka.value,
                district:this.studentprofileform.controls.district.value,
                landmark:this.studentprofileform.controls.landmark.value,
                pincode:this.studentprofileform.controls.pincode.value,
                house_ownership:this.studentprofileform.controls.house_ownership.value,
                internet_coverage:this.studentprofileform.controls.internet_coverage.value,
                distance_coverage:this.studentprofileform.controls.distance_coverage.value,
                access_to_smart_phone:this.studentprofileform.controls.access_to_smart_phone.value,
                access_internet:this.studentprofileform.controls.access_internet.value,
                distance_of_school_to_house:this.studentprofileform.controls.distance_of_school_to_house.value,
                mode_of_transport:this.studentprofileform.controls.mode_of_transport.value,
                // password:this.studentprofileform.controls.password.value,
                // c_password:this.studentprofileform.controls.c_password.value,
                security_ques:this.studentprofileform.controls.security_ques.value,
                security_ans:this.studentprofileform.controls.security_ans.value,
                fathers_qualification:this.studentprofileform.controls.fathers_qualification.value,
                fathers_job:this.studentprofileform.controls.fathers_job.value,
                fathers_annual_income:this.studentprofileform.controls.fathers_annual_income.value,
                mothers_qualification:this.studentprofileform.controls.mothers_qualification.value,
                mothers_job:this.studentprofileform.controls.mothers_job.value,
                mothers_annual_income:this.studentprofileform.controls.mothers_annual_income.value,
                boardname:this.studentprofileform.controls.boardname.value,
                otherboardname:this.studentprofileform.controls.otherboardname.value,
                eligibilitynumber:this.studentprofileform.controls.eligibilitynumber.value,
                namemismatchflag:"No",
                
                // application_status:"Pending",
                // is_loggin:'1',
                seat:this.studentprofileform.controls.seat.value,
                exam:this.studentprofileform.controls.exam.value,
           }
                      // this.isdisable= true;
          
         let seq = this.service.post('/StudentApi11/submitstudentformSchool', stud);
         seq
             .subscribe(res => {
                 console.log(res);
                 alert("Information Updated Successfully");
      //  //window.location.reload();
             }, err => {
                 alert("Error");
             });  
             let subject : {class:any,rollno:any, division:any, enrollmentid:any, schoolid:any, studentid:any,stream:any,course:any, subject_one:any,subject_two:any,subject_three:any,subject_four:any,
              subject_five:any,subject_six:any,subject_seven:any,subject_eight:any,
              subject_nine:any,subject_ten:any,subject_eleven:any} =
             {
                  rollno:this.studentprofileform.controls.rollno.value, 
                  class:this.studentprofileform.controls.class.value, 
                  division:this.studentprofileform.controls.division.value,
                  enrollmentid:sessionStorage.getItem('enrollmentid'),
                  schoolid:sessionStorage.getItem('schoolid'),
                  studentid:this.studentid,
                  stream:this.studentprofileform.controls.stream.value,
                  course:this.studentprofileform.controls.course.value,
                  subject_one:this.studentprofileform.controls.subject_one.value,
                  subject_two:this.studentprofileform.controls.subject_two.value,
                  subject_three:this.studentprofileform.controls.subject_three.value,
                  subject_four:this.studentprofileform.controls.subject_four.value,
                  subject_five:this.studentprofileform.controls.subject_five.value,
                  subject_six:this.studentprofileform.controls.subject_six.value,
                  subject_seven:this.studentprofileform.controls.subject_seven.value,
                  subject_eight:this.studentprofileform.controls.subject_eight.value,
                  subject_nine:this.studentprofileform.controls.subject_nine.value,
                  subject_ten:this.studentprofileform.controls.subject_ten.value,
                  subject_eleven:this.studentprofileform.controls.subject_eleven.value,
                  
             }
              // alert(subject);
           let seql = this.service.post('/StudentApi11/editsavesubject', subject);
           seql
               .subscribe((res:any) => {
                   console.log(res);
                   if(res['status'] == 'false'){
                    alert(res['Data']);
                   }
                   else
                   {
                    this.validated_subjects=true;
                   }
                   
                  //  this.submitted=true;
               }, err => {
                   alert("Error");
               }); 
      
      
        }
      
       
      }
      
      
      });
      
      // this.isdisable= true;
      //  let seq = this.service.post('/StudentApi11/submitstudentform', user);
      //  seq
      //      .subscribe(res => {
      //          console.log(res);
      //          alert("Form Submited");
      //          this.router.navigate(['/candidateprofile']);
      // }, err       => {
      //          alert("Error");
      //      });  
      
               //  upload documents with marksheet
      const formData = new FormData();
      
      formData.append('photo', this.studentprofileform.controls.photo.value);
      formData.append('id', this.studentid);
      this.photo_upload_success=true;
      this.service.upload11thstudentFile(formData).subscribe(
        (res:any) => {
         this.uploadResponse = res;
            console.log(res);
            // this.getstudentform();
        },
        (err: any) => {  
          console.log(err);
        }
      );
      
      formData.append('signature', this.studentprofileform.controls.signature.value);
      formData.append('id', this.studentid);
      this.signature_upload_success=true;
      this.service.upload11thstudentFile(formData).subscribe(
        (res:any) => {
         // this.uploadResponse = res;
            console.log(res);
            // this.getstudentform();
        },
        (err: any) => {  
          console.log(err);
        }
      );
      
      
       formData.append('marksheet', this.studentprofileform.controls.marksheet.value);
       formData.append('id', this.studentid);
       this.signature_upload_success=true;
       this.service.upload11thstudentFile(formData).subscribe(
         (res:any) => {
          // this.uploadResponse = res;
             console.log(res);
             // this.getstudentform();
         },
         (err: any) => {  
           console.log(err);
         }
       );
      }
      
          }
          
          else{
            // alert('in if')
            // alert(this.subj_array + '11111')
            alert(this.subj_array+ " has been selected multiple times from provided options. Please correct the selection and click Save")
            this.validated_subject=false;
          }
        }
        else{
      
          let subject : {  subject_one:any,subject_two:any,subject_three:any,subject_four:any,
            subject_five:any,subject_six:any,subject_seven:any,subject_eight:any,
            subject_nine:any,subject_ten:any,subject_eleven:any} =
           {
               
                subject_one:this.studentprofileform.controls.subject_one.value,
                subject_two:this.studentprofileform.controls.subject_two.value,
                subject_three:this.studentprofileform.controls.subject_three.value,
                subject_four:this.studentprofileform.controls.subject_four.value,
                subject_five:this.studentprofileform.controls.subject_five.value,
                subject_six:this.studentprofileform.controls.subject_six.value,
                subject_seven:this.studentprofileform.controls.subject_seven.value,
                subject_eight:this.studentprofileform.controls.subject_eight.value,
                subject_nine:this.studentprofileform.controls.subject_nine.value,
                subject_ten:this.studentprofileform.controls.subject_ten.value,
                subject_eleven:this.studentprofileform.controls.subject_eleven.value
           }
           this.subj_array=[];
           this.subjectlist = [subject.subject_one,subject.subject_two,subject.subject_three,subject.subject_four,subject.subject_five,subject.subject_six,
          subject.subject_seven,subject.subject_eight,subject.subject_nine,subject.subject_ten,subject.subject_eleven];
      
           let sorted_arr = this.subjectlist.slice().sort();
           for (let i = 0; i < sorted_arr.length - 1; i++) {
            if (sorted_arr[i + 1] == sorted_arr[i]) {
              this.subj_array.push(sorted_arr[i]);//push nonunique subject to array
            }
      
          }
      
          if(this.subj_array.length == 0)
          {
            let subject : {class:any,rollno:any, division:any, enrollmentid:any, schoolid:any, studentid:any,stream:any,course:any, subject_one:any,subject_two:any,subject_three:any,subject_four:any,
              subject_five:any,subject_six:any,subject_seven:any,subject_eight:any,
              subject_nine:any,subject_ten:any,subject_eleven:any} =
             {
                  rollno:this.studentprofileform.controls.rollno.value, 
                  class:this.studentprofileform.controls.class.value, 
                  division:this.studentprofileform.controls.division.value,
                  enrollmentid:sessionStorage.getItem('enrollmentid'),
                  schoolid:sessionStorage.getItem('schoolid'),
                  studentid:this.studentid,
                  stream:this.studentprofileform.controls.stream.value,
                  course:this.studentprofileform.controls.course.value,
                  subject_one:this.studentprofileform.controls.subject_one.value,
                  subject_two:this.studentprofileform.controls.subject_two.value,
                  subject_three:this.studentprofileform.controls.subject_three.value,
                  subject_four:this.studentprofileform.controls.subject_four.value,
                  subject_five:this.studentprofileform.controls.subject_five.value,
                  subject_six:this.studentprofileform.controls.subject_six.value,
                  subject_seven:this.studentprofileform.controls.subject_seven.value,
                  subject_eight:this.studentprofileform.controls.subject_eight.value,
                  subject_nine:this.studentprofileform.controls.subject_nine.value,
                  subject_ten:this.studentprofileform.controls.subject_ten.value,
                  subject_eleven:this.studentprofileform.controls.subject_eleven.value,
                  
             }
             //  alert("Subject Saved");
           let seql = this.service.post('/StudentApi11/editsavesubject', subject);
           seql
               .subscribe((res:any) => {
                   console.log(res);
                   if(res['status'] == 'false'){
                    alert(res['Data']);
                   }
                   else
                   {
                    this.validated_subject=true;
                    this.submitted=true;
                   }
                   
               }, err => {
                  console.log(err);
               }); 
      
               let chekstud:{studentid:any,schoolid:any,username:any}={
                studentid:this.studentid,
                schoolid:this.schoolid,
                username:this.username
              }
      
              this.service.post('/StudentApi11/testauditTrail',chekstud).subscribe((res:any)=>{
                this.studata = res['Data'];
              });          
      
      let user: {studentid:any,stname:any,ftname:any,mtname:any,dob:any,gender:any,email:any,mobile_no:any,alt_mobile_no:any,
      aadhar:any,caste:any,street:any,locality:any,city:any,state:any,taluka:any,district:any,landmark:any,pincode:any,
      house_ownership:any,internet_coverage:any,distance_coverage:any,access_to_smart_phone:any,access_internet:any,
      boardname:any,otherboardname:any,eligibilitynumber:any,
      security_ques:any,security_ans:any,fathers_qualification:any,fathers_job:any,
      fathers_annual_income:any,mothers_qualification:any,mothers_job:any,mothers_annual_income:any,
      distance_of_school_to_house:any,mode_of_transport:any,
      namemismatchflag:any,
      seat:any,
      exam:any      
      }
      
      = {
        // schoolid:this.schoolid,
        // username:this.username,
        studentid:this.studentid,
        stname:this.studentprofileform.controls.stname.value,
        ftname:this.studentprofileform.controls.ftname.value,
        mtname:this.studentprofileform.controls.mtname.value,
        dob:this.converteddate,
        gender:this.studentprofileform.controls.gender.value,
        email:this.studentprofileform.controls.email.value,
        mobile_no:this.studentprofileform.controls.mobile_no.value,
        alt_mobile_no:this.studentprofileform.controls.alt_mobile_no.value,
        aadhar:this.studentprofileform.controls.aadhar.value,
        caste:this.studentprofileform.controls.caste.value,
        street:this.studentprofileform.controls.street.value,
        locality:this.studentprofileform.controls.locality.value,
        city:this.studentprofileform.controls.city.value,
        state:this.studentprofileform.controls.state.value,
        taluka:this.studentprofileform.controls.taluka.value,
        district:this.studentprofileform.controls.district.value,
        landmark:this.studentprofileform.controls.landmark.value,
        pincode:this.studentprofileform.controls.pincode.value,
        house_ownership:this.studentprofileform.controls.house_ownership.value,
        internet_coverage:this.studentprofileform.controls.internet_coverage.value,
        distance_coverage:this.studentprofileform.controls.distance_coverage.value,
        access_to_smart_phone:this.studentprofileform.controls.access_to_smart_phone.value,
        access_internet:this.studentprofileform.controls.access_internet.value,
        distance_of_school_to_house:this.studentprofileform.controls.distance_of_school_to_house.value,
        mode_of_transport:this.studentprofileform.controls.mode_of_transport.value,
        // password:this.studentprofileform.controls.password.value,
        // c_password:this.studentprofileform.controls.c_password.value,
        security_ques:this.studentprofileform.controls.security_ques.value,
        security_ans:this.studentprofileform.controls.security_ans.value,
        fathers_qualification:this.studentprofileform.controls.fathers_qualification.value,
        fathers_job:this.studentprofileform.controls.fathers_job.value,
        fathers_annual_income:this.studentprofileform.controls.fathers_annual_income.value,
        mothers_qualification:this.studentprofileform.controls.mothers_qualification.value,
        mothers_job:this.studentprofileform.controls.mothers_job.value,
        mothers_annual_income:this.studentprofileform.controls.mothers_annual_income.value,
        boardname:this.studentprofileform.controls.boardname.value,
        otherboardname:this.studentprofileform.controls.otherboardname.value,
        eligibilitynumber:this.studentprofileform.controls.eligibilitynumber.value,
        namemismatchflag:"Yes",
        
        // application_status:"Submitted",
        // is_loggin:'1',
        // date: latest_date
        seat:this.studentprofileform.controls.seat.value,
        exam:this.studentprofileform.controls.exam.value,
      }
      
      console.log(user);
      //  alert("Information Saved");
      
      let boarddata :{seat:any,exam:any}={
      seat :this.studentprofileform.controls.seat.value,
      exam:this.studentprofileform.controls.exam.value
      }
      if(boarddata.seat == '')
      {
      let chekstud:{studentid:any,schoolid:any,username:any}={
      studentid:this.studentid,
      schoolid:this.schoolid,
      username:this.username
      }
      
      this.service.post('/StudentApi11/testauditTrail',chekstud).subscribe((res:any)=>{
      this.studata = res['Data'];
      });
      // alert('seat no not enter');
      let stud: {studentid:any,stname:any,ftname:any,mtname:any,dob:any,gender:any,email:any,mobile_no:any,alt_mobile_no:any,aadhar:any,
      caste:any,street:any,locality:any,city:any,state:any,taluka:any,district:any,landmark:any,pincode:any,
      house_ownership:any,internet_coverage:any,distance_coverage:any,access_to_smart_phone:any,access_internet:any,
      boardname:any,otherboardname:any,eligibilitynumber:any
      security_ques:any,security_ans:any,fathers_qualification:any,fathers_job:any,
      fathers_annual_income:any,mothers_qualification:any,mothers_job:any,mothers_annual_income:any,
      distance_of_school_to_house:any,mode_of_transport:any,
      namemismatchflag:any,
      seat:any,
      exam:any      
      }
      
      = {
          // schoolid:this.schoolid,
          // username:this.username,
          studentid:this.studentid,
          stname:this.studentprofileform.controls.stname.value,
          ftname:this.studentprofileform.controls.ftname.value,
          mtname:this.studentprofileform.controls.mtname.value,
          dob:this.converteddate,
          gender:this.studentprofileform.controls.gender.value,
          email:this.studentprofileform.controls.email.value,
          mobile_no:this.studentprofileform.controls.mobile_no.value,
          alt_mobile_no:this.studentprofileform.controls.alt_mobile_no.value,
          aadhar:this.studentprofileform.controls.aadhar.value,
          caste:this.studentprofileform.controls.caste.value,
          street:this.studentprofileform.controls.street.value,
          locality:this.studentprofileform.controls.locality.value,
          city:this.studentprofileform.controls.city.value,
          state:this.studentprofileform.controls.state.value,
          taluka:this.studentprofileform.controls.taluka.value,
          district:this.studentprofileform.controls.district.value,
          landmark:this.studentprofileform.controls.landmark.value,
          pincode:this.studentprofileform.controls.pincode.value,
          house_ownership:this.studentprofileform.controls.house_ownership.value,
          internet_coverage:this.studentprofileform.controls.internet_coverage.value,
          distance_coverage:this.studentprofileform.controls.distance_coverage.value,
          access_to_smart_phone:this.studentprofileform.controls.access_to_smart_phone.value,
          access_internet:this.studentprofileform.controls.access_internet.value,
          distance_of_school_to_house:this.studentprofileform.controls.distance_of_school_to_house.value,
          mode_of_transport:this.studentprofileform.controls.mode_of_transport.value,
          // password:this.studentprofileform.controls.password.value,
          // c_password:this.studentprofileform.controls.c_password.value,
          security_ques:this.studentprofileform.controls.security_ques.value,
          security_ans:this.studentprofileform.controls.security_ans.value,
          fathers_qualification:this.studentprofileform.controls.fathers_qualification.value,
          fathers_job:this.studentprofileform.controls.fathers_job.value,
          fathers_annual_income:this.studentprofileform.controls.fathers_annual_income.value,
          mothers_qualification:this.studentprofileform.controls.mothers_qualification.value,
          mothers_job:this.studentprofileform.controls.mothers_job.value,
          mothers_annual_income:this.studentprofileform.controls.mothers_annual_income.value,
          boardname:this.studentprofileform.controls.boardname.value,
          otherboardname:this.studentprofileform.controls.otherboardname.value,
          eligibilitynumber:this.studentprofileform.controls.eligibilitynumber.value,
          namemismatchflag:"No",
          seat:this.studentprofileform.controls.seat.value,
          exam:this.studentprofileform.controls.exam.value,
         
      }
                // this.isdisable= true;
      let seq = this.service.post('/StudentApi11/submitstudentformSchool', stud);
      seq
          .subscribe(res => {
              console.log(res);
              alert("Information Updated Successfully!!!");
               ////window.location.reload();
              // this.router.navigate(['/viewcandidateprofile']);
              // this.router.onSameUrlNavigation = 'reload'; 
              //////window.location.reload();
      }, err       => {
              alert("Error");
          }); 
      
      
      }
      else{
      let boardseq = this.service.post('/StudentApi11/searchsscrecord', boarddata);
      boardseq.subscribe((res:any)=>{
        if(res.status=='success'){
            
        this.boardinfo = res['Data'];
        console.log(this.boardinfo);
        this.Stname = this.boardinfo[0].Stname;
        console.log(user.stname);
        if(this.Stname !== user.stname)
        { 
          if(confirm('Enter Name and on certificate name are not matched.Do You Want to Continue Submit Information'))
          {
            // alert('ok');
      
            // this.isdisable= true;
            let seq = this.service.post('/StudentApi11/submitstudentformSchool', user);
            seq
                .subscribe(res => {
                    console.log(res);
                    alert("Information Updated Successfully");
                    // //window.location.reload();
                    // this.router.navigate(['/candidateprofile']);
            }, err       => {
                    alert("Error");
                }); 
            
          }
          else
          {
            let chekstud:{studentid:any,schoolid:any,username:any}={
              studentid:this.studentid,
              schoolid:this.schoolid,
              username:this.username
            }
      
            this.service.post('/StudentApi11/testauditTrail',chekstud).subscribe((res:any)=>{
              this.studata = res['Data'];
            });
            
            // alert('not ok');
            let stud: {studentid:any,stname:any,ftname:any,mtname:any,dob:any,gender:any,email:any,mobile_no:any,alt_mobile_no:any,aadhar:any,
              caste:any,street:any,locality:any,city:any,state:any,taluka:any,district:any,landmark:any,pincode:any,
              house_ownership:any,internet_coverage:any,distance_coverage:any,access_to_smart_phone:any,access_internet:any,
              boardname:any,otherboardname:any,eligibilitynumber:any
              security_ques:any,security_ans:any,fathers_qualification:any,fathers_job:any,
              fathers_annual_income:any,mothers_qualification:any,mothers_job:any,mothers_annual_income:any,
              distance_of_school_to_house:any,mode_of_transport:any,
              namemismatchflag:any,
              // application_status:any,date:any
              seat:any,
              exam:any
            }
              
            = {
                  // schoolid:this.schoolid,
                  // username:this.username,
                  studentid:this.studentid,
                  stname:this.studentprofileform.controls.stname.value,
                  ftname:this.studentprofileform.controls.ftname.value,
                  mtname:this.studentprofileform.controls.mtname.value,
                  dob:this.converteddate,
                  gender:this.studentprofileform.controls.gender.value,
                  email:this.studentprofileform.controls.email.value,
                  mobile_no:this.studentprofileform.controls.mobile_no.value,
                  alt_mobile_no:this.studentprofileform.controls.alt_mobile_no.value,
                  aadhar:this.studentprofileform.controls.aadhar.value,
                  caste:this.studentprofileform.controls.caste.value,
                  street:this.studentprofileform.controls.street.value,
                  locality:this.studentprofileform.controls.locality.value,
                  city:this.studentprofileform.controls.city.value,
                  state:this.studentprofileform.controls.state.value,
                  taluka:this.studentprofileform.controls.taluka.value,
                  district:this.studentprofileform.controls.district.value,
                  landmark:this.studentprofileform.controls.landmark.value,
                  pincode:this.studentprofileform.controls.pincode.value,
                  house_ownership:this.studentprofileform.controls.house_ownership.value,
                  internet_coverage:this.studentprofileform.controls.internet_coverage.value,
                  distance_coverage:this.studentprofileform.controls.distance_coverage.value,
                  access_to_smart_phone:this.studentprofileform.controls.access_to_smart_phone.value,
                  access_internet:this.studentprofileform.controls.access_internet.value,
                  distance_of_school_to_house:this.studentprofileform.controls.distance_of_school_to_house.value,
                  mode_of_transport:this.studentprofileform.controls.mode_of_transport.value,
                  // password:this.studentprofileform.controls.password.value,
                  // c_password:this.studentprofileform.controls.c_password.value,
                  security_ques:this.studentprofileform.controls.security_ques.value,
                  security_ans:this.studentprofileform.controls.security_ans.value,
                  fathers_qualification:this.studentprofileform.controls.fathers_qualification.value,
                  fathers_job:this.studentprofileform.controls.fathers_job.value,
                  fathers_annual_income:this.studentprofileform.controls.fathers_annual_income.value,
                  mothers_qualification:this.studentprofileform.controls.mothers_qualification.value,
                  mothers_job:this.studentprofileform.controls.mothers_job.value,
                  mothers_annual_income:this.studentprofileform.controls.mothers_annual_income.value,
                  boardname:this.studentprofileform.controls.boardname.value,
                  otherboardname:this.studentprofileform.controls.otherboardname.value,
                  eligibilitynumber:this.studentprofileform.controls.eligibilitynumber.value,
                  namemismatchflag:"No",
                  seat:this.studentprofileform.controls.seat.value,
                  exam:this.studentprofileform.controls.exam.value,
                  // application_status:"submitted",
                  // is_loggin:'1',
                  // date: latest_date
            }
                        // this.isdisable= true;
              let seq = this.service.post('/StudentApi11/submitstudentformSchool', stud);
              seq
                  .subscribe(res => {
                      console.log(res);
                      alert("Information Updated Successfully");
                      // //window.location.reload();
                      // this.router.navigate(['/edit11th-candidate']);
              }, err       => {
                      alert("Error");
                  }); 
      
          }
            
        }
      

        }//success
        else{
          alert("Please Enter Valid SSC Seat Number and SSC Exam Year");
        }
      });
      }
               //  upload documents with marksheet
      const formData = new FormData();
      
      formData.append('photo', this.studentprofileform.controls.photo.value);
      formData.append('id', this.studentid);
      this.photo_upload_success=true;
      this.service.upload11thstudentFile(formData).subscribe(
        (res:any) => {
         this.uploadResponse = res;
            console.log(res);
            // this.getstudentform();
        },
        (err: any) => {  
          console.log(err);
        }
      );
      
      formData.append('signature', this.studentprofileform.controls.signature.value);
      formData.append('id', this.studentid);
      this.signature_upload_success=true;
      this.service.upload11thstudentFile(formData).subscribe(
        (res:any) => {
         // this.uploadResponse = res;
            console.log(res);
            // this.getstudentform();
        },
        (err: any) => {  
          console.log(err);
        }
      );
      
      
       formData.append('marksheet', this.studentprofileform.controls.marksheet.value);
       formData.append('id', this.studentid);
       this.signature_upload_success=true;
       this.service.upload11thstudentFile(formData).subscribe(
         (res:any) => {
          // this.uploadResponse = res;
             console.log(res);
             // this.getstudentform();
         },
         (err: any) => {  
           console.log(err);
         }
       );
      
      
               
      
          }
          else if(this.subj_array == ',,,,,,,' || this.subj_array == ',,,,,,,,'){
          
            let user: {studentid:any,stname:any,ftname:any,mtname:any,dob:any,gender:any,email:any,mobile_no:any,alt_mobile_no:any,
              aadhar:any,caste:any,street:any,locality:any,city:any,state:any,taluka:any,district:any,landmark:any,pincode:any,
              house_ownership:any,internet_coverage:any,distance_coverage:any,access_to_smart_phone:any,access_internet:any,
              namemismatchflag:any,
              security_ques:any,security_ans:any,fathers_qualification:any,fathers_job:any,
              fathers_annual_income:any,mothers_qualification:any,mothers_job:any,mothers_annual_income:any,
              distance_of_school_to_house:any,mode_of_transport:any,boardname:any,otherboardname:any,
              eligibilitynumber:any,
              // photo:any,
              // signature:any,
             
              // application_status:any
              seat:any,
              exam:any
            }  = {
                  studentid:this.studentid,
                  stname:this.studentprofileform.controls.stname.value,
                  ftname:this.studentprofileform.controls.ftname.value,
                  mtname:this.studentprofileform.controls.mtname.value,
                  dob:this.converteddate,
                  gender:this.studentprofileform.controls.gender.value,
                  email:this.studentprofileform.controls.email.value,
                  mobile_no:this.studentprofileform.controls.mobile_no.value,
                  alt_mobile_no:this.studentprofileform.controls.alt_mobile_no.value,
                  aadhar:this.studentprofileform.controls.aadhar.value,
                  caste:this.studentprofileform.controls.caste.value,
                  street:this.studentprofileform.controls.street.value,
                  locality:this.studentprofileform.controls.locality.value,
                  city:this.studentprofileform.controls.city.value,
                  state:this.studentprofileform.controls.state.value,
                  taluka:this.studentprofileform.controls.taluka.value,
                  district:this.studentprofileform.controls.district.value,
                  landmark:this.studentprofileform.controls.landmark.value,
                  pincode:this.studentprofileform.controls.pincode.value,
                  house_ownership:this.studentprofileform.controls.house_ownership.value,
                  internet_coverage:this.studentprofileform.controls.internet_coverage.value,
                  distance_coverage:this.studentprofileform.controls.distance_coverage.value,
                  access_to_smart_phone:this.studentprofileform.controls.access_to_smart_phone.value,
                  access_internet:this.studentprofileform.controls.access_internet.value,
                  distance_of_school_to_house:this.studentprofileform.controls.distance_of_school_to_house.value,
                  mode_of_transport:this.studentprofileform.controls.mode_of_transport.value,
                  // password:this.studentprofileform.controls.password.value,
                  // c_password:this.studentprofileform.controls.c_password.value,
                  security_ques:this.studentprofileform.controls.security_ques.value,
                  security_ans:this.studentprofileform.controls.security_ans.value,
                  fathers_qualification:this.studentprofileform.controls.fathers_qualification.value,
                  fathers_job:this.studentprofileform.controls.fathers_job.value,
                  fathers_annual_income:this.studentprofileform.controls.fathers_annual_income.value,
                  mothers_qualification:this.studentprofileform.controls.mothers_qualification.value,
                  mothers_job:this.studentprofileform.controls.mothers_job.value,
                  mothers_annual_income:this.studentprofileform.controls.mothers_annual_income.value,
                  boardname:this.studentprofileform.controls.boardname.value,
                  otherboardname:this.studentprofileform.controls.otherboardname.value,
                  eligibilitynumber:this.studentprofileform.controls.eligibilitynumber.value,
                  // photo:this.studentprofileform.controls.photo.value,
                  // signature:this.studentprofileform.controls.signature.value,
                  namemismatchflag:"Yes",
                  // application_status:"Pending"
                  seat:this.studentprofileform.controls.seat.value,
                  exam:this.studentprofileform.controls.exam.value,
             }
      console.log(user);
      //  alert("Information Saved");
      
      let boarddata :{seat:any,exam:any}={
      seat :this.studentprofileform.controls.seat.value,
      exam:this.studentprofileform.controls.exam.value
      }
      if(boarddata.seat == '')
      {
      let stud: {studentid:any,stname:any,ftname:any,mtname:any,dob:any,gender:any,email:any,mobile_no:any,alt_mobile_no:any,aadhar:any,
        caste:any,street:any,locality:any,city:any,state:any,taluka:any,district:any,landmark:any,pincode:any,
        house_ownership:any,internet_coverage:any,distance_coverage:any,access_to_smart_phone:any,access_internet:any,
        boardname:any,otherboardname:any,eligibilitynumber:any
        security_ques:any,security_ans:any,fathers_qualification:any,fathers_job:any,
        fathers_annual_income:any,mothers_qualification:any,mothers_job:any,mothers_annual_income:any,
        distance_of_school_to_house:any,mode_of_transport:any,
        namemismatchflag:any,
        application_status:any,
        seat:any,
        exam:any        
      }
        
       = {
            studentid:this.studentid,
            stname:this.studentprofileform.controls.stname.value,
            ftname:this.studentprofileform.controls.ftname.value,
            mtname:this.studentprofileform.controls.mtname.value,
            dob:this.converteddate,
            gender:this.studentprofileform.controls.gender.value,
            email:this.studentprofileform.controls.email.value,
            mobile_no:this.studentprofileform.controls.mobile_no.value,
            alt_mobile_no:this.studentprofileform.controls.alt_mobile_no.value,
            aadhar:this.studentprofileform.controls.aadhar.value,
            caste:this.studentprofileform.controls.caste.value,
            street:this.studentprofileform.controls.street.value,
            locality:this.studentprofileform.controls.locality.value,
            city:this.studentprofileform.controls.city.value,
            state:this.studentprofileform.controls.state.value,
            taluka:this.studentprofileform.controls.taluka.value,
            district:this.studentprofileform.controls.district.value,
            landmark:this.studentprofileform.controls.landmark.value,
            pincode:this.studentprofileform.controls.pincode.value,
            house_ownership:this.studentprofileform.controls.house_ownership.value,
            internet_coverage:this.studentprofileform.controls.internet_coverage.value,
            distance_coverage:this.studentprofileform.controls.distance_coverage.value,
            access_to_smart_phone:this.studentprofileform.controls.access_to_smart_phone.value,
            access_internet:this.studentprofileform.controls.access_internet.value,
            distance_of_school_to_house:this.studentprofileform.controls.distance_of_school_to_house.value,
            mode_of_transport:this.studentprofileform.controls.mode_of_transport.value,
            // password:this.studentprofileform.controls.password.value,
            // c_password:this.studentprofileform.controls.c_password.value,
            security_ques:this.studentprofileform.controls.security_ques.value,
            security_ans:this.studentprofileform.controls.security_ans.value,
            fathers_qualification:this.studentprofileform.controls.fathers_qualification.value,
            fathers_job:this.studentprofileform.controls.fathers_job.value,
            fathers_annual_income:this.studentprofileform.controls.fathers_annual_income.value,
            mothers_qualification:this.studentprofileform.controls.mothers_qualification.value,
            mothers_job:this.studentprofileform.controls.mothers_job.value,
            mothers_annual_income:this.studentprofileform.controls.mothers_annual_income.value,
            boardname:this.studentprofileform.controls.boardname.value,
            otherboardname:this.studentprofileform.controls.otherboardname.value,
            eligibilitynumber:this.studentprofileform.controls.eligibilitynumber.value,
            namemismatchflag:"No",
            
            application_status:"Pending",
            // is_loggin:'1',
            seat:this.studentprofileform.controls.seat.value,
            exam:this.studentprofileform.controls.exam.value,
       }
                  // this.isdisable= true;
      
      let seq = this.service.post('/StudentApi11/submitstudentformSchool', stud);
      seq
         .subscribe(res => {
             console.log(res);
             alert("Information Updated Successfully");
       //window.location.reload();
         }, err => {
             alert("Error");
         });  
      
         let subject : {class:any,rollno:any, division:any, enrollmentid:any, schoolid:any, studentid:any,stream:any,course:any, subject_one:any,subject_two:any,subject_three:any,subject_four:any,
          subject_five:any,subject_six:any,subject_seven:any,subject_eight:any,
          subject_nine:any,subject_ten:any,subject_eleven:any} =
         {
              rollno:this.studentprofileform.controls.rollno.value, 
              class:this.studentprofileform.controls.class.value, 
              division:this.studentprofileform.controls.division.value,
              enrollmentid:sessionStorage.getItem('enrollmentid'),
              schoolid:sessionStorage.getItem('schoolid'),
              studentid:this.studentid,
              stream:this.studentprofileform.controls.stream.value,
              course:this.studentprofileform.controls.course.value,
              subject_one:this.studentprofileform.controls.subject_one.value,
              subject_two:this.studentprofileform.controls.subject_two.value,
              subject_three:this.studentprofileform.controls.subject_three.value,
              subject_four:this.studentprofileform.controls.subject_four.value,
              subject_five:this.studentprofileform.controls.subject_five.value,
              subject_six:this.studentprofileform.controls.subject_six.value,
              subject_seven:this.studentprofileform.controls.subject_seven.value,
              subject_eight:this.studentprofileform.controls.subject_eight.value,
              subject_nine:this.studentprofileform.controls.subject_nine.value,
              subject_ten:this.studentprofileform.controls.subject_ten.value,
              subject_eleven:this.studentprofileform.controls.subject_eleven.value,
              
         }
          // alert(subject);
       let seql = this.service.post('/StudentApi11/editsavesubject', subject);
       seql
           .subscribe((res:any) => {
               console.log(res);
               if(res['status']=='false'){
                alert(res['Data']);
               }
               else{
                this.validated_subjects=true;
               }
              
              //  this.submitted=true;
           }, err => {
               alert("Error");
           }); 
      }
      else
      {
      let boardseq = this.service.post('/StudentApi11/searchsscrecord', boarddata);
      boardseq.subscribe((res:any)=>{
      this.boardinfo = res['Data'];
      console.log(this.boardinfo);
      this.Stname = this.boardinfo[0].Stname;
      console.log(user.stname);
      if(this.Stname !== user.stname)
      { 
        if(confirm('Enter Name and on certificate name are not matched.Do You Want to Continue Submit Information'))
        {
          // alert('ok');
      
          
          this.isdisable= true;
          
         let seq = this.service.post('/StudentApi11/submitstudentformSchool', user);
         seq
             .subscribe(res => {
                 console.log(res);
                 alert("Information Updated Successfully");
       //window.location.reload();
             }, err => {
                 alert("Error");
             });  
      
          
        }
        else
        {
          // alert('not ok');
          let stud: {studentid:any,stname:any,ftname:any,mtname:any,dob:any,gender:any,email:any,mobile_no:any,alt_mobile_no:any,aadhar:any,
            street:any,locality:any,city:any,state:any,taluka:any,district:any,landmark:any,pincode:any,
            house_ownership:any,internet_coverage:any,distance_coverage:any,access_to_smart_phone:any,access_internet:any,
            boardname:any,otherboardname:any,eligibilitynumber:any
            security_ques:any,security_ans:any,fathers_qualification:any,fathers_job:any,
            fathers_annual_income:any,mothers_qualification:any,mothers_job:any,mothers_annual_income:any,
            distance_of_school_to_house:any,mode_of_transport:any,
            namemismatchflag:any,
            // application_status:any
            seat:any,
            exam:any,
          }
            
           = {
                studentid:this.studentid,
                stname:this.studentprofileform.controls.stname.value,
                ftname:this.studentprofileform.controls.ftname.value,
                mtname:this.studentprofileform.controls.mtname.value,
                dob:this.converteddate,
                gender:this.studentprofileform.controls.gender.value,
                email:this.studentprofileform.controls.email.value,
                mobile_no:this.studentprofileform.controls.mobile_no.value,
                alt_mobile_no:this.studentprofileform.controls.alt_mobile_no.value,
                aadhar:this.studentprofileform.controls.aadhar.value,
                street:this.studentprofileform.controls.street.value,
                locality:this.studentprofileform.controls.locality.value,
                city:this.studentprofileform.controls.city.value,
                state:this.studentprofileform.controls.state.value,
                taluka:this.studentprofileform.controls.taluka.value,
                district:this.studentprofileform.controls.district.value,
                landmark:this.studentprofileform.controls.landmark.value,
                pincode:this.studentprofileform.controls.pincode.value,
                house_ownership:this.studentprofileform.controls.house_ownership.value,
                internet_coverage:this.studentprofileform.controls.internet_coverage.value,
                distance_coverage:this.studentprofileform.controls.distance_coverage.value,
                access_to_smart_phone:this.studentprofileform.controls.access_to_smart_phone.value,
                access_internet:this.studentprofileform.controls.access_internet.value,
                distance_of_school_to_house:this.studentprofileform.controls.distance_of_school_to_house.value,
                mode_of_transport:this.studentprofileform.controls.mode_of_transport.value,
                // password:this.studentprofileform.controls.password.value,
                // c_password:this.studentprofileform.controls.c_password.value,
                security_ques:this.studentprofileform.controls.security_ques.value,
                security_ans:this.studentprofileform.controls.security_ans.value,
                fathers_qualification:this.studentprofileform.controls.fathers_qualification.value,
                fathers_job:this.studentprofileform.controls.fathers_job.value,
                fathers_annual_income:this.studentprofileform.controls.fathers_annual_income.value,
                mothers_qualification:this.studentprofileform.controls.mothers_qualification.value,
                mothers_job:this.studentprofileform.controls.mothers_job.value,
                mothers_annual_income:this.studentprofileform.controls.mothers_annual_income.value,
                boardname:this.studentprofileform.controls.boardname.value,
                otherboardname:this.studentprofileform.controls.otherboardname.value,
                eligibilitynumber:this.studentprofileform.controls.eligibilitynumber.value,
                namemismatchflag:"No",
                seat:this.studentprofileform.controls.seat.value,
                exam:this.studentprofileform.controls.exam.value,
                // application_status:"Pending",
                // is_loggin:'1',
               
           }
                      // this.isdisable= true;
          
         let seq = this.service.post('/StudentApi11/submitstudentformSchool', stud);
         seq
             .subscribe(res => {
                 console.log(res);
                 alert("Information Updated Successfully");
       //window.location.reload();
             }, err => {
                 alert("Error");
             });  
             let subject : {class:any,rollno:any, division:any, enrollmentid:any, schoolid:any, studentid:any,stream:any,course:any, subject_one:any,subject_two:any,subject_three:any,subject_four:any,
              subject_five:any,subject_six:any,subject_seven:any,subject_eight:any,
              subject_nine:any,subject_ten:any,subject_eleven:any} =
             {
                  rollno:this.studentprofileform.controls.rollno.value, 
                  class:this.studentprofileform.controls.class.value, 
                  division:this.studentprofileform.controls.division.value,
                  enrollmentid:sessionStorage.getItem('enrollmentid'),
                  schoolid:sessionStorage.getItem('schoolid'),
                  studentid:this.studentid,
                  stream:this.studentprofileform.controls.stream.value,
                  course:this.studentprofileform.controls.course.value,
                  subject_one:this.studentprofileform.controls.subject_one.value,
                  subject_two:this.studentprofileform.controls.subject_two.value,
                  subject_three:this.studentprofileform.controls.subject_three.value,
                  subject_four:this.studentprofileform.controls.subject_four.value,
                  subject_five:this.studentprofileform.controls.subject_five.value,
                  subject_six:this.studentprofileform.controls.subject_six.value,
                  subject_seven:this.studentprofileform.controls.subject_seven.value,
                  subject_eight:this.studentprofileform.controls.subject_eight.value,
                  subject_nine:this.studentprofileform.controls.subject_nine.value,
                  subject_ten:this.studentprofileform.controls.subject_ten.value,
                  subject_eleven:this.studentprofileform.controls.subject_eleven.value,
                  
             }
              // alert(subject);
           let seql = this.service.post('/StudentApi11/editsavesubject', subject);
           seql
               .subscribe((res:any) => {
                   console.log(res);
                   if(res['status'] == 'false'){
                    alert(res['Data']);
                   }
                   else
                   {
                    this.validated_subjects=true;
                   }
                   
                  //  this.submitted=true;
               }, err => {
                   alert("Error");
               }); 
      
      
        }
      
       
      }
      
      
      });
      
      // this.isdisable= true;
      //  let seq = this.service.post('/StudentApi11/submitstudentform', user);
      //  seq
      //      .subscribe(res => {
      //          console.log(res);
      //          alert("Form Submited");
      //          this.router.navigate(['/candidateprofile']);
      // }, err       => {
      //          alert("Error");
      //      });  
      
               //  upload documents with marksheet
      const formData = new FormData();
      
      formData.append('photo', this.studentprofileform.controls.photo.value);
      formData.append('id', this.studentid);
      this.photo_upload_success=true;
      this.service.upload11thstudentFile(formData).subscribe(
        (res:any) => {
         this.uploadResponse = res;
            console.log(res);
            // this.getstudentform();
        },
        (err: any) => {  
          console.log(err);
        }
      );
      
      formData.append('signature', this.studentprofileform.controls.signature.value);
      formData.append('id', this.studentid);
      this.signature_upload_success=true;
      this.service.upload11thstudentFile(formData).subscribe(
        (res:any) => {
         // this.uploadResponse = res;
            console.log(res);
            // this.getstudentform();
        },
        (err: any) => {  
          console.log(err);
        }
      );
      
      
       formData.append('marksheet', this.studentprofileform.controls.marksheet.value);
       formData.append('id', this.studentid);
       this.signature_upload_success=true;
       this.service.upload11thstudentFile(formData).subscribe(
         (res:any) => {
          // this.uploadResponse = res;
             console.log(res);
             // this.getstudentform();
         },
         (err: any) => {  
           console.log(err);
         }
       );
      }
      
          }
          // else if(this.subj_array == ',,,,,,,,,' || this.subj_array == ',,,,,,,'){
            else if(this.subj_array == '' || this.subj_array == ''){
            let user: {studentid:any,stname:any,ftname:any,mtname:any,dob:any,gender:any,email:any,mobile_no:any,alt_mobile_no:any,
              aadhar:any,caste:any,street:any,locality:any,city:any,state:any,taluka:any,district:any,landmark:any,pincode:any,
              house_ownership:any,internet_coverage:any,distance_coverage:any,access_to_smart_phone:any,access_internet:any,
              namemismatchflag:any,
              security_ques:any,security_ans:any,fathers_qualification:any,fathers_job:any,
              fathers_annual_income:any,mothers_qualification:any,mothers_job:any,mothers_annual_income:any,
              distance_of_school_to_house:any,mode_of_transport:any,boardname:any,otherboardname:any,
              eligibilitynumber:any,
              // photo:any,
              // signature:any,
             
              // application_status:any
              seat:any,
              exam:any
            }  = {
                  studentid:this.studentid,
                  stname:this.studentprofileform.controls.stname.value,
                  ftname:this.studentprofileform.controls.ftname.value,
                  mtname:this.studentprofileform.controls.mtname.value,
                  dob:this.converteddate,
                  gender:this.studentprofileform.controls.gender.value,
                  email:this.studentprofileform.controls.email.value,
                  mobile_no:this.studentprofileform.controls.mobile_no.value,
                  alt_mobile_no:this.studentprofileform.controls.alt_mobile_no.value,
                  aadhar:this.studentprofileform.controls.aadhar.value,
                  caste:this.studentprofileform.controls.caste.value,
                  street:this.studentprofileform.controls.street.value,
                  locality:this.studentprofileform.controls.locality.value,
                  city:this.studentprofileform.controls.city.value,
                  state:this.studentprofileform.controls.state.value,
                  taluka:this.studentprofileform.controls.taluka.value,
                  district:this.studentprofileform.controls.district.value,
                  landmark:this.studentprofileform.controls.landmark.value,
                  pincode:this.studentprofileform.controls.pincode.value,
                  house_ownership:this.studentprofileform.controls.house_ownership.value,
                  internet_coverage:this.studentprofileform.controls.internet_coverage.value,
                  distance_coverage:this.studentprofileform.controls.distance_coverage.value,
                  access_to_smart_phone:this.studentprofileform.controls.access_to_smart_phone.value,
                  access_internet:this.studentprofileform.controls.access_internet.value,
                  distance_of_school_to_house:this.studentprofileform.controls.distance_of_school_to_house.value,
                  mode_of_transport:this.studentprofileform.controls.mode_of_transport.value,
                  // password:this.studentprofileform.controls.password.value,
                  // c_password:this.studentprofileform.controls.c_password.value,
                  security_ques:this.studentprofileform.controls.security_ques.value,
                  security_ans:this.studentprofileform.controls.security_ans.value,
                  fathers_qualification:this.studentprofileform.controls.fathers_qualification.value,
                  fathers_job:this.studentprofileform.controls.fathers_job.value,
                  fathers_annual_income:this.studentprofileform.controls.fathers_annual_income.value,
                  mothers_qualification:this.studentprofileform.controls.mothers_qualification.value,
                  mothers_job:this.studentprofileform.controls.mothers_job.value,
                  mothers_annual_income:this.studentprofileform.controls.mothers_annual_income.value,
                  boardname:this.studentprofileform.controls.boardname.value,
                  otherboardname:this.studentprofileform.controls.otherboardname.value,
                  eligibilitynumber:this.studentprofileform.controls.eligibilitynumber.value,
                  // photo:this.studentprofileform.controls.photo.value,
                  // signature:this.studentprofileform.controls.signature.value,
                  namemismatchflag:"Yes",
                  // application_status:"Pending",
                  seat:this.studentprofileform.controls.seat.value,
                  exam:this.studentprofileform.controls.exam.value,
                  
             }
      console.log(user);
      //  alert("Information Saved");
      
      let boarddata :{seat:any,exam:any}={
      seat :this.studentprofileform.controls.seat.value,
      exam:this.studentprofileform.controls.exam.value
      }
      if(boarddata.seat == '')
      {
      let stud: {studentid:any,stname:any,ftname:any,mtname:any,dob:any,gender:any,email:any,mobile_no:any,alt_mobile_no:any,aadhar:any,
        caste:any,street:any,locality:any,city:any,state:any,taluka:any,district:any,landmark:any,pincode:any,
        house_ownership:any,internet_coverage:any,distance_coverage:any,access_to_smart_phone:any,access_internet:any,
        boardname:any,otherboardname:any,eligibilitynumber:any
        security_ques:any,security_ans:any,fathers_qualification:any,fathers_job:any,
        fathers_annual_income:any,mothers_qualification:any,mothers_job:any,mothers_annual_income:any,
        distance_of_school_to_house:any,mode_of_transport:any,
        namemismatchflag:any,
        application_status:any,
        seat:any,
        exam:any
        
      }
        
       = {
            studentid:this.studentid,
            stname:this.studentprofileform.controls.stname.value,
            ftname:this.studentprofileform.controls.ftname.value,
            mtname:this.studentprofileform.controls.mtname.value,
            dob:this.converteddate,
            gender:this.studentprofileform.controls.gender.value,
            email:this.studentprofileform.controls.email.value,
            mobile_no:this.studentprofileform.controls.mobile_no.value,
            alt_mobile_no:this.studentprofileform.controls.alt_mobile_no.value,
            aadhar:this.studentprofileform.controls.aadhar.value,
            caste:this.studentprofileform.controls.caste.value,
            street:this.studentprofileform.controls.street.value,
            locality:this.studentprofileform.controls.locality.value,
            city:this.studentprofileform.controls.city.value,
            state:this.studentprofileform.controls.state.value,
            taluka:this.studentprofileform.controls.taluka.value,
            district:this.studentprofileform.controls.district.value,
            landmark:this.studentprofileform.controls.landmark.value,
            pincode:this.studentprofileform.controls.pincode.value,
            house_ownership:this.studentprofileform.controls.house_ownership.value,
            internet_coverage:this.studentprofileform.controls.internet_coverage.value,
            distance_coverage:this.studentprofileform.controls.distance_coverage.value,
            access_to_smart_phone:this.studentprofileform.controls.access_to_smart_phone.value,
            access_internet:this.studentprofileform.controls.access_internet.value,
            distance_of_school_to_house:this.studentprofileform.controls.distance_of_school_to_house.value,
            mode_of_transport:this.studentprofileform.controls.mode_of_transport.value,
            // password:this.studentprofileform.controls.password.value,
            // c_password:this.studentprofileform.controls.c_password.value,
            security_ques:this.studentprofileform.controls.security_ques.value,
            security_ans:this.studentprofileform.controls.security_ans.value,
            fathers_qualification:this.studentprofileform.controls.fathers_qualification.value,
            fathers_job:this.studentprofileform.controls.fathers_job.value,
            fathers_annual_income:this.studentprofileform.controls.fathers_annual_income.value,
            mothers_qualification:this.studentprofileform.controls.mothers_qualification.value,
            mothers_job:this.studentprofileform.controls.mothers_job.value,
            mothers_annual_income:this.studentprofileform.controls.mothers_annual_income.value,
            boardname:this.studentprofileform.controls.boardname.value,
            otherboardname:this.studentprofileform.controls.otherboardname.value,
            eligibilitynumber:this.studentprofileform.controls.eligibilitynumber.value,
            namemismatchflag:"No",
            
            application_status:"Pending",
            // is_loggin:'1',
            seat:this.studentprofileform.controls.seat.value,
            exam:this.studentprofileform.controls.exam.value,
       }
                  // this.isdisable= true;
      
      let seq = this.service.post('/StudentApi11/submitstudentformSchool', stud);
      seq
         .subscribe(res => {
             console.log(res);
             alert("Information Updated Successfully");
       //window.location.reload();
         }, err => {
             alert("Error");
         });  
      
         let subject : {class:any,rollno:any, division:any, enrollmentid:any, schoolid:any, studentid:any,stream:any,course:any, subject_one:any,subject_two:any,subject_three:any,subject_four:any,
          subject_five:any,subject_six:any,subject_seven:any,subject_eight:any,
          subject_nine:any,subject_ten:any,subject_eleven:any} =
         {
              rollno:this.studentprofileform.controls.rollno.value, 
              class:this.studentprofileform.controls.class.value, 
              division:this.studentprofileform.controls.division.value,
              enrollmentid:sessionStorage.getItem('enrollmentid'),
              schoolid:sessionStorage.getItem('schoolid'),
              studentid:this.studentid,
              stream:this.studentprofileform.controls.stream.value,
              course:this.studentprofileform.controls.course.value,
              subject_one:this.studentprofileform.controls.subject_one.value,
              subject_two:this.studentprofileform.controls.subject_two.value,
              subject_three:this.studentprofileform.controls.subject_three.value,
              subject_four:this.studentprofileform.controls.subject_four.value,
              subject_five:this.studentprofileform.controls.subject_five.value,
              subject_six:this.studentprofileform.controls.subject_six.value,
              subject_seven:this.studentprofileform.controls.subject_seven.value,
              subject_eight:this.studentprofileform.controls.subject_eight.value,
              subject_nine:this.studentprofileform.controls.subject_nine.value,
              subject_ten:this.studentprofileform.controls.subject_ten.value,
              subject_eleven:this.studentprofileform.controls.subject_eleven.value,
              
         }
          // alert(subject);
       let seql = this.service.post('/StudentApi11/editsavesubject', subject);
       seql
           .subscribe((res:any) => {
               console.log(res);
               if(res['status']=='false'){
                alert(res['Data']);
               }
               else{
                this.validated_subjects=true;
               }
               
              //  this.submitted=true;
           }, err => {
               alert("Error");
           }); 
      }
      else
      {
      let boardseq = this.service.post('/StudentApi11/searchsscrecord', boarddata);
      boardseq.subscribe((res:any)=>{
      this.boardinfo = res['Data'];
      console.log(this.boardinfo);
      this.Stname = this.boardinfo[0].Stname;
      console.log(user.stname);
      if(this.Stname !== user.stname)
      { 
        if(confirm('Enter Name and on certificate name are not matched.Do You Want to Continue Submit Information'))
        {
          // alert('ok');
      
          
          this.isdisable= true;
          
          let seq = this.service.post('/StudentApi11/submitstudentformSchool', user);
         seq.subscribe(res => {
                 console.log(res);
                 alert("Information Updated Successfully");
       //window.location.reload();
             }, err => {
                 alert("Error");
             });  
      
          
        }
        else
        {
          // alert('not ok');
          let stud: {studentid:any,stname:any,ftname:any,mtname:any,dob:any,gender:any,email:any,mobile_no:any,alt_mobile_no:any,aadhar:any,
            street:any,locality:any,city:any,state:any,taluka:any,district:any,landmark:any,pincode:any,
            house_ownership:any,internet_coverage:any,distance_coverage:any,access_to_smart_phone:any,access_internet:any,
            boardname:any,otherboardname:any,eligibilitynumber:any
            security_ques:any,security_ans:any,fathers_qualification:any,fathers_job:any,
            fathers_annual_income:any,mothers_qualification:any,mothers_job:any,mothers_annual_income:any,
            distance_of_school_to_house:any,mode_of_transport:any,
            namemismatchflag:any,
            // application_status:any
            seat:any,
            exam:any
          }
            
           = {
                studentid:this.studentid,
                stname:this.studentprofileform.controls.stname.value,
                ftname:this.studentprofileform.controls.ftname.value,
                mtname:this.studentprofileform.controls.mtname.value,
                dob:this.converteddate,
                gender:this.studentprofileform.controls.gender.value,
                email:this.studentprofileform.controls.email.value,
                mobile_no:this.studentprofileform.controls.mobile_no.value,
                alt_mobile_no:this.studentprofileform.controls.alt_mobile_no.value,
                aadhar:this.studentprofileform.controls.aadhar.value,
                street:this.studentprofileform.controls.street.value,
                locality:this.studentprofileform.controls.locality.value,
                city:this.studentprofileform.controls.city.value,
                state:this.studentprofileform.controls.state.value,
                taluka:this.studentprofileform.controls.taluka.value,
                district:this.studentprofileform.controls.district.value,
                landmark:this.studentprofileform.controls.landmark.value,
                pincode:this.studentprofileform.controls.pincode.value,
                house_ownership:this.studentprofileform.controls.house_ownership.value,
                internet_coverage:this.studentprofileform.controls.internet_coverage.value,
                distance_coverage:this.studentprofileform.controls.distance_coverage.value,
                access_to_smart_phone:this.studentprofileform.controls.access_to_smart_phone.value,
                access_internet:this.studentprofileform.controls.access_internet.value,
                distance_of_school_to_house:this.studentprofileform.controls.distance_of_school_to_house.value,
                mode_of_transport:this.studentprofileform.controls.mode_of_transport.value,
                // password:this.studentprofileform.controls.password.value,
                // c_password:this.studentprofileform.controls.c_password.value,
                security_ques:this.studentprofileform.controls.security_ques.value,
                security_ans:this.studentprofileform.controls.security_ans.value,
                fathers_qualification:this.studentprofileform.controls.fathers_qualification.value,
                fathers_job:this.studentprofileform.controls.fathers_job.value,
                fathers_annual_income:this.studentprofileform.controls.fathers_annual_income.value,
                mothers_qualification:this.studentprofileform.controls.mothers_qualification.value,
                mothers_job:this.studentprofileform.controls.mothers_job.value,
                mothers_annual_income:this.studentprofileform.controls.mothers_annual_income.value,
                boardname:this.studentprofileform.controls.boardname.value,
                otherboardname:this.studentprofileform.controls.otherboardname.value,
                eligibilitynumber:this.studentprofileform.controls.eligibilitynumber.value,
                namemismatchflag:"No",
                
                // application_status:"Pending",
                // is_loggin:'1',
                seat:this.studentprofileform.controls.seat.value,
                exam:this.studentprofileform.controls.exam.value,
           }
                      // this.isdisable= true;
          
         let seq = this.service.post('/StudentApi11/submitstudentformSchool', stud);
         seq
             .subscribe(res => {
                 console.log(res);
                 alert("Information Updated Successfully");
       //window.location.reload();
             }, err => {
                 alert("Error");
             });  
             let subject : {class:any,rollno:any, division:any, enrollmentid:any, schoolid:any, studentid:any,stream:any,course:any, subject_one:any,subject_two:any,subject_three:any,subject_four:any,
              subject_five:any,subject_six:any,subject_seven:any,subject_eight:any,
              subject_nine:any,subject_ten:any,subject_eleven:any} =
             {
                  rollno:this.studentprofileform.controls.rollno.value, 
                  class:this.studentprofileform.controls.class.value, 
                  division:this.studentprofileform.controls.division.value,
                  enrollmentid:sessionStorage.getItem('enrollmentid'),
                  schoolid:sessionStorage.getItem('schoolid'),
                  studentid:this.studentid,
                  stream:this.studentprofileform.controls.stream.value,
                  course:this.studentprofileform.controls.course.value,
                  subject_one:this.studentprofileform.controls.subject_one.value,
                  subject_two:this.studentprofileform.controls.subject_two.value,
                  subject_three:this.studentprofileform.controls.subject_three.value,
                  subject_four:this.studentprofileform.controls.subject_four.value,
                  subject_five:this.studentprofileform.controls.subject_five.value,
                  subject_six:this.studentprofileform.controls.subject_six.value,
                  subject_seven:this.studentprofileform.controls.subject_seven.value,
                  subject_eight:this.studentprofileform.controls.subject_eight.value,
                  subject_nine:this.studentprofileform.controls.subject_nine.value,
                  subject_ten:this.studentprofileform.controls.subject_ten.value,
                  subject_eleven:this.studentprofileform.controls.subject_eleven.value,
                  
             }
              // alert(subject);
           let seql = this.service.post('/StudentApi11/editsavesubject', subject);
           seql
               .subscribe((res:any) => {
                   console.log(res);
                   if(res['status'] == 'false'){
                    alert(res['Data']);
                   }
                   else
                   {
                    this.validated_subjects=true;
                   }
                   
                  //  this.submitted=true;
               }, err => {
                   alert("Error");
               }); 
      
      
        }
      
       
      }
      
      
      });
      
      // this.isdisable= true;
      //  let seq = this.service.post('/StudentApi11/submitstudentform', user);
      //  seq
      //      .subscribe(res => {
      //          console.log(res);
      //          alert("Form Submited");
      //          this.router.navigate(['/candidateprofile']);
      // }, err       => {
      //          alert("Error");
      //      });  
      
               //  upload documents with marksheet
      const formData = new FormData();
      
      formData.append('photo', this.studentprofileform.controls.photo.value);
      formData.append('id', this.studentid);
      this.photo_upload_success=true;
      this.service.upload11thstudentFile(formData).subscribe(
        (res:any) => {
         this.uploadResponse = res;
            console.log(res);
            // this.getstudentform();
        },
        (err: any) => {  
          console.log(err);
        }
      );
      
      formData.append('signature', this.studentprofileform.controls.signature.value);
      formData.append('id', this.studentid);
      this.signature_upload_success=true;
      this.service.upload11thstudentFile(formData).subscribe(
        (res:any) => {
         // this.uploadResponse = res;
            console.log(res);
            // this.getstudentform();
        },
        (err: any) => {  
          console.log(err);
        }
      );
      
      
       formData.append('marksheet', this.studentprofileform.controls.marksheet.value);
       formData.append('id', this.studentid);
       this.signature_upload_success=true;
       this.service.upload11thstudentFile(formData).subscribe(
         (res:any) => {
          // this.uploadResponse = res;
             console.log(res);
             // this.getstudentform();
         },
         (err: any) => {  
           console.log(err);
         }
       );
      }
      
          }
      
          else{
           console.log(this.subj_array);
            alert(this.subj_array+ " has been selected multiple times from provided options. Please correct the selection and click Save")
            this.validated_subject=false;
          }
        }
 
  //  for subject
       
          
  }
  else
  {
    alert('Please Check Captcha');
  }
}
else{
  alert("Please enter valid Date, \n For 11th registration, age should be more than 14");
  // this.studentprofileform.controls.dob.reset;  
}
}
  
 // get all candidate details
 getstudentform()
{
  
   let user:{studentid:any}={
     studentid:this.studentid
   }
   this.service.post('/StudentApi11/getstudentForm',user).subscribe((res:any) => {
        if(res.status == "success"){   
          this.userData = res['Data'];
          this.subjectmap= res['subjectmap'];
          //  console.log(this.userData[0].security_ques);
          
            this.studentprofileform.controls['stname'].setValue(this.userData[0].stname);
            this.studentprofileform.controls['ftname'].setValue(this.userData[0].ftname);
            this.studentprofileform.controls['mtname'].setValue(this.userData[0].mtname);

            //changeDOBformat
            this.userData[0].dob = this.userData[0].dob.split('-');
            if(this.userData[0].dob[0] < 10 && this.userData[0].dob[0][0] != 0){
              this.userData[0].dob[0] = '0'+this.userData[0].dob[0];
            }
            if(this.userData[0].dob[1] < 10 && this.userData[0].dob[1][0] != 0){
              this.userData[0].dob[1] = '0'+this.userData[0].dob[1];
            }
            this.userData[0].dob = this.userData[0].dob[2]+'-'+this.userData[0].dob[1]+'-'+this.userData[0].dob[0];
            // console.log( this.userData[0].dob);
            this.studentprofileform.controls['dob'].setValue(this.userData[0].dob);
            //changeDOBformat
            
            //  console.log(this.studentprofileform.controls['dob'].setValue(this.userData[0].dob));
            this.studentprofileform.controls['gender'].setValue(this.userData[0].gender);
            this.studentprofileform.controls['email'].setValue(this.userData[0].email);
            this.studentprofileform.controls['mobile_no'].setValue(this.userData[0].mobile_no);
            this.studentprofileform.controls['alt_mobile_no'].setValue(this.userData[0].alt_mobile_no);
            this.studentprofileform.controls['aadhar'].setValue(this.userData[0].aadhar);
            this.studentprofileform.controls['caste'].setValue(this.userData[0].caste);
            this.studentprofileform.controls['street'].setValue(this.userData[0].street);
            this.studentprofileform.controls['locality'].setValue(this.userData[0].locality);
            this.studentprofileform.controls['city'].setValue(this.userData[0].city);
            this.studentprofileform.controls['state'].setValue(this.userData[0].state);
            this.studentprofileform.controls['taluka'].setValue(this.userData[0].taluka);
            this.studentprofileform.controls['district'].setValue(this.userData[0].district);
            this.studentprofileform.controls['landmark'].setValue(this.userData[0].landmark);
            this.studentprofileform.controls['pincode'].setValue(this.userData[0].pincode);
            this.studentprofileform.controls['house_ownership'].setValue(this.userData[0].house_ownership);
            this.studentprofileform.controls['internet_coverage'].setValue(this.userData[0].internet_coverage);
            this.studentprofileform.controls['distance_coverage'].setValue(this.userData[0].distance_coverage);
            this.studentprofileform.controls['access_to_smart_phone'].setValue(this.userData[0].access_to_smart_phone);
            this.studentprofileform.controls['access_internet'].setValue(this.userData[0].access_internet);
            this.studentprofileform.controls['distance_of_school_to_house'].setValue(this.userData[0].distance_of_school_to_house);
            this.studentprofileform.controls['mode_of_transport'].setValue(this.userData[0].mode_of_transport);
            this.studentprofileform.controls['security_ques'].setValue(this.userData[0].security_ques);
            this.studentprofileform.controls['security_ans'].setValue(this.userData[0].security_ans);
            this.studentprofileform.controls['fathers_qualification'].setValue(this.userData[0].fathers_qualification);
            this.studentprofileform.controls['fathers_job'].setValue(this.userData[0].fathers_job);
            this.studentprofileform.controls['fathers_annual_income'].setValue(this.userData[0].fathers_annual_income);
            this.studentprofileform.controls['mothers_qualification'].setValue(this.userData[0].mothers_qualification);
            this.studentprofileform.controls['mothers_job'].setValue(this.userData[0].mothers_job);
            this.studentprofileform.controls['mothers_annual_income'].setValue(this.userData[0].mothers_annual_income);
            this.studentprofileform.controls['boardname'].setValue(this.userData[0].boardname);
            this.studentprofileform.controls['otherboardname'].setValue(this.userData[0].otherboardname);
            this.studentprofileform.controls['eligibilitynumber'].setValue(this.userData[0].eligibilitynumber);
            this.studentprofileform.controls['seat'].setValue(this.userData[0].seat);
            this.studentprofileform.controls['exam'].setValue(this.userData[0].exam);
            this.signinfo=this.userData[0].signature;
            this.photoinfo = this.userData[0].photo;
            this.marksinfo=this.userData[0].marksheet;
            if(this.marksinfo!=undefined || this.marksinfo!=""){   
              console.log(this.marksinfo );
              this.studentprofileform.controls['marksheet'].clearValidators();
              this.studentprofileform.controls['marksheet'].updateValueAndValidity();
                  }
            this.studentprofileform.controls['photo'].setValue(this.photoinfo); 
            this.studentprofileform.controls['signature'].setValue(this.signinfo);
            this.studentprofileform.controls['marksheet'].setValue(this.marksinfo);
            
           
        }
        else{
            alert("Please reload page!");
        }  
        

      });
      
 }
    // get all selected subject from student
  
   

   getstudentclassForm()
   {
     let user:{studentid:any}={
       studentid:this.studentid
     }
     let seq = this.service.post('/StudentApi11/getstudentclassForm',user);
      seq.subscribe((res:any) => {
        if(res.status=="success"){
          this.studschool= res['studschool'];
          if(sessionStorage.getItem('schoolid')==this.studschool[0].schoolid){    
              this.studentprofileform.controls['rollno'].setValue(this.studschool[0].rollno);
             this.studentprofileform.controls['division'].setValue(this.studschool[0].division);
             if(this.studschool[0].stream == 'Vocational' || this.studschool[0].stream == 'VOCATIONAL' || this.studschool[0].stream == 'vocational'){
              this.studentprofileform.controls['stream'].setValue('Vocational');
             }
             else
             if(this.studschool[0].stream == 'Science' || this.studschool[0].stream == 'SCIENCE' || this.studschool[0].stream == 'science'){
              this.studentprofileform.controls['stream'].setValue('Science');
             }
             else
             if(this.studschool[0].stream == 'Commerce' || this.studschool[0].stream == 'COMMERCE' || this.studschool[0].stream == 'commerce'){
              this.studentprofileform.controls['stream'].setValue('Commerce');
             }
             else
             if(this.studschool[0].stream == 'Arts' || this.studschool[0].stream == 'ARTS' || this.studschool[0].stream == 'arts'){
              this.studentprofileform.controls['stream'].setValue('Arts');
             }
             this.checkstream =  this.studentprofileform.controls['stream'].value;
            console.log(this.checkstream);
             this.studentprofileform.controls['course'].setValue(this.studschool[0].course);
            this.coursecode = this.studschool[0].course;

           console.log(this.coursecode);
           
          
              
                  // this service for coursename
                  this.service.post('/StudentApi11/getCoursebycode', {coursecode:this.coursecode}).subscribe((res:any)=>{
                    this.coursenewname = res['Data'];
                    console.log(this.coursenewname)
                    this.othercourse = this.coursenewname[0].subject_name;
                    console.log(this.othercourse);
                    this.studentprofileform.controls['coursename'].setValue(this.coursenewname[0].subject_name);
                  });
        
                 
                  this.service.post('/StudentApi11/getSubjectByCoursecode',{coursecode:this.coursecode}).subscribe((res:any) =>{
                    this.coursesubj = res['Data'];
                    console.log(this.coursesubj)
                    this.subjcode = res['status'];
                    console.log(this.subjcode);
                    // if(this.coursecode == '0')
                    // {
                    //   this.getsubjects();
                    //   this.getselectedsubject();
                    // }
                    // else{
                     this.course1 = this.coursesubj[0].subjectname;
                     this.course2 = this.coursesubj[1].subjectname;
                     this.course3 = this.coursesubj[2].subjectname;
                     this.course4 = this.coursesubj[3].subjectname;
                     this.course5 = this.coursesubj[4].subjectname;
                     this.course6 = this.coursesubj[5].subjectname;
                    // }
                   //  console.log(this.subjcode);
                  })
          }
          else{
            alert("Sorry!! You Can only View/Edit records of Your School!");
            this.router.navigate(['/candidates-details']);
          }     
      }    
      else{
        alert("The system detected an incorrect operation!, \n EitherSpecified Student Is Not Registred With This School \n or Student Data is not available");
        this.router.navigate(['/candidates-details']);
      }
      });
   }
 
   getselectedsubject()
   {
     let user:{studentid:any}={
       studentid:this.studentid
     }
       let seq = this.service.post('/StudentApi11/getstudentselectedsubject',user);
       seq.subscribe((res:any)=>{
         this.subjectmap = res['subjectmap'];
         this.selectsubj1 = this.subjectmap[0].Subject;
         console.log(this.subjectmap);
             this.studentprofileform.controls['subject_one'].setValue(this.subjectmap[0].Subject);
              this.studentprofileform.controls['subject_two'].setValue(this.subjectmap[1].Subject);
              this.studentprofileform.controls['subject_three'].setValue(this.subjectmap[2].Subject);
              this.studentprofileform.controls['subject_four'].setValue(this.subjectmap[3].Subject);
              this.studentprofileform.controls['subject_five'].setValue(this.subjectmap[4].Subject);
              this.studentprofileform.controls['subject_six'].setValue(this.subjectmap[5].Subject);
              this.studentprofileform.controls['subject_seven'].setValue(this.subjectmap[6].Subject);
              this.studentprofileform.controls['subject_eight'].setValue(this.subjectmap[7].Subject);
              this.studentprofileform.controls['subject_nine'].setValue(this.subjectmap[8].Subject);
              this.studentprofileform.controls['subject_ten'].setValue(this.subjectmap[9].Subject);
              this.studentprofileform.controls['subject_eleven'].setValue(this.subjectmap[10].Subject);
       })
   }
    // get subjects in dropdown
  
    getsubjects(){
      let user:{}={
        
      }
      let seq = this.service.post('/StudentApi11/getsubjectlist',user);
      seq.subscribe((res:any) => {
        this.subject = res['Data'];
        this.subject1 =res['Data1'];
        this.subject2 =res['Data2'];
        this.subject3 =res['Data3'];
        this.subject4 =res['Data4'];
        this.subject5 =res['Data5'];
        this.subject6 =res['Data6'];
        this.subject7 =res['Data7'];
        this.subject8 =res['Data8'];
        this.subject9 =res['Data9'];
        this.subject10 =res['Data10'];
        this.subject11 =res['Data11'];
        this.subject12 =res['Data12'];
        // console.log(this.subject);
  
   });
    }

    // get course by course code
    
    
getcourses()
{
  let corse:{}={

  }
  let seq = this.service.post('/StudentApi11/getallcourses',corse);
  seq.subscribe((res:any) => {
    this.coursesall = res.Data;
    

});
}
changedate(){ 

  if(this.studentprofileform.controls['dob'].value!=""){
    // this.studentprofileform.controls['dob'] = this.userData[0].dob.split("-").reverse().join("-");
    this.converteddate = this.studentprofileform.controls['dob'].value.split("-").reverse().join("-");
    // this.converteddate = this.userData[0].dob.split("-").reverse().join("-");
    // this.studentprofileform.controls.dob.setValue(this.converteddate);
  }
  console.log(this.studentprofileform.controls['dob']);
}
isDOBvalid() {
  // alert(dateString);
  var today = new Date();
  var birthDate = new Date(this.studentprofileform.controls.dob.value);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  // console.log(today);
  // console.log(birthDate);
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
  {
      age--;
  }
      if(age>=14){
        return true;
      }
      else {
        return false;
      }
  }
  getalltalukas(){
    let seq = this.service.post('/BoardApi/getTaluka',{});
    seq.subscribe((res:any)=>{
        if(res.status== "Sucess"){
            this.alltalukas=res.Data;
        }
        else{
          alert("An error occurred while fetching data, Reloading page!!");
          //window.location.reload();
        }
    })
}

getdistrict(taluka:any){
    this.alltalukas.forEach((elem: { taluka_name: any, taluka_district:any }) => {
        if(elem.taluka_name==taluka.value){
          // alert(elem.taluka_district);
          this.studentprofileform.controls['district'].setValue(elem.taluka_district);
        }
    });
}
 
}
