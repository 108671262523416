<div class="container mt-3">
    <div class="card mb-3">
        <h5 class="card-header">Update Student status </h5>
        <!-- <div class="alert alert-info m-5" role="alert">
            will be Uploaded soon!!
          </div> -->
        <form role="form" [formGroup]="addprimaryform" class="mt-5">

            <div class="row">
                <div class="col-md-4 text-right">
                    <label for="staticEmail" class="col-form-label">Enter Username: </label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="form-control" formControlName="username"
                        [ngClass]="{ 'is-invalid': issubmitted && addpersonal1['username'].errors }" (click)="clearform()">
                    <div *ngIf="issubmitted && addpersonal1.username.errors" class="invalid-feedback">
                        <div *ngIf="addpersonal1.username.errors.required">
                            Please Enter Username.
                        </div>
                    </div>
                </div>

            </div>
            <div class="row" style="margin: 1rem;">
                <div class="col-md-12 text-center">
                    <button class="btn btn-primary btn-sm" (click)="Onsearchcandidate()"> Search </button>
                </div>
            </div>
        </form>
    </div>
    <!-- detail information form starts from here -->
    <div *ngIf="showdiv">
        <form role="form" [formGroup]="studentprofileform" style="margin-bottom: 4rem;">
            <div class="card mb-3">
                <figcaption class="blockquote" style="color: #0062cc; margin-left: 2rem; margin-top: 1rem;">
                    <cite title="Source Title"><b>- Personal Information</b></cite>
                </figcaption>
                <div class="form-group  row mb-3">

                    <label for="staticname" class="col-sm-2 col-form-label">Candidate
                        Name</label>
                    <div class="col-sm-8">
                        <input type="text" formControlName="stname" class="form-control" id="staticName" readonly>

                    </div>
                </div>
                <div class="form-group row mb-3">
                    <label for="staticfathername" class="col-sm-2 col-form-label">Father's
                        Name</label>
                    <div class="col-sm-8">
                        <input type="text" formControlName="ftname" class="form-control" id="inputfathername" readonly>

                    </div>
                </div>
                <div class="form-group row mb-3">
                    <label for="staticmothername" class="col-sm-2 col-form-label">Mother's
                        Name</label>
                    <div class="col-sm-8">
                        <input type="text" formControlName="mtname" class="form-control" id="inputmothername" readonly>

                    </div>
                </div>
                <div class="form-group row mb-3">
                    <label for="staticdob" class="col-sm-2 col-form-label">Date Of
                        Birth</label>
                    <div class="col-sm-3">
                        <input type="text" formControlName="dob" class="form-control" id="inputdob" readonly>

                    </div>
                    <label for="staticgender" class="col-sm-2 col-form-label" style="margin-left: 0rem;">Gender</label>
                    <div class="col-sm-3">
                        <input type="text" formControlName="gender" class="form-control" id="inputgender" readonly>
                    </div>
                </div>
                <div class="form-group row mb-3">
                    <label for="staticcaste" class="col-sm-2 col-form-label">Caste</label>
                    <div class="col-sm-3">
                        <input type="text" formControlName="caste" class="form-control" id="inputcaste" readonly>
                    </div>
                    <label for="staticEmail" class="col-sm-2 col-form-label" style="margin-left: 0rem;">Mobile
                        Number</label>
                    <div class="col-sm-3">
                        <input type="email" formControlName="mobile_no" class="form-control" id="staticEmail" readonly>

                    </div>
                </div>
                <div class="form-group row mb-3">
                    <label for="staticEmail" class="col-sm-2 col-form-label">Email </label>
                    <div class="col-sm-8">
                        <input type="text" formControlName="email" class="form-control" id="staticEmail" readonly>
                    </div>
                </div>
                <div class="form-group row mb-3">
                    <label for="staticEmail" class="col-sm-2 col-form-label">School </label>
                    <div class="col-sm-8">
                        <input type="text" formControlName="scode" class="form-control" id="staticEmail" readonly>
                    </div>
                </div>
                <div class="form-group row mb-3">
                    <label for="staticEmail" class="col-sm-2 col-form-label">Current Student Status </label>
                    <div class="col-sm-8">
                        <input type="text" formControlName="stud_status" class="form-control" id="staticEmail" readonly>
                    </div>
                </div>
                <div class="form-group row mb-3">
                    <label for="staticEmail" class="col-sm-2 col-form-label">Update Student Status </label>
                    <div class="col-sm-8">
                        <select id="inputU_stud_status" formControlName="U_stud_status" class="form-select"
                            [ngClass]="{ 'is-invalid': issubmitted1 && personal.U_stud_status.errors }">
                            <option selected value="">Select</option>
                            <option value="Promoted_Left">Promoted And Left</option>
                            <option value="Detained_Left">Detained And Left</option>
                            <option value="Detained">Detained</option>
                        </select>
                        <div *ngIf="issubmitted1 && personal.U_stud_status.errors" class="invalid-feedback">
                            <div *ngIf="personal.U_stud_status.errors.required">
                                Student Status Is Required.
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12 text-center">
                    <button class="btnSubmit1 btn btn-primary btn-sm" (click)="Onsavestatus()"> Save </button> &nbsp;
                </div>
            </div>
        </form>
    </div>
</div>