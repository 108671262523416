import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { NavbarService } from '../default/navbar/navbar.service';
import { SchoolserviceService } from '../schoolservice.service';

@Component({
  selector: 'app-candidatelanding',
  templateUrl: './candidatelanding.component.html',
  styleUrls: ['./candidatelanding.component.scss']
})
export class CandidatelandingComponent implements OnInit {
  last_date_9thstudent_regi: any;
  last_date_11thstudent_regi: any;
  schoolid: any;
  std: any;
  studentid: any;
  enrolldata: any;
  submission_status: any;
  standard: any;
  studschool: any;
  lastdatestud_9th: any;
  lastdatestud_11th: any;

  constructor(public formbuilder:FormBuilder,public router:Router,public datepipe: DatePipe, public service:SchoolserviceService,public nav:NavbarService) {
  }

  ngOnInit(): void {
    this.router.onSameUrlNavigation = 'reload'; 
    this.schoolid = sessionStorage.getItem('schoolid');
    this.studentid = sessionStorage.getItem('studentid');
    // this.submission_status = sessionStorage.getItem('submission_status');
    this.standard = sessionStorage.getItem('standard');
    
    // this.status = sessionStorage.getItem("submission_status");
    // console.log(this.status);
    
    if(this.standard == '9th')
    {
          // console.log(this.standard);
    this.nav.hide();
    this.nav.dhide();//studentdash
    this.nav.adhide();//boardadmindash
    this.nav.asdhide(); //schooladmin
    this.nav.studshow();
    this.nav.XIstudhide();
    this.nav.aduserhide();
    }
    else{
          // console.log(this.standard);
    this.nav.hide();
    this.nav.dhide();//studentdash
    this.nav.adhide();//boardadmindash
    this.nav.asdhide(); //schooladmin
    this.nav.studhide();
    this.nav.XIstudshow();
    this.nav.aduserhide();
    }

    let user:{studentid:any}={
      studentid:this.studentid
    }
    let seql = this.service.post('/StudentApi11/getstudentclassForm',user);
     seql.subscribe((res:any) => {
            
            this.studschool= res['studschool'];
            
            this.submission_status =  this.studschool[0].submission_status;
           
     });

    let enroll :{studentid:any}={
      studentid:this.studentid
    }
    let seq =this.service.post('/StudentApi9/enrollmenetid',enroll);
    seq.subscribe((res:any) =>{
      this.enrolldata = res['Data'];
      this.std = this.enrolldata;
     console.log(this.std);

     if(this.std == '9th')
    {
      this.nav.studshow(); 
      this.nav.XIstudhide();
    }
    else
    {
      this.nav.studhide(); 
      this.nav.XIstudshow();
    }

  
    });
    let schoolinfo :{schoolid:any}={
      schoolid:this.schoolid
    }
    this.service.post('/School/viewlastdateforCandidates',schoolinfo).subscribe((res: any)=>{

      //last dates for 11th candidates
       this.last_date_11thstudent_regi = res['Data'][1].lastdate
       this.lastdatestud_11th = this.datepipe.transform(this.last_date_11thstudent_regi, 'dd/MM/yyyy');
      //last dates for 9th students
      this.last_date_9thstudent_regi = res['Data'][0].lastdate
      this.lastdatestud_9th = this.datepipe.transform(this.last_date_9thstudent_regi, 'dd/MM/yyyy');
    
      
      })

  }
}
