<div class="container mt-3">
    <div class="card mb-3">
        <h5 class="card-header">Unregistered Schools/Candidates</h5>
        <div class="row p-5">
            <div class="col-md-4 text-right">
                <label for="staticEmail" class="col-form-label fw-bolder">Select Action: </label>
            </div>
            <div class="col-md-4">
                <select class="form-control" (change)="checkactiontaken($event.target)">
                    <option value="" selected disabled>Choose </option>
                    <option value="single">Upload Unregistered School Data </option>
                    <!-- <option value="bulk">Bulk Upload For Un-enrolled students</option> -->
                </select>
            </div>
        </div>
        <!-- actionselected -->
        <div *ngIf="actionselectedsingle" class="row my-3">

            <div class="col-md-12 text-center">
                <!-- <button class="btn btn-primary btn-sm" data-toggle="modal" data-target="#exampleModalCenter"> Add Single
                    Candidate </button> &nbsp; -->
                    <div class="col-md-12 text-right">

                    </div>
        
                    <div class="card-body text-dark">
                        <!-- <div class="col-sm-12 clsAddCandidate">
                      <button type="button" class="btn btn-primary btn-sm" data-toggle="modal" data-target="#exampleModalCenter">Add Candidate's</button>
                    </div> -->
                        <div class="row text-center my-3">
                            <!-- <div class="col-sm-8">
                          
                      </div> -->
                            <div class="col-sm-6 text-right">
                                Unregistered school Excel Download :
                            </div>
                            <div class="col-sm-1">
                                <a class="btn btn-primary btn-sm" href="./assets/img/importexcel_unregi.csv"
                                    download="importexcel_unregi.csv">  For 10th </a>
                            </div>
                            <div class="col-sm-1">
                                <a class="btn btn-primary btn-sm" href="./assets/img/importexcel11th_unregi.csv"
                                    download="importexcel11th_unregi.csv"> For 12th </a>
                            </div>
        
        
                        </div>
                        <form role="form" [formGroup]="selctform" class="mt-5">
                            <!-- <div class="row"></div> -->
                            <div class="row">
                                <div class="col-md-2"></div>
                                <div class="form-group col-md-6">
                                    
                                    <!-- <label for="no_of_students" class="cls-no-of-student">Enter Number Of Students</label>
                                        <input type="text" class="form-control cls-no-of-student" formControlName="stud_numbers"  id="no_of_students" aria-describedby="emailHelp" placeholder="Enter no. of student">
                                    </div> -->
                                    <!-- <label for="no_of_students" class="cls-no-of-student">Upload File</label> -->
                                    <input type="file" class="form-control cls-no-of-student" formControlName="file" id="file"
                                    accept=".csv"
                                    (change)="handleFileInput($event)" placeholder="Upload File"
                                    [ngClass]="{ 'is-invalid': filesubmitted && personal.file.errors }">
                                    <div *ngIf="filesubmitted && personal.file.errors" class="invalid-feedback">
                                        <div *ngIf="personal.file.errors.required">
                                            Please Select CSV File.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group col-md-6">
                                        <input type="submit" class="btn btn-primary btn-sm" value="Submit" (click)="upload()">
                                     </div>
                                </div>
                            </div>
                            <!-- <div class="row">
                                <div class="col-md-2"></div>
                                <div class="form-group col-md-6">
                                   <input type="submit" class="btn btn-primary btn-sm" value="Submit" (click)="upload()">
                                </div>
                            </div> -->
                        </form>
                        <br>
                        <div class="row">
                            <div class="col-md-2"></div>
                            <div class="col-md-6">
                                <span><b>Note :</b> User password is defaulted to <b>123456</b>.</span>
                            </div>
                            <div class="col-sm-4" style="text-align: right;">
                                <!-- <button type="button" class="btn btn-primary btn-sm" (click)="Ondownload()" >Download Excel Template</button> -->
                                <!-- <a [href]="fileUrl" download="assets/img/importexcel.xlsx">DownloadFile</a> -->
                                <!-- <a class="btn btn-primary btn-sm" href="/assets/img/importexcel.xlsx" download="importexcel.xlsx"> Download Excel Template </a> -->
                            </div>
                        </div>
        
                    </div>   
            </div>
        </div>
<!-- un-enrolled student -->
        <div *ngIf="actionselectedbulk" class="row px-5">
        <!-- <div class="row px-5"> -->
            <div class="col-md-12 text-right">

            </div>

            <div class="card-body text-dark">
                <!-- <div class="col-sm-12 clsAddCandidate">
              <button type="button" class="btn btn-primary btn-sm" data-toggle="modal" data-target="#exampleModalCenter">Add Candidate's</button>
            </div> -->
                <div class="row text-center my-3">
                    <!-- <div class="col-sm-8">
                  
              </div> -->
                    <div class="col-sm-6 text-right">
                        Un-enrolled student Excel Download :
                    </div>
                    <div class="col-sm-1">
                        <a class="btn btn-primary btn-sm" href="./assets/img/importexcel_unregi.csv"
                            download="importexcel_unregi.csv"> For 10th </a>
                    </div>
                    <div class="col-sm-1">
                        <a class="btn btn-primary btn-sm" href="./assets/img/importexcel11th_unregi.csv"
                            download="importexcel11th_unregi.csv"> For 12th </a>
                    </div>


                </div>
                <form role="form" [formGroup]="selctform1" class="mt-5">
                    <!-- <div class="row"></div> -->
                    <div class="row">
                        <div class="col-md-2"></div>
                        <div class="form-group col-md-6">

                            <!-- <label for="no_of_students" class="cls-no-of-student">Enter Number Of Students</label>
                    <input type="text" class="form-control cls-no-of-student" formControlName="stud_numbers"  id="no_of_students" aria-describedby="emailHelp" placeholder="Enter no. of student">
                  </div> -->
                            <!-- <label for="no_of_students" class="cls-no-of-student">Upload File</label> -->
                            <input type="file" class="form-control cls-no-of-student" formControlName="file1" id="file1"
                                accept=".csv"
                                (change)="handleFileInput1($event)" placeholder="Upload File"
                                [ngClass]="{ 'is-invalid': filesubmitted1 && personal1.file.errors }">
                            <div *ngIf="filesubmitted1 && personal1.file.errors" class="invalid-feedback">
                                <div *ngIf="personal1.file.errors.required">
                                    Please Select CSV File.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group col-md-6">
                                <input type="submit" class="btn btn-primary btn-sm" value="Submit" (click)="upload_bulk()">
                            </div>
                        </div>

                    </div>
                    <!-- <div class="row">
                        <div class="col-md-2"></div>
                        <div class="form-group col-md-6">
                            <input type="submit" class="btn btn-primary btn-sm" value="Submit" (click)="upload_bulk()">
                        </div>
                    </div> -->
                </form>
                <br>
                <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-6">
                        <span><b>Note :</b> User password is defaulted to <b>123456</b>.</span>
                    </div>
                    <div class="col-sm-4" style="text-align: right;">
                        <!-- <button type="button" class="btn btn-primary btn-sm" (click)="Ondownload()" >Download Excel Template</button> -->
                        <!-- <a [href]="fileUrl" download="assets/img/importexcel.xlsx">DownloadFile</a> -->
                        <!-- <a class="btn btn-primary btn-sm" href="/assets/img/importexcel.xlsx" download="importexcel.xlsx"> Download Excel Template </a> -->
                    </div>
                </div>

            </div>

        </div>
        

    </div>
</div>
<div *ngIf="proccessing" class="loadingcont">
    <div class="spinner"></div>
 </div>