import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SchoolserviceService } from '../schoolservice.service';
import { NavbarService } from '../default/navbar/navbar.service';
import { PrimeNGConfig } from 'primeng/api';
import { Table } from 'primeng/table';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import {candidates_reports} from './candidatesReports';
import * as XLSX from 'xlsx';
import  jsPDF from 'jspdf';  
import html2canvas from 'html2canvas';
import { Subject } from 'rxjs';
// import 'jspdf-autotable';
// pdfMake.vfs = pdfFonts.pdfMake.vfs;
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
// import * as htmlToPdfmake from 'html-to-pdfmake';

@Component({
  selector: 'app-school-stud-report',
  templateUrl: './school-stud-report.component.html',
  styleUrls: ['./school-stud-report.component.scss']
})
export class SchoolStudReportComponent implements OnInit {
  // candidate!:candidates_reports[];
  candidate:any[]=[];
  // getstudreport!: subjects[];
  
  proccessing: boolean = true;
  @ViewChild('dt')
  table!: Table;
  
  @ViewChild('htmlData')
  htmlData!: ElementRef;

  schoolid:any;
  enrollmnt: any;
  fileName= 'Report.xlsx';
  alldata: any;
  getalldata: any[]=[];
  getallstname: any[]=[];
  getgender: any[]=[];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  page = 1;
  count = 0;
  tableSize = 4;
  tableSizesArr = [4, 8, 12,'All'];
  standard: any;
   Arr = Array; //Array type captured in a variable
   tout:boolean=true;
  tempArr: any[]=[];
  studavailable: boolean=true;
  // 
  constructor(public service:SchoolserviceService,public nav:NavbarService,private primengConfig: PrimeNGConfig) { }

  ngOnInit(): void {
    // alert("Generating Report usually takes more time, please wait!!, ")
    this.schoolid = sessionStorage.getItem('schoolid');
    this.standard = sessionStorage.getItem('Standard');
    console.log(this.standard)
    
    this.nav.hide();
    this.nav.dhide();
    this.nav.adhide();
    this.nav.asdshow();
    this.nav.studhide();
    this.nav.XIstudhide();
    this.nav.aduserhide();
  //   this.service.getcandidateReportLarge().then((candidates:any) => {
  //     this.candidate = candidates;
      
  // });
//teja


//teja


// this.primengConfig.ripple = true;

//datatables settings
this.dtOptions = {
  pagingType: 'full_numbers',
  pageLength: 5,
  lengthMenu : [10, 25, 50],
  language: {
    paginate: {
        first:      "<<",
        last:       ">>",
        next:       ">",
        previous:   "<"
      }
    },
    processing: true,
    scrollX:true,
    scrollY: '500',
  };
  setTimeout(() => {
    this.tout=false;
  }, 3000);
  setInterval(() => {
    this.tout=true;
  }, 8000);
  setInterval(() => {
    this.tout=false;
  }, 12000);
  this.getreport();
  }

  // tabSize(event:any){
  //   this.page = event;
  //   this.getreport();
  
  // }  
  
  // tableData(event:any): void {
  //   this.tableSize = event.target.value;
  //   this.page = 1;
  //   this.getreport();
   
  // }
  // onTableSizeChange(event:any): void {
  //   this.tableSize = event.target.value;
  //   this.page = 1;
  //   this.getreport();
  // } 
    // getEventValue($event:any) :string {
    //   return $event.target.value;
    // }

getreport()
{
  let schoolinfo:{schoolid:any,standard:any}={
    schoolid: this.schoolid,
    standard:this.standard
  }
  this.service.post('/School/reportDisplay',schoolinfo).subscribe((res:any)=>{
    if(res.status== "Success"){
      this.candidate = res.Data;
      this.studavailable=true;
      this.proccessing=false;
    }
    else{
      this.proccessing=false;
      this.candidate =[];
      this.studavailable=false;
      // alert(this.candidate)
    }
     this.dtTrigger.next();
  });
}

printPage() {
  window.print();
}

// generatepdf(){

//   let schoolinfo:{schoolid:any}={
//     schoolid: this.schoolid
//   }
//   this.service.post('/School/reportDisplay',schoolinfo).subscribe((res:any)=>{
//       this.alldata = res.Data;
//       // this.getstudreport = candidate.Subjects;
//       console.log(this.alldata);
//       this.getalldata = [];
//       this.getallstname = [];
//       this.getgender = [];
//       this.getallstname = [];
//       this.getallstname = [];
//       this.getallstname = [];
//       for (let i = 0; i < this.alldata.length; i++) {
//         this.getalldata.push(this.alldata[i].rollno);
//         this.getallstname.push(this.alldata[i].stname + this.alldata[i].ftname + this.alldata[i].mtname);
//         this.getgender.push(this.alldata[i].gender);
//       }

     
//       // this.alldata.forEach((element: any) => {
//       //   console.log(element)
       
//       // });

//       // setRowData(this.alldata.slice(1500,2000));
//       console.log(this.getalldata)
//       console.log(this.getallstname)
//       const documentDefinition = {   content: [
//         {
    
          
         
//           table: {
//             // headers are automatically repeated if the table spans over multiple pages
//             // you can declare how many rows should be treated as headers
//             headerRows: 1,
//             widths: [ '*',100,'auto', '*','*','*','*' ],
    
         

//             body: [
              
//               [ 'Student Roll No', 'Student/Mother/Father Name', 'Gender', 'Sub Code','SubName','Year','DOB & Annual Income' ],
//               [ this.getalldata, this.getallstname, this.getgender, 'Value 4','value1', 'Value 2', 'Value 3' ],
//             ]
//           },
          
//           layout: 	{
//             hLineWidth: function (i: any, node: any) {
//             return (i === 0 || i === node.table.body.length) ? 2 : 1;
//           },
//           vLineWidth: function (i: any, node: any)  {
//             return (i === 0 || i === node.table.widths.length) ? 2 : 1;
//           },
//           hLineColor: function (i: any, node: any) {
//             return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
//           },
//           vLineColor: function (i: any, node: any)  {
//             return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
//           } // optional
//         }
//         }
//       ] };
//       pdfMake.createPdf(documentDefinition).open();
//   })



//  }

public SavePDF(): void {  

  let rec = document.getElementById('htmlData') as HTMLElement;
    
  html2canvas(rec).then(canvas => {
      
      let fileWidth = 208;
      let fileHeight = canvas.height * fileWidth / canvas.width;
      
      const FILEURI = canvas.toDataURL('image/png')
      let PDF = new jsPDF('p', 'mm', 'a4');
      let position = 0;
      PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight)
      
      PDF.save('School_report.pdf');
  });      
}  

  exportexcel():void 
{

 this.tempArr = [];
 
  for (var i = 0; i < this.candidate.length; i++) {
    // let tempArrsub =(this.candidate[i].stud_sub_data, this.candidate[i].stud_sas_sub_data) => this.candidate[i].stud_sub_data.map((k, i) => [k, b[i]]);
    
    // (this.candidate[i].stud_sub_data,this.candidate[i].stud_sas_sub_data) ;
    // console.log(tempArrsub);
    var j;
    var k;
  for (j = 0, k=0; j< this.candidate[i].stud_sub_data.length,k< this.candidate[i].stud_sas_sub_data.length; j++,k++) {
   if(j==0){
      this.tempArr.push(
        {
          "Student Id":this.candidate[i].stud_data.studentid,
          "Full name":this.candidate[i].stud_data.STUD_Name.split(/\n/)[0],
          "Mother's name":this.candidate[i].stud_data.STUD_Name.split(/\n/)[2],
          "Father's name":this.candidate[i].stud_data.STUD_Name.split(/\n/)[1],
          "Sex":this.candidate[i].stud_data.Stud_Details.split(/\n/)[1],
          "CAT":this.candidate[i].stud_data.Stud_Details.split(/\n/)[0],
          "Sub Position":this.candidate[i].stud_sub_data[j].subject_position,
          "Sub Code":this.candidate[i].stud_sub_data[j].subject_code,
          "Sub Name":this.candidate[i].stud_sub_data[j].subjectname,
          "SAS Sub Position":this.candidate[i].stud_sas_sub_data[k].subject_position,
          "SAS Sub Code":this.candidate[i].stud_sas_sub_data[k].subject_code,
          "SAS Sub Name":this.candidate[i].stud_sas_sub_data[k].subjectname,
          "Med":this.candidate[i].stud_data.medium,
          "Year":this.candidate[i].stud_data.acc_year,
          "Student status":this.candidate[i].stud_data.studentstatus,
          "D.O.B":this.candidate[i].stud_data.dob,
          // "Annal Inc":this.candidate[i].stud_data.fathers_annual_income,
          // "Minority":"Y",
  
        }
      );
    
   }
   else{
     this.tempArr.push(
      {
        "Student Id":"",
        "Full name":"",
        "Mother's name":"",
        "Father's name":"",
        "Sex":"",
        "CAT":"",
        "Sub Position":this.candidate[i].stud_sub_data[j].subject_position,
        "Sub Code":this.candidate[i].stud_sub_data[j].subject_code,
        "Sub Name":this.candidate[i].stud_sub_data[j].subjectname,
        "SAS Sub Position":this.candidate[i].stud_sas_sub_data[k].subject_position,
        "SAS Sub Code":this.candidate[i].stud_sas_sub_data[k].subject_code,
        "SAS Sub Name":this.candidate[i].stud_sas_sub_data[k].subjectname,
        "Med":"",
        "Year":"",
        "Student status":"",
        "D.O.B":"",
        // "Annal Inc":"",
        // "Minority":"",

      }
    );
    
   }
    }
  }

  /* table id is passed over here */   
  // let element = document.getElementById('excel-table'); 
//  console.log(element);
  
  // const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
  const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet(this.tempArr);

  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  /* save to file */
  XLSX.writeFile(wb, this.fileName);
  
  
}


}
function foreach(arg0: any) {
  throw new Error('Function not implemented.');
}

