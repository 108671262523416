<div class="container login-container bg-light mb-3">
  <br>
  <div class="card border-dark mb-3 justify-content-center">
    <h5 class="card-header" style="background-color: #05a7c5c2;"><i class="bi bi-calendar2-fill"></i> Configure Last Date Of Submission</h5>
    <div class="card-body text-dark ">
     
          <ul class="nav nav-pills justify-content-center mb-3" id="pills-tab" role="tablist" >
              <li class="nav-item">
                <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#Standard" role="tab" aria-controls="Standard" aria-selected="true" (click)="resetCurrDate()">School</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#Student" role="tab" aria-controls="Student" aria-selected="false" (click)="resetCurrDate()">Student</a>
              </li>
             
            </ul>
     
      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="Standard" role="tabpanel" aria-labelledby="pills-home-tab">
            <form role="form" [formGroup]= "schooldateconfigform">
              <div  class="justify-content-md-center">
                  <div class="form-group row mb-3">
                    <label for="staticname" class="col-sm-4 col-form-label">Standard</label>
                    <div class="col-sm-6">
                      <select id="inputgender" formControlName="schoolstandard" class="form-select" 
                       [ngClass]="{ 'is-invalid': submitted && personal.schoolstandard.errors }"
                       [(ngModel)]="StandardValueSchool" (change)="getCurrentsLastdates($event.target)">
                        <option selected>Select </option>
                        <option value="9th">9th</option>
                        <option value="10th">10th</option>                       
                        <option value="11th">11th</option>                       
                        <option value="12th">12th</option>                       
                      </select>                      
                      <div *ngIf="submitted && personal.schoolstandard.errors" class="invalid-feedback">
                        <div *ngIf="personal.schoolstandard.errors.required">
                          standard Is Required.
                        </div>
                      </div>
                    </div>                    
                  </div>
                  <div class="row mb-3">
                    <div class="currentLastDate col-sm-4">Curent Last date</div>
                    <div class="currentLastDate col-sm-6" *ngIf="lastdatesaved">{{lastdatesaved}}</div>
                    <!-- <div class="currentLastDate col-sm-6" *ngIf="StandardValueSchool=='11th'">{{lastdate_11th}}</div> -->
                  </div>
                  <div class="form-group row mb-3">
                      <label for="staticname" class="col-sm-4 col-form-label">Select Last Date:</label>
                      <div class="col-sm-6">
                        <input type="date" formControlName="schoollastdate" class="form-control"  [ngClass]="{ 'is-invalid': submitted && personal.schoollastdate.errors }"
                           >  
                        <div *ngIf="submitted && personal.schoollastdate.errors" class="invalid-feedback">
                          <div *ngIf="personal.schoollastdate.errors.required">
                            Last Date Is Required.
                          </div>
                        </div>
                      </div>
                  </div>
                  <div class="form-group" style="text-align: center;">
                      
                    <input type="button" class="btnSubmit btn btn-primary"  value="Save" (click)="saveschoolconfig()" /> &nbsp;
                    <input type="button" class="btnSubmit btn btn-secondary"  value="Cancel" (click)="schoolreset()" /> &nbsp;
                 
                  </div>
              </div>
            </form>  
        </div>
        <div class="tab-pane fade" id="Student" role="tabpanel" aria-labelledby="pills-profile-tab">
          <form role="form" [formGroup]= "studentdateconfigform">
              <div  class="justify-content-md-center">
                  <div class="form-group row mb-3">
                    <label for="staticname" class="col-sm-4 col-form-label">Standard</label>
                    <div class="col-sm-6">
                      <select id="inputgender" formControlName="studentstandard" class="form-select"  
                      [ngClass]="{ 'is-invalid': studsubmitted && studdate.studentstandard.errors }"
                      [(ngModel)]="StandardValueStud" (change)="getCurrentsLastdates($event.target)">                      
                      <option selected>Select </option>
                      <option value="9th">9th</option>
                      <option value="10th">10th</option>                       
                      <option value="11th">11th</option>                       
                      <option value="12th">12th</option>`                       
                      </select>
                      <div *ngIf="studsubmitted && studdate.studentstandard.errors" class="invalid-feedback">
                        <div *ngIf="studdate.studentstandard.errors.required">
                          standard Is Required.
                        </div>
                      </div>
                    </div>                    
                  </div>
                  <div class="row mb-3">
                    <div class="currentLastDate col-sm-4">Curent Last date</div>
                    <!-- <div class="currentLastDate col-sm-6" *ngIf="StandardValueStud=='9th'">{{last_date_9thstudent_regi}} </div>
                    <div class="currentLastDate col-sm-6" *ngIf="StandardValueStud=='11th'">{{last_date_11thstudent_regi}} </div> -->
                    <div class="currentLastDate col-sm-6" *ngIf="lastdatesavedregi">{{lastdatesavedregi}} </div>
                  </div>
                  <div class="form-group row mb-3">
                      <label for="staticname" class="col-sm-4 col-form-label">Select Last Date:</label>
                      <div class="col-sm-6">
                        <input type="date" formControlName="studlastdate" class="form-control"  [ngClass]="{ 'is-invalid': studsubmitted && studdate.studlastdate.errors }">
                          
                        <div *ngIf="studsubmitted && studdate.studlastdate.errors" class="invalid-feedback">
                          <div *ngIf="studdate.studlastdate.errors.required">
                            Last Date Is Required.
                          </div>
                        </div>
                      </div>
                  </div>
                  <div class="form-group" style="text-align: center;">
                      
                    <input type="button" class="btnSubmit btn btn-primary"  value="Save" (click)="savestudentconfig()" /> &nbsp;
                    <input type="button" class="btnSubmit btn btn-secondary"  value="Cancel" (click)="studentsreset()" /> &nbsp;
                 
                  </div>
              </div>
            </form>
        </div>
        
      </div>
      </div>
  </div>
</div>