import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NavbarService } from '../default/navbar/navbar.service';
import { DataTableDirective } from 'angular-datatables';
import { Table } from 'primeng/table';
import { PrimeNGConfig } from 'primeng/api';
import { schoolCandidate } from './schoolCandidate';
import { SchoolserviceService } from '../schoolservice.service';

@Component({
  selector: 'app-viewschool-candidatelist',
  templateUrl: './viewschool-candidatelist.component.html',
  styleUrls: ['./viewschool-candidatelist.component.scss'],
})
export class ViewschoolCandidatelistComponent implements OnInit {
  schoolid!:String;
  schoolName!:String;
  schoolCandidates!: schoolCandidate[]; 
  totalNoOfStudents!:Number;
  loading: boolean = true;

  @ViewChild('dt')
  table!: Table;
  role_type: any;
  streamcourseShow: any;
  constructor(public router:Router, public service:SchoolserviceService,private primengConfig: PrimeNGConfig,public nav:NavbarService,private _routeParams: ActivatedRoute) {

    
  }

  ngOnInit(): void {

    this.role_type =  sessionStorage.getItem('role_type');
    if(this.role_type == 'boardadmin')
    {
      this.nav.hide();
      this.nav.dhide();
      this.nav.adshow();
      this.nav.aduserhide();
      this.nav.asdhide();
      this.nav.studhide();
      this.nav.XIstudhide();
    }
    else
    {
      this.nav.hide();
      this.nav.dhide();
      this.nav.adhide();
      this.nav.adusershow();
      this.nav.asdhide();
      this.nav.studhide();
      this.nav.XIstudhide();
    }

    
    var id = this._routeParams.params.subscribe(params => {
      this.schoolid = String(params["schoolid"]); 
      this.schoolName = String(params["schoolName"]);
       console.log("School Id: "+this.schoolid);  
      
          this.getCurrentSchoolCandidates();
          this.loading = false;
          
    });
   
    if(isNaN(Number(this.schoolid))){
      this.streamcourseShow = true;
      console.log(this.streamcourseShow);
    }
    else{
      this.streamcourseShow = false;
      console.log(this.streamcourseShow);
    }
    this.primengConfig.ripple = true;
  }
  getCurrentSchoolCandidates(){
    let schoolid :{schoolid:any}={
      schoolid:this.schoolid
    }
    this.service.post('/BoardApi/viewAllStudentsSchool',schoolid).subscribe((currentSchoolCandidates: any) => {
      console.log(currentSchoolCandidates.Data);
      this.schoolCandidates=currentSchoolCandidates.Data ;
      this.totalNoOfStudents= this.schoolCandidates.length;
      })
  }
  getEventValue($event:any) :string {
    return $event.target.value;
  }
  getColEventValue($event:any) :string {
    return $event.target.value;
  }
  
}
