import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SchoolserviceService } from '../schoolservice.service';
import { DatePipe } from '@angular/common';
import { NavbarService } from '../default/navbar/navbar.service';
@Component({
  selector: 'app-conf-last-date-sub',
  templateUrl: './conf-last-date-sub.component.html',
  styleUrls: ['./conf-last-date-sub.component.scss'],
  providers:[DatePipe]
})
export class ConfLastDateSubComponent implements OnInit {
  schooldateconfigform: FormGroup;
  submitted = false;
  studsubmitted = false;
  studentdateconfigform: FormGroup;
  StandardValueSchool!:any;
  StandardValueStud!:any;
  last_date_9thstudent_regi!:any;
  last_date_11thstudent_regi!:any;
  last_date_9thschool_submit!:any;
  last_date_11thschool_submit!:any;
  role_type: any;
  lastdate_9th: any;
  lastdate_11th: any;
  lastdate_12th: any;
  lastdate_10th: any;
  lastdatesaved: any="";
  lastdatesavedregi: any="";
  constructor(public formbuilder:FormBuilder,public router:Router, public service:SchoolserviceService,public datepipe: DatePipe,public nav:NavbarService) {
  this.schooldateconfigform = formbuilder.group({
    schoolstandard:['',Validators.required],
    schoollastdate:['',Validators.required]
  });
  this.studentdateconfigform = formbuilder.group({
    studentstandard:['',Validators.required],
    studlastdate:['',Validators.required]
  });

  }

  ngOnInit(): void {
    this.role_type =  sessionStorage.getItem('role_type');
    if(this.role_type == 'boardadmin')
    {
      this.nav.hide();
      this.nav.dhide();
      this.nav.adshow();
      this.nav.aduserhide();
      this.nav.asdhide();
      this.nav.studhide();
      this.nav.XIstudhide();
    }
    else
    {
      this.nav.hide();
      this.nav.dhide();
      this.nav.adhide();
      this.nav.adusershow();
      this.nav.asdhide();
      this.nav.studhide();
      this.nav.XIstudhide();
    }

  }

  get personal() { 
    return this.schooldateconfigform.controls; }

  get studdate(){
    return this.studentdateconfigform.controls;
  }

    saveschoolconfig()
    {
        this.submitted = true;
       if(this.schooldateconfigform.invalid)
       {
        alert("Fill All Deatils");
        return;
       }
       this.confgLastDateforSchool();
    }  

    savestudentconfig()
    {
      this.studsubmitted = true;
     if(this.studentdateconfigform.invalid)
     {
      alert("Fill All Deatils");
      return;
     }
     this.confgLastDateforStud()
  }

    schoolreset()
    {
      this.schooldateconfigform.reset();
    }
    studentsreset()
    {
      this.studentdateconfigform.reset();
    } 
     confgLastDatefor11thSchool(){

  }
  //show current last dates
  getCurrentsLastdates(e:any){
    
    let schoolinfo: {  } = { 
    }
  this.service.post('/School/viewlastdateforCandidates',schoolinfo).subscribe((res: any)=>{
    console.log(res);
   //last dates for 11th candidates
    //  this.last_date_9thstudent_regi = this.datepipe.transform(res['Data'][0].lastdate, 'dd/MM/yyyy')
    //last dates for 9th students
    // this.last_date_11thstudent_regi = this.datepipe.transform(res['Data'][1].lastdate, 'dd/MM/yyyy')
    if(res['status']=="Success"){
    for(let i=0;i<res['Data'].length;i++){
      if(res['Data'][i].student==e.value){
        // this.last_date_9thschool_submit = res['Data'][1].lastdate
        this.lastdatesavedregi = this.datepipe.transform(res['Data'][i].lastdate, 'dd/MM/yyyy');
        break;
        
       }
       else{
        this.lastdatesavedregi=="";
          }
   }
  }
  
 })
    this.service.post('/School/viewlastdateforSchools',schoolinfo).subscribe((res: any)=>{
    console.log(res);
   //last dates for 11th school        
    //  this.last_date_9thschool_submit = res['Data'][1].lastdate
    //  this.lastdate_9th = this.datepipe.transform(this.last_date_9thschool_submit, 'dd/MM/yyyy')
    // //last dates for 9th school        
    // this.last_date_11thschool_submit = res['Data'][0].lastdate
    // this.lastdate_11th = this.datepipe.transform(this.last_date_11thschool_submit, 'dd/MM/yyyy')
    if(res['status']=="Success"){
   for(let i=0;i<res['Data'].length;i++){
     if(res['Data'][i].student==e.value){
         // this.last_date_9thschool_submit = res['Data'][1].lastdate
        this.lastdatesaved = this.datepipe.transform(res['Data'][i].lastdate, 'dd/MM/yyyy');
        break;
       }
       else{
        this.lastdatesaved = "";
      }
   }
  }
 
 })
}
  //
  confgLastDateforStud(){
    if(this.StandardValueStud!='Select'){
      let StudLastDateDetails: {standard:any, lastdate:any} = {
      standard:this.StandardValueStud,
      // lastdate:this.datepipe.transform(this.studentdateconfigform.value.studlastdate, 'dd/MM/yyyy')    
      lastdate:this.studentdateconfigform.controls.studlastdate.value      
    }
    console.log(StudLastDateDetails);
    this.service.post('/BoardApi/lastdateforCandidates',StudLastDateDetails).subscribe((lastDateStudStatus: any) => {
        console.log(lastDateStudStatus['status']); 
        let updateUserSatatus= lastDateStudStatus['status'];
          console.log(updateUserSatatus);
          if(updateUserSatatus == 'Success')
          {
            alert(' '+lastDateStudStatus['Msg']);  
          }    
          this.studentdateconfigform.reset();
          this.studsubmitted = false;
    })
  }
  else{
    alert("Please Select Standard!");
  }
  }
  confgLastDateforSchool(){
    if(this.StandardValueSchool!='Select'){

    let SchoolLastDateDetails: {standard:any, lastdate:any} = {
      standard:this.StandardValueSchool,
      // lastdate:this.datepipe.transform(this.schooldateconfigform.controls.schoollastdate.value, 'dd/MM/yyyy')    
      lastdate: this.schooldateconfigform.controls.schoollastdate.value
    }
    this.service.post('/BoardApi/lastdateforSchools',SchoolLastDateDetails).subscribe((lastDateSchoolStatus: any) => {
        console.log(lastDateSchoolStatus['status']); 
        let updateUserSatatus= lastDateSchoolStatus['status'];
          console.log(updateUserSatatus);
          if(updateUserSatatus == 'Success')
          {
            alert(' '+lastDateSchoolStatus['Msg']);  
          }  
          this.schooldateconfigform.reset();
          this.submitted = false;  
    })
  }//if 
  else{
    alert("Please Select Standard!");
  }
  }
  resetCurrDate(){
    this.lastdatesavedregi=="";
    this.lastdatesaved = "";
  }
}
