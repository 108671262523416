import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SchoolserviceService } from '../schoolservice.service';
import { DatePipe } from '@angular/common';
import { NavbarService } from '../default/navbar/navbar.service';
import { allNoti } from './allNotiRemMsg';

@Component({
  selector: 'app-conf-notification',
  templateUrl: './conf-notification.component.html',
  styleUrls: ['./conf-notification.component.scss'],
  providers:[DatePipe]
})
export class ConfNotificationComponent implements OnInit {
  notiConfigform: FormGroup;
  Notisubmitted = false;
  Reminsubmitted = false;
  reminderConfigform: FormGroup;
  MsgToAllform: FormGroup;
  msgSubmitted = false;
  AllNoticArray!:allNoti[];
  target_audience!:String;
  // @ViewChild(closebutton)
  // closebutton: closebutton;
  constructor(public formbuilder:FormBuilder,public router:Router, public service:SchoolserviceService,public datepipe: DatePipe,public nav:NavbarService) { 
    this.notiConfigform = formbuilder.group({
      notificatonTxt:['',Validators.required],    
      notificatontitle:['',Validators.required],
      targetAudiNoti:['',Validators.required]
    });
    this.reminderConfigform = formbuilder.group({
      reminderTxt:['',Validators.required],
      remindertitle:['',Validators.required],
      targetAudiRem:['',Validators.required]      
    });
    this.MsgToAllform = formbuilder.group({
      msgTxt:['',Validators.required],
      msgtitle:['',Validators.required]      
    });
  }

  ngOnInit(): void {
    
    this.nav.hide();
    this.nav.dhide();
    this.nav.adshow();
    this.nav.asdhide();
  }
  get notificationCont() { 
    return this.notiConfigform.controls; }

  get reminderCont(){
    return this.reminderConfigform.controls;
  }
  get msgCont(){
    return this.MsgToAllform.controls;
  }
  saveNotification()
    {      
      this.Notisubmitted = true;
       if(this.notiConfigform.invalid)
       {
        alert("Fill All Deatils");
        return;
       }
       this.sendNotification();
    }
  notificationReset(){
    this.notiConfigform.reset();
  }  
saveMessage()
    {      
      this.msgSubmitted = true;
       if(this.MsgToAllform.invalid)
       {
        alert("Fill All Deatils");
        return;
       }
       this.sendMessageToAll();
    }
  msgReset(){
    this.MsgToAllform.reset();
  } 
  saveReminder()
    {
      this.Reminsubmitted = true;
     if(this.reminderConfigform.invalid)
     {
      alert("Fill All Deatils");
      return;
     }
     this.sendReminder();
  }
  reminderReset(){
    this.reminderConfigform.reset();
  } 
  sendNotification(){
    let NotificationDetails: {message:any,title:any} = {
      message:this.notiConfigform.value.notificatonTxt,
      title:this.notiConfigform.value.notificatontitle    
    }
    if(this.notiConfigform.value.targetAudiNoti=="studNoti"){
        this.service.post('/BoardApi/configNotificationforCandidate',NotificationDetails).subscribe((notifStatus: any) => {
          console.log(notifStatus['status']); 
          let updateSatatus= notifStatus['status'];
            console.log(updateSatatus);
            if(updateSatatus == 'Success')
            {
              alert(' '+notifStatus['Msg']+'for Students');  
            }    
            this.notiConfigform.reset();
            this.Notisubmitted = false;
      })
    }
    else{
      this.service.post('/BoardApi/configNotificationforSchool',NotificationDetails).subscribe((notifStatus: any) => {
        console.log(notifStatus['status']); 
        let updateSatatus= notifStatus['status'];
          console.log(updateSatatus);
          if(updateSatatus == 'Success')
          {
            alert(' '+notifStatus['Msg']+'for Schools');  
          }    
          this.notiConfigform.reset();
          this.Notisubmitted = false;
    })
    }
    
  }
  
  sendReminder(){
    let reminderDetails: {message:any,title:any} = {
      message:this.reminderConfigform.value.reminderTxt,
      title:this.reminderConfigform.value.remindertitle
      
    }
    if(this.reminderConfigform.value.targetAudiRem=="studRem"){
    this.service.post('/BoardApi/configRemindersforCandidate',reminderDetails).subscribe((remindStatus: any) => {
        console.log(remindStatus['status']); 
        let updateSatatus= remindStatus['status'];
          console.log(updateSatatus);
          if(updateSatatus == 'Success')
          {
            alert(' '+remindStatus['Msg']+'for Students');  
          }    
          this.reminderConfigform.reset();
          this.Reminsubmitted = false;
    })
  }
  else{
    this.service.post('/BoardApi/configRemindersforSchool',reminderDetails).subscribe((remindStatus: any) => {
      console.log(remindStatus['status']); 
      let updateSatatus= remindStatus['status'];
        console.log(updateSatatus);
        if(updateSatatus == 'Success')
        {
          alert(' '+remindStatus['Msg']+'for Schools');  
        }    
        this.reminderConfigform.reset();
        this.Reminsubmitted = false;
  })
  }
  }
  sendMessageToAll(){
    let msgDetails: {message:any,title:any} = {
      message:this.MsgToAllform.value.msgTxt,
      title:this.MsgToAllform.value.msgtitle    
    }
    
    this.service.post('/BoardApi/configMessages',msgDetails).subscribe((msgStatus: any) => {
      // console.log(msgStatus['status']); 
      let updateSatatus= msgStatus['status'];
        console.log(updateSatatus);
        if(updateSatatus == 'Success')
        {
          alert(' '+msgStatus['Msg']);  
        }    
        this.notiConfigform.reset();
        this.Notisubmitted = false;
    })    
    
  }
  showAllNotifications(){
    let noparam: {} = {
    }
    if(this.notiConfigform.value.targetAudiNoti==''){
      alert("Please select School /Student");
      
    }
    else if(this.notiConfigform.value.targetAudiNoti=='studNoti'){
      
    this.service.post('/BoardApi/viewnotificationsforCandidate',noparam).subscribe((notiSchool: any) => {
      if(notiSchool['Data']==""){
        this.target_audience="No Notification to show";
        this.AllNoticArray=[];
      }
      else{
      console.log(notiSchool); 
      this.AllNoticArray=notiSchool['Data']
      console.log(this.AllNoticArray);   
      this.target_audience="Notification For Students" ;
      }
    })
      
    }
    else{

      this.service.post('/BoardApi/viewnotificationsforSchool',noparam).subscribe((notiSchool: any) => {
        if(notiSchool['Data']==""){
          this.target_audience="No Notification to show";     
          this.AllNoticArray=[];     
        }
        else{
        console.log(notiSchool); 
        this.AllNoticArray=notiSchool['Data']
        console.log(this.AllNoticArray);
        this.target_audience="Notification For Schools";
        }
        })
    }
  } 
 
  showAllReminders(){
    let noparam: {} = {
    }
    if(this.reminderConfigform.value.targetAudiNoti==''){
      alert("Please select School /Student");
      
    }
    else if(this.reminderConfigform.value.targetAudiRem=='studRem'){
    this.service.post('/BoardApi/viewremindersforCandidate',noparam).subscribe((notiSchool: any) => {
      if(notiSchool['Data']==""){
        this.target_audience="No Reminders to show";
        this.AllNoticArray=[];
      }
      else{        
      console.log(notiSchool); 
      this.AllNoticArray=notiSchool['Data']
      console.log(this.AllNoticArray);   
      this.target_audience="Reminder For Students";
      }
      })
    }
    else{
      this.service.post('/BoardApi/viewremindersforSchool',noparam).subscribe((notiSchool: any) => {
        if(notiSchool['Data']==""){        
          this.target_audience="No Reminders to show";
          this.AllNoticArray=[];
        }
        else{
        console.log(notiSchool); 
        this.AllNoticArray=notiSchool['Data']
        console.log(this.AllNoticArray);
        this.target_audience="Reminder For Schools";
        }
        })
    }
  }
  showAllMessages(){
    let noparam: {} = {
    }
    this.service.post('/BoardApi/viewMessages',noparam).subscribe((notiSchool: any) => {
      if(notiSchool['Data']==""){
        this.target_audience="No Messages to show";
        this.AllNoticArray=[];
      }
      else{
      console.log(notiSchool); 
      this.AllNoticArray=notiSchool['Data']
      console.log(this.AllNoticArray);   
      this.target_audience="All Messages";
      }
      })
  }
}
