<div class="container login-container bg-light mb-3">
  <br>
  <div class="card border-dark mb-3">
      <h5 class="card-header">Student Profile</h5>
      <div class="card-body text-dark ">
          <figcaption class="blockquote" style="color: #0062cc;">
              <cite title="Source Title"><b>- Personal Information</b></cite>
           </figcaption> <hr>
           <form role="form" [formGroup]= "studentprofileform"> 
               <div  class="justify-content-md-center">
                   <!-- <div class="form-group row mb-3">
                       <div class="col-sm-2">
                          <img src= "{{this.imgstring}}" alt="{{this.imgstring}}" >
                       </div>
                   </div>
                   <a href="{{this.imgstring}}">view</a> -->
                  <div class="form-group row mb-3">
                      
                      <label for="staticname" class="col-sm-2 col-form-label">Class</label>
                      <div class="col-sm-2">
                        <input type="text" formControlName="class" value ="IX" class="form-control" id="staticName"  readonly>
                      </div>
                     
                      <label for="staticname" class="col-sm-1 col-form-label">Division</label>
                      <div class="col-sm-2">
                        <input type="text"  class="form-control" readonly formControlName="division" id="staticName"  value="A" readonly>
                        
                      </div>
                      
                    </div>
                  <div class="form-group row mb-3">
                      
                      <label for="staticname" class="col-sm-2 col-form-label">Candidate's Name</label>
                      <div class="col-sm-5">
                        <input type="text" formControlName="stname" class="form-control" id="staticName" readonly>
                       
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label for="staticfathername" class="col-sm-2 col-form-label">Father's Name</label>
                      <div class="col-sm-5">
                        <input type="text" formControlName="ftname" class="form-control" id="inputfathername" readonly>
                         
                     
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label for="staticmothername" class="col-sm-2 col-form-label">Mother's Name</label>
                      <div class="col-sm-5">
                        <input type="text" formControlName="mtname" class="form-control" id="inputmothername" readonly>
                         
               
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label for="staticdob" class="col-sm-2 col-form-label">Date Of Birth</label>
                      <div class="col-sm-5">
                        <input type="text" formControlName="dob" class="form-control" id="inputdob" readonly>
                        
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label for="staticgender" class="col-sm-2 col-form-label">Gender</label>
                      <div class="col-sm-5">
                          <select id="inputgender" formControlName="gender" class="form-select" [attr.disabled]="true" >
                              <option selected>Select Gender</option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                              <option value="Transe_gender">Transe Gender</option>
                            </select>
                       
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label for="staticEmail" class="col-sm-2 col-form-label">Email</label>
                      <div class="col-sm-5">
                        <input type="text" formControlName="email" class="form-control" id="staticEmail" readonly>
                           
                        </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label for="staticEmail" class="col-sm-2 col-form-label"> Mobile Number</label>
                      <div class="col-sm-5">
                        <input type="text" formControlName="mobile_no" class="form-control" id="staticEmail" readonly>
                         
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label for="staticEmail" class="col-sm-2 col-form-label">Alternate Contact Number</label>
                      <div class="col-sm-5">
                        <input type="text" formControlName="alt_mobile_no" class="form-control" id="staticEmail" readonly>
                      
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label for="staticcaste" class="col-sm-2 col-form-label">Caste</label>
                      <div class="col-sm-5">
                        <select id="inputcaste" formControlName="caste" class="form-select"  [attr.disabled]="true">
                          <option hidden value="" disabled selected>Select Caste</option>
                          <option value="General">General</option>
                          <option value="OBC">OBC</option>
                          <option value="SC">SC</option>
                          <option value="ST">ST</option>
                          <!-- <option value="East_Indians">East Indians</option>
                          <option value="Goan_Catholics">Goan Catholics</option>
                          <option value="Karwari_Catholics">Karwari Catholics</option>
                          <option value="Koli_Christians">Koli Christians</option>
                          <option value="Mangalorean_Catholics">Mangalorean Catholics</option> -->
                        </select>
                        
                        
                      </div>
                    </div>
                    <figcaption class="blockquote" style="color: #0062cc;">
                      <cite title="Source Title"><b>- Address Information</b></cite>
                   </figcaption> <hr>
                   <div class="form-group row mb-3">
                      <label for="staticEmail" class="col-sm-2 col-form-label">Street</label>
                      <div class="col-sm-5">
                        <input type="text" formControlName="street" class="form-control" id="staticEmail" readonly>
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label for="staticEmail" class="col-sm-2 col-form-label">Locality</label>
                      <div class="col-sm-5">
                        <input type="text" formControlName="locality" class="form-control" id="staticEmail" readonly>
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label for="staticEmail" class="col-sm-2 col-form-label">City/Town/Village</label>
                      <div class="col-sm-5">
                        <input type="text" formControlName="city" class="form-control" id="staticEmail" readonly>
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label for="staticEmail" class="col-sm-2 col-form-label">State</label>
                      <div class="col-sm-5">
                        <input type="text" formControlName="state" class="form-control" id="staticEmail" readonly>
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label for="staticEmail" class="col-sm-2 col-form-label">Taluka</label>
                      <div class="col-sm-3">
                        <input type="text" formControlName="taluka" class="form-control" id="staticEmail" readonly>
                      </div>
                      <label for="staticEmail" class="col-sm-1 col-form-label">District</label>
                      <div class="col-sm-3">
                        <input type="text" formControlName="district" class="form-control" id="staticEmail" readonly>
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label for="staticEmail" class="col-sm-2 col-form-label">Landmark</label>
                      <div class="col-sm-3">
                        <input type="text" formControlName="landmark" class="form-control" id="staticEmail" readonly>
                      </div>
                      <label for="staticEmail" class="col-sm-1 col-form-label">Pincode</label>
                      <div class="col-sm-3">
                        <input type="text" formControlName="pincode" class="form-control" id="staticEmail" readonly >
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label for="staticEmail" class="col-sm-2 col-form-label">House OwnerShip</label>
                      <div class="col-sm-3" style="margin-top: 9px;">
                          <input class="form-check-input col-sm-3" type="radio" [attr.disabled]="true" formControlName="house_ownership" name="house_ownership" id="gridRadios1" value="Own" > Own
                      </div>
                      <div class="col-sm-3" style="margin-top: 9px;">
                          <input class="form-check-input col-sm-3" type="radio" [attr.disabled]="true" formControlName="house_ownership" name="house_ownership" id="gridRadios2" value="Rent"  > Rent
                    
                      </div>
                   </div>
                    <div class="hidden form-group row mb-3">
                      <label for="staticEmail" class="col-sm-2 col-form-label">Internet Coverage</label>
                      <div class="col-sm-3" style="margin-top: 9px;">
                          <input class="form-check-input col-sm-3" type="radio" [attr.disabled]="true" formControlName="internet_coverage" name="internet_coverage" id="gridRadios1" value="Yes" > Yes
                      </div>
                      <div class="col-sm-3" style="margin-top: 9px;">
                          <input class="form-check-input col-sm-3" type="radio" [attr.disabled]="true" formControlName="internet_coverage" name="internet_coverage" id="gridRadios2" value="No" > No
                    
                      </div>
                   </div>
                   <div class="form-group row mb-3">
                      <label for="staticEmail" class="col-sm-2 col-form-label">Distance To Coverage</label>
                      <div class="col-sm-5">
                          <input type="text" class="form-control" formControlName="distance_coverage" id="staticEmail" readonly>
                       </div>
                      
                   </div>
                   <div class="form-group row mb-3"  >
                    <label for="staticname" class="col-sm-2 col-form-label">Distance of School from House (in Kms)</label>
                      <div class="col-sm-5">
                        <input type="text"  class="form-control" formControlName="distance_of_school_to_house" id="staticName"  readonly>
                                  
                      </div>
                   </div>
                   <div class="form-group row mb-3"  >
                    <label for="staticname" class="col-sm-2 col-form-label">Mode of Transport</label>
                    <div class="col-sm-5">
                      <select id="inputgender" formControlName="mode_of_transport" class="form-select" [attr.disabled]="true" >
                        <option selected disabled>Select Mode Of Transport</option>
                        <option value="Walking">Walking</option>
                        <option value="Private_Vehicle">Private Vehicle</option>
                        <option value="Auto_Rikshaw">Auto Rickshaw</option>
                        <option value="Public_Transport">Public Transport</option>
                      </select>
                    </div>
                   
                   </div>
                    <div class="form-group row mb-3">
                      <label for="staticEmail" class="col-sm-2 col-form-label">Access to Smart Phone</label>
                      <div class="col-sm-3" style="margin-top: 9px;">
                          <input class="form-check-input col-sm-3" type="radio" [attr.disabled]="true" formControlName="access_to_smart_phone" name="access_smartphone" id="gridRadios1" value="Yes"   > Yes
                      </div>
                      <div class="col-sm-3" style="margin-top: 9px;">
                          <input class="form-check-input col-sm-3" type="radio" [attr.disabled]="true" formControlName="access_to_smart_phone" name="access_smartphone" id="gridRadios2" value="No"   > No
                    
                      </div>
                   </div>
                   <div class="form-group row mb-3">
                      <label for="staticEmail" class="col-sm-2 col-form-label">Access to Internet</label>
                      <div class="col-sm-3" style="margin-top: 9px;">
                          <input class="form-check-input col-sm-3" type="radio" [attr.disabled]="true" formControlName="access_internet" name="access_internet" id="gridRadios1" value="Yes"   > Yes
                      </div>
                      <div class="col-sm-3" style="margin-top: 9px;">
                          <input class="form-check-input col-sm-3" type="radio" [attr.disabled]="true" formControlName="access_internet" name="access_internet" id="gridRadios2" value="No"   > No
                    
                      </div>
                   </div>
                   <figcaption class="blockquote" style="color: #0062cc;">
                      <cite title="Source Title"><b>- Other Information</b></cite>
                   </figcaption> <hr>

                   <div class="form-group row mb-3">
                      <label for="staticEmail" class="col-sm-2 col-form-label">Father's Qualification</label>
                      <div class="col-sm-5">
                          <input type="text" class="form-control" formControlName="fathers_qualification" id="staticEmail" readonly>
                       </div>
                      
                    </div>
                    <div class="form-group row mb-3">
                       <label for="staticEmail" class="col-sm-2 col-form-label">Father's Job</label>
                      <div class="col-sm-5">
                          <input type="text" class="form-control" formControlName="fathers_job" id="staticEmail" readonly>
                       </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label for="staticEmail" class="col-sm-2 col-form-label">Father's Annual Income</label>
                      <div class="col-sm-5">
                          <input type="text" class="form-control" formControlName="fathers_annual_income" id="staticEmail" readonly>
                       </div>
                      
                    </div>
                    <div class="form-group row mb-3">
                       <label for="staticEmail" class="col-sm-2 col-form-label">Mother's Qualification</label>
                      <div class="col-sm-5">
                          <input type="text" class="form-control" formControlName="mothers_qualification" id="staticEmail" readonly>
                       </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label for="staticEmail" class="col-sm-2 col-form-label">Mother's Job</label>
                      <div class="col-sm-5">
                          <input type="text" class="form-control" formControlName="mothers_job" id="staticEmail" readonly>
                       </div>
                      
                    </div>
                    <div class="form-group row mb-3">
                       <label for="staticEmail" class="col-sm-2 col-form-label">Mother's Annual Income</label>
                      <div class="col-sm-5">
                          <input type="text" class="form-control" formControlName="mothers_annual_income" id="staticEmail" readonly>
                       </div>
                    </div>
                    <figcaption class="blockquote" style="color: #0062cc;">
                      <cite title="Source Title"><b>- Academic Information </b></cite>
                   </figcaption> <hr>
                   <div class="form-group row mb-3">
                    <label for="subject_one" class="col-sm-2 col-form-label">Subject 1</label>
                    <div class="col-sm-5">
                      
                          <select id="subject_one" formControlName="subject_one"  class="form-select" name="subject_one"  [attr.disabled]="true" >
                            <option selected>Select </option>
                            <option *ngFor="let sub of subject" [value]="sub.subject_code" >{{sub.subjectname}}</option>
                            <!-- <option [value]="sub.position">Marathi</option> -->
                          </select>
                          
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label for="subject_two" class="col-sm-2 col-form-label">Subject 2</label>
                    <div class="col-sm-5">
                        <select id="subject_two" formControlName="subject_two" class="form-select" [attr.disabled]="true" >
                            <option selected>Select </option>
                            <option *ngFor="let sub1 of subject1" [value]="sub1.subject_code">{{sub1.subjectname}}</option>
                            <!-- <option value="Marathi">Marathi</option> -->
                          </select>
                          
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label for="subject_three" class="col-sm-2 col-form-label">Subject 3</label>
                    <div class="col-sm-5">
                        <select id="subject_three" formControlName="subject_three" class="form-select" [attr.disabled]="true" >
                            <option selected>Select </option>
                            <option *ngFor="let sub2 of subject2" [value]="sub2.subject_code">{{sub2.subjectname}}</option>
                            <!-- <option value="Marathi">Soc Sci</option> -->
                          </select>
                          
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label for="subject_four" class="col-sm-2 col-form-label">Subject 4</label>
                    <div class="col-sm-5">
                        <select id="subject_four" formControlName="subject_four" class="form-select" [attr.disabled]="true"  >
                            <option selected>Select </option>
                            <option *ngFor="let sub3 of subject3" [value]="sub3.subject_code">{{sub3.subjectname}}</option>
                            <!-- <option value="Marathi">Marathi</option> -->
                          </select>
                          
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label for="subject_five" class="col-sm-2 col-form-label">Subject 5</label>
                    <div class="col-sm-5">
                        <select id="subject_five" formControlName="subject_five" class="form-select" [attr.disabled]="true"  >
                            <option selected>Select </option>
                            <option *ngFor="let sub4 of subject4" [value]="sub4.subject_code">{{sub4.subjectname}}</option>
                            <!-- <option value="Marathi">Marathi</option> -->
                          </select>
                          
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label for="subject_six" class="col-sm-2 col-form-label">Subject 6</label>
                    <div class="col-sm-5">
                        <select id="subject_six" formControlName="subject_six" class="form-select" [attr.disabled]="true"  >
                            <option selected value="">Select </option>
                            <option *ngFor="let sub5 of subject5" [value]="sub5.subject_code">{{sub5.subjectname}}</option>
                            <!-- <option value="Marathi">Marathi</option> -->
                          </select>
                         
                    </div>
                  </div>
             <div class="form-group row mb-3">
                  <label for="subject_seven" class="col-sm-2 col-form-label">Subject 7</label>
                  <div class="col-sm-5">
                      <select id="subject_seven" formControlName="subject_seven" class="form-select" [attr.disabled]="true"  >
                          <option selected value="">Select </option>
                          <option *ngFor="let sub6 of subject6" [value]="sub6.subject_code">{{sub6.subjectname}}</option>
                          <!-- <option value="Marathi">Marathi</option> -->
                        </select>
                       
                  </div>
                </div>
                <div class="form-group row mb-3">
                  <label for="subject_eight" class="col-sm-2 col-form-label">Subject 8</label>
                  <div class="col-sm-5">
                      <select id="subject_eight" formControlName="subject_eight" class="form-select" [attr.disabled]="true"  >
                          <option selected value="">Select </option>
                          <option *ngFor="let sub7 of subject7" [value]="sub7.subject_code">{{sub7.subjectname}}</option>
                          <!-- <option value="Marathi">Marathi</option> -->
                        </select>
                       
                  </div>
              </div>
              <div class="form-group row mb-3">
                <label for="subject_nine" class="col-sm-2 col-form-label">Subject 9</label>
                <div class="col-sm-5">
                    <select id="subject_nine" formControlName="subject_nine" class="form-select" [attr.disabled]="true"  >
                        <option selected value="">Select </option>
                        <option *ngFor="let sub8 of subject8" [value]="sub8.subject_code">{{sub8.subjectname}}</option>
                        <!-- <option value="Marathi">Marathi</option> -->
                      </select>
                     
                </div>
            </div>
            <div class="form-group row mb-3">
              <label for="subject_ten" class="col-sm-2 col-form-label">Subject 10</label>
              <div class="col-sm-5">
                  <select id="subject_ten" formControlName="subject_ten" class="form-select" [attr.disabled]="true"  >
                      <option selected value="">Select </option>
                      <option *ngFor="let sub9 of subject9" [value]="sub9.subject_code">{{sub9.subjectname}}</option>
                      <!-- <option value="Marathi">Marathi</option> -->
                    </select>
                   
              </div>
          </div>
          <div class="form-group row mb-3">
            <label for="subject_eleven" class="col-sm-2 col-form-label">Subject 11</label>
            <div class="col-sm-5">
                <select id="subject_eleven" formControlName="subject_eleven" class="form-select" [attr.disabled]="true"  >
                    <option selected value="">Select </option>
                    <option *ngFor="let sub10 of subject10" [value]="sub10.subject_code">{{sub10.subjectname}}</option>                      <!-- <option value="Marathi">Marathi</option> -->
                  </select>
                 
            </div>
        </div>
          <div class="form-group row mb-3">
            <label for="subject_eleven" class="col-sm-2 col-form-label">Subject 12</label>
            <div class="col-sm-5">
                <select id="subject_eleven" formControlName="subject_eleven" class="form-select" [attr.disabled]="true"  >
                    <option selected value="">Select </option>
                    <option *ngFor="let sub11 of subject11" [value]="sub11.subject_code">{{sub11.subjectname}}</option>                      <!-- <option value="Marathi">Marathi</option> -->
                  </select>
                 
            </div>
        </div>
          <div class="form-group row mb-3">
            <label for="subject_eleven" class="col-sm-2 col-form-label">Subject 13</label>
            <div class="col-sm-5">
                <select id="subject_eleven" formControlName="subject_eleven" class="form-select" [attr.disabled]="true"  >
                    <option selected value="">Select </option>
                    <option *ngFor="let sub12 of subject12" [value]="sub12.subject_code">{{sub12.subjectname}}</option>                      <!-- <option value="Marathi">Marathi</option> -->
                  </select>
                 
            </div>
        </div>
                    <figcaption class="blockquote" style="color: #0062cc;">
                      <cite title="Source Title"><b>- Uploaded Documents </b></cite>
                   </figcaption> <hr>
                   <div class="form-group row mb-3">
                    <label for="staticEmail" class="col-sm-2 col-form-label">Photo</label>
                   <div class="col-sm-3">
                    <!-- <a href="{{photoinfo}}" class="btn btn-success btn-sm photobtn" target="_blank">view</a> -->
                    <button type="button" class="btn btn-success" data-toggle="modal" data-target="#photoModal">
                      View
                    </button>
                    <!-- Modal -->
                      <div class="modal fade" data-backdrop="static" id="photoModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                          <div class="modal-content">
                             <div class="modal-header">
                              <h5 class="modal-title" id="exampleModalLabel">Uploaded Photo</h5>
                              <!--<button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button> -->
                            </div>
                            <div class="modal-body mx-auto viewimgmodalbody">
                                <img [src]="photoinfo">
                            </div>
                            <div class="modal-footer">
                              <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
                            </div>
                          </div>
                        </div>
                      </div> 
                    <!-- modal end -->
                  </div>
                  <label for="staticEmail" class="col-sm-2 col-form-label">Signature</label>
              
                  <div class="col-sm-3">
                    <!-- <a href="{{signinfo}}" class="btn btn-success btn-sm photobtn" target="_blank">view</a> -->
                    <button type="button" class="btn btn-success" data-toggle="modal" data-target="#signModal">
                      View
                    </button>
                    <!-- Modal -->
                      <div class="modal fade" data-backdrop="static" id="signModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                          <div class="modal-content">
                             <div class="modal-header">
                              <h5 class="modal-title" id="exampleModalLabel">Uploaded Signature</h5>
                              <!--<button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button> -->
                            </div>
                            <div class="modal-body mx-auto viewimgmodalbody">
                                <img [src]="signinfo">
                            </div>
                            <div class="modal-footer">
                              <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
                            </div>
                          </div>
                        </div>
                      </div> 
                    <!-- modal end -->
                  </div>
               
                 </div>
               
                    <figcaption class="blockquote" style="color: #0062cc;">
                      <cite title="Source Title"><b>-  Security Details</b></cite>
                   </figcaption> <hr>
                   <div class="form-group row mb-3">
                    <label for="staticgender" class="col-sm-2 col-form-label">Security Question</label>
                    <div class="col-sm-5">
                        <select id="inputgender" formControlName="security_ques" class="form-select" [attr.disabled]="true" >
                            <option selected>Select </option>
                            <option value="What is the name of your first school">What is the name of your first school?</option>
                            <option value="What is your neighbour's pet name">What is your neighbour's pet name ?</option>
                            <option value="Which is Your Favourite book">Which is Your Favourite book?</option>
                          </select>
                          
                      
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label for="staticEmail" class="col-sm-2 col-form-label">Security Answer</label>
                    <div class="col-sm-5">
                        <input type="text" formControlName="security_ans" class="form-control" id="security_ans" readonly>
                       
                     </div>
                  </div>
                   
               </div>      
           </form> 
      </div>
    </div>
    <div class="row mb-3"></div>
</div>