<div class="container login-container bg-light mb-3">
  <br>
  <div class="row mb-3">
      <div class="col-md-6">
        <div class="card">
          <h5 class="card-header cardheader"><i class="bi bi-building"></i> GBSHSE </h5>
          <div class="card-body">
              <button class="btn btn-secondary" [routerLink]="['/home']">Candidate & School Registraion Portal</button>
          </div>
        </div>&nbsp;
          <div class="card" style="height: 338px;">
              <h5 class="card-header"><i class="bi bi-calendar-date-fill"></i> Important Dates</h5>
              <div class="card-body">
                  <table class="table table-striped table-bordered">
                      <!-- <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">First</th>
                          <th scope="col">Last</th>
                          <th scope="col">Handle</th>
                        </tr>
                      </thead> -->
                      <tbody >
                        <tr>
                          
                          <td>Last Date of Submission for Schools- Class 11</td>
                          <td>{{alldates11school}}</td>
                          
                        </tr>
                        <tr>
                          <td>Last Date of Submission for Candidates- Class 11</td>
                          <td>{{alldates11}}</td>
                        </tr>
                        <tr>
                          <td>Last Date of Submission for Schools- Class 9</td>
                          <td>{{alldates9school}}</td>
                        </tr>
                        <tr>
                          <td>Last Date of Submission for Candidates- Class 9</td>
                          <td>
                            {{alldates9}}
                          </td>
                        </tr>
                        
                      </tbody>
                    </table>
              </div>
          </div>

      </div> 
      <div class="col-md-6">
          <div class="card" style="height: 472px;">
              <h5 class="card-header cardheader"><i class="bi bi-lock-fill"></i> Registered Board User Sign In</h5>
              <!-- <h5 class="stdlogin">
                <div class="form-group row" >
                  <div class="form-check col-md-6">
                    <input class="form-check-input radio" type="radio" name="gridRadios" id="schoolForm" value="option2" [ngClass]="{ 'active':activeTab==='sscResult'}" (click)="sscResult('sscResult')">
                    <label class="form-check-label" for="gridRadios2">
                      &nbsp;School Login
                    </label>
                  
                  </div>
                </div>
              </h5> -->
             
              <div class="card-body">
                
                  <div  class="hsscdiv">

                    <form [formGroup]="loginform" >
                      
                      <div class="form-group row">
                        <label for="staticEmail" class="col-sm-4 col-form-label">Username</label>
                        <div class="form-group col-sm-8 my-2">
                          <input type="text" class="form-control" formControlName="username" placeholder="Your Username *" value="" 
                          [ngClass]="{ 'is-invalid': submitted && l.username.errors }"/>
                          <div *ngIf="submitted && l.username.errors" class="invalid-feedback">
                            <div *ngIf="l.username.errors.required">
                              Username Is Required.
                            </div>
                          </div>
                      </div>
                      </div>
                      <div class="form-group row">
                        <label for="inputPassword" class="col-sm-4 col-form-label">Password</label>
                        <div class="form-group col-sm-8 my-2">
                          <input type="password" class="form-control" formControlName="password" placeholder="Your Password *" value="" 
                          [ngClass]="{ 'is-invalid': submitted && l.password.errors }"/>
                          <div *ngIf="submitted && l.password.errors" class="invalid-feedback">
                            <div *ngIf="l.password.errors.required">
                              Password Is Required.
                            </div>
                          </div>
                      </div>
                      </div>
                      <div class="row">
                          <label for="inputPassword" class="col-sm-4 col-form-label">Security Pin</label>
                          
                          <span class="col-sm-6 captcha" id="captcha" style="background-image:url('./assets/img/brand/dot.jpg');background-repeat: no-repeat;width:'180px'">{{captcha}} </span>
                          <span class="col-sm-2"> <a (click)="refresh()"> <img src="./assets/img/brand/refresh.png" height="50px" width="50px"></a></span>
                         
                      </div>
                      <div class="form-group row my-2">
                              
                              <label for="inputPassword" class="col-sm-4 col-form-label">Enter Security Pin</label>
                              <div class="form-group col-sm-8 my-2">
                                  <input type="text" class="form-control" formControlName="captcha_text" placeholder="Enter Above Captcha" value=""
                                  [ngClass]="{ 'is-invalid': submitted && l.captcha_text.errors }" />
                                  <div *ngIf="submitted && l.captcha_text.errors" class="invalid-feedback">
                                    <div *ngIf="l.captcha_text.errors.required">
                                      Captcha Is Required.
                                    </div>
                                  </div>
                              </div>
                             
                          <div class="form-group col-sm-12 center my-5 ">
                              <div class="form-group text-center">
                              <!-- <input type="button" class="btnSubmit btn btn-primary" (click)="onSchoolLogin(loginform.value)" value="Login" /> -->
                              <input type="button" class="btnSubmit btn btn-primary" (click)="onBoardLogin(loginform.value)" value="Login" />
                              </div> 
                          </div>
                      </div>
                          
                    </form>
                   
                  </div>
                  
                  
              </div>
            </div>
      </div>
     
  </div>
  <div class="row mb-3">
      <div class="col-md-12">
          
           <div class="card">
            <h5 class="card-header logolink"> Jointly Conducted by</h5>
             <div class="card-body row">
                <div class="col-md-offset-2 col-md-2 text-center">
                  <img src="../../assets/img/jointly-working-institute-logo/institute1.jpg" class="img" width="100px" height="89px">
              
                </div>
                <div class="col-md-2 text-center">
                  <img src="../../assets/img/jointly-working-institute-logo/institute2.jpg" class="img" width="100px" height="89px">
             
                </div>
                <div class="col-md-2 text-center">
                  <img src="../../assets/img/jointly-working-institute-logo/institute3.jpg" class="img" width="100px" height="89px">
              
                </div>
                <div class="col-md-2 text-center">
                  <img src="../../assets/img/jointly-working-institute-logo/institute5.jpg" class="img" width="100px" height="89px">
             
                </div>
                <div class="col-md-2 text-center">
                  <img src="../../assets/img/jointly-working-institute-logo/institute6.jpg" class="img" width="100px" height="89px">
             
                </div>
                <div class="col-md-2 text-center">
                  <img src="../../assets/img/jointly-working-institute-logo/institute7.jpg" class="img" width="100px" height="89px">
             
                </div>
             </div>
            </div>

      </div>
  </div>
  <!-- <div class="row mb-3">
      <div class="col-md-12">
          <div class="card">
              <h5 class="card-header"><i class="bi bi-chat-left-dots-fill"></i> Message</h5>
              <div class="card-body">
                  <h6 class="text-center">
                      Online Submission of Registration , Payment of Registration Fee and Printing of Registration Details Page for JEE (Advanced) 2020 is Over.
For any further information, please visit https://jeeadv.ac.in OR contact IIT Delhi at jeeadv[at]admin[dot]iitd[dot]ac[dot]in.
                  </h6>
              </div>
            </div>

      </div>
  </div> -->
  
</div>
<div class="row mb-3"></div>